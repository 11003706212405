export const modal = {
  state: {
    modalOpen: null,
    transform: true,
    cancel: false,
    SNNOrDLModal: false,
    showSNNorDLModal: false,
  },
  reducers: {
    toggleTransform: (state, bool) => ({
      ...state,
      transform: bool,
    }),
    toggleOpen: (state, param) => ({
      ...state,
      modalOpen: param,
    }),
    toggleCancel: (state, bool) => ({
      ...state,
      cancel: bool,
    }),
    toggleClose: (state, param) => ({
      ...state,
      modalOpen: param,
    }),
    saveCustomMessage: (state, customMessage) => ({
      ...state,
      customMessage,
    }),
    hasSSNOrDLModal: (state, bool) => ({
      ...state,
      SNNOrDLModal: bool,
    }),
    showSSNOrDLModal: (state, bool) => ({
      ...state,
      showSNNorDLModal: bool,
    }),
  },
};