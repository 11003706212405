export const NEW = 'new';
export const EDIT = 'edit';
export const EXISTING = 'existing';
export const PAYMENT_PLAN = 'paymentPlan';
export const PARTIAL_PAYMENT = 'partialPayment';
export const FULL_PAYMENT = 'fullPayment';
export const SUCCESS = 'success';
export const MONTHLY = 'monthly';
export const WEEKLY = 'weekly';
export const CHECK_OUT = 'checkOut';
export const DEFAULT = 'default';
export const DAY_OF_MONTH = 'dayOfMonth';
export const DAY_OF_WEEK = 'dayOfWeek';
export const PLAN_NAME = 'planName';
export const NUMBER_OF_MONTHS = 'numberOfMonths';
export const MONTHLY_PAYMENT = 'monthlyPayment';
export const CREDIT_CARD = 'creditCard';
export const PAYMENT_FREQUENCY = 'paymentFrequency';
export const PAYMENT_FREQUENCY_DISABLED = 'paymentFrequencyDisabled';
export const WEEKS = 'weeks';
export const MONTHS = 'months';
export const CASH = 'cash';
