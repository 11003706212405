import React, { useEffect, useState } from 'react';
import { Text } from '../../styleguide';

const HAS_EMPLOYER_STATUSES = ['Employed', 'Part Time'];

export default React.memo(props => {
  const {
    formData,
    onChange,
    schema,
    uiSchema,
    registry,
    registry: {
      fields: { SchemaField },
    },
    idSchema,
    errorSchema,
    required,
  } = props;

  const [employerFormData, setEmployerFormData] = useState(formData.employer);
  const [employmentStatusFormData, setEmploymentStatusFormData] = useState(formData.employmentStatus);
  const isEmployed = HAS_EMPLOYER_STATUSES.indexOf(employmentStatusFormData) !== -1;

  useEffect(() => {
    if (formData.employmentStatus !== employmentStatusFormData || formData.employer !== employerFormData) {
      onChange({
        employmentStatus: employmentStatusFormData,
        employer: isEmployed ? employerFormData : undefined,
      });
    }
  }, [employerFormData, employmentStatusFormData]);

  return (
    <>
      <Text tag="p" text={schema.title} type="subGroupTitle" />
      <SchemaField
        formData={employmentStatusFormData}
        schema={schema.properties.employmentStatus}
        uiSchema={uiSchema.employmentStatus}
        registry={registry}
        onChange={setEmploymentStatusFormData}
        idSchema={idSchema.employmentStatus}
        errorSchema={errorSchema.employmentStatus}
        required={required}
      />
      {isEmployed && (
        <SchemaField
          formData={employerFormData}
          schema={schema.properties.employer}
          uiSchema={uiSchema.employer}
          registry={registry}
          onChange={setEmployerFormData}
          idSchema={idSchema.employer}
          errorSchema={errorSchema.employer}
        />
      )}
    </>
  );
});
