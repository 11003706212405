export const myHealth = {
  state: {
    myHealth: [],
    hash: '#care_team',
    state: 'patient_app_myHealth',
    scrollPositions: [],
    nodes: [],
  },
  reducers: {
    fetchStart: (state, { myHealthScreen }) => ({
      ...state,
      myHealth: myHealthScreen,
    }),
    setHash: (state, hash) => ({
      ...state,
      hash,
    }),
    setHashPositions: (state, { scrollPositions, nodes }) => ({
      ...state,
      scrollPositions,
      nodes,
    }),
    refetch: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    clearStore: _ => ({ ...myHealth.state }),
  },
};
