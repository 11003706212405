import { ApolloClient, InMemoryCache } from 'apollo-client-preset';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-fetch';
import moment from 'moment';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { get, isObject } from '@carecloud/cloudpak';
import Environment from './Environment';
import { store } from '../models';
import mix from '../components/services/mixpanel';
import { DEVELOPMENT } from '../constants/Properties';

const uri =
  process.env.NODE_ENV === 'test'
    ? require('../../public/assets/config.json').BREEZE_API_GATEWAY
    : Environment.get('BREEZE_API_GATEWAY');

const errorLink = onError(error => {
  console.log('apoloerror', error);
  store.dispatch.mixpanel.addMetadata({ error });
  const { response, graphQLErrors } = error;
  if (response) {
    store.dispatch.error.addError(response);
    response.errors = null;
  }
  if (graphQLErrors && graphQLErrors.length) {
    const { message, name } = graphQLErrors[0];
    if (
      (process.env.DEPLOYMENT_ENV === 'development' || process.env.NODE_ENV !== 'production') &&
      name === 'Middleware Error'
    ) {
      const {
        data: { error },
      } = graphQLErrors[0];
      store.dispatch.errorPage.showMiddlewareErrorPage({
        middlewareErrorOccurred: true,
        errorMessage: message,
        errorSubMessage: error.toLowerCase().replace(/['"]+/g, ''),
      });
    } else if (name === 'RefreshTokenError' || message === 'The incoming token has expired.') {
      store.dispatch.clientSideModal.toggleOpen({ id: 'apolloErrorModal', message });
    } else if (message === 'Endpoint request timed out') {
      store.dispatch.errorPage.showErrorPage({ errorOccurred: true });
    } else {
      store.dispatch.toast.open({
        type: 'error',
        message,
      });
    }
    store.dispatch.loader.activateLoader({ loading: false });
    store.dispatch.loader.persistLoader({ persist: false });
    store.dispatch.button.polling({});
  } else {
    store.dispatch.errorPage.showErrorPage({ errorOccurred: true });
  }
});
const httpLink = createHttpLink({
  uri,
  fetchOptions: {
    introspection: false,
  },
  fetch,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    practiceSelected: sessionStorage.getItem('practiceSelected') || store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_id  || JSON.parse(sessionStorage.getItem('singleSelectedpractice')),
    practiceSelectedName: sessionStorage.getItem('practiceSelectedName') || store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_name,
    practiceSelectedMgmt: sessionStorage.getItem('practiceSelectedMgmt') || store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_mgmt,
    app: sessionStorage.getItem('CC-Breeze-Web-app'),
    username: sessionStorage.getItem('CC-Breeze-Web-username') || sessionStorage.getItem('CC-Breeze-Web-email'),
    authenticationToken: sessionStorage.getItem('CC-Breeze-Web-authenticationToken'),
    accessToken: sessionStorage.getItem('CC-Breeze-Web-accessToken'),
    refreshToken: sessionStorage.getItem('CC-Breeze-Web-refreshToken'),
    localTime: moment().format(),
    language: localStorage.getItem('CC-Breeze-Web-language') || 'en',
    delegateId: sessionStorage.getItem('CC-Breeze-Web-delegateId'),
    delegateFirstName: sessionStorage.getItem('CC-Breeze-Web-delegateFirstName'),
    delegateLastName: sessionStorage.getItem('CC-Breeze-Web-delegateLastName'),
    delegateEmail: sessionStorage.getItem('CC-Breeze-Web-delegateEmail'),
    userAgent: window.navigator.userAgent,
    'X-Content-Type-Options': 'nosniff',
    'X-Frame-Options': 'SAMEORIGIN',
    'X-XSS-Protection': '1; mode=block',
    'Strict-Transport-Security': 'max-age=2592000; includeSubDomains',
    'Content-Security-Policy': "default-src 'self'",
    ...(sessionStorage.getItem('CC-Breeze-Web-representedUserId')
      ? { representedUserId: sessionStorage.getItem('CC-Breeze-Web-representedUserId') }
      : {}),
  },
}));
const mixpanelLink = new ApolloLink((operation, forward) => {
  /* eslint-disable camelcase */
  const { operationName, variables } = operation;
  if (variables && variables.input) {
    const {
      practice_id,
      practiceId,
      patient_id: prepaymentPatientId,
      amount,
      paymentPlanId,
      cardId,
      cancellationComments,
      appointment: { patient: { id: patient_id } = {} } = {},
      patientId,
      appointmentId,
      checkoutIds,
      providerId,
      locationId,
    } = variables.input;
    const {
      addAppointment: {
        visitType: appointment_type,
        visitReason: visit_reason,
        prePayment: pre_payment,
        selectedProvider: { providerGuid } = {},
        locationGuid,
      } = {},
      layout: { metaData } = {},
      appointmentCheckIn: {
        appointment_type: checkinAppointmentTypeFromStore,
        provider_id: checkinProviderFromStore,
        location_id: checkinLocationIdFromStore,
      } = {},
      mixpanel: {
        nonActionableMetadata: { cancellationReason, checkinInProgress, appointmentCancellationVisitType } = {},
      } = {},
    } = store.getState();
    let practice = {};
    const finalPracticeId = practice_id || practiceId;
    if (metaData && metaData.practiceInformation && finalPracticeId) {
      practice = metaData.practiceInformation.find(practice => practice.practice_id === finalPracticeId) || {};
    }
    const { practice_name } = practice;
    const mixpanelActionMap = {
      RequestAppointmentInput: _ =>
        mix.requestAppointmentInput({
          practice_id: finalPracticeId,
          practice_name,
          appointment_type,
          provider_id: providerGuid,
          patient_id: patient_id || prepaymentPatientId,
          location_id: locationGuid,
          visit_reason,
          pre_payment,
          inCheckout: false,
        }),
      GetAppointmentPrepayment: _ => mixpanelActionMap.RequestAppointmentInput(),
      CancelAppointment: _ =>
        mix.cancelAppointment({
          practice_id: finalPracticeId,
          practice_name,
          cancellationComments,
          patient_id: patientId || variables.input.patient_id,
          provider_id: providerId || variables.input.provider_id,
          location_id: locationId || variables.input.location_id,
          appointment_type: appointmentCancellationVisitType,
          payment_amount: amount,
          cancellationReason,
        }),
      MakeCancellationPayment: _ => mixpanelActionMap.CancelAppointment(),
      CreateNewUser: _ => mix.newUserSignup(),
      createAccount: _ => mix.newUserSignup(),
      MakeOneTimePayment: _ => mix.oneTimePayment({ practiceId: finalPracticeId, amount, paymentPlanId, patientId }),
      MakePayment: _ => mix.paymentStarted({ amount, cardId }),
      GetAppointmentCheckIn: _ =>
        // this formShown is set after the getappointmentcheckin query is fired so if a user is landing on a checkin form other than infoCheckPage, formShown will === infoCheckPage
        checkinInProgress &&
        mix.checkinResumed({
          appointment_id: appointmentId,
          practice_id: practiceId,
          appointment_type: checkinAppointmentTypeFromStore,
          provider_id: checkinProviderFromStore,
          location_id: checkinLocationIdFromStore,
        }),
      ChangeEmail: _ => mix.updateEmailClicked(),
      ChangePassword: _ => mix.updatePasswordClicked(),
      updateDocuments: _ => mix.updateDocuments(),
      PostCheckInDemographics: _ => mix.updateDocuments(),
      updateDemographics: _ => mix.updateDocuments(),
      ToggleNotifications: _ => mix.updateNotifications(),
      makeCheckoutAppointment: _ =>
        mix.requestAppointmentInput({
          practice_name,
          appointment_type,
          provider_id: providerGuid,
          pre_payment,
          visit_reason,
          patient_id: checkoutIds.patient_id,
          practice_id: checkoutIds.practice_id,
          location_id: checkoutIds.location_guid,
          inCheckout: true,
        }),
    };
    const mixpanelAction = mixpanelActionMap[operationName];
    if (mixpanelAction) mixpanelAction();
  }
  return forward(operation).map(response => {
    mix.queryResponse({ operationName, variables });
    return response;
  });
  /* eslint-enable camelcase */
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    notifyOnNetworkStatusChange: true,
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};
const benchmarkingLink = new ApolloLink((operation, forward) => {
  const { operationName } = operation;
  store.dispatch.benchmarking.setStartTime({ operationName, time: moment() });
  return forward(operation).map(response => {
    if (isObject(response)) {
      const extractedCallName = Object.keys(response.data)[0] || String();
      const innerKey = get(response, ['data', [extractedCallName]], {});
      if (!isObject(innerKey)) return response;
      innerKey.whatRequestAmI = extractedCallName;
    }
    return response;
  });
});

const client = new ApolloClient({
  link: ApolloLink.from([
    ...(process.env.NODE_ENV === DEVELOPMENT ? [benchmarkingLink] : []),
    mixpanelLink,
    authLink,
    errorLink,
    httpLink,
  ]),
  cache: new InMemoryCache(),
  defaultOptions,
});

export default client;
