import { isString } from '@carecloud/cloudpak';
import React from 'react';
import classNames from 'classnames';
import styles from './paymentPlan.module.scss';
import { formatMoney } from '../../services/utilities';
import { SchemaParser } from '../../services';
import * as Payments from '../../../constants/Payments';

const paymentPlan = React.memo(
  ({
    selectExistingPlan,
    currentPlan,
    paymentType,
    monthlyPayment,
    numberOfMonths,
    updatePlan,
    selectNewPaymentPlan,
    dayOfDropdown,
    planName,
    monthError,
    paymentError,
    frequencyDropdown,
    planDisplay,
    text,
    editPaymentPlan,
    existingPlanName,
    disableInput,
    terms,
    settings,
    paymentAmount,
    paymentFrequency,
    weeklyDropdown,
    amountEntered,
    blankInputs,
    disableFrequency,
    chosenRule,
    belowMin,
    selectNew,
    displayExistingPlans,
    clickedOnAdd,
    disableAdd,
    verifiedPlans,
    displayInput,
    minMax,
    lastPaymentAmount,
  }) =>
    paymentType === 'paymentPlan' && (
      <div className={styles.paymentPlanContainer}>
        {paymentAmount && editPaymentPlan && (
          <SchemaParser schema={[paymentAmount]} />
        )}
        {amountEntered && (
          <div>
            {verifiedPlans && verifiedPlans.length > 0 && (
              <div className={styles.existingPlanNotice}>
                {text.itSeemsYouHaveSome} <b>{text.existingPaymentPlans}</b>{' '}
                <div>{text.howDoYouWantToProceed}</div>
              </div>
            )}
            {editPaymentPlan &&
              verifiedPlans &&
              verifiedPlans.length > 0 &&
              settings.can_create_multiple_plans &&
              verifiedPlans.length >= 1 &&
              !belowMin &&
              +displayInput.replace(/[^\d.]/g, '') >= minMax.min && (
                <label htmlFor="new">
                  <div className={styles.radioContainer}>
                    <input
                      type="radio"
                      className={styles.radioInput}
                      onClick={e => selectNewPaymentPlan({ e })}
                      id="new"
                      name="option"
                      value="new"
                      defaultChecked
                      checked={selectNew && !clickedOnAdd}
                    />
                    <div className={styles.labelContainer}>
                      <div>{text.createNew}</div>
                    </div>
                  </div>
                </label>
              )}
            {verifiedPlans && verifiedPlans.length > 0 && (
              <label htmlFor="addToPlan">
                <div className={styles.addToPlanContainer}>
                  <input
                    type="radio"
                    className={styles.radioInput}
                    onClick={() => displayExistingPlans()}
                    id="addToPlan"
                    name="option"
                    value="addToPlan"
                    disabled={disableAdd}
                  />
                  <div className={styles.labelContainer}>
                    <div
                      className={classNames({ [styles.disabled]: disableAdd })}
                    >
                      {text.addToExistingPlan}
                    </div>
                  </div>
                </div>
              </label>
            )}
            <div className={styles.existingPlans}>
              {verifiedPlans &&
                clickedOnAdd &&
                verifiedPlans.map(plan => (
                  <div
                    className={classNames(styles.currentPlan, {
                      [styles.unselectablePlan]: plan?.planCardExpired,
                    })}
                  >
                    <label htmlFor={plan.id}>
                      <div className={styles.existingPlanContainer}>
                        <input
                          type="radio"
                          className={styles.radioInput}
                          onClick={e => selectExistingPlan({ e, plan })}
                          id={plan.id}
                          name="option"
                          value="existing"
                          key={plan.id}
                          disabled={plan?.planCardExpired}
                        />
                        <div className={styles.labelContainer}>
                          <div>
                            {plan.planName
                              ? `${text.addToExistingPlan} "${plan.planName}"`
                              : text.addToExistingPlan}
                          </div>
                        </div>
                      </div>
                    </label>
                    <div className={styles.planName}>{plan.practice}</div>
                    <div className={styles.planDetails}>
                      <div className={styles.left}>
                        <div className={styles.leftLabelContainer}>
                          <div>{text.total}:</div>
                          <div>{text.plan}:</div>
                        </div>
                        <div>
                          <div>
                            $
                            {currentPlan && currentPlan.id === plan.id
                              ? formatMoney({ amount: planDisplay.total })
                              : formatMoney({ amount: plan.total })}
                          </div>
                          <div>
                            {`$${formatMoney({
                              amount:
                                currentPlan && currentPlan.id === plan.id
                                  ? planDisplay.monthlyPayment
                                  : plan.monthlyPayment,
                            })} ${
                              plan.frequencyCode === Payments.MONTHLY
                                ? text.month
                                : text.week
                            }`}
                          </div>
                        </div>
                      </div>
                      <div className={styles.right}>
                        <div>
                          <span>{text.paid}:</span> $
                          {formatMoney({ amount: plan.paid })}
                        </div>
                        <div>
                          <span>{text.pending}:</span> $
                          {`${
                            currentPlan && currentPlan.id === plan.id
                              ? formatMoney({ amount: planDisplay.pending })
                              : formatMoney({ amount: plan.total - plan.paid })
                          } (${plan.installmentPaid} ${text.of} ${
                            plan.installments
                          } 
                ${text.installments})`}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <h3 className={styles.choosePayment}>{text.paymentPlanDetails}</h3>
            {chosenRule && (
              <div className={styles.paymentDescription}>
                {text.currentBalance}
                <b>
                  {(paymentFrequency === Payments.WEEKLY
                    ? ` ${text.maximumOfWeekly} `
                    : ` ${text.maximumOf} `
                  ).replace('%1$s', chosenRule.max_duration.value)}
                </b>
                {text.andRequiresA}
                <b>
                  {(paymentFrequency === Payments.WEEKLY
                    ? ` ${text.minimumPaymentOfWeekly}`
                    : ` ${text.minimumPaymentOf}`
                  ).replace(
                    '%2$s',
                    `$${formatMoney({
                      amount: chosenRule.minimum_payment_required.value,
                    })}`,
                  )}
                </b>
              </div>
            )}
            <h4 className={styles.detailHeader}>{text.paymentPlanName}</h4>
            <div className={styles.inputContainer}>
              <input
                className={styles.planNameInput}
                id="planName"
                type="text"
                placeholder={text.planNamePlaceholder}
                onChange={updatePlan}
                value={
                  planName || isString(planName) ? planName : existingPlanName
                }
              />
              <span className={styles.optional}>{text.optional}</span>
            </div>
            <div className={styles.detailContainer}>
              <div
                className={classNames(styles.monthContainer, {
                  [styles.frequencyDisabled]: disableFrequency,
                })}
              >
                <h4 className={styles.detailHeader}>{text.frequency}</h4>
                {frequencyDropdown && (
                  <SchemaParser schema={[frequencyDropdown]} />
                )}
              </div>
              <div className={styles.dateContainer}>
                <h4 className={styles.detailHeader}>
                  {paymentFrequency === Payments.WEEKLY
                    ? text.dayOfWeek
                    : text.dayOfMonth}
                </h4>
                {dayOfDropdown && (
                  <SchemaParser
                    schema={[
                      paymentFrequency === Payments.WEEKLY
                        ? weeklyDropdown
                        : dayOfDropdown,
                    ]}
                  />
                )}
              </div>
            </div>
            <div className={styles.detailContainer}>
              <div className={styles.monthContainer}>
                <h4 className={styles.detailHeader}>
                  {paymentFrequency === Payments.WEEKLY
                    ? text.numberOfWeeks
                    : text.numberOfMonths}
                </h4>
                <input
                  id="numberOfMonths"
                  className={classNames(styles.monthsNumber, {
                    [styles.disabled]: disableInput,
                  })}
                  onChange={updatePlan}
                  type="text"
                  value={blankInputs ? '' : numberOfMonths}
                  disabled={disableInput}
                />
              </div>
              <div className={styles.dateContainer}>
                <h4 className={styles.detailHeader}>
                  {paymentFrequency === Payments.WEEKLY
                    ? text.weeklyPayment
                    : text.monthlyPayment}
                </h4>
                <input
                  id="monthlyPayment"
                  onChange={updatePlan}
                  className={classNames(styles.monthsNumber, {
                    [styles.disabled]: disableInput,
                  })}
                  type="text"
                  value={
                    blankInputs
                      ? ''
                      : `$${formatMoney({ amount: monthlyPayment })}`
                  }
                  disabled={disableInput}
                />
              </div>
            </div>
            <div />
            {monthError && <div className={styles.error}>{monthError} </div>}
            {paymentError && (
              <div className={styles.error}>{paymentError} </div>
            )}
            {text.disabledInput && disableInput && (
              <div className={styles.paymentDescription}>
                {text.disabledInput}
              </div>
            )}
            {!!lastPaymentAmount && (
              <div className={styles.lastPaymentAdjustment}>
                {' '}
                {text.lastPaymentAdjustment} ${lastPaymentAmount}
              </div>
            )}
            {terms && <SchemaParser schema={[terms]} />}
          </div>
        )}
      </div>
    ),
);

export const PaymentPlan = paymentPlan;
