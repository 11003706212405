export const appointmentCheckIn = {
  state: { appointmentCheckIn: [], Bit:false, formId: null},
  reducers: {
    fetchStart: (state, { appointmentCheckInScreen, queryString, formId, thirdPartyRedirect }) => ({
      ...state,
      appointmentCheckIn: appointmentCheckInScreen,
      queryString,
      formId,
      thirdPartyRedirect,
    }),
    storeAppointmentData: (state, data) => ({
      ...state,
      ...data,
    }),
    formValid: (state, data) => ({
      ...state,
      ...data,
    }),
    formId:(state, data) => ({
      ...state,
      ...data,
    }),
    TakingIntakeformBit: (state, bool) => ({
      ...state,
      Bit: bool,
    }),
    resetState: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    storeFindings: (state, findingsId) => ({ ...state, findingsId }),
    storeRedirectPath: (state, path) => ({ ...state, path }),
    paymentPlanCreated: (state, paymentPlanCreated) => ({
      ...state,
      paymentPlanCreated,
    }),
    resetPaymentPlanCreated: state => ({ ...state, paymentPlanCreated: false }),
    clearStore: _ => ({ ...appointmentCheckIn.state }),
  },
};
