import React from 'react';
import styles from './InputField.module.scss';

export default React.memo(props => {
  const { disabled, name, onChange = null, placeholder, value, password, stopCopy, stopPaste } = props;

  const handleChange = event => {
    let value = (event?.target?.value || '').trimLeft(' ');
    // replaces em dash in iOS
    if (value.includes('—')) {
      value = value.replace('—', '--');
    }
    // smart punctuations
    value = value.replace(/[\u2018\u2019]/g, "'");
    value = value.replace(/[\u201C\u201D]/g, '"');
    onChange({
      target: {
        name,
        value,
      },
    });
  };

  const prevent = e => e.preventDefault();

  return (
    <input
      className={styles.input}
      disabled={disabled}
      name={name}
      onChange={onChange && handleChange}
      placeholder={placeholder}
      value={value}
      type={password && 'password'}
      onCopy={stopCopy && prevent}
      onPaste={stopPaste && prevent}
    />
  );
});
