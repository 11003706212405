import React, { Component } from 'react';
import { compose, isEmpty, has } from '@carecloud/cloudpak';
import { ContentPage } from '..';
import { Redirect, withRouter } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { GetShop } from '../../../graphql/queries';
import { store } from '../../../models';
import { breakBackButton } from '../../services/utilities';

import { parseResult } from '../../../graphql/utilities';

class ShopContainer extends Component {
  componentDidMount() {
    breakBackButton();
  }
  componentWillReceiveProps({ shop, getShop, fetchStart, activateLoader, loader: { loading } }) {
    const queryLoading = getShop && getShop.loading;
    if (!queryLoading && loading && !has(shop.shopInfo, 'shopId')) {
      activateLoader({ loading: false });
    }
    if (isEmpty(shop.shop) && !queryLoading) {
      parseResult({ query: getShop, dataField: 'getShop', resultHandler: fetchStart });
    }
  }

  componentWillUnmount() {
    const { activateLoader } = this.props;
    store.dispatch.shop.resetState();
    activateLoader({ loading: true });
  }

  render() {
    const {
      shop: { shop, shopInfo },
      loader: { loading },
    } = this.props;
    if (shop && shopInfo) {
      window._environment = window._environment || {};
      window._environment.ecwid_host = shopInfo.ecwid_host;
      window._environment.ecwid_api = shopInfo.ecwid_api;
      window._environment.ecwid_key = shopInfo.ecwid_key;
      window._environment.ecwid_channel_id = shopInfo.ecwid_channel_id;
      window.ecwid_script_defer = true;
      window.ecwid_dynamic_widgets = true;
      window.ecwid_sso_profile = shopInfo.sso;
      if (has(shopInfo, 'shopId')) return <Redirect to={`shop/${shopInfo.shopId}?practice_id=${shopInfo.practiceId}`} />;
    }
    return shop && <ContentPage schemas={shop} loading={loading} />;
  }
}
const mapStateToProps = ({ shop, shopInfo, loader }) => ({ shop, shopInfo, loader });
const mapDispatchToProps = ({ shop, loader }) => ({
  fetchStart: shop.fetchStart,
  activateLoader: loader.activateLoader,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(GetShop, { name: 'getShop', skip: ({ shop }) => has(shop.shopInfo, 'shopId') }),
);
export default enhancer(ShopContainer);
