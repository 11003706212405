import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { compose, get } from '@carecloud/cloudpak';
import validateFormData from 'react-jsonschema-form/lib/validate';
import * as Mutations from '../../../graphql/mutations';
import { Form } from '../../rjsf';
import { store } from '../../../models';
import ProfileEmergencyContact from '../profile/EmergencyContact';
import ProfileEmploymentInfo from '../profile/EmploymentInfo';
import ProfileInsurance from '../profile/Insurance';
import ProfilePhysician from '../profile/Physician';
import ContentHeader from './ContentHeader';
import { checkDateOfBirth } from '../../commonjs';

const customFields = {
  ProfileEmergencyContact,
  ProfileEmploymentInfo,
  ProfileInsurance,
  ProfilePhysician,
};

const enableUpdateButton = complete =>
  store.dispatch.button.enableButton({
    btn: 'nextStepBtn',
    complete,
  });

const transformIdentity = identity => ({
  identityDocumentPhotos: [
    {
      delete: !identity.frontPhoto,
      page: 1,
      identityDocumentPhoto: identity.frontPhoto,
    },
    {
      delete: !identity.backPhoto,
      page: 2,
      identityDocumentPhoto: identity.backPhoto,
    },
  ],
});

const transformInsurance = insurance =>
  insurance.map(item => {
    const payload = {
      insuranceId: Number.isInteger(item.id) ? null : item.id,
      insurancePhotos: [
        {
          delete: !item.frontPhoto,
          page: 1,
          insurancePhoto: item.frontPhoto,
        },
        {
          delete: !item.backPhoto,
          page: 2,
          insurancePhoto: item.backPhoto,
        },
      ],
      insuranceProvider: item.providerCustomName || item.providerName,
      insurancePlan: item.planCustomName || item.planName,
      insuranceMemberId: item.memberId,
      insuranceGroupId: item.groupNumber,
      insuranceType: item.type,
      effectiveFrom: item.effectiveFrom,
      relationship: item.relationshipType,
      policyHolderFirstName: item.policyHolderFirstName,
      policyHolderMiddleName: item.policyHolderMiddleName,
      policyHolderLastName: item.policyHolderLastName,
      policyHolderGender: item.policyHolderGender,
      policyHolderDateOfBirth: item.policyHolderDateOfBirth,
      delete: item.delete,
    };

    return payload;
  });

const transformFormData = formData => {
  const data = {
    checkInIds: formData.checkInIds,
    additionalInfo: formData.additionalInfo,
    demographics: {
      address: formData.address,
      ...formData.details,
      personalDetails: {
        ...formData.name,
        ...formData.details.personalDetails,
      },
    },
  };

  if (formData.identityDocument) {
    data.demographics.identityDocument = transformIdentity(formData.identityDocument);
  }

  if (formData.insurances) {
    data.demographics.insurances = transformInsurance(formData.insurances);
  }

  return data;
};

export const Component = React.memo(({ client, formData, schema, submit, uiSchema, history }) => {
  const [form, setForm] = useState({ formData });
  const [fetchZipcode, setFetchZipcode] = useState(false);
  const {
    details: {
      personalDetails: { dateOfBirth },
    },
  } = form.formData;
  const formContext = useMemo(() => ({ errors: [] }), []);
  const { errors } = validateFormData(form.formData, schema);
  const isFormValid =
    !checkDateOfBirth({ inputDates: dateOfBirth }) &&
    (!Array.isArray(errors) || errors.length < 1) &&
    formContext.errors.length < 1;

  const fetchDataAgainstZipcode = async () => {
    if (form.formData?.address?.zipcode?.length === 5) {
      try {
        const zipcode = form.formData?.address?.zipcode;
        const { data } = await client.mutate({
          mutation: Mutations.getZipcodeInfo,
          variables: {
            zipcode,
          },
        });
        const { getZipcodeInfo } = data;
        const { lookups } = getZipcodeInfo;
        const {
          0: { result },
        } = lookups;
        const dataForm = JSON.parse(JSON.stringify(form));
        if (!result[0].valid) {
          dataForm.formData.address.state = '';
          dataForm.formData.address.city = '';
          enableUpdateButton(false);
        } else {
          dataForm.formData.address.state = result[0].cities[0].stateAbbreviation;
          dataForm.formData.address.city = result[0].zipcodes[0].defaultCity;
        }
        setForm(dataForm);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSubmit = async () => {
    store.dispatch.modal.showSSNOrDLModal(false);
    if (store.getState().modal.modalOpen === 'confirmSSNOrDLModal') {
      store.dispatch.modal.toggleClose('');
      store.dispatch.loader.show();
    }
    const { fetchStart } = store.dispatch.appointmentCheckIn;
    store.dispatch.loader.show();
    enableUpdateButton(false);
    const { data } = await client.mutate({
      mutation: Mutations.infoCheckPage,
      variables: {
        input: transformFormData(form.formData),
      },
    });
    if (get(data, 'postCheckInDemographics.submissionResult.success')) {
      store.dispatch.form.toggleSubmit();
      store.dispatch.mixpanel.addMetadata({ successRedirect: true });
      store.dispatch.appointments.resetState();
      history.push('/appointments');
    } else if (data.postCheckInDemographics !== null) {
      store.dispatch.form.toggleSubmit();
      fetchStart(data.postCheckInDemographics);
    } else {
      store.dispatch.form.toggleSubmit();
      enableUpdateButton(true);
    }
    store.dispatch.loader.hide();
  };
  useEffect(() => {
    if (!fetchZipcode) {
      setFetchZipcode(true);
      return;
    }
    fetchDataAgainstZipcode();
  }, [form.formData?.address?.zipcode]);
  const isInsurancePitureRequired = formData?.identityDocument?.PICTURE_INSURANCE_CARD;
     const front= form.formData?.insurances?.map(insurance => insurance.frontPhoto)?.[0];
     const back =  form.formData?.insurances?.map(x  => x.backPhoto)?.[0];
     store.dispatch.settings.insuranceInfo(isInsurancePitureRequired);
    const IsDocumentRequired = formData?.identityDocument?.required
      const RequiredIdentityPiture = form.formData?.identityDocument?.frontPhoto
    useEffect(() => {
      if ( isFormValid && form.formData?.address?.state && form.formData?.address?.city && (IsDocumentRequired? RequiredIdentityPiture !=="" :true)&& (isInsurancePitureRequired? front !==""&& back !=="":true)) {
        enableUpdateButton(true);
      } else {
        enableUpdateButton(false);
      }
      // enableUpdateButton(isFormValid);
    }, [isFormValid, form.formData?.address?.state, form.formData?.address?.city,RequiredIdentityPiture,front,back]);

  useEffect(() => {
    if (
      !transformFormData(form.formData)?.demographics?.personalDetails?.driversLicenseNumber &&
      !transformFormData(form.formData)?.demographics?.personalDetails?.ssn
    ) {
      store.dispatch.modal.showSSNOrDLModal(true);
    } else {
      store.dispatch.modal.showSSNOrDLModal(false);
    }
  }, [
    transformFormData(form.formData)?.demographics?.personalDetails?.driversLicenseNumber,
    transformFormData(form.formData)?.demographics?.personalDetails?.ssn,
  ]);

  if (submit) {
    handleSubmit();
  }
  const [modifiedSchema, setModifiedSchema] = useState({ ...schema });
  useEffect(() => {
    const updatedSchema = { ...modifiedSchema };
    store.dispatch.settings.insuranceInfo(isInsurancePitureRequired)
    if (formData.insurances !== undefined && isInsurancePitureRequired === true) {
      updatedSchema.properties.insurances.items.labels.imageFront = (
        <>{"Front"}<span style={{ color: 'red' }}>*</span></>
      );
      updatedSchema.properties.insurances.items.labels.imageBack = (
        <>{"Back"}<span style={{ color: 'red' }}>*</span></>
      );
    } else if (formData.insurances !== undefined && isInsurancePitureRequired === false) {
      updatedSchema.properties.insurances.items.labels.imageFront = "Front";
      updatedSchema.properties.insurances.items.labels.imageBack = "Back";
    }

    setModifiedSchema(updatedSchema);
  }, [formData.insurances, isInsurancePitureRequired]);

  return (
    <div>
      <ContentHeader title={schema.title} description={schema.description} icon={isFormValid} />
      <Form
        customFields={customFields}
        formContext={formContext}
        formData={form.formData}
        onChange={setForm}
        schema={modifiedSchema}
        uiSchema={uiSchema}
      />
    </div>
  );
});

const mapStateToProps = ({ form: { submit } }) => ({ submit });

export default compose(withApollo, connect(mapStateToProps))(Component);