import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { isEmpty, compose } from '@carecloud/cloudpak';
import { store } from '../../../models';
import { ContentPage } from '..';
import { GetFormHistory } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { breakBackButton } from '../../services/utilities';
import { formHistoryContext as FormHistoryContext } from '../../../contexts';

class FormHistoryContainer extends Component {
  componentDidMount() {
    breakBackButton();
  }

  componentWillReceiveProps({
    formHistory,
    getFormHistory,
    fetchStart,
    activateLoader,
    creditCard: { processing },
    loader: { loading },
  }) {
    if (isEmpty(formHistory.formHistory) && !getFormHistory.loading) {
      parseResult({
        query: getFormHistory,
        dataField: 'getFormHistory',
        resultHandler: fetchStart,
      });
    }
    if (!getFormHistory.loading && loading && !processing) {
      activateLoader({ loading: false });
    }
  }

  componentWillUnmount() {
    const { activateLoader } = this.props;
    activateLoader({ loading: true });
  }
  addFormsToSign = item => {
    const { formsToSign, appointmentProvider: id } = this.props.formHistory;
    const forms = formsToSign[id];
    if (forms && forms.includes(item.param)) {
      forms.splice(forms.indexOf(item.param), 1);
    } else if (forms && !item.isInitial) {
      forms.push(item.param);
    }
    store.dispatch.formHistory.addForms({ forms, id });
  };
  render() {
    const {
      formHistory: { formHistory },
      loader: { loading },
    } = this.props;
    return (
      formHistory && (
        <FormHistoryContext.Provider
          value={{ addFormsToSign: this.addFormsToSign }}
        >
          <ContentPage schemas={[formHistory]} loading={loading} />
        </FormHistoryContext.Provider>
      )
    );
  }
}
const mapStateToProps = ({ formHistory, loader, creditCard }) => ({
  formHistory,
  loader,
  creditCard,
});
const mapDispatchToProps = ({ formHistory, loader }) => ({
  fetchStart: formHistory.fetchStart,
  activateLoader: loader.activateLoader,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GetFormHistory, { name: 'getFormHistory' }),
);
export default enhancer(FormHistoryContainer);
