export const phoneNumber = value => {
  if (!value) {
    return '';
  }

  let displayValue = value.replace(/[^\d]+/g, '');
  displayValue = displayValue.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

  return `+1 ${displayValue}`;
};

export const zipCode = value => {
  if (!value) {
    return '';
  }

  let displayValue = value.replace(/^[0-9]{5}(-[0-9]{4})?$/, '');
  displayValue = displayValue.replace(/(\d{5})(\d{4})/, '$1-$2');

  return displayValue;
};
