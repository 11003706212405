import { Image } from '..';
import { NavLink, useLocation } from 'react-router-dom';
import { isEmpty } from '@carecloud/cloudpak';
import React from 'react';
import classNames from 'classnames';
import scrollToElement from 'scroll-to-element';
import styles from './nav_icon.module.scss';
import { _BLANK, _PARENT } from '../../../constants/Properties';
import { store } from '../../../models';

const navIcon = React.memo(({ id, title, alt, href, src, target = '_self', myHealth: { myHealth, hash } = {}, activeClass = '' }) => {
  const location = useLocation();
  const externalLinks = [_BLANK, _PARENT];
  const isActive = (location.pathname.includes(href) && activeClass==='active')
  return !externalLinks.includes(target) ? (
    <NavLink
      exact
      to={href}
      className={classNames(styles.block, (id.includes('sidebarItemIcon') || id.includes('quickAction') || id.includes('careTeamBtn')) && styles.noUnderline, { active: hash === href })}
      onClick={() => {
        if (!isEmpty(myHealth) && window.location.pathname.includes('my_health') && href.includes('#')) {
          store.dispatch.myHealth.setHash(href);
          scrollToElement(href, { offset: -300 });
        }
      }}
      target={target}
    >
      <div id={id} className={classNames(styles[id], isActive ? activeClass : null)}>
        {isActive ?   
          <Image {...{ src: `${src.slice(0, src.length-4)}-breeze.svg`, alt }} /> :
          <Image {...{ src, alt }} />
        }
        {(id.includes('sidebarItemIcon')) ? 
          <p>{title}</p> :
          <span className={styles.tooltip}>{title}</span>
        }
      </div>
    </NavLink>
  ) : (
    <a
      href={href}
      className={classNames(styles.block, { active: hash === href })}
      onClick={() => {
        if (!isEmpty(myHealth) && window.location.pathname.includes('my_health') && href.includes('#')) {
          store.dispatch.myHealth.setHash(href);
          scrollToElement(href, { offset: -300 });
        }
      }}
      target={target}
    >
      <div id={id} className={styles[id]}>
        <Image {...{ src, alt }} />
        <span className={styles.tooltip}>{title}</span>
      </div>
    </a>
  );
});

export const NavIcon = navIcon;
