export const surveys = {
  state: {
    surveysTopLeftHeader: [],
    practiceName: String(),
    surveysSelection: [],
    surveysHeading: [],
    survey: [],
    surveys: [],
    surveyData: Object(),
    singleSurveyView: false,
    textFeedback: String(),
    checkoutSurvey: false,
    guestSurvey: false,
    guestSurveyCtx: Object(),
  },
  reducers: {
    fetchStart: (
      state,
      { surveysSelection, surveysHeading, survey, surveys, surveysTopLeftHeader, guestSurveyCtx },
    ) => ({
      ...state,
      surveysTopLeftHeader,
      surveysSelection,
      surveysHeading,
      survey,
      surveys,
      guestSurveyCtx,
    }),
    selectSurvey: (state, { selectedSurveyCard, selectedSurvey, practiceName }) => ({
      ...state,
      survey: [selectedSurvey],
      surveysSelection: [selectedSurveyCard],
      practiceName,
      singleSurveyView: true,
    }),
    storeSettingsAndData: (
      state,
      {
        param: questionId,
        number: questionRating,
        belowThreshold = null,
        satisfiedButNotShare = null,
        uuid,
        version,
        appointmentId,
        patientId,
        practiceMgmt,
        practiceId,
        averageRating,
        surveyType,
        surveyHash,
        userId,
      },
    ) => ({
      ...state,
      surveyData: {
        ...state.surveyData,
        questionsAndAnswers: {
          ...state.surveyData.questionsAndAnswers,
          ...(questionId ? { [questionId]: questionRating } : {}),
        },
        belowThreshold,
        satisfiedButNotShare,
        uuid,
        version,
        ...(!state.surveyData.appointmentId ? { appointmentId } : {}),
        ...(!state.surveyData.patientId ? { patientId } : {}),
        ...(!state.surveyData.practiceMgmt ? { practiceMgmt } : {}),
        ...(!state.surveyData.practiceId ? { practiceId } : {}),
        averageRating,
        surveyType,
        surveyHash,
        userId,
      },
    }),
    storeTextFeedback: (state, { textAreaValue: textFeedback }) => ({
      ...state,
      textFeedback,
    }),
    setSurveysNextScreen: (state, { surveyNext }) => ({
      ...state,
      survey: [surveyNext],
    }),
    clearStore: state => ({
      ...state,
      surveysTopLeftHeader: [],
      practiceName: String(),
      surveysSelection: [],
      surveysHeading: [],
      survey: [],
      surveys: [],
      surveyData: Object(),
      singleSurveyView: false,
      textFeedback: String(),
      refetch: true,
      checkoutSurvey: false,
      guestSurvey: false,
      guestSurveyCtx: Object(),
    }),
    refetch: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    setCheckoutSurvey: state => ({ ...state, checkoutSurvey: true, singleSurveyView: true }),
    setGuestSurvey: state => ({ ...state, guestSurvey: true, singleSurveyView: true }),
    clearGuestMobileSurveyHeader: state => ({
      ...state,
      surveysTopLeftHeader: [],
      guestSurveyCtx: Object(),
      surveysSelection: [],
    }),
  },
};
