import { compose, isEmpty } from '@carecloud/cloudpak';
import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { store } from '../../../models';
import { GetSurveys } from '../../../graphql/queries';
import { GetSurveysNext } from '../../../graphql/mutations';
import { Surveys } from './Surveys';
import { isMobileDevice, breakBackButton } from '../../services/utilities';
import { parseParams } from '../../services/routing';
import { parseResult } from '../../../graphql/utilities';
import { surveysContext as SurveysContext } from '../../../contexts';

class SurveysContainer extends Component {
  state = { guestMobileNext: false, surveyInterruptible: false };
  componentDidMount() {
    if (this.props.match.url.includes('guest')) {
      store.dispatch.loader.persistLoader({ persist: true });
      store.dispatch.surveys.setGuestSurvey();
      this.setGuestSurveyScreen();
    }
    if (store.getState().surveys.checkoutSurvey) {
      const {
        practice_id,
        provider_guid: provider_id,
        location_guid: location_id,
      } = store.getState().appointmentCheckOut;
      store.dispatch.appointments.resetState();
      store.dispatch.mixpanel.addMetadata({
        surveyStarted: true,
        is_guest_mode: false,
        location_id,
        practice_id,
        provider_id,
        access_mode: 'Checkout',
      });
    }
    if (!store.getState().surveys.guestSurvey)
      this.setState({ surveyInterruptible: true });
    breakBackButton();
  }

  componentWillReceiveProps({
    surveys: { surveysSelection, guestSurvey, refetch },
    getSurveys,
    fetchStart,
    activateLoader,
    loader: { loading },
  }) {
    const queryLoading = getSurveys && getSurveys.loading;
    if (refetch && !queryLoading) {
      store.dispatch.loader.persistLoader({ persist: true });
      this.refetch();
      store.dispatch.surveys.didRefetch();
      return;
    }
    if (!queryLoading && loading) {
      activateLoader({ loading: false });
    }
    if (isEmpty(surveysSelection) && !queryLoading && !guestSurvey) {
      parseResult({
        query: getSurveys,
        dataField: 'getSurveys',
        resultHandler: fetchStart,
      });
    }
  }
  componentDidUpdate() {
    const {
      location,
      surveys: { singleSurveyView },
    } = this.props;
    if (location.pathname === '/surveys' && singleSurveyView)
      return store.dispatch.surveys.clearStore();
  }
  componentWillUnmount() {
    if (
      this.state.surveyInterruptible &&
      !store.getState().surveys.guestSurvey
    ) {
      if (!store.getState().surveys.checkoutSurvey)
        store.dispatch.mixpanel.addMetadata({ surveyCancelled: true });
      else {
        store.dispatch.mixpanel.addMetadata({
          checkoutCancelled: true,
          checkoutLastStep: 'Surveys',
        });
      }
    }
    this.props.activateLoader({ loading: true });
  }

  setGuestSurveyScreen = async () => {
    const {
      survey_hash: surveyHash = null,
      user_id: userId = null,
    } = parseParams(this.props);
    const {
      data: {
        getSurveys: {
          surveysSelection,
          surveys,
          surveysHeading,
          surveyAlreadyComplete,
          guestSurveyCtx = String(),
        },
      },
    } = await this.props.client.query({
      query: GetSurveys,
      variables: { input: { surveyHash, userId, guestSurvey: true } },
    });
    store.dispatch.surveys.fetchStart({
      surveysSelection,
      survey: surveyAlreadyComplete || surveys,
      surveysHeading,
      guestSurveyCtx,
    });
    store.dispatch.loader.persistLoader({ persist: false });
    store.dispatch.mixpanel.addMetadata({
      surveyStarted: true,
      is_guest_mode: true,
      practice_id: guestSurveyCtx.mixpanelMetaData.practice_id,
      location_id: guestSurveyCtx.mixpanelMetaData.location_id,
      provider_id: guestSurveyCtx.mixpanelMetaData.provider_id,
      access_mode: 'Standard',
    });
  };

  getSurveyNext = async params => {
    const inGuestMode = store.getState().surveys.guestSurvey;
    const inCheckout = store.getState().surveys.checkoutSurvey;
    const {
      socialNetworkLinks = [],
      postWithNegativeFeedback = false,
      enable: enableSocialSharing,
      withGoBack = false,
      redirectToCheckout = false,
      ignoreFeedbackText = false,
    } = params;
    if (withGoBack) return this.goBackToSurveys();
    if (!postWithNegativeFeedback) this.setInRedux(params);
    store.dispatch.loader.persistLoader({ persist: true });
    const {
      data: {
        getSurveysNext: {
          surveyNext,
          continueCheckoutData = null,
          status = null,
        },
      },
    } = await this.props.client.mutate({
      mutation: GetSurveysNext,
      variables: {
        input: {
          socialNetworkLinks,
          postWithNegativeFeedback,
          surveyData: {
            ...store.getState().surveys.surveyData,
            ...(ignoreFeedbackText
              ? {}
              : { feedback: store.getState().surveys.textFeedback }),
          },
          enableSocialSharing,
          inCheckout,
          inGuestMode,
          redirectToCheckout,
        },
      },
    });
    if (status === 200) {
      const {
        surveyData: { belowThreshold, averageRating } = {},
      } = store.getState().surveys;
      store.dispatch.mixpanel.addMetadata({
        surveyComplete: { belowThreshold, rating_given: averageRating },
      });
      this.setState({ surveyInterruptible: false });
    }
    const availableContinueCheckoutData =
      store.getState().appointmentCheckOut.externalCheckoutData ||
      continueCheckoutData;
    availableContinueCheckoutData &&
      store.dispatch.appointmentCheckOut.setExternalCheckoutData({
        externalCheckoutData: availableContinueCheckoutData,
      });
    if (redirectToCheckout) return this.successRedirect();

    store.dispatch.surveys.setSurveysNextScreen({ surveyNext });
    // inguest and mobile? handle alt ui
    if (inGuestMode && isMobileDevice()) {
      store.dispatch.surveys.clearGuestMobileSurveyHeader({});
      this.setState({ guestMobileNext: true });
    }
    store.dispatch.loader.persistLoader({ persist: false });
  };

  setInRedux = params => {
    const {
      NetworkLinksRatingThreshold,
      ratingThreshold,
      questions,
      uuid,
      version,
      appointmentId,
      patientId,
      practiceId,
      practiceMgmt,
      surveyType,
    } = params;
    const {
      surveyData: { questionsAndAnswers },
    } = store.getState().surveys;
    const answers = !isEmpty(questionsAndAnswers)
      ? Object.values(questionsAndAnswers)
      : [];
    const answeredQuestions = !isEmpty(questionsAndAnswers)
      ? Object.keys(questionsAndAnswers)
      : [];
    questions &&
      questions.forEach(q => {
        !answeredQuestions.includes(q.uuid) &&
          store.dispatch.surveys.storeSettingsAndData({
            param: q.uuid,
            number: 0,
          });
      });
    const nonZeroAnswersLength = answers.filter(x => x !== 0).length;
    const averageRating =
      parseFloat(
        (
          answers.reduce((acc, curr) => acc + curr, 0) / nonZeroAnswersLength
        ).toFixed(3),
      ) || 0;
      const belowThreshold =
      nonZeroAnswersLength === 0 ? true : averageRating < ratingThreshold;
      const {
      survey_hash: surveyHash = null,
      user_id: userId = null,
    } = parseParams(this.props);
    store.dispatch.surveys.storeSettingsAndData({
      belowThreshold,
      satisfiedButNotShare: (averageRating >= ratingThreshold) && (averageRating < NetworkLinksRatingThreshold),
      uuid,
      version,
      appointmentId,
      patientId,
      practiceMgmt,
      practiceId,
      averageRating,
      surveyType,
      surveyHash,
      userId,
    });
  };

  successRedirect = _ => {
    const {
      modal: { modalOpen },
    } = this.props;
    modalOpen && store.dispatch.modal.toggleClose();
    document.body.classList.remove('modal-open');
    store.dispatch.loader.persistLoader({ persist: false });
    store.dispatch.appointments.resetState();
    store.dispatch.surveys.clearStore();
    this.props.history.push('/appointments/check_out');
  };

  goBackToSurveys = _ => {
    store.dispatch.surveys.clearStore();
    this.refetch();
  };

  refetch = async _ => {
    store.dispatch.button.resetState();
    const data = await this.props.client.query({ query: GetSurveys });
    store.dispatch.surveys.fetchStart(data.data.getSurveys);
    store.dispatch.loader.persistLoader({ persist: false });
  };

  handleSelectSurvey = ({
    practiceName,
    index,
    mixPanelMetadata: { location_id, practice_id, provider_id } = {},
  }) => {
    const { surveysSelection, surveys } = store.getState().surveys;
    const selectedSurveyCard = surveysSelection[index];
    const selectedSurvey = surveys[index];
    store.dispatch.surveys.selectSurvey({
      selectedSurveyCard,
      selectedSurvey,
      practiceName,
    });
    this.props.history.push('/surveys/view');
    store.dispatch.mixpanel.addMetadata({
      surveyStarted: true,
      is_guest_mode: false,
      location_id,
      practice_id,
      provider_id,
      access_mode: 'Standard',
    });
  };

  handleTextInput = ({ textAreaValue }) => {
    store.dispatch.surveys.storeTextFeedback({ textAreaValue });
    store.dispatch.button.enableButton({
      btn: 'surveySubmitButton',
      complete: textAreaValue,
    });
  };

  render() {
    const {
      loader: { loading },
      surveys: { surveysSelection },
    } = this.props;
    return (
      surveysSelection && (
        <SurveysContext.Provider
          value={{
            goBackToSurveys: this.goBackToSurveys,
            handleSelectSurvey: this.handleSelectSurvey,
            getSurveyNext: this.getSurveyNext,
            handleTextInput: this.handleTextInput,
            successRedirect: this.successRedirect,
          }}
        >
          <Surveys
            props={{
              ...this.props,
              loading,
              guestMobileNext: this.state.guestMobileNext,
              isMobile: isMobileDevice(),
            }}
          />
        </SurveysContext.Provider>
      )
    );
  }
}
const mapStateToProps = ({
  surveys,
  loader,
  surveys: { surveyData },
  modal,
}) => ({
  surveys,
  surveyData,
  loader,
  modal,
  isMobile: isMobileDevice(),
});
const mapDispatchToProps = ({ surveys, loader }) => ({
  fetchStart: surveys.fetchStart,
  activateLoader: loader.activateLoader,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GetSurveys, {
    name: 'getSurveys',
    skip: ({ match: { url } }) => url.includes('guest'),
  }),
);
export default enhancer(SurveysContainer);
