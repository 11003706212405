export const parseParams = props => {
  if (!props || !props.location || !props.location.search) {
    return {};
  }
  let paramsArray = props.location.search.replace('?', '').split('&');
  paramsArray = paramsArray.map(paramPair => {
    const [key, value] = paramPair.split('=');
    return { [key]: value };
  });
  return Object.assign({}, ...paramsArray);
};
