import React from 'react';
import moment from 'moment';
import styles from './CareTeamMember.module.scss';
import SchemaParser from '../../services/SchemaParser';

export const CareTeamMemberContainer = React.memo(
  ({
    avatar,
    provider,
    specialty,
    practice,
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    phone,
    lastAppt,
  }) => {
    const parseLastAppt = ({ lastAppt }) => {
      const [label, date] = lastAppt.split(': ');
      return `${label}: ${date &&
        moment(date)
          .local()
          .format('MMMM Do')}`;
    };
    return (
      <div className={styles.wrapper}>
        <div className={styles.indicator} />
        <div className={styles.providerSection}>
          <SchemaParser schema={[avatar]} />
          <div className={styles.providerInfo}>
            <h6>{provider}</h6>
            <p>{specialty}</p>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.address}>
          <p>{practice}</p>
          {addressLine1 && <p>{addressLine1}</p>}
          {addressLine2 && <p>{addressLine2}</p>}
          {addressLine3 && <p>{addressLine3}</p>}
          {addressLine4 && <p>{addressLine4}</p>}
          {phone && <p>{phone}</p>}
        </div>
        <div className={styles.lastApptWrapper}>
          <h6>{parseLastAppt({ lastAppt })}</h6>
        </div>
      </div>
    );
  },
);

export default CareTeamMemberContainer;
