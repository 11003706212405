export const dateTimeSelect = {
  state: {},
  reducers: {
    changeSelected: (state, { id, selected, value }) => ({
      ...state,
      [id]: { selected, value },
    }),
    resetSelected: (state, { id }) => ({
      ...state,
      [id]: null,
    }),
  },
};
