export const payments = {
                state: { payments: [], loading: false, paymentBtnDisabled: false },
                reducers: {
                  fetchStart: (state, { paymentsScreen, pageDetails, collapsedPayments }) => ({
                    ...state,
                    payments: paymentsScreen,
                    pageDetails,
                    collapsedPayments,
                  }),
                  storeCollapsedPayments: (state, { collapsedPayments }) => ({
                    ...state,
                    collapsedPayments,
                  }),
                  loading: (state, loading) => ({
                    ...state,
                    loading,
                  }),
                  clearPayments: _ => ({ payments: [] }),
                  togglePaymentButton: (state, disabled) => ({
                    ...state,
                    paymentBtnDisabled: disabled,
                  }),
                  refetch: state => ({ ...state, refetch: true }),
                  didRefetch: state => ({ ...state, refetch: false }),
                                clearStore: _ => ({ ...payments.state }),
         },
       };
