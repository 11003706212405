import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import styles from './getStarted.module.scss';
import { ContentPage } from '..';
import { GetGetStarted } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { store } from '../../../models';

class GetStarted extends Component {
  componentDidUpdate() {
    this.props.guestEntry.guestScreen.length &&
      this.props.loading &&
      store.dispatch.loader.activateLoader({});
  }

  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
  }

  render() {
    const {
      guestEntry: { guestScreen },
      loader: { loading },
      location: { search = '' },
    } = this.props;
    const queryString = new URLSearchParams(search);
    const language = queryString.get('language');
    return guestScreen.length ? (
      <div className={styles.guestGetStartedWrapper}>
        <ContentPage className={styles.guestContentPage} schemas={[guestScreen]} loading={loading} />
      </div>
    ) : (
      <Query query={GetGetStarted} variables={{ language }}>
        {({ data: query, loading }) => {
          parseResult({
            query,
            dataField: 'getGetStarted',
            resultHandler: store.dispatch.guestEntry.fetchStart,
          });
          return <ContentPage schemas={[]} loading={loading} />;
        }}
      </Query>
    );
  }
}

const mapStateToProps = ({ guestEntry, loader }) => ({ guestEntry, loader });

const enhancer = compose(connect(mapStateToProps));
export default enhancer(GetStarted);
