export const error = {
  state: { errors: null },
  reducers: {
    addError: (state, { errors }) => ({
      ...state,
      errors,
    }),
    resetError: () => ({ errors: null }),
  },
};
