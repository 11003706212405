const language = localStorage.getItem('CC-Breeze-Web-language');
export const NAVBAR_TEXTS = [
  {page:'/', text: language === 'es' ? 'Tablero' : 'Dashboard'},
  {page:'/dashboard', text: language === 'es' ? 'Tablero' : 'Dashboard'},
  {page:'/appointments', text: language === 'es' ? 'Citas' : 'Appointments'},
  {page:'/my_health', text: language === 'es' ? 'Mi Salud' : 'My Health'},
  {page:'/appointments/add_appointment', text: language === 'es' ? 'Añadir Cita' : 'Add Appointment'},
  {page:'/payments', text: language === 'es' ? 'Pagos' : 'Payments'},
  {page:'/messages', text: language === 'es' ? 'Mensajes' : 'Messages'},
  {page:'/form_history', text: language === 'es' ? 'Formularios' : 'Forms'},
  {page:'/surveys', text: language === 'es' ? 'Encuestas' : 'Surveys'},
  {page:'/surveys/view', text: language === 'es' ? 'Encuestas' : 'Surveys'},
  {page:'/profile_settings/personal', text: language === 'es' ? 'Actualización de información del paciente' : 'Patient Info Update'},
  {page:'/profile_settings/demographics', text: language === 'es' ? 'Actualización demográfica' : 'Demographics Update'},
  {page:'/profile_settings/documents', text: language === 'es' ? 'Actualización de documentos' : 'Documents Update'},
  {page:'/profile_settings/payment', text: language === 'es' ? 'Actualización de pago' : 'Payment Update'},
  {page:'/profile_settings/notifications', text: language === 'es' ? 'Actualización de notificaciones' : 'Notifications Update'},
  {page:'/profile_settings/authentication', text: language === 'es' ? 'Actualización de autenticación' : 'Authentication Update'},
  {page:'/profile_settings/photo', text: language === 'es' ? 'Actualización de fotos' : 'Photo Update'},
  {page:'/shop', text: language === 'es' ? 'Tienda' : 'Shop'},
  {page:'/help', text: language === 'es' ? 'Soporte técnico' : 'Technical Support'},
  {page:'/activity_log', text: language === 'es' ? 'Registro de actividades' : 'Activity Log'},
]
export const INCOMPLETE_REGISTRATION_ROUTE = [
  {url: '/profile_settings/personal', key: 'personal_details', text: language === 'es' ? 'Personal' : 'Personal', fields: ['first_name', 'last_name', 'phone_number', 'phone_type', 'address1', 'zipcode', 'city', 'state']},
  {url: '/profile_settings/demographics', key: 'personal_details', text: language === 'es' ? 'Demografía' : 'Demographics', fields: ['date_of_birth', 'gender', 'primary_race', 'secondary_race', 'ethnicity', 'preferred_name', 'ssn', 'drivers_license_number', 'drivers_license_state', 'secondary_phone_number', 'secondary_phone_type', 'preferred_contact', 'referral_source']},
  {url: '/profile_settings/demographics', key: 'emergency_contact', text: language === 'es' ? 'Demografía' : 'Demographics'},
  {url: '/profile_settings/demographics', key: 'employment_info', text: language === 'es' ? 'Demografía' : 'Demographics'},
  {url: '/profile_settings/demographics', key: 'primary_care_physician', text: language === 'es' ? 'Demografía' : 'Demographics'},
  {url: '/profile_settings/demographics', key: 'referring_physician', text: language === 'es' ? 'Demografía' : 'Demographics'},
  {url: '/profile_settings/personal', key: 'address', text: language === 'es' ? 'Personal' : 'Personal'},
  {url: '/profile_settings/documents', key: 'insurances', text: language === 'es' ? 'Documentos' : 'Documents'},
  {url: '/profile_settings/documents', key: 'identity_document', text: language === 'es' ? 'Documentos' : 'Documents'},
  {url: '/profile_settings/payment', key: 'payment', text: language === 'es' ? 'Pago' : 'payment'},
  {url: '/profile_settings/notifications', key: 'updates', text: language === 'es' ? 'Notificaciones' : 'Notifications'},

]