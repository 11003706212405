import { phoneNumber, zipCode } from '../../../constants/Formatters';

export const PAGE_SIZE = 12;

export const fullName = formData => {
  if (!formData) {
    return '';
  }

  return [formData.first_name, formData.last_name].filter(item => !!item).join(' ');
};

export const getAttributes = (item, labels) => {
  if (!item) {
    return [];
  }

  return [
    {
      name: labels.specialityText,
      value: item.specialty,
    },
    {
      name: labels.addressText,
      value: [item?.address?.address1, item?.address?.address2].join(' '),
    },
    {
      name: labels.zipCodeText,
      value: zipCode(item?.address?.zipcode),
    },
    {
      name: labels.cityText,
      value: item?.address?.city,
    },
    {
      name: labels.stateText,
      value: item?.address?.state,
    },
    {
      name: labels.phoneNumberText,
      value: phoneNumber(item?.address?.phone_number),
    },
  ].map(({ name, value }) => ({
    name,
    value: value || '-',
  }));
};
