export const appointmentPrepayment = {
  state: { appointmentPrepayment: [] },
  reducers: {
    fetchStart: (state, { appointmentPrepaymentScreen }) => ({
      ...state,
      appointmentPrepayment: appointmentPrepaymentScreen,
    }),
    clearPrepayScreen: _ => ({ appointmentPrepayment: [] }),
    clearStore: _ => appointmentPrepayment.reducers.clearPrepayScreen(),
  },
};
