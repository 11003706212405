import { COUNTDOWN_TO_LOGOUT } from '../constants/Inactivity';

export const clientSideModal = {
  state: {
    modalOpen: String(),
    transform: true,
    cancel: false,
    message: String(),
    timer: COUNTDOWN_TO_LOGOUT,
  },
  reducers: {
    toggleTransform: (state, bool) => ({
      ...state,
      transform: bool,
    }),
    toggleOpen: (state, { id, message, timer }) => ({
      ...state,
      modalOpen: id,
      message,
      timer,
    }),
    toggleCancel: (state, bool) => ({
      ...state,
      cancel: bool,
    }),
    updateTimer: (state, { timer }) => ({
      ...state,
      timer,
    }),
    toggleClose: state => ({
      ...state,
      modalOpen: String(),
    }),
    resetTimer: state => ({
      ...state,
      timer: COUNTDOWN_TO_LOGOUT,
    }),
  },
};
