import React from 'react';
import { MaskField, FormField } from '../styleguide';

export default ({
  required,
  name,
  disabled,
  formData: value,
  schema: { title: label, validationMessages, mask, placeholder },
  onChange,
  rawErrors,
}) => {
  const handleChange = ({ target: { value } }) => onChange(value === '' ? undefined : value);
  const errorId = Array.isArray(rawErrors) && rawErrors[0];
  const errorMessage = validationMessages ? validationMessages[errorId] : errorId;

  return (
    <FormField error={errorMessage} text={label} required={required} value={value}>
      <MaskField
        disabled={disabled}
        name={name}
        onChange={handleChange}
        value={value}
        mask={mask}
        placeholder={placeholder}
      />
    </FormField>
  );
};
