import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, isEmpty } from '@carecloud/cloudpak';
import classNames from 'classnames';
import styles from './formHistoryFormView.module.scss';
import { store } from '../../../models';
import { JsonSchemaForm } from '../../ui';

class FormHistoryFormViewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { form: {} };
    const {
      params: { action },
    } = this.props.match;
    const {
      formHistory: {
        appointmentProvider: id,
        allForms: { historicalForms = [], pendingForms = [] },
        formsToSign = {},
        text = {},
      },
    } = this.props;
    const toSign = action === 'sign';
    const selectedForms = !isEmpty(formsToSign) ? [...formsToSign[id]] : [];
    if (toSign) {
      this.state = { ...this.state, formsToSign: selectedForms, formsCount: selectedForms.length };
    }
    const uuid = toSign ? selectedForms.shift() : action;
    let form = {};
    if (!toSign) {
      if (action.includes('_') && historicalForms.length) {
        const [id, date] = action.split('_');
        form = this.viewHistoricalForm({ forms: historicalForms, id, date });
      } else if (pendingForms.length) {
        form = this.viewPendingForms({ forms: pendingForms, uuid });
      }
    } else if (toSign && pendingForms.length) {
      form = this.viewPendingForms({ forms: pendingForms, uuid });
    }
    if (isEmpty(form)) {
      this.props.history.push('/form_history');
      store.dispatch.loader.activateLoader({ loading: true });
    } else {
      this.state = { ...this.state, form, isSigned: !toSign, formIndex: 1, text, version: form.form.metadata.version };
    }
  }
  componentDidMount() {
    store.dispatch.loader.activateLoader({ loading: false });
  }
  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
  }
  getProps = _ => {
    const {
      form: {
        form: {
          payload: { title, uuid, description, fields },
        },
        payload,
        metadata,
      },
      formsCount,
      formIndex,
      text,
      version,
    } = this.state;
    if (!fields) return {};
    const { required, properties } = fields;
    const {
      formViewHeading,
      formViewLeftSide,
      practiceId,
      booleans,
      patientId,
      errorRequiredLabel,
      formHistory = {},
    } = this.props.formHistory;
    const { canReviewForms } = formHistory[2].components[0];
    const canSignForms = canReviewForms ? { id: 'formSubmitButton', submitText: text.signForm } : {};
    return {
      nextForm: this.nextForm,
      id: this.state.isSigned ? 'signedForms' : 'pendingForms',
      totalPages: 1,
      formData: [payload && payload.response],
      uuid,
      title: [title],
      description: [description],
      heading: formViewHeading,
      booleans,
      formsCount,
      formIndex,
      version,
      pendingForms: { practiceId, patientId },
      client: this.props.client,
      history: this.props.history,
      state: this.state.isSigned ? 'signed' : 'pending',
      schema: [{ title, required, properties, type: 'object' }],
      leftSide: formViewLeftSide.filter(left => left.id === practiceId),
      submitButton: this.state.isSigned ? undefined : canSignForms,
      updatedDate: metadata && metadata.updated_dt,
      errorRequiredLabel,
    };
  };
  nextForm = () => {
    const {
      formHistory: {
        allForms: { pendingForms },
      },
    } = this.props;
    const { formsToSign } = this.state;
    const uuid = formsToSign.shift();
    const form = this.viewPendingForms({ forms: pendingForms, uuid });
    isEmpty(form) && this.props.history.push('/form_history');
    this.setState({
      formsToSign,
      form,
      formIndex: this.state.formIndex + 1,
      version: form.form.metadata.version,
    });
  };
  viewHistoricalForm = ({ forms, id, date }) =>
    forms.find(form => form.payload.form_uuid === id && form.payload.updated_dt === date);
  viewPendingForms = ({ forms, uuid }) => forms.find(form => form.form.payload.uuid === uuid);
  render() {
    const { formHistory: { formViewLeftSide = [], practiceId = String() } = {} } = this.props;
    const currentForm = formViewLeftSide.find(form => form.id === practiceId) || {};
    const { isDisabled } = currentForm;
    return !isEmpty(this.state.form) ? (
      <div
        className={classNames(styles.root, {
          [styles.signed]: this.state.isSigned || isDisabled,
        })}
      >
        <JsonSchemaForm props={this.getProps()} />
      </div>
    ) : null;
  }
}
const mapStateToProps = ({ formHistory }) => ({ formHistory });
const enhancer = compose(withRouter, withApollo, connect(mapStateToProps));
export default enhancer(FormHistoryFormViewContainer);
