import { Button, PageLoader } from '..';
import { get } from '@carecloud/cloudpak';
import styles from './Message.module.scss';
import React from 'react';
import SchemaParser from '../../services/SchemaParser';
import classNames from 'classnames';
import moment from 'moment';
import { SMALL_LOADER_SIZE, SMALL_LOADER_THICKNESS } from '../../../constants/Loader';

const message = React.memo(
  ({
    id,
    content,
    provider,
    providerAvatar,
    createdAt,
    attachments = [],
    type,
    messages: { sendingMessage, failedMessages, messagesScreen: { messagesSection: { retryLabel } = {} } = {} } = {},
    getAttachment,
  }) => {
    const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;

    return (
      <div>
        {attachments && !!attachments.length && attachments[0] && (
          <div
            className={classNames(
              { [styles.receivedMessage]: type === 'received' },
              { [styles.sentMessage]: type === 'sent' },
            )}
          >
            <SchemaParser schema={[type === 'received' && providerAvatar]} />
            <div
              id="callout"
              className={classNames(
                {
                  [styles.failedDetails]: failedMessages.find(conversation => id === conversation.replyId),
                },
                {
                  [styles.sending]: sendingMessage && sendingMessage.sending && sendingMessage.id === id,
                },
                [styles.messageDetails],
              )}
            >
              <h4>
                <span>{provider},</span>{' '}
                {moment(createdAt).isBefore(moment(), 'week')
                  ? moment(createdAt)
                      .local()
                      .format('MM/DD/YYYY h:mm A')
                  : moment(createdAt)
                      .local()
                      .format('dddd h:mm A')}
              </h4>
              <div className={styles.sentDetails}>
                {sendingMessage && sendingMessage.sending && sendingMessage.id === id && (
                  <div className={styles.sendingLoaderWrapper}>
                    <PageLoader
                      overlay={false}
                      fixed={false}
                      size={SMALL_LOADER_SIZE}
                      thickness={SMALL_LOADER_THICKNESS}
                    />
                  </div>
                )}
                {sendingMessage &&
                  !sendingMessage.sending &&
                  failedMessages.find(conversation => id === conversation.replyId) && (
                    <Button
                      {...{
                        id: 'retrySendingMessagebtn',
                        theme: 'retrySendingMessagebtn',
                        text: retryLabel,
                        type: 'button',
                        action: {
                          model: 'messages',
                          trigger: 'getMessageToRetry',
                          param: id,
                        },
                      }}
                    />
                  )}
                <div className={classNames(styles.attachmentParent)}>
                  {attachments.map(attachment => (
                    <div
                      key={attachment}
                      className={classNames(
                        { [styles.receivedAttachment]: type === 'received' },
                        { [styles.sentAttachmentBubble]: type === 'sent' },
                        {
                          [styles.sending]: sendingMessage && sendingMessage.sending && sendingMessage.id === id,
                        },
                        {
                          [styles.sent]: sendingMessage && !sendingMessage.sending && sendingMessage.id === id,
                        },
                      )}
                      role="button"
                      tabIndex={0}
                      onClick={getAttachment({
                        nodeId:
                          practiceMgmt === 'vertex'
                            ? get(attachment, 'document.id', String())
                            : get(attachment, 'document.document_handler', String()),
                        fileName: get(attachment, 'document.name', String()),
                        fileType:get(attachment, 'document.document_format', type => type.toUpperCase()),
                      })}
                    >
                      <div id={id} className={styles.attachmentDetails}>
                        <div className={styles.attachmentFileType}>
                          <div>{get(attachment, 'document.document_format', type => type.toUpperCase())}</div>
                        </div>
                        <div className={styles.attachmentDescription}>
                          <p>{get(attachment, 'document.description')}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {content && (
          <div
            className={classNames(
              { [styles.receivedMessage]: type === 'received' },
              { [styles.sentMessage]: type === 'sent' },
            )}
          >
            {attachments && attachments.length ? (
              <div style={{ width: 32 }} />
            ) : (
              <SchemaParser schema={[type === 'received' && providerAvatar]} />
            )}
            <div
              id="callout"
              className={classNames(
                {
                  [styles.failedDetails]: failedMessages.find(conversation => id === conversation.replyId),
                },
                {
                  [styles.sending]: sendingMessage && sendingMessage.sending && sendingMessage.id === id,
                },
                [styles.messageDetails],
              )}
            >
              {!attachments && (
                <h4>
                  <span>{provider},</span>{' '}
                  {moment(createdAt).isBefore(moment(), 'week')
                    ? moment(createdAt)
                        .local()
                        .format('MM/DD/YYYY h:mm A')
                    : moment(createdAt)
                        .local()
                        .format('dddd h:mm A')}
                </h4>
              )}
              <div className={styles.sentDetails}>
                {sendingMessage && sendingMessage.sending && sendingMessage.id === id && (
                  <div className={styles.sendingLoaderWrapper}>
                    <PageLoader
                      overlay={false}
                      fixed={false}
                      size={SMALL_LOADER_SIZE}
                      thickness={SMALL_LOADER_THICKNESS}
                    />
                  </div>
                )}
                {sendingMessage &&
                  !sendingMessage.sending &&
                  failedMessages.find(conversation => id === conversation.replyId) && (
                    <Button
                      {...{
                        id: 'retrySendingMessagebtn',
                        theme: 'retrySendingMessagebtn',
                        text: retryLabel,
                        type: 'button',
                        action: {
                          model: 'messages',
                          trigger: 'getMessageToRetry',
                          param: id,
                        },
                      }}
                    />
                  )}
                <div
                  className={classNames(
                    { [styles.receivedBubble]: type === 'received' },
                    { [styles.sentBubble]: type === 'sent' },
                    {
                      [styles.sending]: sendingMessage && sendingMessage.sending && sendingMessage.id === id,
                    },
                    {
                      [styles.sent]: sendingMessage && !sendingMessage.sending && sendingMessage.id === id,
                    },
                    { [styles.noTab]: attachments && attachments.length },
                  )}
                >
                  {/* eslint-disable react/no-danger */}
                  <div
                    id={id}
                    className={styles.dangerouslySetInnerHTML}
                    dangerouslySetInnerHTML={{ __html: content }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  },
);

export const Message = message;
