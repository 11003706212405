/* eslint jsx-a11y/no-static-element-interactions: 0 */ // --> OFF

import React from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import classNames from 'classnames';
import { SchemaParser } from '../../services';

export const ProviderCard = ({
  props: {
    styles,
    messages,
    addAppointment,
    messages: { createNew },
    id,
    theme,
    avatar,
    provider,
    specialty,
    value,
    role,
    tabIndex,
    clickHandler,
    providerSelected,
    option,
    practiceName,
    providerLocation,
    appointmentProvider,
    messageSubject,
    viewMessagesOf,
    provider_id,
    read,
    count,
    customHeaders,
    isMobile,
    surveys: { checkoutSurvey } = {},
  },
}) => (
  <label htmlFor={`label${id}`}>
    <div
      id={id}
      role={role}
      onClick={!option.hidden && clickHandler}
      tabIndex={tabIndex}
      className={classNames(
        styles.providerCard,
        { [styles.selectedProvider]: provider_id === providerSelected },
        { [styles.showMessages]: provider_id !== undefined && provider_id === viewMessagesOf.param },
        { [styles.addAppointmentDisabled]: appointmentProvider && !createNew && !checkoutSurvey },
        { [styles.addAppointmentSelected]: id === appointmentProvider },
        styles[theme],
        { [styles.isMobileSurveyCardContainer]: isMobile },
      )}
    >
      {customHeaders && <SchemaParser schema={[customHeaders]} />}
      <SchemaParser schema={[avatar]} />
      {!!count && <span className={styles.withCount}>{count}</span>}
      {!read && (!isEmpty(viewMessagesOf) || createNew) && <div id="unread" className={styles.readMarker} />}
      <div
        className={classNames(styles.providerInfo, {
          [styles.shifted]: !read && (!isEmpty(viewMessagesOf) || createNew),
        })}
      >
        {value && providerSelected && !messageSubject && !provider_id && (
          <p className={classNames(styles.firstLabel)}>{value}</p>
        )}
        {messageSubject && <p className={styles.firstLabel}>{messageSubject}</p>}
        <p
          className={
            (addAppointment && !provider) || (messageSubject && messages) || (!provider_id && value)
              ? styles.secondLabel
              : styles.firstLabel
          }
        >
          {provider}
        </p>
        {providerLocation && <p className={styles.secondLabel}>{providerLocation}</p>}
        {(!value || !providerSelected) && specialty && <p className={styles.secondLabel}>{specialty}</p>}
        {practiceName && <p className={styles.secondLabel}>{practiceName}</p>}
      </div>
      {option && option.type === 'radio' && (
        <div className={styles.radioContainer}>
          <input
            type="radio"
            id={`label${id}`}
            name={option.group}
            hidden={option.hidden}
            disabled={option.hidden}
            defaultChecked={option.checked}
          />
        </div>
      )}
    </div>
  </label>
);
