const state = { focused: false };
export const datePicker = {
  state,
  reducers: {
    selectDate: (state, { id, value }) => ({
      ...state,
      [id]: value,
    }),
    setFocus: (state, value) => ({
      ...state,
      focused: value,
    }),
    resetState: _ => ({ ...state }),
  },
};
