import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LogRocket from 'logrocket';
import { App } from './components/root/App';
import Environment from './graphql/Environment';
import { unregister } from './registerServiceWorker';

unregister();
switch (process.env.NODE_ENV) {
  case 'production':
    LogRocket.init('carecloud/breeze-web-pa');
    sessionStorage.setItem('logRocketInitiated', true);
    break;

  case 'development':
    LogRocket.init('carecloud/breeze-web-pa');
    sessionStorage.setItem('logRocketInitiated', true);
    break;

  case 'staging':
    LogRocket.init('carecloud/breeze-web-pa');
    sessionStorage.setItem('logRocketInitiated', true);
    break;

  default:
    sessionStorage.setItem('logRocketInitiated', false);
    break;
}

const rootEl = document.getElementById('root');

const render = _ => ReactDOM.render(<App />, rootEl);
if (module.hot) module.hot.accept(App, _ => render());

render();
