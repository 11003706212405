import React, { PureComponent } from 'react';

import { ContentPage } from '..';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { GetOops } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { store } from '../../../models';
import styles from './Oops.module.scss';

class Oops extends PureComponent {
  componentDidUpdate() {
    this.props.errorPage.oopsScreen.length && this.props.loading && store.dispatch.loader.activateLoader({});
  }
  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
  }
  render() {
    return this.props.errorPage.oopsScreen.length ? (
      <ContentPage className={styles.oopsWrapper} schemas={[this.props.errorPage.oopsScreen]} />
    ) : (
      <Query query={GetOops}>
        {({ data: query, loading }) => {
          parseResult({ query, dataField: 'getOops', resultHandler: store.dispatch.errorPage.showOopsErrorPage });
          return <ContentPage schemas={[]} loading={loading} />;
        }}
      </Query>
    );
  }
}

const mapStateToProps = ({ loader: { loading }, errorPage }) => ({ loading, errorPage });

export default connect(mapStateToProps)(Oops);
