import React from 'react'
// import 'react-dates/lib/css/_datepicker.css';
import styles from '../input/datePickerCalendarOnly.module.scss';

const CustomInfoPanel = ({
  labels,
  handleSave,
  handleCancel,
}) => {

  return (
    <div className={styles.infoPanelButtonsWrapper}>
      <button
        className={styles.cancelBtn}
        onClick={handleCancel}
      >
        {labels.cancel}
      </button>
      <button
        className={styles.saveBtn}
        onClick={handleSave}
      >
        {labels.save}
      </button>
    </div>
  )
}

export default CustomInfoPanel