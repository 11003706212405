import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import classNames from 'classnames';
import styles from './toast.module.scss';

const toast = ({ props: { open, transform, clickHandler, mouseLeave, mouseEnter, data: { message, type } = {} } }) =>
  open && message ? (
    <div
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      className={classNames(styles.wrapper, { [styles.transform]: transform }, { [styles.center]: type === 'success' })}
    >
      <div className={classNames(styles.message, styles[type])}>
        {type === 'success' && <FontAwesomeIcon icon="check" />}
        <p>{message}</p>
        <i onClick={clickHandler} role="menuItem" tabIndex="0" className="material-icons">
          highlight_off
        </i>
      </div>
    </div>
  ) : null;

export const Toast = toast;
