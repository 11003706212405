import React, { useState } from 'react';
import styles from './FormField.module.scss';
import { Label, Text } from '..';

export default React.memo(props => {
  const { children, error, required, text, value } = props;

  const [touched, setTouched] = useState(true);

  return (
    <div className={styles.field} onBlur={setTouched}>
      {text && <Label text={text} required={required && !value} />}
      {children}
      {error && (touched || value) && <Text tag="p" text={error} type="error" />}
    </div>
  );
});
