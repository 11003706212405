import { init } from '@rematch/core';
import { isEmpty, isObject } from '@carecloud/cloudpak';
import LogRocket from 'logrocket';
import { createLogger } from 'redux-logger';
import moment from 'moment';
import mix from '../components/services/mixpanel';
import { Mixpanel } from '../constants';
import { login } from './Login';
import { appointments } from './Appointments';
import { addAppointment } from './AddAppointment';
import { availableHours } from './AvailableHours';
import { appointmentCheckIn } from './AppointmentCheckIn';
import { payments } from './Payments';
import { myHealth } from './MyHealth';
import { clientSideModal } from './ClientSideModal';
import { modal } from './Modal';
import { toast } from './Toast';
import { layout } from './Layout';
import { makePayments } from './makePayments';
import { inputDropdown } from './InputDropdown';
import { radioInput } from './RadioInput';
import { messages } from './Messages';
import { partialPayment } from './PartialPayment';
import { paymentPlan } from './PaymentPlan';
import { support } from './Support';
import { shop } from './Shop';
import { shopMakePayment } from './ShopMakePayment';
import { signup } from './Signup';
import { loader } from './Loader';
import { formGroup } from './FormGroup';
import { form } from './Form';
import { formHistory } from './FormHistory';
import { creditCard } from './CreditCard';
import { settings } from './Settings';
import { appointmentPrepayment } from './AppointmentPrepayment';
import { oneTimePayment } from './oneTimePayment';
import { editPaymentPlan } from './EditPaymentPlan';
import { appointmentCheckOut } from './AppointmentCheckOut';
import { appointmentCancellation } from './AppointmentCancellation';
import { dateTimeSelect } from './DateTimeSelect';
import { button } from './Button';
import { datePicker } from './DatePicker';
import { dropdown } from './Dropdown';
import { error } from './Error';
import { errorPage } from './ErrorPage';
import { expander } from './Expander';
import { mixpanel } from './Mixpanel';
import { notification } from './Notification';
import { guestEntry } from './GuestEntry';
import { surveys } from './Surveys';
import { guest } from './Guest';
import { activityLog } from './ActivityLog';
import { delegate } from './Delegate';
import { delegateProfiles } from './DelegateProfiles';
import { delegateCard } from './DelegateCard';
import { benchmarking } from './Benchmarking';
import { benchmarkResults } from './BenchmarkResults';
import { FETCH_START, DEVELOPMENT, REFETCH } from '../constants/Properties';
import { camelCaseAppend } from '../graphql/utilities';
import { averageFromArray } from '../components/services/utilities';
import { manageProfiles } from './ManageProfiles';
import { hierarchicalCheckboxes } from './HierarchicalCheckboxes';
import { intelligentScheduler } from './IntelligentScheduler';
import { dashboard } from './Dashboard';

const logger = _ => {
  if (process.env.NODE_ENV === 'production') {
    return LogRocket.reduxMiddleware();
  }
  return createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });
};
const mixpanelMiddleWare = _ => next => action => {
  const { Actions } = Mixpanel;
  const { type, payload = {}, meta } = action;
  const mixpanelActionMap = {
    [Actions.FORM_GROUP_CHANGE]: _ => mix.formGroupChange(payload),
    [Actions.FORM_GROUP_SELECT_CHANGE]: _ => mix.formGroupChange(payload),
    [Actions.MODAL_OPEN]: _ => mix.modalOpen(payload),
    [Actions.MIXPANEL_ADD_META_DATA]: _ => mix.mixpanelEvent(payload),
    [Actions.SHOP_LAUNCHED]: _ => mix.shopLaunched(),
    [Actions.START_SHOP_CHECKOUT]: _ => mix.startShopCheckout(payload),
    [Actions.RADIO_SELECTED]: _ => mix.radioSelected(payload, meta),
    [Actions.PREPAYMENT_STARTED]: _ => mix.prepaymentStarted(),
    [Actions.CHECKIN_QR_TOGGLED]: _ => mix.checkinQrToggled(payload),
    [Actions.FORM_SUBMITTED]: _ => mix.formSubmitted(),
  };
  const mixpanelAction = mixpanelActionMap[type];
  if (mixpanelAction) mixpanelAction();
  next(action);
};

const localStore = init({
  models: { benchmarkResults },
  redux: { middlewares: [logger()] },
});

const benchmarkingMiddleWare = store => next => action => {
  if (!isObject(action.payload)) return next(action);
  const { type = '/', payload: { whatRequestAmI = String(), innocentDelay = 0 } = {} } = action;
  const reducer = type.split('/')[1];
  if ((reducer !== FETCH_START && reducer !== REFETCH) || !!innocentDelay) return next(action);
  const benchmarkingState = store.getState().benchmarking;
  const startedAt = benchmarkingState[camelCaseAppend(whatRequestAmI)] || benchmarkingState[whatRequestAmI];
  const endedAt = moment();
  const startToFinish = endedAt.diff(startedAt, 'milliseconds');
  const guiltyDelay = startToFinish - innocentDelay;
  const guiltyPercentage = `${Math.round((guiltyDelay / startToFinish) * 100)}%`;
  if (guiltyDelay < 0) return next(action);

  localStore.dispatch.benchmarkResults.setRequestResult({
    operationName: whatRequestAmI,
    guiltyDelay,
    innocentDelay,
    guiltyPercentage,
  });

  const commonLogStyles =
    'font-weight: 600; font-size: 14px; padding: 2px 0px; text-shadow: 2px 2px 1px rgba(0,0,0,0.6);';
  window.console.log(
    '%c %s',
    `color: red; ${commonLogStyles}`,
    `${whatRequestAmI}'s Back-End delay is ${innocentDelay} ms`,
  );
  window.console.log(
    '%c %s',
    `color: #5BC5D0; ${commonLogStyles}`,
    `${whatRequestAmI}'s Front-End delay is ${guiltyDelay} ms (${guiltyPercentage})`,
  );

  const currentHistory = sessionStorage.getItem('CC-Breeze-Benchmark-History')
    ? JSON.parse(sessionStorage.getItem('CC-Breeze-Benchmark-History'))
    : {};
  const pushAndReturn = ({ existingArr, newItem }) => {
    existingArr.push(newItem);
    return existingArr;
  };
  const scopedHistory = isEmpty(currentHistory) ? {} : currentHistory[`${whatRequestAmI}History`];
  const historyNotHyrdrated = isEmpty(scopedHistory);
  const guiltyDelayAverage = historyNotHyrdrated
    ? guiltyDelay
    : Math.round(averageFromArray(scopedHistory.guiltyDelays));
  const innocentDelayAverage = historyNotHyrdrated
    ? innocentDelay
    : Math.round(averageFromArray(scopedHistory.innocentDelays));
  const requestResults = {
    [`${whatRequestAmI}History`]: {
      guiltyDelays: historyNotHyrdrated
        ? [guiltyDelay]
        : pushAndReturn({ existingArr: scopedHistory.guiltyDelays, newItem: guiltyDelay }),
      innocentDelays: historyNotHyrdrated
        ? [innocentDelay]
        : pushAndReturn({ existingArr: scopedHistory.innocentDelays, newItem: innocentDelay }),
      guiltyPercentages: historyNotHyrdrated
        ? [guiltyPercentage]
        : pushAndReturn({ existingArr: scopedHistory.guiltyPercentages, newItem: guiltyPercentage }),
    },
    [`${whatRequestAmI}Averages`]: {
      guiltyDelay: guiltyDelayAverage,
      innocentDelay: innocentDelayAverage,
      guiltyPercentage: historyNotHyrdrated
        ? String()
        : `${Math.round((guiltyDelayAverage / (innocentDelayAverage + guiltyDelayAverage)) * 100)} %`,
    },
  };
  sessionStorage.setItem('CC-Breeze-Benchmark-History', JSON.stringify({ ...currentHistory, ...requestResults }));
  next(action);
};

export const store = init({
  models: {
    appointments,
    addAppointment,
    button,
    layout,
    login,
    messages,
    clientSideModal,
    modal,
    myHealth,
    payments,
    radioInput,
    editPaymentPlan,
    makePayments,
    inputDropdown,
    partialPayment,
    formHistory,
    paymentPlan,
    signup,
    support,
    shop,
    loader,
    appointmentCheckIn,
    appointmentCheckOut,
    formGroup,
    toast,
    creditCard,
    settings,
    appointmentPrepayment,
    availableHours,
    dateTimeSelect,
    oneTimePayment,
    appointmentCancellation,
    datePicker,
    dropdown,
    shopMakePayment,
    form,
    error,
    errorPage,
    expander,
    mixpanel,
    notification,
    guestEntry,
    surveys,
    guest,
    activityLog,
    delegate,
    delegateProfiles,
    delegateCard,
    benchmarking,
    manageProfiles,
    hierarchicalCheckboxes,
    intelligentScheduler,
    dashboard,
  },
  redux: {
    middlewares: [
      logger(),
      mixpanelMiddleWare,
      ...(process.env.NODE_ENV === DEVELOPMENT ? [benchmarkingMiddleWare] : []),
    ],
    rootReducers: { RESET: _ => undefined },
    devtoolOptions: {
      trace: true,
      traceLimit: 25,
    },
  },
});
