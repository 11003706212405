import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import moment from 'moment';
import React from 'react';
import classNames from 'classnames';
import styles from './paymentSection.module.scss';
import SchemaParser from '../../services/SchemaParser';
import { formatMoney } from '../../services/utilities';

const PaymentSectionContainer = React.memo(
  ({
    toggleOpen,
    modal: { modalOpen },
    modals,
    payment,
    planCompletedOn,
    planDescription,
    message,
    date,
    processing,
    avatar,
    progress,
    image,
    id,
    text,
    format,
  }) => {
    const clickHandler = modalId => toggleOpen(modalId);
    return (
      <div>
        <div
          className={classNames(styles.wrapper, {
            [styles.historyWrapper]: date,
          })}
        >
          {processing && <div className={styles.processing}>processing</div>}
          <div
            className={styles.container}
            role="button"
            tabIndex="0"
            onClick={() => clickHandler(id)}
          >
            <div
              className={classNames(styles.upper, {
                [styles.monthly]: progress,
              })}
            >
              <h3 className={styles.payment}>
                ${formatMoney({ amount: payment })}
                {progress && (
                  <span className={styles.perMonth}>{text && text.month}</span>
                )}
              </h3>
            </div>
            <div className={styles.lower}>
              {progress && <SchemaParser schema={[progress]} />}
              {image && <SchemaParser schema={[image]} />}
              <div
                className={classNames(styles.avatarContainer, {
                  [styles.avatarShift]: progress,
                })}
              >
                <SchemaParser schema={[avatar]} />
              </div>
              <h4 className={styles.message}>{message}</h4>
              <p className={styles.paymentPlanSubtitle}>{planDescription}</p>
              {date && (
                <div className={styles.date}>
                  {planCompletedOn}
                  {!format
                    ? date
                    : moment(date)
                        .local()
                        .format(format)}
                </div>
              )}
            </div>
          </div>
        </div>
        {modals && modalOpen === modals.id && (
          <SchemaParser schema={[modals]} />
        )}
      </div>
    );
  },
);

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = ({ modal }) => ({
  toggleModal: modal.toggleModal,
  toggleOpen: modal.toggleOpen,
});

PaymentSectionContainer.whyDidYouRender = true;

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));
export default enhancer(PaymentSectionContainer);
