export const partialPayment = {
  state: { input: 0, error: { display: false }, displayInput: '' },
  reducers: {
    updateInput: (state, input) => ({
      ...state,
      input,
    }),
    updateDisplayInput: (state, displayInput) => ({
      ...state,
      displayInput,
    }),
    updateMinimum: (state, minimum) => ({
      ...state,
      minimum,
    }),
    toggleError: (state, { display, paymentPlanErr }) => ({
      ...state,
      error: { display, paymentPlanErr },
    }),
    resetInput: state => ({
      ...state,
      input: 0,
      displayInput: '',
    }),
  },
};
