import React from 'react';
import { DateField, FormField } from '../styleguide';
import { checkDateOfBirth } from '../commonjs';

export default ({
  required,
  name,
  disabled,
  formData: value,
  schema: { title: label, validationMessages, displayFormat, baseFormat, placeholder },
  onChange,
  rawErrors,
}) => {
  const handleChange = ({ target: { value } }) => onChange(value === '' ? undefined : value);
  const errorId = Array.isArray(rawErrors) && rawErrors[0];
  const errorMessage = { eMessage: '' };

  if (checkDateOfBirth({ inputDates: value })) {
    errorMessage.eMessage = validationMessages.pattern;
  } else if (validationMessages) {
    errorMessage.eMessage = validationMessages[errorId];
  } else {
    return errorId;
  }
  return (
    <FormField error={errorMessage.eMessage} text={label} required={required} value={value}>
      <DateField
        baseFormat={baseFormat}
        disabled={disabled}
        displayFormat={displayFormat}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        value={value}
      />
    </FormField>
  );
};
