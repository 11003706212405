import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/react-hoc';
import { Query } from '@apollo/react-components';
import { compose, get } from '@carecloud/cloudpak';
import { store } from '../../../models';
import { GetManageProfiles } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { ContentPage } from '..';
import { mergeDelegateProfile, disconnectDelegateProfile } from '../../../graphql/mutations';
import { manageProfilesContext as ManageProfilesContext } from '../../../contexts';

const ManageProfilesContainer = ({ loading, manageProfiles, client }) => {
  const prepareRequest = _ => {
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.modal.toggleClose();
  };

  const cleanupRequest = _ => {
    store.dispatch.loader.activateLoader({ loading: false });
    store.dispatch.expander.closeAll();
  };

  const handleError = _ => {
    const { errors } = store.getState().error;
    if (errors.length) {
      store.dispatch.modal.saveCustomMessage(get(errors, '[0].message'));
      store.dispatch.error.resetError();
      return store.dispatch.modal.toggleOpen('manageProfilesErrorModal');
    }
  };

  const confirmMergeDelegates = async ({ fromDelegateeProfileId, toDelegateeProfileId, delegateUserId }) => {
    prepareRequest();
    const res = await client.mutate({
      mutation: mergeDelegateProfile,
      variables: { input: { fromDelegateeProfileId, toDelegateeProfileId, delegateUserId } },
    });
    const {
      data: { mergeDelegateProfile: data },
      status,
    } = res;
    cleanupRequest();
    handleError();
    if (status === 200) return store.dispatch.manageProfiles.fetchStart({ manageProfiles: data });
  };

  const confirmDisconnectDelegates = async ({ patientId, delegateUserId, practiceMgmt, practiceId }) => {
    prepareRequest();
    const { disconnectDelegateProfile: { status } } = await client.mutate({
      mutation: disconnectDelegateProfile,
      variables: { input: { patientId, delegateUserId, practiceMgmt, practiceId } },
    });
    cleanupRequest();
    handleError();
    if (status === 200) return store.dispatch.manageProfiles.clearStore();
  };

  const ambramovAppeaser = useCallback(_ => {
    store.dispatch.loader.activateLoader({ loading: true });
    return store.dispatch.manageProfiles.clearStore();
  }, []);

  useEffect(
    _ => {
      ambramovAppeaser();
    },
    [ambramovAppeaser],
  );

  useEffect(
    _ => {
      manageProfiles.length && loading && store.dispatch.loader.activateLoader({ loading: false });
    },
    [manageProfiles],
  );

  return manageProfiles.length ? (
    <ManageProfilesContext.Provider value={{ confirmMergeDelegates, confirmDisconnectDelegates }}>
      <ContentPage schemas={[manageProfiles]} loading={loading} />
    </ManageProfilesContext.Provider>
  ) : (
    <Query query={GetManageProfiles}>
      {({ data: query, loading }) => {
        parseResult({ query, dataField: 'getManageProfiles', resultHandler: store.dispatch.manageProfiles.fetchStart });
        return <ContentPage schemas={[]} loading={loading} />;
      }}
    </Query>
  );
};
const mapStateToProps = ({ loader: { loading }, manageProfiles: { manageProfiles } }) => ({
  loading,
  manageProfiles,
});

const enhancer = compose(
  withApollo,
  connect(mapStateToProps),
);

export default enhancer(ManageProfilesContainer);
