const state = {
  appointments: [],
  state: 'patient_app_appointments',
  showOverlay: null,
  iOSOverlay: true,
  cancellingAppointment: false,
  appointmentData: {},
  missingRegistrationFields: [],
  highlightMissingForms: false,
};
export const appointments = {
  state,
  reducers: {
    fetchStart: (state, { appointmentsScreen, details, missingRegistrationFields }) => ({
      ...state,
      appointments: appointmentsScreen,
      headingSection: appointmentsScreen,
      appointmentDetails: details,
      missingRegistrationFields,
    }),
    toggleOverlay: (state, { id }) => ({
      ...state,
      showOverlay: id,
    }),
    toggleiOS: (state, param) => ({
      ...state,
      iOSOverlay: param,
    }),
    storeAppointmentData: (state, appointmentData) => ({
      ...state,
      appointmentData,
    }),
    toggleCancellingAppointment: state => ({
      ...state,
      cancellingAppointment: !state.cancellingAppointment,
      showOverlay: null,
    }),
    showMissingForms: (state, {param}) => ({
      ...state,
      highlightMissingForms: param,
    }),
    setMissingRegistrationFields: (state, {missingRegistrationFields}) => ({
      ...state,
      missingRegistrationFields,
    }),
    resetState: _ => ({ ...state, refetch: true }),
    refetch: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    clearStore: _ => ({ ...state }),
    resetMissingFields: state => ({
      ...state,
      missingRegistrationFields: [],
      highlightMissingForms: false,
    }),
  },
};
