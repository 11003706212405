import React from 'react';
import classNames from 'classnames';
import styles from './group.module.scss';
import { HORIZONTAL, VERTICAL, GRID } from '../../../constants/Properties';
import { isMobileDevice } from '../../services/utilities';

const group = ({ children, props: { direction, theme, id } }) => (
  <div
    id={id}
    className={classNames(
      styles.group,
      { [styles.horizontal]: direction === HORIZONTAL },
      { [styles.vertical]: direction === VERTICAL },
      { [styles.grid]: direction === GRID },
      { [styles.isMobile]: isMobileDevice() },
      styles[theme],
    )}
  >
    {children}
  </div>
);

export const Group = group;
