import React, { useEffect } from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { ContentPage } from '..';
import { Session } from '../../services';
import { GetDelegateProfile } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { isMobileDevice } from '../../services/utilities';
import { store } from '../../../models';
import styles from './DelegateProfile.module.scss';

const DelegateProfile = React.memo(({ loading, delegateProfile }) => {
  const { limboCognitoTokens } = store.getState().login;
  const sessionTokens = {
    email: sessionStorage.getItem('CC-Breeze-Web-email'),
    accessToken: sessionStorage.getItem('CC-Breeze-Web-accessToken'),
    authenticationToken: sessionStorage.getItem('CC-Breeze-Web-authenticationToken'),
    refreshToken: sessionStorage.getItem('CC-Breeze-Web-refreshToken'),
  };
  const cognitoTokens = isEmpty(limboCognitoTokens) ? sessionTokens : limboCognitoTokens;
  useEffect(_ => {
    store.dispatch.loader.activateLoader({ loading: true });
    Session.setSession(limboCognitoTokens);
  }, []);
  useEffect(
    _ => {
      delegateProfile && delegateProfile.length && loading && store.dispatch.loader.activateLoader({ loading: false });
      return _ => {
        store.dispatch.login.clearCognitoToken();
        store.dispatch.loader.activateLoader({ loading: true });
      };
    },
    [delegateProfile],
  );
  return delegateProfile && delegateProfile.length ? (
    <div
      className={classNames(styles.delegateProfileWrapper, { [styles.delegateProfileWrapperMobile]: isMobileDevice() })}
    >
      <ContentPage
        className={isMobileDevice() ? styles.delegateProfileMobileContent : styles.delegateProfileContent}
        schemas={delegateProfile}
      />
    </div>
  ) : (
    <Query query={GetDelegateProfile} variables={{ input: { ...cognitoTokens } }}>
      {({ data: query, loading }) => {
        parseResult({
          query,
          dataField: 'getDelegateProfile',
          resultHandler: store.dispatch.delegateProfiles.fetchStart,
        });
        return <ContentPage schemas={[]} loading={loading} />;
      }}
    </Query>
  );
});

const mapStateToProps = ({ loader: { loading }, delegateProfiles: { delegateProfile } }) => ({
  loading,
  delegateProfile,
});

export default connect(mapStateToProps)(DelegateProfile);
