export const dropdown = {
  state: { dropDownOpen: 'hamburgerMenu' },
  reducers: {
    toggleOpen: (state, param) => {
      if (state.dropDownOpen === param) {
        return {
          ...state,
          dropDownOpen: null,
        };
      }
      return {
        ...state,
        dropDownOpen: param,
      };
    },
  },
};
