import { isEmpty, get, compose } from '@carecloud/cloudpak';
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import { store } from '../../../models';
import { LoadMoreForms } from '../../../graphql/queries';
import styles from './formHistorySelect.module.scss';
import { SchemaParser } from '../../services';

const FormHistorySelectContainer = props => <Component {...{ ...props, match: null }} />;

const Component = React.memo(props => {
  const {
    appointmentProvider,
    forms,
    practiceHeader,
    formsToSign,
    practiceId,
    providerForms,
    providerPageDetails,
    allForms: { historicalForms },
    client,
    text,
    history,
    headers: { practice, unsignedCount },
    appointmentProvider: id,
    noForms,
    noSelectedProvider,
    localActions,
    providerCards,
    canReviewForms,
  } = props;
  const loadMoreForms = async event => {
    const { current_page: currentPage, total_pages: totalPages, per_page: perPage } = providerPageDetails[
      appointmentProvider
    ];
    if (totalPages > 1 && currentPage !== totalPages) {
      const list = event.currentTarget;
      if (list.scrollTop + list.clientHeight >= list.scrollHeight) {
        const formCount = currentPage * perPage;
        store.dispatch.loader.persistLoader({ persist: true });
        const { data } = await client.query({
          query: LoadMoreForms,
          variables: {
            input: {
              practiceId,
              pageNumber: currentPage + 1,
              pageCount: perPage,
              formCount: formCount + 100,
            },
          },
        });
        store.dispatch.loader.persistLoader({ persist: false });
        if (!isEmpty(data.loadMoreForms)) {
          const {
            loadMoreForms: { pageDetails, formsBlock, historicalForms: newForms },
          } = data;
          store.dispatch.formHistory.appendForms({
            forms: [...providerForms[appointmentProvider], ...formsBlock],
            pageDetails,
            historicalForms: [...historicalForms, ...newForms],
            id: appointmentProvider,
          });
        }
      }
    }
  };
  const signForms = _ => {
    if (formsToSign[appointmentProvider] && formsToSign[appointmentProvider].length) {
      store.dispatch.formHistory.storeText({ text });
      history.push('/form_history/sign');
    }
  };
  return (
    <div>
      <div className={styles.contentContainer}>
        <div className={styles.column}>
          <div className={styles.heading}>
            <SchemaParser schema={[practice]} />
            {!!unsignedCount && <span className={styles.unsingedCount}>{unsignedCount}</span>}
          </div>
          <div className={styles.cardContainer}>
            <SchemaParser schema={[providerCards]} />
          </div>
        </div>
        <div className={styles.formColumn}>
          {practiceHeader && <SchemaParser schema={[practiceHeader]} />}
          <div className={styles.centerContent}>
            {(_ => {
              if (!appointmentProvider) return <SchemaParser schema={[noSelectedProvider]} />;
              else if (isEmpty(forms)) {
                return <SchemaParser schema={[noForms]} />;
              } else {
                return (
                  <>
                    <div className={styles.formContainer} onScroll={loadMoreForms}>
                      <SchemaParser schema={[providerForms[appointmentProvider]]} localActions={localActions} />
                    </div>
                    <div className={styles.info}>{text.info}</div>
                  </>
                );
              }
            })()}
          </div>
        </div>
      </div>
      {!isEmpty(forms) && canReviewForms && (
        <button
          className={classNames(styles.button, { [styles.enabled]: !isEmpty(get(formsToSign, id)) })}
          onClick={signForms}
        >
          {text.button}
        </button>
      )}
    </div>
  );
});

const enhancer = compose(withRouter);
export default enhancer(FormHistorySelectContainer);
