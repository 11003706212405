import React from 'react';
import styles from './UnsupportedBrowser.module.scss';

const UnsupportedBrowser = () => (
  <div className={styles.unsupportedBrowserContainer}>
    <div id="unsupportedBrowserContainer">
      <img id="unsupportedBrowserSorryImage" src="https://assets.gobreeze.com/sorry.svg" alt="unsupported browser" />
      <div id="unsupportedBrowserHeader">
        <h6>Unsupported Browser</h6>
      </div>
      <div id="unsupportedBrowserSubheaderGroup">
        <h6 id="unsupportedBrowserSubheader">You appear to be using an unsupported browser.</h6>
        <h6 id="unsupportedBrowserSubheader2">Please use the links below to download a supported browser.</h6>
      </div>
      <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" id="unsupportedBrowserEdgeIcon" rel="noopener">
        <img src="https://assets.gobreeze.com/edge-icon.svg" alt="Edge" />
      </a>
      <a href="https://www.google.com/chrome/" id="unsupportedBrowserChromeIcon" rel="noopener">
        <img src="https://assets.gobreeze.com/chrome-icon.svg" alt="Chrome" />
      </a>
      <a href="https://www.mozilla.org/en-US/firefox/new/" id="unsupportedBrowserFirefoxIcon" rel="noopener">
        <img src="https://assets.gobreeze.com/firefox-icon.svg" alt="Firefox" />
      </a>
    </div>
  </div>
);

export default UnsupportedBrowser;
