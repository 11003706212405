import React, { useEffect, useReducer } from 'react';
import styles from './Insurance.module.scss';
import { Button, FormField, Help, ListControls, Text } from '../../styleguide';
import InsuranceCard from './InsuranceCard';
import {
  reducer,
  getProviderPlans,
  canAddItem,
  canClearItem,
  hasItemDuplicates,
  hasItemValues,
  areItemsValid,
  getInitialState,
  isItemValid,
} from './Insurance.reducer';

export default React.memo(props => {
  const {
    formContext,
    formData,
    onChange,
    schema: {
      title,
      description,
      showCheckmark,
      items: { genders, insuranceTypes, labels, providers, relationshipTypes },
    },
  } = props;

  const [state, dispatch] = useReducer(reducer, formData, getInitialState);
  const { items, itemsValidity, persistentFields } = state;
  const displayItems = items.filter(item => !item.delete);

  useEffect(() => {
    formContext.errors = areItemsValid(displayItems, itemsValidity) ? [] : ['Insurance'];
  }, [items, itemsValidity]);

  useEffect(() => {
    if (items !== formData) {
      onChange(items);
    }
  }, [items]);

  return (
    <>
      {displayItems &&
        displayItems.length > 0 &&
        displayItems.map((item, itemIndex) => (
          <div className={styles.item} key={item.id}>
            <Text
              tag="p"
              text={title}
              type="groupTitle"
              icon={showCheckmark && isItemValid(items, item, itemsValidity)}
            />
            <Help text={description}>
              <InsuranceCard
                formData={item}
                genders={genders}
                insuranceTypes={insuranceTypes}
                labels={labels}
                onUpdate={dispatch}
                onValidate={dispatch}
                persistentFields={persistentFields[item.id]}
                plans={getProviderPlans(providers, item.providerName)}
                providers={providers}
                relationshipTypes={relationshipTypes}
              />

              {hasItemDuplicates(displayItems, item) && (
                <FormField>
                  <Text tag="p" text={labels.duplicateInsuranceError} type="error" />
                </FormField>
              )}

              <ListControls
                showClear={canClearItem(item, persistentFields[item.id])}
                onClear={_ => dispatch({ type: 'clearItem', item, persistentFields: persistentFields[item.id] })}
                canClear
                clearText={labels.insuranceGroupClearItem}
                removeText={labels.insuranceGroupRemoveItem}
                onRemove={_ => dispatch({ type: 'removeItem', item })}
                showRemove
                canRemove
                addText={labels.insuranceGroupAddItem}
                onAdd={_ => dispatch({ type: 'addItem' })}
                canAdd={hasItemValues(item)}
                showAdd={canAddItem(displayItems, itemIndex)}
              />
            </Help>
          </div>
        ))}
      {(!displayItems || displayItems.length < 1) && (
        <>
          <Text tag="p" text={title} type="groupTitle" icon={showCheckmark} />
          <Help text={description}>
            <div className={styles.emptyWrapper}>
              <p>{labels.insuranceGroupEmpty}</p>
              <Button
                main
                secondary
                short
                low
                text={labels.insuranceGroupEmptyAdd}
                onClick={_ => dispatch({ type: 'addItem' })}
              />
            </div>
          </Help>
        </>
      )}
    </>
  );
});
