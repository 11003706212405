import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export default React.memo(props => {
  const {
    destructive = false,
    disabled = false,
    highlight = false,
    long = false,
    low = false,
    main = false,
    onClick,
    primary = false,
    secondary = false,
    short = false,
    text,
  } = props;

  return (
    <button
      className={classNames(
        { [styles['button-primary']]: primary },
        { [styles['button-secondary']]: secondary },
        { [styles.low]: low },
        { [styles.short]: short },
        { [styles.long]: long },
        { [styles.destructive]: destructive },
        { [styles.highlight]: highlight },
        { [styles.main]: main },
      )}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {text}
    </button>
  );
});
