import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageLoader } from '../../ui/page_loader/pageLoader';
import styles from './SearchBoxField.module.scss';
import { Button, SearchField, SelectField, Text } from '..';

const defaultResults = [];

export default React.memo(
  ({
    applyText,
    cancelText,
    queryPlaceholderText,
    statePlaceholderText,
    states,
    titleText,
    placeholderText,
    onCancel,
    onSearch,
    onApply,
    results = defaultResults,
    loading = false,
    children,
    selected,
    query,
  }) => {
    const [filters, setFilters] = useState({ query });
    const hasResults = Array.isArray(results) && results.length > 0;
    const hasQuery = filters?.query || '';
    const canApply = !loading && selected;
    const canCancel = !loading;

    const handleFilterChange = event => {
      const value = event?.target?.value || '';
      const name = event?.target?.name || '';

      setFilters({
        ...filters,
        [name]: value,
      });
    };

    const handleSearchApply = _ => onSearch(filters);
    const handleSearchCancel = _ => {
      const newFilters = {
        ...filters,
        query: '',
      };

      setFilters(newFilters);
      onSearch(newFilters);
    };

    return (
      <>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <p className={styles.title}>{titleText}</p>
            </div>
            <div className={styles.stateContainer}>
              <SelectField
                name="countryState"
                bordered
                disabled={loading}
                onChange={handleFilterChange}
                options={states}
                placeholder={statePlaceholderText}
                value={filters?.countryState}
              />
            </div>
            <div className={styles.queryContainer}>
              <SearchField
                name="query"
                disabled={loading}
                onApply={handleSearchApply}
                onCancel={handleSearchCancel}
                onChange={handleFilterChange}
                placeholder={queryPlaceholderText}
                value={filters?.query}
              />
            </div>
          </div>
          <div className={styles.content}>
            {!loading && hasResults && <div className={styles.results}>{children}</div>}
            {!loading && !hasResults && (
              <div className={styles.empty}>
                {hasQuery && (
                  <>
                    <FontAwesomeIcon icon="search" color="#B0BEC5" />
                    <Text tag="p" text={placeholderText} type="placeholder" />
                  </>
                )}
                {!hasQuery && <FontAwesomeIcon icon="search" color="#ECEFF1" />}
              </div>
            )}
            {loading && <PageLoader className="centered" overlay={false} fixed={false} />}
          </div>
          <div className={styles.footer}>
            <Button secondary main long text={cancelText} onClick={onCancel} disabled={!canCancel} />
            <Button primary main long text={applyText} onClick={onApply} disabled={!canApply} />
          </div>
        </div>
      </>
    );
  },
);
