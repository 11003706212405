import React, { Component } from 'react';

class ShopSSO extends Component {
  constructor(props) {
    super(props);
    const ecwidScript = document.createElement('script');
    ecwidScript.type = 'text/javascript';
    ecwidScript.src = `${window.location.origin}/shop-sso.js`;
    document.head.appendChild(ecwidScript);

    const metaTag = document.createElement('meta');
    metaTag.name = 'viewport';
    metaTag.content = 'width=device-width, initial-scale=1';
    document.head.append(metaTag);
  }
  render() {
    return <div id="store_display" />;
  }
}

export default ShopSSO;
