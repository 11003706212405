import React from 'react';
import classNames from 'classnames';
import styles from './progressmeter.module.scss';

export const ProgressMeter = React.memo(({ progress, theme }) => (
  <div className={styles.progressWrapper}>
    <div className={classNames(styles.progressContainer, styles[theme])}>
      <div className={`progress-circle progress-${progress}`}>
        <span />
      </div>
    </div>
  </div>
));
