export const notification = {
  state: {
    open: false,
    data: {},
    labels: {},
    actions: {},
  },
  reducers: {
    open: (state, { data, labels, actions }) => ({
      ...state,
      open: true,
      data,
      labels,
      actions,
    }),
    close: state => ({
      ...state,
      open: false,
    }),
  },
};
