import React from 'react';
import { Text } from '../styleguide';

export default props => {
  const {
    errorSchema,
    registry: {
      fields: { ObjectField },
    },
    schema: { title, showCheckmark },
  } = props;

  const hasErrors = Object.keys(errorSchema || {}).length > 0;

  return (
    <>
      {title && <Text tag="p" text={title} type="sectionTitle" icon={showCheckmark && !hasErrors} />}
      <ObjectField {...props} />
    </>
  );
};
