export const reducer = (state, action) => {
  switch (action.type) {
    case 'setNoMedsCheckbox': {
      return { ...state, noMedsCheckbox: action.noMedsCheckbox };
    }
    case 'addUnlisted': {
      return {
        ...state,
        unlistedMedsArray: [...state.unlistedMedsArray, { displayName: '', action: 'add' }],
      };
    }
    case 'clearUnlisted': {
      const unlistedMedsArray = state.unlistedMedsArray
        .map(item => {
          if (item.displayName === action.displayName && item.action === 'add') {
            return;
          } else if (item.displayName === action.displayName) {
            return { ...item, action: 'delete' };
          }
          return item;
        })
        .filter(item => item);
      return { ...state, unlistedMedsArray };
    }
    case 'updateUnlisted': {
      const unlistedMedsArray = state.unlistedMedsArray
        .map(item => {
          if (item.displayName === action.payload.displayName && item.action === 'add') {
            return;
          } else if (!item.displayName) {
            return { ...item, ...action.payload };
          }
          return item;
        })
        .filter(item => item);
      return { ...state, unlistedMedsArray };
    }
    case 'removeUnlisted': {
      const unlistedMedsArray = state.unlistedMedsArray
        .map(item => {
          if (item.displayName === action.displayName && item.action === 'add') {
            return;
          } else if (item.displayName === action.displayName) {
            return { ...item, action: 'delete' };
          }
          return item;
        })
        .filter(item => item);
      return { ...state, unlistedMedsArray };
    }
    case 'addListed': {
      return {
        ...state,
        medicationsArray: [
          ...state.medicationsArray,
          {
            action: 'add',
          },
        ],
      };
    }
    case 'clearListed': {
      const medicationsArray = state.medicationsArray
        .map(item => {
          if (item.displayName === action.displayName && item.action === 'add') {
            return;
          } else if (item.displayName === action.displayName) {
            return { ...item, action: 'delete' };
          }
          return item;
        })
        .filter(item => item);
      medicationsArray.splice(action.index, 0, { action: 'add' });
      return { ...state, medicationsArray };
    }
    case 'updateListed': {
      const medicationsArray = state.medicationsArray
        .map(item => {
          if (!item.displayName) {
            return { ...item, ...action.payload };
          } else if (action.payload && item.displayName === action.payload.displayName) {
            return;
          }
          return item;
        })
        .filter(item => item);
      return { ...state, medicationsArray };
    }
    case 'removeListed': {
      const medicationsArray = state.medicationsArray
        .map(item => {
          if (item.displayName === action.displayName && item?.action === 'add') {
            return;
          } else if (item.displayName === action.displayName) {
            return { ...item, action: 'delete' };
          }
          return item;
        })
        .filter(item => item);
      return { ...state, medicationsArray };
    }
    default:
      return state;
  }
};

export const getListed = state => state.medicationsArray.filter(item => item.action !== 'delete')
export const getUnlisted = state => state.unlistedMedsArray
export const getValidListed = state =>
  state.medicationsArray.filter(item => !!item.displayName);
export const getValidUnlisted = state =>
  getUnlisted(state).filter(item => !!item.displayName);
export const getValidState = state => {
  return {
    ...state,
    medicationsArray: getValidListed(state),
    unlistedMedsArray: getValidUnlisted(state),
  };
};
export const getIsCompleted = state => {
  const listed = getValidListed(state);
  const unlisted = getValidUnlisted(state);

  return state.noMedsCheckbox || listed.length > 0 || unlisted.length > 0;
};
