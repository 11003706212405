export const COUNTDOWN_TO_LOGOUT = 600;
export const DISPLAY_LOGOUT_NOTIFICATION_AT = 60;
export const FIVE_HUNDRED_MS = 500;
export const ZERO = 0;
export const SCROLL = 'scroll';
export const LOGOUT_NOTIFICATION_LABELS = {
  en: {
    SECONDS: 'seconds.',
    SESSION_EXPIRE: 'Your session is about to expire. You will be logged out in',
    CONTINUE: 'Continue Session',
    LOGOUT: 'Logout',
  },
  es: {
    SECONDS: 'segundos.',
    SESSION_EXPIRE: 'Su sesión está a punto de expirar. Será desconectado en ',
    CONTINUE: 'Continuar Sesíon',
    LOGOUT: 'Salir',
  },
}[localStorage.getItem('CC-Breeze-Web-language') || 'en'];
