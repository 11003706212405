import React, { Component } from 'react';
import { compose, isEmpty, get } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import { ContentPage } from '..';
import { graphql, withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { store } from '../../../models';
import { GetAddAppointment } from '../../../graphql/queries';
import { parseParams } from '../../services/routing';
import { parseResult } from '../../../graphql/utilities';
import { CHECK_OUT, GUEST } from '../../../constants/Paths';
import { AddAppointment as AddAppointmentConstants, Paths } from '../../../constants/index';
import { addAppointmentContext as AddAppointmentContext } from '../../../contexts';

class AddAppointmentContainer extends Component {
  componentDidMount() {
    store.dispatch.addAppointment.clearStore();
    store.dispatch.addAppointment.resetSelectedOptions();
  }

  componentDidUpdate() {
    const { getAddAppointment, history } = this.props;
    const {
      addAppointment,
      loader: { loading },
    } = store.getState();
    const queryLoading = getAddAppointment && getAddAppointment.loading;
    if (!queryLoading && loading) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
    // check if getAddAppointment.getAddAppointment exists, which means request was successful
    if (isEmpty(addAppointment.addAppointment) && !queryLoading && get(getAddAppointment, 'getAddAppointment')) {
      parseResult({
        query: getAddAppointment,
        dataField: 'getAddAppointment',
        resultHandler: store.dispatch.addAppointment.fetchStart,
      });
      const inCheckOut = this.props.location.pathname.includes(CHECK_OUT);
      const alternateWf = inCheckOut || store.getState().guestEntry.guestMode;
      const {
        practiceDropdownSchema = {},
        patientId = String(),
        defaultPractice: { valueObject: { practiceId, practiceMgmt } = {} } = {},
      } = get(getAddAppointment, 'getAddAppointment.addAppointmentScreen.appointmentWorkflowMain', {});
      if (!isEmpty(getAddAppointment)) {
        store.dispatch.addAppointment.setPracticePatientIds({ practiceId, practiceMgmt, patientId });
        inCheckOut && this.preselectProvider();
        !alternateWf && store.dispatch.addAppointment.setPracticeDropdownSchema({ practiceDropdownSchema });
      }
    }
    // checkin if grapqhl request was called and finished loading
    // and if getAddAppointment.getAppointment is null, which means request returned error
    // and route to appointments if error was returned
    else if (
      !get(getAddAppointment, 'getAddAppointment') &&
      get(getAddAppointment, 'called') &&
      !get(getAddAppointment, 'loading')
    ) {
      history.push('/appointments');
    }
  }
  componentWillUnmount() {
    const { history: { location: { pathname } = {} } = {} } = this.props;
    store.getState().mixpanel.nonActionableMetadata.checkoutInProgress &&
      !pathname.includes(Paths.CHECK_OUT) &&
      store.dispatch.mixpanel.addMetadata({
        checkoutCancelled: true,
        checkoutLastStep: 'Next Appointment',
      });
    store.dispatch.addAppointment.selectDate({ startDate: null });
    store.dispatch.loader.activateLoader({ loading: true });
  }

  preselectProvider = _ => {
    const {
      appointmentCheckOut: { resource_id: checkOutResourceId = NaN } = {},
      addAppointment: { providerCards = [] } = {},
    } = store.getState();
    const toBePreselectedProvider = providerCards.find(p => p.resource_ids === checkOutResourceId);
    store.dispatch.addAppointment.selectProvider(get(toBePreselectedProvider, 'action.param', {}));
  };

  checkOutScheduleLater = _ => {
    store.dispatch.addAppointment.clearStore();
    store.dispatch.appointmentCheckOut.setFromAddAppointment(true);
    return this.props.history.push('/appointments/check_out');
  };

  render() {
    const {
      addAppointment: { addAppointment },
    } = this.props;
    return (
      addAppointment && (
        <AddAppointmentContext.Provider value={{ checkOutScheduleLater: this.checkOutScheduleLater }}>
          <ContentPage schemas={[addAppointment]} />
        </AddAppointmentContext.Provider>
      )
    );
  }
}
const mapStateToProps = ({ addAppointment }) => ({ addAppointment });

const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps),
  graphql(GetAddAppointment, {
    name: 'getAddAppointment',
    skip: () => !isEmpty(store.getState().addAppointment.addAppointment),
    options: props => {
      const {
        appointmentCheckOut: {
          practice_id: checkOutPracticeId = String(),
          patient_id: checkOutPatientId = String(),
          practice_mgmt: checkOutPracticeMgmt = String(),
          provider_id: checkOutProviderId = NaN,
          resource_id: checkOutResourceId = NaN,
        } = {},
        addAppointment: { selectedProviderId },
      } = store.getState();
      const inCheckOut = props.location.pathname.includes(CHECK_OUT);
      const alternateWfData = {
        ...(inCheckOut
          ? {
              practiceId: checkOutPracticeId,
              practiceMgmt: checkOutPracticeMgmt,
              selectedProviderId: checkOutProviderId,
              resourceId: checkOutResourceId.toString(),
              patientId: checkOutPatientId,
            }
          : {}),
      };
      return {
        variables: {
          input: {
            practiceId: parseParams(props).practiceId || alternateWfData.practiceId || (sessionStorage.getItem('practiceSelected') ? sessionStorage.getItem('practiceSelected') : store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_id ),
            practiceMgmt: parseParams(props).practiceMgmt || alternateWfData.practiceMgmt || (sessionStorage.getItem('practiceSelectedMgmt') ? sessionStorage.getItem('practiceSelectedMgmt') : store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_mgmt),
            patientId: parseParams(props).patientId || alternateWfData.patientId,
            selectedProviderId: selectedProviderId || alternateWfData.selectedProviderId,
            inCheckOut,
            isGuest: props.location.pathname.includes(GUEST),
            ...(inCheckOut
              ? {
                  resourceId: checkOutResourceId,
                  requests: [
                    AddAppointmentConstants.VISIT_REASONS,
                    AddAppointmentConstants.LOCATIONS,
                    AddAppointmentConstants.RESOURCES,
                  ],
                }
              : {}),
          },
        },
      };
    },
  }),
);
export default enhancer(AddAppointmentContainer);
