import gql from 'graphql-tag';

export const GetLogin = gql`
  query GetLogin($input: GetLoginInput!) {
    getLogin(input: $input)
  }
`;

// Retrieves signup form based on email invitation link
export const GetSignup = gql`
  query GetSignup($invitationId: String!, $invitationEmail: String!, $sms: String) {
    getSignup(invitationId: $invitationId, invitationEmail: $invitationEmail, sms: $sms)
  }
`;

export const ConfirmDelegateEmail = gql`
  query ConfirmDelegateEmail($input: ConfirmDelegateEmailInput!) {
    confirmDelegateEmail(input: $input)
  }
`;

export const GetSmsSignupConfirmation = gql`
  query GetSmsSignupConfirmation($email: String!) {
    getSmsSignupConfirmation(email: $email)
  }
`;

export const ConfirmSignupEmail = gql`
  query ConfirmSignupEmail($input: ConfirmSignupEmailInput!) {
    confirmSignupEmail(input: $input)
  }
`;

export const ResendConfirmationEmail = gql`
  query resendConfirmationEmail($input: ResendConfirmationEmail!) {
    resendConfirmationEmail(input: $input)
  }
`;

export const GetAppointments = gql`
  query GetAppointments {
    getAppointments
  }
`;
export const GetMoreAppointments = gql`
  query GetMoreAppointments($page: Int!) {
    getMoreAppointments(page: $page)
  }
`;

export const GetPatientStatement = gql`
  query GetPatientStatement($statementId: String!) {
    getPatientStatement(statementId: $statementId)
  }
`;

export const GetGuestPatientStatement = gql`
  query GetGuestPatientStatement($statementId: String!,$practiceId: String!, $patientId: String!, $practiceMgmt: String!) {
    getGuestPatientStatement(statementId: $statementId, practiceId: $practiceId, patientId: $patientId, practiceMgmt: $practiceMgmt)
  }
`;

export const GetMoreTransactions = gql`
  query GetMoreTransactions($page: Int!) {
    getMoreTransactions(page: $page)
  }
`;

export const GetEditPaymentPlan = gql`
  query GetEditPaymentPlan($practiceId: String!, $paymentPlanId: String!) {
    getEditPaymentPlan(practiceId: $practiceId, paymentPlanId: $paymentPlanId)
  }
`;

export const GetAppointmentPrepayment = gql`
  query GetAppointmentPrepayment($input: AddAppointmentPrepaymentInput!) {
    getAppointmentPrepayment(input: $input)
  }
`;

export const GetAppointmentCheckOut = gql`
  query GetAppointmentCheckOut($input: CheckoutInput!) {
    getAppointmentCheckOut(input: $input)
  }
`;

export const GetAvailableHours = gql`
  query GetAvailableHours($input: AvailableHoursInput!) {
    getAvailableHours(input: $input)
  }
`;

export const GetIntelligentScheduler = gql`
  query GetIntelligentScheduler($input: PracticeId) {
    getIntelligentScheduler(input: $input)
  }
`;

export const GetPayments = gql`
  query GetPayments($patientId: String) {
    getPayments(patientId: $patientId)
  }
`;

export const GetOneTimePayment = gql`
  query GetOneTimePayment($practiceId: String!, $paymentPlanId: String!, $oneTimePaymentId: String, $state: String) {
    getOneTimePayment(
      practiceId: $practiceId
      paymentPlanId: $paymentPlanId
      oneTimePaymentId: $oneTimePaymentId
      state: $state
    )
  }
`;

export const GetMakePayments = gql`
  query GetMakePayments($patientId: String!, $practiceId: String!, $guestPaymentData: JSON) {
    getMakePayments(patientId: $patientId, practiceId: $practiceId, guestPaymentData: $guestPaymentData)
  }
`;

export const GetMyHealth = gql`
  query GetMyHealth($input:GetmyhealthInput) {
    getMyHealth(input:$input)
  }
`;

export const GetLayout = gql`
  query GetLayout {
    getLayout
  }
`;

export const GetMessages = gql`
  query GetMessages {
    getMessages
  }
`;

export const GetSupport = gql`
  query GetSupport {
    getSupport
  }
`;

export const GetActivityLog = gql`
  query GetActivityLog {
    getActivityLog
  }
`;

export const GetShop = gql`
  query GetShop {
    getShop
  }
`;

export const GetShopMakePayment = gql`
  query GetShopMakePayment($storeId: String!, $transactionId: String!, $amount: String!) {
    getShopMakePayment(storeId: $storeId, transactionId: $transactionId, amount: $amount)
  }
`;

export const GetAppointmentCheckIn = gql`
  query GetAppointmentCheckIn($input: CheckInIdsInput!, $additionalInfo: AppointmentInformationInput) {
    getAppointmentCheckIn(input: $input, additionalInfo: $additionalInfo)
  }
`;

export const GetSettings = gql`
  query GetSettings($input: SettingsInput) {
    getSettings(input: $input)
  }
`;

export const GetFormHistory = gql`
  query GetFormHistory {
    getFormHistory
  }
`;

export const LoadMoreForms = gql`
  query LoadMoreForms($input: LoadMoreFormsInput!) {
    loadMoreForms(input: $input)
  }
`;

export const SearchPhysicians = gql`
  query SearchPhysicians($input: PhysicianSearchInput!) {
    searchPhysicians(input: $input)
  }
`;

export const ContinueCheckout = gql`
  query continueCheckout($input: CheckoutInput!) {
    continueCheckout(input: $input)
  }
`;

export const ContinueCheckoutPayment = gql`
  query continueCheckoutPayment($input: CheckoutInput!) {
    continueCheckoutPayment(input: $input)
  }
`;

export const ContinueCheckInPayment = gql`
  query ContinueCheckInPayment($input: CheckInIdsInput!) {
    continueCheckInPayment(input: $input)
  }
`;

export const getPdf = gql`
  query GetPdf($patientId: Int!) {
    getPdf(patientId: $patientId)
  }
`;
export const getLab = gql`
  query GetLab($labId: Int!) {
    getLab(labId: $labId)
  }
`;

export const searchAllergies = gql`
  query SearchAllergies($input: SearchMedsAllergiesInput!) {
    searchAllergies(input: $input)
  }
`;

export const searchMedications = gql`
  query SearchMedications($input: SearchMedsAllergiesInput!) {
    searchMedications(input: $input)
  }
`;

export const GetEnvironmentVariables = gql`
  query GetEnvironmentVariables {
    getEnvironmentVariables
  }
`;

export const GetGuestAppointment = gql`
  query GetGuestAppointment {
    getGuestAppointment
  }
`;
export const GetGuestRescheduleAppointment = gql`
  query GetGuestRescheduleAppointment {
    getGuestRescheduleAppointment
  }
`;


export const GetGuestPay = gql`
  query GetGuestPay($input: GetGuestPayInput!) {
    getGuestPay(input: $input)
  }
`;

export const GetGuestPayNoCode = gql`
  query GetGuestPayNoCode($input: GetGuestPayNoCodeInput!) {
    getGuestPayNoCode(input: $input)
  }
`;

export const GetAddAppointment = gql`
  query getAddAppointment($input: GetAddAppointmentInput) {
    getAddAppointment(input: $input)
  }
`;
export const getGuestWorkflowSuccess = gql`
  query getGuestWorkflowSuccess($input: GuestSuccessInput!) {
    getGuestWorkflowSuccess(input: $input)
  }
`;
export const GetDelegateProfile = gql`
  query GetDelegateProfile($input: GetDelegateProfileInput!) {
    getDelegateProfile(input: $input)
  }
`;

export const GetDelegateVerification = gql`
  query GetDelegateVerification($input: GetDelegateVerificationInput!) {
    getDelegateVerification(input: $input)
  }
`;
export const GetDelegateLogin = gql`
  query GetDelegateLogin($input: GetDelegateLoginInput!) {
    getDelegateLogin(input: $input)
  }
`;
export const GetDelegateForgotPassword = gql`
  query GetDelegateForgotPassword {
    getDelegateForgotPassword
  }
`;
export const GetDelegateCreateAccount = gql`
  query GetDelegateCreateAccount($input: GetDelegateCreateAccountInput!) {
    getDelegateCreateAccount(input: $input)
  }
`;

export const GetGetStarted = gql`
  query GetGetStared($language: String) {
    getGetStarted(language: $language)
  }
`;

export const GetSurveys = gql`
  query GetSurveys($input: GetSurveysInput) {
    getSurveys(input: $input)
  }
`;

export const GetUnshortenedUrl = gql`
  query GetUnshortenedUrl($input: GetUnshortenedUrlInput!) {
    getUnshortenedUrl(input: $input)
  }
`;

export const getGuestPaymentReceipt = gql`
  query getGuestPaymentReceipt($input: GuestPaymentReceiptInput!) {
    getGuestPaymentReceipt(input: $input)
  }
`;

export const GetVisitSummary = gql`
  query GetVisitSummary($input: GetVisitSummary) {
    getVisitSummary(input: $input)
  }
`;

export const GetCreateVisitSummary = gql`
  query GetCreateVisitSummary($input: GetCreateVisitSummary) {
    getCreateVisitSummary(input: $input)
  }
`;

export const GetDelegate = gql`
  query GetDelegate {
    getDelegate
  }
`;

export const GetOops = gql`
  query GetOops {
    getOops
  }
`;

export const GetGuestAlreadyDone = gql`
  query GetGuestAlreadyDone($input: GetGuestAlreadyDoneInput!) {
    getGuestAlreadyDone(input: $input)
  }
`;

export const GetUnsupportedBrowser = gql`
  query GetUnsupportedBrowser {
    getUnsupportedBrowser
  }
`;

export const GetVideoVisitUrl = gql`
  query GetVideoVisitUrl($input: GetVideoVisitUrlInput!) {
    getVideoVisitUrl(input: $input)
  }
`;

export const GetMessageAttachment = gql`
  query GetMessageAttachment($input: GetMessageAttachmentInput!) {
    getMessageAttachment(input: $input)
  }
`;
export const GetManageProfiles = gql`
  query GetManageProfiles {
    getManageProfiles
  }
`;
export const GetMoreMessages = gql`
  query GetMoreMessages($input: GetMoreMessagesInput!) {
    getMoreMessages(input: $input)
  }
`;

export const GetDashboard = gql`
  query GetDashboard {
    getDashboard
  }
`;
export const GetAvailablevertexTime = gql`
  query GetAvailablevertexTime($input: VertixAvailbleTimeInput){
    getAvailableVertexTime(input: $input)
  }
`;
export const VertexCreateVisitSummaryQuery = gql`
  query VertexCreateVisitSummary($input: VertexCreateVisitSummaryInput!) {
    vertexCreateVisitSummary(input: $input)
  }
`;
export const DownLoadvertexVisitSummary = gql`
  query DownLoadvertexVisitSummary($input: downLoadvertexVisitSummaryInput!) {
    downLoadvertexVisitSummary(input: $input)
  }
`;