export const MAX_MONTHS_OUT = 15;
export const MAX_DATE_RANGE = 34;
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const MIN_DATE_RANGE = 0;
export const CHOOSE_DATE_ID = 'chooseADifferentDate';
export const CALENDAR_ICON_ID = 'availableHoursDatePickerIcon';
export const ICON_URL = 'https://assets.gobreeze.com/calendar.svg';
export const LEFT_ARROW_URL = 'https://assets.gobreeze.com/arrow-left.svg';
export const RIGHT_ARROW_URL = 'https://assets.gobreeze.com/arrow-right.svg';
export const START_DATE_ID = 'startDateId';
export const END_DATE_ID = 'endDateId';
export const DAY_SIZE = 40;
export const WEEKDAY_FORMAT = 'ddd';
export const PREVIOUS_MONTH = 'previousMonth';
export const NEXT_MONTH = 'nextMonth';
