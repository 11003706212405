import React from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import { FormGroup, Header } from '../index';
import { Utilities } from '../../services';
import styles from './Form.module.scss';

export const Form = ({
  props: { id, header, subHeader, formGroups, completed, formShown, updateForm, theme, checkMark, submitWholeForm },
  props,
}) => {
  return (
    formShown === id && (
      <div id={id} className={styles[theme]}>
        <div className={styles.headingWrapper}>
          {header && (
            <Header
              {...{
                ...header,
                theme: Utilities.parseThemeFromId({ id: header.id }),
              }}
            />
          )}
          {checkMark && completed && <i className="material-icons">done</i>}
        </div>
        {!isEmpty(subHeader) && (
          <Header
            {...{
              ...subHeader,
              theme: Utilities.parseThemeFromId({ id: subHeader.id }),
            }}
          />
        )}

        {formGroups.map(formGroup => (
          <FormGroup key={formGroup.id} props={{ ...props, ...formGroup, updateForm, submitWholeForm }} />
        ))}
      </div>
    )
  );
};
