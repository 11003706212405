import { compose, isEmpty, capitalize } from '@carecloud/cloudpak';
import RJSForm from 'react-jsonschema-form';
import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { withApollo } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import * as Mutations from '../../../graphql/mutations';
import { store } from '../../../models';
import SchemaParser from '../../services/SchemaParser';
import fields from './fields';
import { Session } from '../../services';
import { parseParams } from '../../services/routing';
import styles from './RJSForm.module.scss';
import { isMobileDevice } from '../../services/utilities';

class RJSFormContainer extends PureComponent {
  state = {
    formKey: `${Date.now()}`,
    schema: this.props.schema,
    uiSchema: this.props.uiSchema,
    valid: false,
    formData: this.props.formData,
    validationErrors: {},
    error: String(),
  };
  onChange = ({ errors, formData: rowData }) => {
    const formData = Object.keys(rowData).reduce((acc, key) => {
      const value = rowData[key];
      if (this.hasValue(value)) acc[key] = value;
      return acc;
    }, {});
    this.setState({ formData, valid: !errors.length && this.fieldsValid({ formData }) });
  };

  hasValue = value => {
    if (Array.isArray(value)) {
      return value.some(item => item.action !== 'delete');
    }
    const typeMap = {
      boolean: value,
      undefined: false,
      number: !!`${value}`,
      string: !!`${value}`,
      object: !isEmpty(value),
    };
    const type = typeof value;
    return typeMap[type];
  };

  fieldsValid = ({ formData }) =>
    this.props.multiFieldValidations.reduce((valid, { condition, fields }) => {
      const validations = {
        MATCH: fields => formData[fields[0]] === formData[fields[1]],
        OR: fields => fields.some(field => this.hasValue(formData[field])),
        COMPLETION_EQUAL: fields => fields.every(field => !!formData[field]) || fields.every(field => !formData[field]),
      };
      return valid && validations[condition](fields);
    }, true);

  formOnSubmit = ({ formData }) => {
    store.dispatch.loader.persistLoader({ persist: true });
    this.submit(formData);
  };

  submit = async formData => {
    try {
      const { id, addCurrentParams, createSession } = this.props;
      const postObj = formData;
      let externalInput = {};
      if (addCurrentParams) externalInput = parseParams(this.props);
      const { data } = await this.props.client.mutate({
        mutation: Mutations[id],
        variables: { input: { ...postObj, ...externalInput } },
      });
      this.setState({ formKey: Date.now() });
      const {
        authentication,
        contentRefresh = [],
        redirect,
        session = {},
        profile_id,
        modal,
        type,
        customMessage,
        errorMessage,
        schemaFromError,
        addModalToSchema,
        modalSchema,
      } = data[id];
      contentRefresh.forEach(model => {
        store.dispatch[model].resetState();
      });
      store.dispatch.loader.persistLoader({ persist: false });
      store.dispatch({ type: 'toast/open', payload: data[Object.keys(data)[0]] });
      if (!isEmpty(schemaFromError)) {
        const { model, dataField, schema } = schemaFromError;
        return store.dispatch[model].fetchStart(schema[dataField]);
      }
      Object.keys(session).forEach(key => sessionStorage.setItem(key, session[key]));
      const success = type === 'success';
      if (success) {
        if (!isEmpty(authentication) && isMobileDevice()) return this.props.history.push('/');
        if (createSession) {
          store.dispatch.guestEntry.setGuestMode(false);
          store.dispatch.delegateProfiles.setProfile(profile_id);
          Session.setSession(authentication);
        }
      }
      if (customMessage) store.dispatch.modal.saveCustomMessage(customMessage);
      if (data[id] && modal) {
        const modalId = `${id}${capitalize(modal)}Modal`;
        if (addModalToSchema) store.dispatch[addModalToSchema].addModalToScreen({ modalSchema });
        return store.dispatch.modal.toggleOpen(modalId);
      }
      if (errorMessage) this.setState({ error: errorMessage });
      if (redirect) {
        this.props.history.push(redirect); //NEED SOME CONDITION FOR GUEST TRANSITIONS
        store.dispatch.loader.activateLoader({ loading: true });
        return store.dispatch.guestEntry.setReloadGuest(true);
      }
    } catch (error) {
      store.dispatch.loader.persistLoader({ persist: false });
      console.error(error);
      this.setState({ formKey: Date.now() });
      return false;
    }
  };
  addErrors = (label, errors) =>
    this.setState(({ validationErrors }) => ({ validationErrors: { ...validationErrors, [label]: errors } }));
  validate = (formData, errors) => {
    const valid = this.fieldsValid({ formData });
    if (!valid) errors.addError('Fields Invalid');
    return errors;
  };
  render() {
    const { id, heading, leftSide, submitButton, title, description } = this.props;
    const { schema, uiSchema, formData, valid, validationErrors, error } = this.state;
    const buttonDisabled =
      !valid || Object.keys(validationErrors).reduce((acc, key) => acc || validationErrors[key].length, false);
    return (
      <div className={classNames(styles.wrapper, styles.id)}>
        <SchemaParser schema={[heading]} />
        <div className={styles.contentWrapper}>
          {leftSide && (
            <div className={styles.leftSideWrapper}>
              <SchemaParser schema={leftSide} />
              {!isEmpty(submitButton) && (
                <button
                  id={submitButton.id}
                  className={classNames(styles.submitButton, { [styles.disabled]: buttonDisabled })}
                  onClick={_ => this.submitButton.click()}
                >
                  {submitButton.submitText}
                </button>
              )}
            </div>
          )}
          {error && <div className={styles.errorMessage}>{error}</div>}
          <div className={classNames(styles.formWrapper, styles[id])}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            <RJSForm
              key={this.state.formKey}
              liveValidate
              validate={this.validate}
              showErrorList
              ErrorList={_ => null}
              schema={schema}
              uiSchema={uiSchema}
              formContext={{ id, addErrors: this.addErrors }}
              fields={fields}
              onSubmit={this.formOnSubmit}
              formData={formData}
              onChange={this.onChange}
            >
              <button
                ref={btn => {
                  this.submitButton = btn;
                }}
                style={{ display: 'none' }}
              />
            </RJSForm>
            {!leftSide && !isEmpty(submitButton) && (
              <button
                id={submitButton.id}
                className={classNames(styles.submitButtonBottom, { [styles.disabled]: buttonDisabled })}
                onClick={_ => this.submitButton.click()}
              >
                {submitButton.submitText}
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const enhancer = compose(
  withApollo,
  withRouter,
);
export default enhancer(RJSFormContainer);
