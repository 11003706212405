import { useEffect, useRef } from 'react';
import Bowser from 'bowser';
import { get } from '@carecloud/cloudpak';
import { Utilities } from '../../constants';

export const compressImage = (file, callBackFunc) => {
  const maxWidth = 800;
  const maxHeight = 800;
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onloadend = event => {
    const img = new Image();
    img.src = get(event, 'target.result', '');
    img.onload = () => {
      if (img.width > maxWidth) {
        img.height = Math.round((img.height * maxWidth) / img.width);
        img.width = maxWidth;
      }
      if (img.height > maxHeight) {
        img.width = Math.round((img.width * maxHeight) / img.height);
        img.height = maxHeight;
      }
      const elem = document.createElement('canvas');
      elem.width = img.width;
      elem.height = img.height;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width, img.height);
      ctx.canvas.toBlob(
        blob => {
          const reducedFile = new File([blob], file.name, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          const reducedReader = new FileReader();
          reducedReader.readAsDataURL(reducedFile);
          reducedReader.onloadend = reducedEvent => {
            callBackFunc(reducedFile, get(reducedEvent, 'target.result', ''));
          };
        },
        'image/jpeg',
        1,
      );
    };
  };
};

export const parseThemeFromId = ({ id }) => {
  if (Number.isInteger(id)) return null;
  return id.split(/[0-9]/)[0];
};
/* eslint-enable no-unused-vars*/
export const transition = ({ start, end, duration }) => {
  setTimeout(() => {
    end();
  }, duration);
  start();
};

export const formatMoney = ({ amount, minDigits = 2, maxDigits = 2 }) =>
  amount.toLocaleString('EN', { minimumFractionDigits: minDigits, maximumFractionDigits: maxDigits });

export const filterEmptyAddressLines = obj => {
  if (!obj) return;
  const lines = Object.values(obj);
  return lines.filter(line => line !== '').length;
};

export const serializeObjForParams = obj =>
  Object.keys(obj)
    .map(k => encodeURIComponent(`${k}=${obj[k]}&`))
    .join('');

export const deSerializeObjValues = obj => {
  const newObj = obj;
  Object.keys(newObj).forEach(k => {
    newObj[k] = decodeURIComponent(newObj[k]);
  });
  return newObj;
};

export const parseWorkflowFromSURLPath = str =>
  str
    .split('/')[3]
    .charAt(0)
    .toUpperCase() + str.split('/')[3].slice(1);

// https://stackoverflow.com/a/24600597 as navigator.maxTouchPoints is incompatible with Safari
export const isMobileDevice = _ => /Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent);

export const getMobileDevice = _ => {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/windows phone/i.test(userAgent)) return Utilities.WINDOWS;
  if (/android/i.test(userAgent)) return Utilities.GOOGLE;
  if (/iPad|iPhone|iPod/.test(userAgent)) return Utilities.APPLE;
  return Utilities.DESKTOP;
};

export const isValidBrowser = _ => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  return browser.getBrowser().name !== 'Internet Explorer';
};

// copied from Cyclops
export const formatMonth = (dateToParse = String()) => {
  const parsedMonth = parseInt(dateToParse.toString().substring(5, 7), 10);
  const monthFormat = 'MMMM';
  const trunctatedMonthFormat = 'MMM';
  if (parsedMonth < 5 || parsedMonth > 7) {
    return trunctatedMonthFormat;
  }
  return monthFormat;
};

export const averageFromArray = valuesArr => valuesArr.reduce((acc, curr) => acc + curr, 0) / valuesArr.length;

export const breakBackButton = _ => {
  /* eslint-disable */
  history.pushState(null, null, location.href);
  window.onpopstate = function() {
    window.history.go(1);
  };
};
// ref: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
export const useIntervalHook = (cb, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = cb;
  }, [cb]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
