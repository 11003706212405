/*eslint-disable camelcase */
import React, { Component } from 'react';
import { compose, get, isEmpty } from '@carecloud/cloudpak';
import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { store } from '../../../../models';
import { ContentPage } from '../..';
import { ContinueCheckout, ContinueCheckoutPayment, GetAppointmentCheckOut } from '../../../../graphql/queries';
import { parseResult } from '../../../../graphql/utilities';
import { ContextProvider } from '../../../root/TopLevelContext';
import { Paths } from '../../../../constants/index';
import { breakBackButton } from '../../../services/utilities';

class AppointmentCheckOutContainer extends Component {
  static contextType = ContextProvider;
  componentDidMount() {
    const {
      appointment_id,
      patient_id,
      practice_id,
      externalCheckoutData,
      appointmentCheckOutPayment,
      fromAddAppointment,
    } = this.props.appointmentCheckOut;
    this.context._setActions({
      ...(isEmpty(appointmentCheckOutPayment)
        ? { successRedirect: this.successRedirect, continueCheckout: this.continueCheckout }
        : { continueCheckout: this.continueCheckout }),
    });
    /*eslint-disable camelcase */
    if (!(appointment_id && patient_id && practice_id)) this.props.history.push('/appointments');
    if (externalCheckoutData || fromAddAppointment) this.continueCheckout();
    breakBackButton();
  }
  async componentDidUpdate() {
    const {
      appointmentCheckOut,
      getAppointmentCheckOut,
      creditCard: { processing },
    } = this.props;
    if (
      get(getAppointmentCheckOut, 'getAppointmentCheckOut.submissionResult.success') &&
      isEmpty(appointmentCheckOut.appointmentCheckOut)
    ) {
      store.dispatch.appointmentCheckOut.fetchStart({
        appointmentCheckOutScreen: getAppointmentCheckOut?.getAppointmentCheckOut?.appointmentCheckOutScreen,
      });
      this.killLoaders();
      return store.dispatch.modal.toggleOpen('checkoutDoneModal');
    }
    if (appointmentCheckOut.refetch && !getAppointmentCheckOut.loading) {
      store.dispatch.loader.activateLoader({ loading: true });
      await this.refetch({ getAppointmentCheckOut });
      return store.dispatch.appointmentCheckOut.didRefetch();
    }

    if (isEmpty(appointmentCheckOut.appointmentCheckOutPayment)) {
      const queryLoading = getAppointmentCheckOut && getAppointmentCheckOut.loading;
      if (!queryLoading && store.getState().loader.loading && !processing) {
        store.dispatch.loader.activateLoader({ loading: false });
      }
      if (isEmpty(appointmentCheckOut.appointmentCheckOut) && !queryLoading) {
        parseResult({
          query: getAppointmentCheckOut,
          dataField: 'getAppointmentCheckOut',
          resultHandler: store.dispatch.appointmentCheckOut.fetchStart,
        });
        if (get(getAppointmentCheckOut, 'getAppointmentCheckOut.continueNext')) {
          return this.continueCheckout();
        }
        if (get(getAppointmentCheckOut, 'getAppointmentCheckOut.redirectSurvey')) {
          const {
            getAppointmentCheckOut: { surveysSelection, surveys, surveysHeading },
          } = getAppointmentCheckOut;
          return this.continueCheckoutSurvey({ surveysSelection, surveys, surveysHeading });
        }
        if (get(getAppointmentCheckOut, 'getAppointmentCheckOut.redirectAddAppointment')) {
          return this.props.history.push('/appointments/check_out/add_appointment');
        }
        store.dispatch.mixpanel.addMetadata({ checkoutNextAppointment: true });
      }
    }
  }

  componentWillUnmount() {
    const { appointmentCheckOut, history: { location: { pathname } = {} } = {} } = this.props;
    store.dispatch.loader.activateLoader({ loading: true });
    const checkOutPayment = !isEmpty(appointmentCheckOut.appointmentCheckOutPayment);
    checkOutPayment && store.dispatch.appointmentCheckOut.resetStore();
    store.dispatch.appointments.resetState();
    if (!pathname.includes(Paths.CHECK_OUT) && store.getState().mixpanel.nonActionableMetadata.checkoutInProgress) {
      store.dispatch.mixpanel.addMetadata({
        checkoutCancelled: true,
        checkoutLastStep: checkOutPayment ? 'Payments' : 'Next Appointment',
      });
    }
    store.dispatch.appointmentCheckOut.resetPaymentPlanCreated();
  }

  killLoaders = _ => {
    store.dispatch.loader.activateLoader({ loading: false });
    store.dispatch.loader.persistLoader({ persist: false });
  };

  continueCheckoutSurvey = ({ surveysSelection, surveys, surveysHeading }) => {
    store.dispatch.mixpanel.addNonActionableMetadata({ checkoutSurveyAvailable: true });
    store.dispatch.surveys.fetchStart({ surveysSelection, survey: surveys, surveysHeading });
    store.dispatch.surveys.setCheckoutSurvey();
    store.dispatch.surveys.didRefetch();
    this.killLoaders();
    this.props.history.push('/appointments/check_out/survey');
  };

  continueCheckout = async (params = {}) => {
    store.dispatch.loader.persistLoader({ persist: true });
    const { checkoutPayment: fromPayment = false, checkoutPrePayment: fromPrePayment = false } = params;
    const {
      appointment_id,
      patient_id,
      practice_id,
      practice_mgmt,
      externalCheckoutData,
      paymentPlanCreated,
    } = store.getState().appointmentCheckOut;
    store.getState().modal.modalOpen && store.dispatch.modal.toggleClose();
    document.body.classList.remove('modal-open');
    store.dispatch.radioInput.resetRadioInput();
    store.dispatch.paymentPlan.clearPaymentOption();
    const { data } = await this.props.client.query({
      query: fromPayment ? ContinueCheckoutPayment : ContinueCheckout,
      variables: {
        input: {
          // missing these when going out of container
          appointment_id,
          patient_id,
          practice_id,
          practice_mgmt,
          externalCheckoutData,
          paymentPlanCreated,
        },
      },
    });
    store.dispatch.appointmentCheckOut.setFromAddAppointment(false);
    fromPayment && store.dispatch.appointmentCheckOut.resetAppointmentCheckOutPaymentStore();
    fromPrePayment && store.dispatch.appointmentPrepayment.clearStore();
    if (get(data, 'continueCheckout.redirectSurvey')) {
      const {
        continueCheckout: { surveysSelection, surveys, surveysHeading },
      } = data;
      return this.continueCheckoutSurvey({ surveysSelection, surveys, surveysHeading });
    }
    if (get(data, 'continueCheckoutPayment.redirectSurvey')) {
      const {
        continueCheckoutPayment: { surveysSelection, surveys, surveysHeading },
      } = data;
      return this.continueCheckoutSurvey({ surveysSelection, surveys, surveysHeading });
    }
    if (get(data, 'continueCheckout.submissionResult.success')) {
      const appointmentCheckOutScreen = data?.continueCheckout?.appointmentCheckOutScreen;
      const { fetchStart, storeRedirectPath } = store.dispatch.appointmentCheckOut;
      isEmpty(store.getState().appointmentCheckOut.appointmentCheckOutScreen) &&
        fetchStart({ appointmentCheckOutScreen });
      storeRedirectPath(data.continueCheckout.submissionResult.redirect);
      this.killLoaders();
      return store.dispatch.modal.toggleOpen('checkoutDoneModal');
    } else if (fromPayment) {
      store.dispatch.mixpanel.addMetadata({
        successRedirect: true,
        extraMixpanelBaggage: get(data, 'continueCheckoutPayment.submissionResult.extraMixpanelBaggage'),
      });
      store.dispatch.appointments.resetState();
      this.props.history.push('/appointments');
    } else {
      if (data && !isEmpty(data.continueCheckout)) {
        store.dispatch.appointmentCheckOut.continueCheckout(data.continueCheckout);
      }
      store.dispatch.appointmentCheckOut.fetchCheckOutPayment({ appointmentCheckOutPayment: [] });
      store.dispatch.appointmentCheckOut.fetchCheckOutPayment({
        appointmentCheckOutPayment: get(data, 'continueCheckout.appointmentCheckOutScreen', []),
      });
      store.dispatch.mixpanel.addMetadata({ checkoutPayment: true });
    }
    if (fromPrePayment) this.props.history.push('/appointments/check_out');
    this.killLoaders();
  };
  successRedirect = ({ extraMixpanelBaggage }) => {
    const {
      appointmentCheckOut: { path },
    } = this.props;
    store.dispatch.appointments.resetState();
    store.dispatch.mixpanel.addMetadata({
      successRedirect: true,
      extraMixpanelBaggage,
    });
    path ? this.props.history.push(`/${path}`) : this.props.history.push('/appointments');
  };
  refetch = async ({ getAppointmentCheckOut }) => {
    const { data } = await getAppointmentCheckOut.refetch();
    if (!data) return;
    if (get(data, 'getAppointmentCheckOut.success', false)) {
      store.dispatch.appointments.resetState();
      this.props.history.push(`/${data.getAppointmentCheckOut.submissionResult.redirect}`);
    } else {
      store.dispatch.appointmentCheckOut.resetStore();
      store.dispatch.appointmentCheckOut.fetchCheckOutPayment(data.getAppointmentCheckOut);
    }
  };

  render() {
    const {
      appointmentCheckOut: { appointmentCheckOut, appointmentCheckOutPayment },
    } = this.props;
    return (
      <ContentPage
        schemas={isEmpty(appointmentCheckOutPayment) ? [appointmentCheckOut] : [appointmentCheckOutPayment]}
      />
    );
  }
}
const mapStateToProps = ({ appointmentCheckOut, creditCard }) => ({
  appointmentCheckOut,
  creditCard,
});
const enhancer = compose(
  withApollo,
  connect(mapStateToProps),
  graphql(GetAppointmentCheckOut, {
    name: 'getAppointmentCheckOut',
    skip: ({ appointmentCheckOut: { appointment_id, patient_id, practice_id, fromAddAppointment } }) =>
      !(appointment_id && patient_id && practice_id) || fromAddAppointment,
    options: ({ appointmentCheckOut: { appointment_id, patient_id, practice_id, practice_mgmt, paymentPlanCreated } }) => ({
      variables: {
        input: {
          appointment_id,
          patient_id,
          practice_id,
          practice_mgmt,
          paymentPlanCreated,
        },
      },
    }),
  }),
);
export default enhancer(AppointmentCheckOutContainer);
