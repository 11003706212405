import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import styles from './icon.module.scss';
import { store } from '../../../models';
import { ContextProvider } from '../../root/TopLevelContext';
import * as babyContexts from '../../../contexts';

const icon = React.memo(({ icon, theme, id, action = {}, route, history, onClick, prefix }) => {
  const { model = String(), trigger = String(), param = String(), babyContext = String() } = action;
  const grandpaContext = useContext(ContextProvider);
  const variableContext = babyContext && useContext(babyContexts[`${babyContext}Context`]);
  if (!isEmpty(action)) {
    return (
      <div id={id}>
        <span className={styles.prefix}>{prefix}</span>
        <FontAwesomeIcon
          id={id}
          icon={icon}
          className={styles[theme]}
          onClick={() => {
            if (model === 'localActions') grandpaContext._getAction(trigger) && grandpaContext._getAction(trigger)(param);
            else if (babyContext) variableContext[trigger](param);
            else store.dispatch[model][trigger](param);
          }}
        />
      </div>
    );
  }
  if (route) {
    return <FontAwesomeIcon id={id} icon={icon} className={styles[theme]} onClick={() => history.push(route)} />;
  }
  return (
    <FontAwesomeIcon
      id={id}
      icon={icon}
      className={styles[theme]}
      onClick={() => {
        onClick && onClick();
      }}
    />
  );
});

export const Icon = icon;
