import React, { memo, useContext, useEffect } from 'react';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { RadioInput } from './radioInput';
import { store } from '../../../models';
import { ContextProvider } from '../../root/TopLevelContext';
import * as babyContexts from '../../../contexts';

const RadioInputContainer = memo(props => {
  const { radioInput, groupName, value, setDefault, action = {}, label , cloverapi,payeezy } = props;
  const { model = String(), trigger = String(), param = String(), babyContext = String() } = action;
  const grandpaContext = useContext(ContextProvider);
  const variableContext = babyContext && useContext(babyContexts[`${babyContext}Context`]);
  const { updateValue, updateValueId } = store.dispatch.radioInput;

  const handleClick = async event => {
    try {
      if (!isEmpty(action)) {
        if (model === 'localActions') grandpaContext._getAction(trigger)(param);
        else if (babyContext) variableContext[trigger](param);
        else if (model === 'formGroup' && trigger === 'change') {
          const paramFormGroup = { id: groupName, value, fieldValid:true, paymentValid: false, isInitial: undefined, formMapping: groupName };
          store.dispatch[model][trigger](paramFormGroup);
        }
        else store.dispatch[model][trigger](param);
      }
      if (groupName.includes('selectPractice')) {
        store.dispatch.radioInput.updateValue('selectBusiness', label)
        store.dispatch.radioInput.updateValue('selectBusinessValue', value)
        store.dispatch.radioInput.updateValue('payeezy', payeezy)
        store.dispatch.radioInput.updateValue('cloverapi', cloverapi)
        
        store.dispatch.button.enableButton({
          btn: 'selectPracticeContinueBtn',
          complete: true,
        });
      }
      if (groupName.includes('intelligentScheduler')) {
        const questionNumberIdx = parseInt(props.id.substring(props.id.indexOf('Q') + 1, props.id.length - 2), 10) - 1;
        store.dispatch.intelligentScheduler.setAnswered(questionNumberIdx, value);
        store.dispatch.intelligentScheduler.setVisitType(props.visit_type);
        store.dispatch.intelligentScheduler.setSchedulerResourceSelection(props.schedulerResourceSelection);
        store.dispatch.addAppointment.setPrePayment(props.prePayment?.amount ? props.prePayment?.amount : 0)
      }
      if (groupName.includes('twofaRadio')){
        if (value === 'phone'){
          document.getElementById('twofaEmailInputWrapper').style.display = 'none';
          document.getElementById('twofaPhoneInputWrapper').style.display = 'block';
        }
        if (value === 'email'){
          document.getElementById('twofaPhoneInputWrapper').style.display = 'none';
          document.getElementById('twofaEmailInputWrapper').style.display = 'block';
        }
      }
      event.target.dataset.value
        ? updateValue(event.target.name, `${event.target.value} data: ${event.target.dataset.value}`)
        : updateValue(event.target.name, event.target.value);
      updateValueId(`${event.target.name}Value`, event.target.id);
      await store.dispatch.mixpanel.addNonActionableMetadata({ [event.target.name]: label });
    } catch (error) {
      window.console.log(error);
      store.dispatch.loader.persistLoader({ persist: false });
    }
  };

  const handleChange = event => handleClick(event);

  useEffect(() => {
    setDefault && updateValue(groupName, value);
    // if a form has a radio save it in redux in formgroup
    if (model === 'formGroup' && trigger === 'change' && props.checked) {
      const paramFormGroup = { id: groupName, value, fieldValid:true, paymentValid: false, isInitial: true, formMapping: groupName };
      store.dispatch[model][trigger](paramFormGroup);
    }
  }, []);

  return <RadioInput {...{ ...props, handleChange, radioInput }} />;
});

const mapStateToProps = ({ radioInput }) => ({ radioInput });

const enhancer = compose(withApollo, withRouter, connect(mapStateToProps));
export default enhancer(RadioInputContainer);
