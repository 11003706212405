import { graphql, withApollo } from 'react-apollo';
import { get, compose } from '@carecloud/cloudpak';
import React, { PureComponent } from 'react';
import axios from 'axios';
import moment from 'moment';
import { store } from '../../../models';
import {
  CreateMessage,
  DeleteMessage,
  MarkMessageRead,
  MarkMessageUnread,
  ReplyMessage,
} from '../../../graphql/mutations';
import { GetMessages, GetMessageAttachment } from '../../../graphql/queries';
import { MessagesSection } from './MessagesSection';

class MessagesSectionContainer extends PureComponent {
  state = { value: '', showHelperText: false, file: String() };
  async componentDidMount() {
    const {
      messages: {
        refetch,
        viewMessagesOf: { param: currentMessageId },
      },
    } = this.props;
    if (typeof refetch === 'undefined') {
      await this.getLastMessageTime(currentMessageId);
      this.getLastMessageToScroll(currentMessageId);
    }
    window.scrollTo(0, 0);
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      messages: {
        subjectEvent: { value: subjectValue } = {},
        sendNewMessage,
        deleteMessage,
        markAsRead,
        markAsUnread,
        messageToResend,
        viewMessagesOf: { param: currentMessageId } = {},
      },
    } = this.props;
    const {
      messages: { createNew, providerSelected, viewMessagesOf: { param: previousMessageId } = {} },
    } = prevProps;
    // Hide missing subject tooltip when subject has been entered (tests for white space only subjects)
    if (subjectValue && !/^\s+$/.test(subjectValue) && createNew && prevState.value) {
      this.setState({ showHelperText: false }); // eslint-disable-line react/no-did-update-set-state
    }
    // Show missing subject tooltip when subject is empty/only contains white space and cancel the send message mutation
    if ((!subjectValue || /^\s+$/.test(subjectValue)) && prevState.value && sendNewMessage && createNew) {
      this.setState({ showHelperText: true }); // eslint-disable-line react/no-did-update-set-state
      return store.dispatch.messages.sendMessages(false);
    }
    // Clear message input when changing conversations
    if (currentMessageId !== previousMessageId) {
      this.setState({ value: '' }); // eslint-disable-line react/no-did-update-set-state
      (await currentMessageId) && this.getLastMessageTime(currentMessageId);
      currentMessageId && this.getLastMessageToScroll(currentMessageId);
    }
    // Sends a new reply in an existing conversation
    if (prevState.value && sendNewMessage && !createNew) {
      return this.handleReplyMessage();
    }
    // Attempts to re-send a failed reply
    if (messageToResend && sendNewMessage && !createNew) {
      return this.handleRetryMessage(messageToResend);
    }
    // Creates a new conversation (tests for white space only subjects)
    const { value, file } = this.state;
    if (
      (value || file) &&
      subjectValue &&
      !/^\s+$/.test(subjectValue) &&
      sendNewMessage &&
      createNew &&
      providerSelected
    ) {
      return this.handleCreateMessage();
    }
    // Deletes an entire conversation
    if (deleteMessage) {
      store.dispatch.messages.deleteMessage(false);
      return this.handleDeleteMessage();
    }
    if (markAsUnread) {
      store.dispatch.messages.markUnread(false);
      return this.handleMarkUnread();
    }
    if (markAsRead) {
      store.dispatch.messages.markRead(false);
      return this.handleMarkRead();
    }
  }

  onChange = _ => e => {
    const { createNew } = this.props.messages;
    // Store current value of the user's reply message
    this.setState({ value: e });
    // If the user clears their message while the helper tooltip is open, hide it.
    if (!e && createNew) {
      this.setState({ showHelperText: false });
    }
  };

  getLastMessageTime = id => {
    const providerMessage = this.providerMessage({ id });
    if (!providerMessage) return;
    const conversationLength = this.conversationLength(providerMessage);
    const lastMessageTime = providerMessage.messages[conversationLength - 1].createdAt;
    const currentTime = moment();
    const format = moment(lastMessageTime).isBefore(currentTime, 'week') ? 'MM/DD/YYYY h:mm A' : 'dddd h:mm A';
    const formattedTime = moment(lastMessageTime)
      .local()
      .format(format);
    store.dispatch.messages.setConversationTime(formattedTime);
  };

  getLastMessageToScroll = id => {
    const providerMessage = this.providerMessage({ id });
    if (!providerMessage) return;
    const conversationLength = this.conversationLength(providerMessage);
    const lastMessageDiv = providerMessage.messages[conversationLength - 1].id;
    return this.scrollToBottom(lastMessageDiv);
  };

  handleUpload = async e => {
    e.preventDefault();
    if (!e.target.value) return;
    const fileNameArray = e.target.value.split(/\/|\\/);
    const fileName = fileNameArray[fileNameArray.length - 1];
    const [, fileFormat = String()] = fileName.match(/\.([^.]+)$/);
    const file = e.target.files[0];
    const form = new FormData();
    form.append('file', file);
    const token = sessionStorage.getItem('CC-Breeze-Web-authenticationToken');
    const { dmsEndpoint } = store.getState().messages.messagesScreen;
    const {
      data: { nodeid },
    } = await axios.post(`${dmsEndpoint}?token=${token}`, form);
    this.setState({ file: nodeid, fileFormat, fileName });
  };
  providerMessage = ({ id }) => this.props.providerMessages.find(message => message.message_id === id);

  conversationLength = providerMessage => providerMessage.messages.length;

  scrollToBottom = element => {
    const hasElement = document.getElementById(element);
    hasElement &&
      hasElement.scrollIntoView({
        block: 'center',
        inline: 'nearest',
      });

    const Root = document.getElementById('root');
    Root &&
      Root.scrollIntoView({
        block: 'start',
        inline: 'nearest',
      });
  };

  handleReplyMessage = async _ => {
    const {
      messages: {
        messagesScreen: { userId, messagesSection: { providerMessages } = {} } = {},
        viewMessagesOf: { param },
      },
    } = this.props;
     const providerID =  providerMessages.find(message => message.message_id === param).provider_id;
     // Get all mutation variables for sending a new message
     const input = {
       body: this.state.value,
       messageId: param,
       username: sessionStorage.getItem('CC-Breeze-Web-username'),
       userId:userId.toString(),
       providerId:providerID.toString(),
     };

    // Obtain message conversation's length to include in new reply's id
    const messageLength = providerMessages.find(message => message.message_id === param).messages.length;

    // Obtain user's name to display in new reply (front-end only)
    const messageUser = providerMessages.find(message => message.message_id === param).user.toLowerCase();

    // Get all values to render new message on screen until next refresh (front-end only)
    const newMessage = {
      id: `messageSent${param}${messageLength}`,
      component: 'MESSAGE',
      content: this.state.value,
      provider: messageUser,
      type: 'sent',
      createdAt: moment(),
    };

    try {
      // clear message input
      this.setState({ value: '' });
      // Instantly display new reply (front-end only)
      await store.dispatch.messages.appendNewMessage({
        newMessage,
        conversationId: param,
      });
      this.scrollToBottom(`messageSent${param}${messageLength}`);
      // Start animation for message being sent
      store.dispatch.messages.toggleSending({
        id: `messageSent${param}${messageLength}`,
        sending: true,
      });
      // Trigger mutation to add new reply
      const data = await this.props.replyMessage({ variables: { input } });
      if (data.data.replyMessage === 200) {
        // Update header timestamp of conversation
        this.getLastMessageTime(param);
        // End animation
        store.dispatch.messages.toggleSending({
          id: `messageSent${param}${messageLength}`,
          sending: false,
        });
      }
    } catch (error) {
      console.error(error);
      this.scrollToBottom(`messageSent${param}${messageLength}`);
      // Store failed reply message details
      store.dispatch.messages.storeFailedMessage({
        ...input,
        replyId: `messageSent${param}${messageLength}`,
      });
      // End animation
      store.dispatch.messages.toggleSending({
        id: `messageSent${param}${messageLength}`,
        sending: false,
      });
    }
  };

  handleRetryMessage = async messageToResend => {
    const {
      failedMessages,
      viewMessagesOf: { param: messageId },
    } = this.props.messages;
    // Get failed message to re-send in mutation
    const failedMessage = failedMessages.find(conversation => conversation.replyId === messageToResend);
    const input = Object.assign({}, failedMessage);
    delete input.replyId;

    try {
      this.scrollToBottom(messageToResend);
      store.dispatch.messages.sendMessages(false);
      // Start animation for message being re-sent
      store.dispatch.messages.toggleSending({
        id: messageToResend,
        sending: true,
      });
      // Trigger mutation to re-send reply
      const data = await this.props.replyMessage({ variables: { input } });
      if (data.data.replyMessage === 200) {
        // Update header timestamp of conversation
        this.getLastMessageTime(messageId);
        await store.dispatch.messages.removeFailedMessage(messageToResend);
        // End animation
        store.dispatch.messages.toggleSending({
          id: messageToResend,
          sending: false,
        });
      }
    } catch (error) {
      console.error(error);
      this.scrollToBottom(messageToResend);
      // End animation
      store.dispatch.messages.toggleSending({
        id: messageToResend,
        sending: false,
      });
    }
  };

  handleCreateMessage = async _ => {
    const {
      messages: {
        providerSelected: { patient_id, provider_id, provider, practice_id } = {},
        subjectEvent: { value: subjectValue } = {},
      } = {},
      client,
    } = this.props;
    // Get all mutation variables for creating a new conversation
    const { file, fileFormat, fileName } = this.state;
    const input = {
      patientId: patient_id.toString(),
      providerId: provider_id.toString(),
      providerName: provider,
      subject: subjectValue,
      body: this.state.value,
      nodeId: file,
      fileFormat,
      description: fileName,
      practiceId: practice_id,
    };
    try {
      // clear message input
      this.setState({ value: '', file: '', fileFormat: '', fileName: '' });
      // Trigger mutation to add new conversation
      await this.props.createMessage({ variables: { input } });
      // Refetch messages to utilize stored message details
      const { data: { getMessages: { messagesScreen = {} } = {} } = {} } = await client.query({
        query: GetMessages,
      });
      store.dispatch.messages.fetchStart({ messagesScreen: {} });
      store.dispatch.messages.fetchStart({ messagesScreen });
      store.dispatch.messages.sendMessages(false);
      store.dispatch.messages.didRefetch(false);
      const { providerMessages } = get(messagesScreen, 'messagesSection', []);
      // Get all values to render new conversation on screen until next refresh
      const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
      let attachments = [];
      if (practiceMgmt === 'carecloud') {
        if (file) {
          attachments = [
            {
              document: {
                document_format: fileFormat,
                description: fileName,
                name: fileName,
                document_handler: file,
              },
            },
          ];
        }
      } else {
        attachments = providerMessages[0].messages[0].attachments || [];
      }
      const newConversation = {
        id: `newMessageConversation${providerMessages[0].message_id}`,
        message_id: providerMessages[0].message_id,
        provider,
        user: providerMessages[0].user.toLowerCase(),
        read: true,
        subject: subjectValue,
        provider_id:providerMessages[0].provider_id,
        messages: [
          {
            id: `messageSent${providerMessages[0].message_id}`,
            component: 'MESSAGE',
            provider: providerMessages[0].user.toLowerCase(),
            content: providerMessages[0].messages[0].content,
            type: 'sent',
            attachments,
          },
        ],
      };
      // Set timestamp of new conversation
      store.dispatch.messages.setConversationTime(
        moment(providerMessages[0].messages[0].createdAt)
          .local()
          .calendar(),
      );
      // Instantly display new conversation
      store.dispatch.messages.appendNewConversation({ newConversation });
      // Set new conversation as the selected conversation
      store.dispatch.messages.viewMessages({
        param: providerMessages[0].message_id,
        messageSubject: subjectValue,
        provider,
        read: true,
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleDeleteMessage = async _ => {
    const {
      messages: {
        viewMessagesOf: { param },
      },
    } = this.props;
    // Trigger mutation to send a delete selected message conversation
    try {
      await this.props.deleteMessage({ variables: { messageId: param } });
      store.dispatch.messages.removeSelectedConversation({
        conversationId: param,
      });
      store.dispatch.modal.toggleCancel(true);
    } catch (error) {
      console.error(error);
    }
  };

  handleMarkRead = async _ => {
    const {
      messages: {
        viewMessagesOf: { param },
      },
    } = this.props;

    // Trigger mutation to send a delete selected message conversation
    try {
      await this.props.markMessageRead({ variables: { messageId: param } });
      store.dispatch.messages.removeUnreadMarker({ conversationId: param });
    } catch (error) {
      console.error(error);
    }
  };

  handleMarkUnread = async _ => {
    const {
      messages: {
        viewMessagesOf: { param },
      },
    } = this.props;

    // Trigger mutation to send a delete selected message conversation
    try {
      await this.props.markMessageUnread({ variables: { messageId: param } });
      store.dispatch.messages.addUnreadMarker({ conversationId: param });
    } catch (error) {
      console.error(error);
    }
  };

  getAttachment = ({ nodeId, fileName, fileType }) => async () => {

    store.dispatch.loader.activateLoader({ loading: true });
    try {
      const { data } = await this.props.client.query({
        query: GetMessageAttachment,
        variables: { input: { nodeId } },
      });
      store.dispatch.loader.activateLoader({ loading: false });
      if (fileType === 'xml' || fileType === 'html') {
        let mimeType;
        if (fileType === 'xml') {
          mimeType = 'application/xml';
        } else if (fileType === 'html') {
          mimeType = 'text/html';
        }
        const blob = new Blob([data.getMessageAttachment], {
          type: mimeType,
        });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);
      } else {
        store.dispatch.loader.activateLoader({ loading: false });
        const downloadLink = document.createElement('a');
        downloadLink.href = `data:application/pdf;base64,${data.getMessageAttachment}`;
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
    } catch (error) {
      console.error('Error downloading file:', error);
      store.dispatch.loader.activateLoader({ loading: false });
    }
  };

  render() {
    const {
      messages: {
        lastMessageTime,
        markAsRead,
        providerSelected,
        createNew,
        viewMessagesOf,
        viewMessagesOf: { read },
        subjectEvent: { value: subjectValue },
      },
    } = this.props;
    const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
    return (
      <MessagesSection
        {...{
          ...this.props,
          props: this.props,
          markAsRead,
          read,
          providerSelected,
          createNew,
          viewMessagesOf,
          subjectValue,
          lastMessageTime,
          showHelperText: this.state.showHelperText,
          value: this.state.value,
          onChange: this.onChange(),
          handleUpload: this.handleUpload,
          getAttachment: this.getAttachment,
          hasAttachment: !!this.state.file,
          practiceMgmt,
        }}
      />
    );
  }
}

const enhancer = compose(
  withApollo,
  graphql(ReplyMessage, { name: 'replyMessage' }),
  graphql(CreateMessage, { name: 'createMessage' }),
  graphql(DeleteMessage, { name: 'deleteMessage' }),
  graphql(MarkMessageUnread, { name: 'markMessageUnread' }),
  graphql(MarkMessageRead, { name: 'markMessageRead' }),
);
export default enhancer(MessagesSectionContainer);
