import React, { memo } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ClientSideModal } from '../clientSideModal';
import { store } from '../../../models';
import { apolloErrorContext as ApolloErrorContext } from '../../../contexts';

const ApolloErrorContainer = memo(props => {
  const { clientSideModal: { transform, modalOpen, message } } = props;
  const backToLogin = _ => {
    window.sessionStorage.clear();
    store.dispatch.clientSideModal.toggleClose();
    this.props.history.push('/');
  };

  return (
    <ApolloErrorContext.Provider value={{ backToLogin }}>
      <ClientSideModal
        {...{
          ...props,
          id: 'apolloErrorModal',
          transform,
          modalOpen,
          message,
          iconId: 'apolloErrorIcon',
          iconIcon: 'exclamation-triangle',
          buttons: [
            {
              id: 'apolloErrorBtn',
              theme: 'apolloErrorBtn',
              text: 'Return To Login',
              route: '/login',
              action: { trigger: 'backToLogin', babyContext: 'apolloError' },
              disabled: false,
            },
          ],
        }}
      />
    </ApolloErrorContext.Provider>
  );
});

const mapStateToProps = ({ clientSideModal }) => ({ clientSideModal });

export default connect(mapStateToProps)(withRouter(ApolloErrorContainer));
