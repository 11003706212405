import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { store } from '../../../models';
import MiddlewareErrorPage from './MiddlewareErrorPage';
import ErrorPage from './ErrorPage';

class ErrorPageContainer extends Component {
  componentDidMount() {
    this.refreshPage();
  }

  componentWillUnmount() {
    store.dispatch.errorPage.resetState();
  }

  refreshPage = _ => {
    const { errorPage: { errorOccurred, middlewareErrorOccurred } } = this.props;
    errorOccurred &&
      store.dispatch.errorPage.showErrorPage({ errorOccurred: false });
    middlewareErrorOccurred &&
      store.dispatch.errorPage.showMiddlewareErrorPage({ middlewareErrorOccurred: false });
  };

  render() {
    const {
      children,
      errorPage: {
        errorOccurred,
        middlewareErrorOccurred,
        errorMessage,
        errorSubMessage,
      },
    } = this.props;
    if (
      (process.env.DEPLOYMENT_ENV === 'development' ||
        process.env.NODE_ENV !== 'production') &&
      middlewareErrorOccurred
    ) {
      return (
        <MiddlewareErrorPage
          props={{
            ...this.props,
            clickHandler: this.refreshPage,
            errorMessage,
            errorSubMessage,
          }}
        />
      );
    }
    if (errorOccurred) {
      return (
        <ErrorPage props={{ ...this.props, clickHandler: this.refreshPage }} />
      );
    }
    return children;
  }
}
const mapStateToProps = ({ loader, errorPage }) => ({ loader, errorPage });
const enhancer = compose(withRouter, connect(mapStateToProps));

export default enhancer(withRouter(ErrorPageContainer));
