import { isEmpty } from '@carecloud/cloudpak';
import React from 'react';
import classNames from 'classnames';
import { Button, Image } from '..';
import { formatMoney, filterEmptyAddressLines } from '../../services/utilities';
import styles from './radioInput.module.scss';
import VidoeVisitGrayIcon from '../../../assets/images/videovisit-grayicon.svg';

const radioInput = React.memo(
  ({
    theme,
    id,
    groupName,
    value,
    label,
    description,
    icon,
    subLabel,
    details,
    close,
    alt,
    handleChange,
    checked,
    svg,
    hidden,
    prePayment,
    controlChecked,
    radioInput: { visitTypeRadioValue, locationRadioValue },
    radioInput,
    data,
    props,
    setDefault,
    defaultLabel,
    disabled,
    expanderId,
    disabledLabel,
    visit_reason_id: visitReasonId,
    location_ids: locationIds,
    video_option,
  }) => (
    <label htmlFor={id}>
      <div
        className={classNames(
          {
            [styles[theme]]: theme,
            [styles.disabled]: disabled,
            [styles.descriptionRadio]: !isEmpty(description),
            [styles.descriptionRadioExtended]: filterEmptyAddressLines(description) > 2,
            [styles.hidden]: radioInput.hide === id,
            [styles.addAppointmentDisabled]:
              ((visitTypeRadioValue && visitReasonId) || (locationRadioValue && locationIds)) &&
              ![locationRadioValue, visitTypeRadioValue].includes(id),
          },
          styles.radioContainer,
        )}
      >
        <input
          type={disabled ? 'hidden' : 'radio'}
          className={styles.radioInput}
          onChange={handleChange}
          id={id.toString()}
          name={groupName}
          value={value}
          defaultChecked={checked}
          checked={controlChecked && !disabled && id === radioInput[controlChecked]}
          data-value={data}
          hidden={hidden}
          disabled={hidden}
        />
        <span className={styles.disabledLabel}>{disabledLabel}</span>
        {setDefault && <span className={styles.defaultLabel}>{defaultLabel}</span>}
        {prePayment && !groupName.includes('intelligentScheduler') && (
          <div className={styles.prePayment}>
            {`${prePayment.label}: $${formatMoney({ amount: prePayment.amount })}`}
          </div>
        )}
        <div className={styles.labelContainer}>
          {svg && <i className="material-icons">{svg}</i>}
          {(icon || video_option) && (
            <Image
              {...{
                id: 'icon',
                theme: 'icon',
                src: icon || (video_option && VidoeVisitGrayIcon),
                alt,
              }}
            />
          )}
          <div>
            <span className={classNames({ [styles.firstLabelDescription]: !isEmpty(description) })}>{label}</span>
            {!isEmpty(description) && (
              <div className={styles.descriptionLines}>
                {Object.values(description).map((l, i) => (
                  <span key={`${l}${i}`}>{l}</span> // eslint-disable-line react/no-array-index-key
                ))}
              </div>
            )}
            {details && (
              <div>
                <div className={styles.radioButtons}>
                  <Button
                    {...{
                      ...props,
                      id: `${id}DetailsBtn`,
                      text: details,
                      type: 'button',
                      action: { babyContext: 'expander', trigger: 'expandContent', param: expanderId },
                    }}
                  />
                  <Button
                    {...{
                      ...props,
                      id: `${id}CloseBtn`,
                      text: close,
                      type: 'button',
                      action: { babyContext: 'expander', trigger: 'expandContent', param: expanderId },
                    }}
                  />
                </div>
                {subLabel && <h6 className={styles.subLabel}>{subLabel}</h6>}
              </div>
            )}
          </div>
        </div>
      </div>
    </label>
  ),
);

export const RadioInput = radioInput;
