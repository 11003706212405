import React, { useState } from 'react';
import classNames from 'classnames';
import { DateRangePicker } from '..';
import moment from 'moment';
import SchemaParser from '../../services/SchemaParser';
import styles from './dateTimeSelect.module.scss';
import { store } from '../../../models';

export const DateTimeSelect = React.memo(
  ({ dateTimeSection, title, id, datePickerLabel, clickHandler, isSelected, noAvailableHours, labels }) => {
    const isSpanish =
      (store.getState().guestEntry?.guestScreen[2]?.text === 'Español' ||
        store.getState().layout?.layoutScreen?.pageHeader?.rightSection?.components[0]?.text === 'Español') &&
      true;

    const [closedDays, addClosedDay] = useState(new Set());
    const isClosed = id => closedDays && closedDays.has(id);
    const handleChevronClick = ({ id }) => {
      if (isClosed(id)) {
        const copied = closedDays;
        copied.delete(id);
        return addClosedDay(new Set(copied));
      }
      return addClosedDay(new Set(closedDays.add(id)));
    };
    return (
      <div id={id} className={styles.wrapper}>
        <div className={styles.heading}>
          <h3>{title}</h3>
          <DateRangePicker {...{ datePickerLabel, labels, id }} />
        </div>
        {!dateTimeSection.some(section => section.time.times.length > 0) ? (
          <div className={styles.noAvailableHours}>
            <SchemaParser schema={noAvailableHours} />
          </div>
        ) : (
          dateTimeSection.map(({ date, prependedLabel = String(), format, time, id }) => (
            <div className={styles.dateTimeSection} key={id}>
              <h4 className={styles.dateTimeLabel}>
                {date &&
                  `${prependedLabel}${moment(date)
                    .local()
                    .format(isSpanish ? 'D [de] MMMM [de], YYYY' : format)}`}
              </h4>
              <i
                onClick={_ => handleChevronClick({ id })}
                id="expand"
                role="button"
                tabIndex="0"
                className={classNames(styles.chevron, 'material-icons', { [styles.rotateChevron]: isClosed(id) })}
              >
                keyboard_arrow_down
              </i>
              <div className={classNames(styles.timeSection, { [styles.timeSectionHidden]: isClosed(id) })}>
                {time &&
                  time.times.map(({ id, start_time, end_time }) => (
                    <div
                      key={id}
                      role="menuItem"
                      tabIndex="0"
                      className={isSelected({ timeId: id }) ? styles.selectedTimeBubble : styles.timeBubble}
                      id={id}
                      onClick={() => clickHandler({ selected: id, value: { start_time, end_time } })}
                    >
                      <p>
                        {moment(start_time)
                          .local()
                          .format(time.format)}
                      </p>
                    </div>
                  ))}
                {time.times.length < 1 && (
                  <div className={styles.noAvailableHours}>
                    <SchemaParser schema={noAvailableHours} />
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    );
  },
);
