import React, { PureComponent } from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import classNames from 'classnames';
import styles from './radios.module.scss';

class radios extends PureComponent {
  constructor(props) {
    super(props);
    const { schema, formData, onChange } = props;
    this.state = { [schema.uuid]: formData };
    onChange(formData);
  }
  render() {
    const {
      schema,
      required,
      uiSchema: { booleans, requiredLabel, errorRequiredLabel } = {},
      onChange,
      rawErrors = [],
      errors,
    } = this.props;
    const isBoolean = schema.type === 'boolean';
    const radios = isBoolean ? booleans : schema['x-schema-form'].titleMap;
    return (
      <div>
        <div className={classNames(styles.radioButtons, { [styles.radioButtonsBoolean]: isBoolean })}>
          <div className={styles.label}>
            <label className={styles.title}>
              {schema.title} {'  '}
              {required && <span className={styles.required}>{requiredLabel || required}</span>}
            </label>
          </div>
          {radios.map(({ value, name }) => (
            <React.Fragment key={name}>
              <label htmlFor={`${value}`} style={{ display: 'flex' }}>
                <div className={styles.radioContainer}>
                  <input
                    type="radio"
                    id={`${value}`}
                    name={schema.uuid}
                    value={name}
                    checked={this.state[schema.uuid] === value}
                    onChange={_ => {
                      this.setState({ [schema.uuid]: value });
                      onChange(value);
                    }}
                  />
                </div>
                <div className={styles.radioLabel}>
                  <div className={classNames({ [styles.isSelected]: this.state[schema.uuid] === value })}>{name}</div>
                </div>
              </label>
            </React.Fragment>
          ))}
        </div>
        {!isEmpty(rawErrors)
          ? rawErrors.map(error => (
            <div className={styles.errorMessage} key={error}>
              {errorRequiredLabel}
            </div>
          ))
          : errors &&
            errors.map(error => (
              <div key={error} className={styles.errorMessage}>
                {error}
              </div>
            ))}
        {schema.description && (
          <div
            className={classNames(styles.supportingText, { [styles.supportingTextGreen]: typeof this.state[schema.uuid] !== 'undefined' })}
          >
            {schema.description}
          </div>
        )}
      </div>
    );
  }
}

export default radios;
