/* eslint-disable camelcase */
/* here be dragons, abandon all hope ye who enter */
import * as mix from 'mixpanel-browser';
import { isEmpty, set, get } from '@carecloud/cloudpak';
import { store } from '../../models';
import { Events, Properties, FormGroupChanges, DayOfWeekMap } from '../../constants/Mixpanel';
import { parseParams } from './routing';
import Environment from '../../graphql/Environment';

class Mix {
  constructor() {
    this.enableTracking = Environment.get('MIXPANEL_ENABLED');
    mix.init(Environment.get('MIXPANEL_TOKEN'));
    this.persistedData = {};
  }
  set(userProperties) {
    this.enableTracking && mix.people.set(userProperties);
  }
  identify(identifier) {
    this.enableTracking && mix.identify(identifier);
  }
  track(eventName, propertyObj) {
    this.enableTracking && mix.track(eventName, propertyObj);
  }
  register(propertyObj) {
    this.enableTracking && mix.register(propertyObj);
  }
  increment(eventName, incrementBy) {
    this.enableTracking && mix.people.increment(eventName, incrementBy);
  }
  time(eventName) {
    this.enableTracking && mix.time_event(eventName);
  }
  requestAppointmentInput({
    practice_id,
    practice_name,
    appointment_type,
    provider_id,
    patient_id,
    location_id,
    visit_reason,
    pre_payment,
    inCheckout,
  }) {
    this.track(Events.APPOINTMENT_REQUESTED, {
      practice_id,
      practice_name,
      appointment_type,
      provider_id,
      patient_id,
      location_id,
      visit_reason,
      payment_made: pre_payment,
    });
    this.increment(Events.APPOINTMENT_REQUESTED, 1);
    inCheckout && this.checkoutNextAppointmentDone();
  }
  cancelAppointment({
    practice_id,
    practice_name,
    cancellationComments,
    patient_id,
    provider_id: providerId,
    location_id: locationId,
    appointment_type,
    payment_amount = 0,
    cancellationReason,
  }) {
    const getCancellationReason = _ => {
      if (cancellationReason === Properties.OTHER) {
        if (!cancellationComments) return Properties.OTHER;
        return cancellationComments;
      }
      return cancellationReason;
    };
    this.track(Events.APPOINTMENT_CANCELLED, {
      cancellation_reason: getCancellationReason(),
      practice_id,
      practice_name,
      patient_id,
      providerId,
      locationId,
      appointment_type,
      payment_amount,
    });
    this.increment(Events.APPOINTMENT_CANCELLED, 1);
  }
  newUserSignup() {
    const { nonActionableMetadata: { signup: { practiceId: practice_id, usedBtn: used_signup_button = false } = {} } = {} } = store.getState().mixpanel;
    this.track(Events.NEW_USER_SIGNUP, { practice_id, used_signup_button });
  }
  updateDocuments() {
    const isCheckIn = window.location.href.includes(Properties.CHECK_IN);
    const { practice_id, appointment_id, location_id, provider_id } = store.getState().appointmentCheckIn;
    const { documentsOrDemographicsUpdated } = this.persistedData;
    if (documentsOrDemographicsUpdated) {
      documentsOrDemographicsUpdated[FormGroupChanges.CHECKIN_INSURANCE_UPLOAD] &&
        this.track(Events.ADD_INSURANCE_PHOTO, {
          updated_during_checkin: isCheckIn,
          ...(isCheckIn ? { practice_id, appointment_id, location_id, provider_id } : {}),
        });
      documentsOrDemographicsUpdated[FormGroupChanges.CHECKIN_ID_UPLOAD] &&
        this.track(Events.ADD_IDENTITY_DOCUMENT, {
          updated_during_checkin: isCheckIn,
          ...(isCheckIn ? { practice_id, appointment_id, location_id, provider_id } : {}),
        });
      documentsOrDemographicsUpdated[Properties.UPDATED_DEMOGRAPHICS] &&
        this.track(Events.UPDATED_DEMOGRAPHICS, { updated_during_checkin: isCheckIn });

      return delete this.persistedData.documentsOrDemographicsUpdated;
    }
  }
  updateNotifications() {
    const { notificationsUpdated } = this.persistedData;
    notificationsUpdated && this.track(Events.UPDATED_NOTIFICATIONS, {});
    return delete this.persistedData.notificationsUpdated;
  }
  checkIn() {
    const {
      appointment_type,
      practice_id,
      appointment_id,
      provider_id,
      patient_id,
      location_id,
    } = store.getState().appointmentCheckIn;
    if (!store.getState().mixpanel.nonActionableMetadata.checkinInProgress) {
      this.track(Events.CHECKIN_STARTED, {
        appointment_type,
        practice_id,
        appointment_id,
        is_guest_user: false,
        provider_id,
        patient_id,
        location_id,
        identity_document_available:
          !isEmpty(store.getState().form.fields) &&
          get(store.getState().form, 'fields.checkInDocumentSectionIdentityLabel.length', 0) !== 0,
        insurance_available:
          !isEmpty(store.getState().form.fields) &&
          get(store.getState().form, 'fields.checkInDocumentSectionInsuranceUpload.length', 0) !== 0,
      });
      this.time(Events.CHECKIN_TIME);
      store.dispatch.mixpanel.addNonActionableMetadata({ checkinInProgress: true });
    }
  }
  checkOut({ appointment_type, practice_id, appointment_id, provider_guid: provider_id, location_guid: location_id }) {
    this.track(Events.CHECKOUT_STARTED, {
      appointment_type,
      practice_id,
      appointment_id,
      provider_id,
      location_id,
      is_guest_user: false,
    });
    this.time(Events.CHECKOUT_TIME);
    store.dispatch.mixpanel.addNonActionableMetadata({ checkoutInProgress: true });
  }
  formGroupChange(payload) {
    const { metaData, isInitial, initialValue } = payload;
    const isCheckIn = window.location.href.includes(Properties.CHECK_IN);
    const changeMapFunction = {
      allergySelect: _ =>
        metaData.changedTotal && this.track(Events.UPDATE_ALLERGIES, { allergies_update_count: metaData.changedTotal }),
      medicationSelect: _ =>
        metaData.changedTotal &&
        this.track(Events.UPDATE_MEDICATIONS, { medications_update_count: metaData.changedTotal }),
      [FormGroupChanges.CHECKIN_INSURANCE_UPLOAD]: _ => {
        this.persistedData = set(
          this.persistedData,
          `documentsOrDemographicsUpdated.${FormGroupChanges.CHECKIN_INSURANCE_UPLOAD}`,
          true,
        );
      },
      [FormGroupChanges.CHECKIN_ID_UPLOAD]: _ => {
        this.persistedData = set(
          this.persistedData,
          `documentsOrDemographicsUpdated.${FormGroupChanges.CHECKIN_ID_UPLOAD}`,
          true,
        );
      },
      [FormGroupChanges.SMS_NOTIFICATIONS]: _ => {
        this.persistedData = set(
          this.persistedData,
          `notificationsUpdated.${FormGroupChanges.SMS_NOTIFICATIONS}`,
          true,
        );
      },
      [FormGroupChanges.EMAIL_NOTIFICATIONS]: _ => {
        this.persistedData = set(
          this.persistedData,
          `notificationsUpdated.${FormGroupChanges.EMAIL_NOTIFICATIONS}`,
          true,
        );
      },
    }[payload.id];
    if (changeMapFunction && !isInitial) return changeMapFunction(payload);

    const isSettingsDemographics = window.location.href.includes(Properties.SETTINGS_DEMOGRAPHICS);
    const isInfoCheck = store.getState().appointmentCheckIn.formId === Properties.INFO_CHECK_PAGE;

    if (!isInitial && !initialValue && (isSettingsDemographics || (isCheckIn && isInfoCheck))) {
      this.persistedData = set(
        this.persistedData,
        `documentsOrDemographicsUpdated.${Properties.UPDATED_DEMOGRAPHICS}`,
        true,
      );
    }
  }
  modalOpen(payload) {
    const eventMap = {
      makePaymentsCancelPayment: _ =>
        this.track(Events.PAY_LATER_SELECTED, { balance_amount: store.getState().mixpanel.nonActionableMetadata.paymentAmount }),
      cancelAppointmentModal: _ => this.track(Events.APPOINTMENT_CANCELLATION_STARTED, {}),
    };
    const modalEvent = eventMap[payload && payload.replace(/([0-9])\w+/, String())];
    if (!modalEvent) return;
    modalEvent(payload);
  }
  mixpanelEvent(payload) {
    const {
      loginSuccess,
      educationLinkButton,
      viewDetails,
      labRecord,
      medicalRecord,
      formComplete,
      successRedirect,
      paymentType,
      creditCardUpdate,
      error,
      leftShop,
      baseProperties,
      surveyComplete,
      paymentPlanCancelled,
      paymentPlanSubmittedOrEdited,
      scheduledPayment,
      scheduledPaymentDeleted,
      surveyStarted,
      surveyCancelled,
      patientFormsCheckin,
      patientFormsComplete,
      demographicsDocsCheckin,
      medsAllergiesCheckin,
      intakeFormsCheckin,
      intakeFormsCheckinComplete,
      checkoutNextAppointment,
      checkoutPayment,
      checkoutPaymentDone,
      cancellationPaymentStarted,
      createAccount,
      checkinStarted,
      checkoutCancelled,
      checkoutLastStep,
      checkinCancelled,
      checkinLastStep,
      hamburgerMenu,
      addToExistingPaymentPlan,
      extraMixpanelBaggage: { partialPaymentAllowed } = {},
    } = payload;
    error && this.errorEvent(error);
    if (leftShop) {
      this.track(Events.LEFT_SHOP, {});
      this.track(Events.SHOPPING_SESSION_TIME, {});
    }
    creditCardUpdate &&
      this.track(Events.UPDATED_CREDIT_CARDS, { updated_during_payment: !window.location.href.includes(Properties.PROFILE_SETTINGS) });
    loginSuccess && this.track(Events.SUCCESSFUL_LOGIN, { login_type: Properties.PASSWORD, ...baseProperties });
    labRecord && this.track(Events.VIEW_LAB_RESULT, {});
    medicalRecord && this.track(Events.VIEW_MEDICAL_RECORD, {});
    formComplete &&
      this.track(Events.COMPLETED_FORMS, {
        completed_forms_count: formComplete.total,
        forms_type: formComplete.type === Properties.PRACTICE_FORM ? Properties.CONSENT_FORM : formComplete.type,
      });
    if (successRedirect && window.location.href.includes(Properties.CHECK_IN)) {
      this.checkInComplete();
    }
    successRedirect &&
      window.location.href.includes(Properties.CHECK_OUT) &&
      this.checkOutComplete({ partialPaymentAllowed });
    checkinStarted && this.checkIn();
    paymentPlanCancelled && this.increment(Events.TOTAL_PAYMENT_PLAN_CANCELLED, 1);
    hamburgerMenu === Properties.SUPPORT_PATH && this.track(Events.HELP_BUTTON_CLICKED, {});
    if (paymentPlanSubmittedOrEdited) {
      const {
        practiceId: practice_id,
        paymentPlanId: payment_plan_id,
        totalAmount: plan_amount,
        frequencyCode: frequency,
        paymentAmount: payment_amount,
        paymentDay: payment_day,
        installments: installment_count,
        isNew,
      } = paymentPlanSubmittedOrEdited;
      this.track(isNew ? Events.PAYMENT_PLAN_SUBMITTED : Events.PAYMENT_PLAN_EDITED, {
        practice_id,
        payment_plan_id,
        plan_amount,
        frequency,
        payment_amount,
        payment_day: frequency === Properties.MONTHLY ? payment_day : DayOfWeekMap[payment_day],
        installment_count,
      });
    }
    scheduledPayment && this.increment(Events.TOTAL_SCHEDULED_PAYMENTS, 1);
    scheduledPaymentDeleted && this.increment(Events.TOTAL_SCHEDULED_PAYMENT_DELETED, 1);
    checkoutCancelled && this.track(Events.CHECKOUT_CANCELLED, { last_step: checkoutLastStep });
    if (checkinCancelled) {
      this.track(Events.CHECKIN_CANCELLED, { last_step: checkinLastStep });
    }
    if (surveyStarted) {
      const { is_guest_mode, location_id, practice_id, provider_id, access_mode } = payload;
      this.time(Events.SURVEY_TIME);
      this.track(Events.SURVEY_STARTED, {
        is_guest_mode,
        location_id,
        practice_id,
        provider_id,
        access_mode,
      });
      this.persistedData.surveys = { location_id, practice_id, provider_id, is_guest_mode, access_mode };
    }
    patientFormsCheckin && this.time(Events.CHECKIN_PATIENT_FORMS);
    if (patientFormsComplete) {
      this.track(Events.CHECKIN_COMPLETED_FORMS, {
        form_type: Properties.PATIENT_FORMS,
        completed_forms_count: payload.patientFormsCount,
      });
      this.track(Events.CHECKIN_PATIENT_FORMS, {});
    }
    demographicsDocsCheckin && this.time(Events.CHECKIN_DEMOGRAPHICS_DOCUMENTS);
    medsAllergiesCheckin && this.time(Events.CHECKIN_MEDICATIONS_ALLEGRIES);
    intakeFormsCheckin && this.time(Events.CHECKIN_INTAKE_FORMS);
    if (intakeFormsCheckinComplete) {
      this.track(Events.CHECKIN_COMPLETED_FORMS, {
        form_type: Properties.INTAKE_FORMS,
        completed_forms_count: payload.intakeFormsCount,
      });
      this.track(Events.CHECKIN_INTAKE_FORMS, {});
    }
    checkoutNextAppointment && this.time(Events.CHECKOUT_NEXT_APPOINTMENT);
    checkoutPayment && this.time(Events.CHECKOUT_PAYMENT);
    if (checkoutPaymentDone) {
      this.persistedData.checkout = { paymentMade: true };
      this.track(Events.CHECKOUT_PAYMENT, {});
    }
    createAccount &&
      this.track(Events.CREATE_NEW_ACCOUNT, {
        used_create_new_account_button: payload.createAccount.usedBtn,
        practice_id: payload.createAccount.practiceId,
      });
    if (cancellationPaymentStarted) {
      const { practice_id, provider_id, location_id } = payload;
      const { nonActionableMetadata: { paymentAmount: amount } = {} } = store.getState().mixpanel;
      this.track(Events.CANCELLATION_PAYMENT_STARTED, { practice_id, provider_id, location_id, amount });
    }
    if (paymentType === Properties.CREATE_PAYMENT_PLAN || addToExistingPaymentPlan) {
      const { mixpanel: { nonActionableMetadata: { paymentAmount: balance_amount, practiceId: practice_id } = {} } = {} } = store.getState();
      this.track(Events.PAYMENT_PLAN_STARTED, {
        balance_amount,
        practice_id,
        is_add_existing: !!addToExistingPaymentPlan,
      });
      this.increment(Events.PAYMENT_PLAN_CREATED, 1);
    }
    if (surveyComplete) {
      surveyComplete.belowThreshold
        ? this.increment(Events.TOTAL_UNSATISFIED_SURVEYS_COMPLETE, 1)
        : this.increment(Events.TOTAL_SATISFIED_SURVEYS_COMPLETE, 1);
      this.increment(Events.TOTAL_SURVEYS_COMPLETE, 1);
      this.track(Events.SURVEY_TIME, {});
      const { surveys: { location_id, practice_id, provider_id, is_guest_mode, access_mode } = {} } = this.persistedData;
      this.track(Events.SURVEY_COMPLETED, {
        location_id,
        practice_id,
        provider_id,
        is_guest_mode,
        access_mode,
        rating_given: surveyComplete.rating_given,
      });
    }
    if (surveyCancelled) {
      const { surveys: { location_id, practice_id, provider_id, access_mode } = {} } = this.persistedData;
      this.track(Events.SURVEY_CANCELLED, { location_id, practice_id, provider_id, access_mode });
    }
    if (educationLinkButton) {
      const medication_name = store.getState().mixpanel.viewDetails.item.RX;
      this.track(Events.VIEW_MEDICATION_EDUCATION, { medication_name });
    }
    if (viewDetails) {
      const { table, item } = viewDetails;
      const eventFunction = {
        [Properties.MEDS_TABLE]: () => this.track(Events.VIEW_MEDICATIONS_DETAIL, { medication_name: item.RX }),
        [Properties.ALLERGIES_TABLE]: () => this.track(Events.VIEW_ALLERGIES_DETAIL, { allergy_name: item.Name }),
      }[table];
      if (eventFunction) eventFunction();
    }
  }
  errorEvent({
    operation: {
      operationName,
      variables: { input },
    },
  }) {
    this.apolloAfterwareResponse({ event: Events.FAILED_PAYMENT, operationName, input });
  }
  queryResponse({ operationName, variables = {} }) {
    const { error } = store.getState().mixpanel;
    isEmpty(error)
      ? this.apolloAfterwareResponse({ event: Events.COMPLETE_PAYMENT, operationName, input: variables.input })
      : store.dispatch.mixpanel.addMetadata({ error: null });
  }
  apolloAfterwareResponse({ event, operationName, input = {} }) {
    const errorMutationsFunction = {
      MakePayment: () =>
        this.track(event, {
          payment_amount: input.amount,
          payment_method_type: Properties.CARD_ON_FILE,
        }),
      MakeShopPayment: () =>
        this.track(event, {
          payment_amount: input.amount,
          payment_method_type: Properties.CARD_ON_FILE,
        }),
      MakeCheckoutPayment: () =>
        this.track(event, {
          payment_amount: input.amount,
          payment_method_type: Properties.CARD_ON_FILE,
        }),
      MakeOneTimePayment: () => {
        this.increment(Events.TOTAL_ONE_TIME_PAYMENTS_MADE, 1);
        return this.track(event, {
          payment_amount: input.amount,
          payment_method_type: Properties.CARD_ON_FILE,
        });
      },
      MakeCancellationPayment: () =>
        this.track(event, {
          payment_amount: input.amount,
          payment_method_type: Properties.CARD_ON_FILE,
        }),
      MakeCheckInPayment: () =>
        this.track(event, {
          payment_amount: input.amount,
          payment_method_type: Properties.CARD_ON_FILE,
        }),
      MakePrepayment: () => {
        this.increment(Events.TOTAL_PREPAYMENTS_MADE, 1);
        return this.track(event, {
          payment_amount: input.prepayment,
          payment_method_type: Properties.CARD_ON_FILE,
        });
      },
      MakeCheckoutPrepayment: () =>
        this.track(event, {
          payment_amount: input.prepayment,
          payment_method_type: Properties.CARD_ON_FILE,
        }),
    }[operationName];
    if (errorMutationsFunction) {
      errorMutationsFunction();
      if (event === Events.COMPLETE_PAYMENT) {
        this.increment(Events.TOTAL_PAYMENTS_MADE, 1);
        this.increment(Events.TOTAL_PAYMENTS_AMOUNT, input.amount);
      }
    }
  }
  formSubmitted() {
    store.getState().formGroup.formShown === Properties.INFO_CHECK_PAGE &&
      this.track(Events.CHECKIN_DEMOGRAPHICS_DOCUMENTS, {});
    store.getState().formGroup.formShown === Properties.MEDS_ALLERGIES &&
      this.track(Events.CHECKIN_MEDICATIONS_ALLEGRIES, {});
  }
  checkoutNextAppointmentDone() {
    this.track(Events.CHECKOUT_NEXT_APPOINTMENT, {});
  }
  checkOutComplete({ partialPaymentAllowed }) {
    const {
      appointment_type,
      practice_id,
      appointment_id,
      provider_guid: provider_id,
      location_guid: location_id,
    } = store.getState().appointmentCheckOut;
    const {
      nonActionableMetadata: {
        checkoutSurveyAvailable = false,
        checkoutPaymentPlanCreated = false,
        checkoutPaymentAmount,
      } = {},
    } = store.getState().mixpanel;
    const { checkout: { paymentMade: payment_made = false } = {} } = this.persistedData;
    this.track(Events.CHECKOUT_COMPLETED, {
      appointment_type,
      practice_id,
      appointment_id,
      provider_id,
      location_id,
      is_guest_user: false,
      payment_made,
      survey_available: checkoutSurveyAvailable,
      payment_plan_created: checkoutPaymentPlanCreated,
      payment_amount: checkoutPaymentAmount,
      partial_payment_available: partialPaymentAllowed,
    });
    this.increment(Events.TOTAL_CHECKOUTS_COMPLETED, 1);
    this.track(Events.CHECKOUT_TIME, {});
    store.dispatch.mixpanel.addNonActionableMetadata({ checkoutInProgress: false });
  }
  checkInComplete() {
    const {
      appointment_type,
      practice_id,
      appointment_id,
      provider_id,
      location_id,
    } = store.getState().appointmentCheckIn;
    if (store.getState().mixpanel.nonActionableMetadata.checkinInProgress) {
      this.track(Events.CHECKIN_COMPLETED, {
        appointment_type,
        practice_id,
        appointment_id,
        is_guest_user: false,
        provider_id,
        location_id,
      });
      this.increment(Events.TOTAL_CHECKINS_COMPLETED, 1);
      this.track(Events.CHECKIN_TIME, {});
      store.dispatch.mixpanel.addNonActionableMetadata({ checkinInProgress: false });
    }
  }
  shopLaunched() {
    const splitPath = window.location.href
      .split('/')
      .pop()
      .split('?');
    const { practice_id } = parseParams({ location: { search: `?${splitPath[1]}` } });
    this.track(Events.SHOP_LAUNCHED, { store_id: splitPath[0], practice_id });
    this.time(Events.SHOPPING_SESSION_TIME);
  }
  radioSelected(payload, meta) {
    const { mixpanel: { nonActionableMetadata: { paymentAmount: amount, practiceId: practice_id } = {} } } = store.getState();
    const selectionTypeFunction = {
      paymentType: () =>
        this.track(
          {
            fullPayment: Events.FULL_PAYMENT_SELECTED,
            partialPayment: Events.PARTIAL_PAYMENT_SELECTED,
          }[meta],
          { amount, practice_id },
        ),
      paymentMethod: () => this.track(Events.PAYMENT_METHOD_SELECTED, { payment_method_type: Properties.CREDIT_CARD }),
    }[payload];
    if (selectionTypeFunction) selectionTypeFunction();
  }
  prepaymentStarted() {
    const { mixpanel: { nonActionableMetadata: { paymentAmount: amount, practice_id, location_id, provider_id } = {} } } = store.getState();
    this.track(Events.PREPAYMENT_STARTED, { practice_id, amount, provider_id, location_id });
  }
  startShopCheckout(payload) {
    const params = parseParams({ location: { search: `?${window.location.href.split('?').pop()}` } });
    this.track(Events.START_RETAIL_CHECKOUT, {
      store_id: params.store_id,
      order_id: params.transaction_id,
      order_amount: params.amount,
      practice_id: payload.paymentData.practiceId,
    });
  }
  oneTimePayment({ practiceId, amount, paymentPlanId, patientId }) {
    this.track(Events.ONETIME_PAYMENT_STARTED, {
      practice_id: practiceId,
      payment_plan_id: paymentPlanId,
      payment_amount: amount,
      patient_id: patientId,
    });
  }
  paymentStarted({ amount, cardId }) {
    this.track(Events.PAYMENT_STARTED, {
      payment_amount: amount,
      payment_method_type: cardId ? Properties.CARD_ON_FILE : Properties.NEW_CARD,
    });
  }
  checkinQrToggled({ location_id, patient_id, practice_id, provider_id }) {
    this.track(Events.CHECKIN_QR_TOGGLED, {
      location_id,
      patient_id,
      practice_id,
      provider_id,
      appointment_type: store.getState().appointmentCheckIn.appointment_type,
    });
  }
  checkinResumed({ location_id, patient_id, practice_id, provider_id, appointment_id, appointment_type }) {
    this.track(Events.CHECKIN_RESUMED, {
      location_id,
      patient_id,
      practice_id,
      provider_id,
      appointment_id,
      appointment_type,
      is_guest_user: false,
    });
  }
  updateEmailClicked() {
    this.track(Events.UPDATE_EMAIL_CLICKED, {});
  }
  updatePasswordClicked() {
    this.track(Events.UPDATE_PASSWORD_CLICKED, {});
  }
}
export default new Mix();
