import React, { useState } from 'react';
import { FormField, InputField, ListControls } from '../../styleguide';

const UnlistedMeds = ({
  index,
  key,
  schema,
  required,
  medication,
  formData,
  handleClear,
  handleAdd,
  handleRemove,
  dispatch,
}) => {
  const [unSavedMedication, setUnSavedMedication] = useState('');
  return (
    <div key={key}>
      <FormField text={schema.items.title} required={required} value={medication?.displayName}>
        <InputField
          name="displayName"
          value={unSavedMedication || medication?.displayName}
          onChange={({ target: { value } }) => setUnSavedMedication(value)}
        />
      </FormField>
      <ListControls
        addText={schema.items.addText}
        canAdd={!!medication?.displayName}
        canClear={!!medication?.displayName}
        canRemove={formData.length > 1}
        clearText={schema.items.clearText}
        onAdd={handleAdd}
        onClear={_ => handleClear(medication?.displayName)}
        onRemove={_ => handleRemove(medication?.displayName)}
        onSave={() => {
          dispatch({
            type: 'updateUnlisted',
            payload: { displayName: unSavedMedication },
          });
          setUnSavedMedication('');
        }}
        removeText={schema.items.removeText}
        showAdd={formData.length === index + 1}
        showClear={!!medication?.displayName && formData.length === 1}
        showRemove={formData.length > 1 || !medication?.displayName}
        showSave={!!unSavedMedication.length && !medication?.displayName}
      />
    </div>
  );
};
export default React.memo(({ formData = [], schema, dispatch, required }) => {
  const handleAdd = _ => dispatch({ type: 'addUnlisted' });
  const handleClear = displayName => dispatch({ type: 'clearUnlisted', displayName });
  const handleRemove = displayName => dispatch({ type: 'removeUnlisted', displayName });

  return (
    <>
      {formData.map((medication, index) => {
        const key = `al-meds-${index}`;
        return (
          <UnlistedMeds
            key={key}
            index={index}
            schema={schema}
            required={required}
            medication={medication}
            formData={formData}
            handleAdd={handleAdd}
            handleClear={handleClear}
            handleRemove={handleRemove}
            dispatch={dispatch}
          />
        );
      })}
    </>
  );
});
