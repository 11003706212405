import React from 'react';
import styles from './LinkButton.module.scss';

export default React.memo(props => {
  const { color = 'primary', disabled, onClick, text } = props;

  return (
    <button className={`${styles.wrapper} ${styles[color]}`} disabled={disabled} onClick={onClick} type="button">
      {text}
    </button>
  );
});
