import React, { createContext } from 'react'; //eslint-disable-line no-unused-vars

export const expanderContext = createContext({});
export const dropdownContext = createContext({});
export const settingsContext = createContext({});
export const surveysContext = createContext({});
export const addAppointmentWorkflowContext = createContext({});
export const appointmentContext = createContext({});
export const intakeFormContext = createContext({});
export const historyAppointmentContext = createContext({});
export const formHistoryContext = createContext({});
export const myHealthContext = createContext({});
export const addAppointmentContext = createContext({});
export const availableHoursContext = createContext({});
export const paymentsContext = createContext({});
export const paymentSectionCollapsedContext = createContext({});
export const creditCardContext = createContext({});
export const shopContext = createContext({});
export const appointmentCheckInContext = createContext({});
export const manageProfilesContext = createContext({});
export const appointmentPrepaymentContext = createContext({});
export const apolloErrorContext = createContext({});
export const oneTimePaymentContext = createContext({});
export const signupContext = createContext({});
export const loginContext = createContext({});
export const checkInContext = createContext({});
export const dashboardContext = createContext({});
export const VertixavailbleTimeContext = createContext({});
