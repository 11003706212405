import React from 'react';
import { InputField, FormField } from '../styleguide';

export default ({
  required,
  readonly,
  name,
  disabled,
  formData: value,
  schema: { title: label, validationMessages, placeholder, password, stopCopy, stopPaste },
  onChange,
  rawErrors,
}) => {
  const handleChange = ({ target: { value } }) => onChange(value === '' ? undefined : value);
  const errorId = Array.isArray(rawErrors) && rawErrors[0];
  const errorMessage = (validationMessages && validationMessages[errorId]) || errorId;

  return (
    <FormField error={errorMessage} text={label} required={required} value={value}>
      <InputField
        disabled={disabled || readonly}
        name={name}
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        password={password}
        stopCopy={stopCopy}
        stopPaste={stopPaste}
      />
    </FormField>
  );
};
