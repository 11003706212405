import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.label`
  align-items: center;
  border-bottom: 1px solid #cfd8dc;
  cursor: pointer;
  display: grid;
  grid-column-gap: 16px;
  grid-template-columns: calc(100% - 16px - 22px) 22px;
  padding: 8px 0;
  line-height: 1;
`;

const Text = styled.div`
  color: ${props => props.color};
  font-size: 16px;
  line-height: 20px;
`;

const Control = styled.div`
  input {
    border: none;
    display: block;
    height: 0px;
    margin: 0;
    opacity: 0;
    padding: 0;
    visibility: hidden;
    width: 1px;
  }

  svg {
    font-size: 22px;
    line-height: 22px;
    margin: 0;
    padding: 0;
  }
`;

export default React.memo(({ disabled, name, onChange, value, text }) => {
  const textColor = value && !disabled ? '#5BC5D0' : '#607D8B';

  return (
    <>
      <Container>
        <Text color={textColor}>{text}</Text>
        <Control>
          <input type="checkbox" disabled={disabled} name={name} checked={value} onChange={onChange} />

          {value ? (
            <FontAwesomeIcon icon="check-square" color={disabled ? '#CFD8DC' : '#5BC5D0'} />
          ) : (
            <FontAwesomeIcon icon={['far', 'square']} color="#CFD8DC" />
          )}
        </Control>
      </Container>
    </>
  );
});
