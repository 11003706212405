import React, { useState } from 'react';
import classNames from 'classnames';
import SchemaParser from '../../services/SchemaParser';
import styles from './paginationGroup.module.scss';
import { HORIZONTAL, VERTICAL, GRID } from '../../../constants/Properties';

const PaginationGroup = React.memo(({ id, direction, components = [], itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSet] = useState(components);
  const [currentPageData, setCurrentPageData] = useState(components.slice(0, itemsPerPage));
  const handlePageChange = direction => _ => {
    const newPage = currentPage + direction;
    const sliceStart = newPage;
    const sliceEnd = sliceStart + itemsPerPage;
    const newCurrentPageData = dataSet.slice(sliceStart, sliceEnd);
    setCurrentPage(newPage);
    setCurrentPageData(newCurrentPageData);
  };
  const previousButtonDisabled = currentPage === 0;
  const nextButtonDisabled = currentPage + itemsPerPage >= dataSet.length;
  return (
    <div
      className={classNames(styles.paginationGroupWrapper, styles[id], {
        [styles.horizontal]: direction === HORIZONTAL,
        [styles.vertical]: direction === VERTICAL,
        [styles.grid]: direction === GRID,
      })}
    >
      <button
        className={classNames(styles.previousButton, { [styles.hidden]: previousButtonDisabled })}
        disabled={previousButtonDisabled}
        onClick={handlePageChange(-itemsPerPage)}
      />
      <div className={classNames(styles.content)}>
        <SchemaParser schema={currentPageData} />
      </div>
      <button
        className={classNames(styles.nextButton, { [styles.hidden]: nextButtonDisabled })}
        disabled={nextButtonDisabled}
        onClick={handlePageChange(itemsPerPage)}
      />
    </div>
  );
});

export default PaginationGroup;
