import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { compose, isEmpty, get } from '@carecloud/cloudpak';
import { graphql, withApollo } from 'react-apollo';
import * as CryptoJS from 'crypto-js';
import { connect } from 'react-redux';
import { store } from '../../../models';
import {
  AcceptConnectInvite,
  GetUserToken,
  RecoverPassword,
  ResetPassword,
  signInResendOTP,
} from '../../../graphql/mutations';
import { GetLogin, GetSettings } from '../../../graphql/queries';
import { isMobileDevice, getMobileDevice } from '../../services/utilities';
import { Session, Routing, SchemaParser } from '../../services';
import Login from './Login';
import { Utilities } from '../../../constants';
import { parseParams } from '../../services/routing';
import { ContextProvider } from '../../root/TopLevelContext';
import { loginContext as LoginContext } from '../../../contexts';

class LoginContainer extends Component {
  static contextType = ContextProvider;
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadCheckmarkComplete: false,
      loadCrossIncomplete: false,
      checkmark: false,
      cross: false,
      errorMessage: null,
      inviteCode: null,
      otpModal: null,
      loginCredentials: {
        email: '',
        password: '',
      },
      otp: '',
    };

    const {
      location: { search = '', hash },
    } = this.props;
    if (search.includes('source=portal') || hash.includes('source=portal')) {
      store.dispatch.login.activateAlternateView('patientPortal');
    } else if (search.includes('confirmation_code')) {
      isMobileDevice() && store.dispatch.login.createNewPasswordFlowDisplay();
      store.dispatch.login.createNewPasswordView();
    } else if (search.includes('i')) {
      const queryString = new URLSearchParams(search);
      this.state.inviteCode = queryString.get('i');
    } else {
      (_ => {
        const { images = [] } = this.props.rightSide;
        if (images.length > 1) {
          let imageIndex = 1; // start from 2nd slide as 1st is always predefined
          this.sliderIntervalId = setInterval(_ => {
            if (imageIndex === images.length) imageIndex = 0;
            store.dispatch.login.changePicture(images[imageIndex], imageIndex !== 3);

            imageIndex++;
          }, 5000);
        }
      })();
    }
    sessionStorage.removeItem('CC-Breeze-Web-persistedGuestData');
  }
  componentDidMount() {
    this.context._setActions({
      changeLanguage: this.changeLanguage,
      checkForEmptyInput: this.checkForEmptyInput,
      checkForEmptyInputEmail: this.checkForEmptyInputEmail,
      RecoverEmail: this.RecoverEmail,
      forgotShowPassword: this.forgotShowPassword,
      passwordShow: this.passwordShow,
      passwordShowByName: this.passwordShowByName,
      passwordShownBtn: this.passwordShownBtn,
      passwordButtonShow: this.passwordButtonShow,

    });
  }
  componentDidUpdate() {
    this.passwordShownBtn();
    const { login, getLogin, error } = this.props;
    if (isEmpty(login.screens) && !getLogin.loading) {
      const loginScreens = get(getLogin, 'getLogin');
      loginScreens && store.dispatch.login.fetchStart(loginScreens);
      if (get(getLogin, 'getLogin.whiteLabelColor', String())) {
        store.dispatch.login.activateAlternateView('whiteLabelPortal');
      }
    }
    this.parseParams(this.props);
    if (error.errors) {
      store.dispatch.error.resetError();
      this.setState({ errorMessage:this.props.error.errors[0].message}); //eslint-disable-line react/no-did-update-set-state
      if( this.props.error.errors[0].message ==="Attempt limit exceeded, please try after some time."){
        this.crossComplete();
      }else{
        this.checkmarkComplete();
      }
      login.recoverPasswordCheckmark &&
        setTimeout(() => {
          store.dispatch.login.showCheckmark(false);
          this.setState({ errorMessage: null });
          isMobileDevice() && store.dispatch.login.createNewPasswordFlowDisplay();
        }, 5000);
    }
  }
  componentWillUnmount() {
    clearInterval(this.sliderIntervalId);
  }

  // Detects whether a user has reached the login page from a successful mobile signup
  isMobileSignup = _ => window.location.href.includes('?redirect=mobileSignup');

  parseParams = nextProps => {
    const params = Routing.parseParams(nextProps);
    if (params.action) {
      this[params.action](params.id);
    }
  };
  handleSubmit = async e => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    store.dispatch.error.resetError();
    this.setState(prevState => {
      return {
        ...prevState,
        loginCredentials: {
          email,
          password,
        },
      };
    });
    store.dispatch.button.enableButton({
      btn: 'twofaResendBtn',
      complete: false,
    });
    this.generateUuid();
    this.getCognitoPayload(email, password);
  };

  generateUuid = () =>{
    let uuid;
    if(!localStorage.getItem('device_identifier')){
       uuid =crypto.randomUUID();
      localStorage.setItem('device_identifier',uuid);
    }
  }
  getCognitoPayload = async (email, password, otp = null, device_identifier = localStorage.getItem('device_identifier')) => {
    const { location, history } = this.props;
    const { inviteCode } = this.state;
    let path = location.search ? location.pathname + location.search : location.pathname;
    try {
      store.dispatch.login.showSignInError(false);
      // Enable page loader
      this.setState({ loading: true });
      const { data } = await this.props.client.mutate({
        mutation: GetUserToken,
        variables: {
          email,
          password,
          ...(otp && { otp }),
          device_identifier,
        },
      });
      if (data.getUserToken?.data?.show2faPopup) {
        store.dispatch.dashboard.showTwofaPopup(true);
      }
      if (data.getUserToken?.otpModal) {
        this.setState({ otpModal: data.getUserToken.otpModal });
        store.dispatch.modal.toggleOpen('signInOTPModal');
        this.setState({ loading: false });
        return;
      }
      const totalPractices = data.getUserToken?.data.practiceInformation.length;
      let executionValue = "";
      if (data?.getUserToken?.data?.practiceInformation?.[0].cloverapi === true) {
        executionValue = "cloverapi";
      } else if (data?.getUserToken?.data?.practiceInformation?.[0].payeezy === true) {
        executionValue = "payeezy";
      }else{
        executionValue = 'no-value'
      }
      sessionStorage.setItem('Patient-Ids-List', JSON.stringify(data.getUserToken?.data.patient_ids_list));
      sessionStorage.setItem('totalPractice', JSON.stringify(data.getUserToken?.data.practiceInformation.length));
      sessionStorage.setItem('singleSelectedpractice', JSON.stringify(data.getUserToken?.data.practiceInformation?.[0].practice_id));
      sessionStorage.setItem('execution', executionValue);
      sessionStorage.setItem('practiceSelectedMgmt', data.getUserToken?.data.practiceInformation?.[0].practice_mgmt);
      const patientId = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.patient_id;
      const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
      const practiceId = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_id;
      if (totalPractices > 1) {
        store.dispatch.login.setSelectPractice(data.getUserToken.data.selectPracticeSchema);
      }
      const {
        getUserToken: {
          data: {
            cognitoPayload,
            cognitoPayload: { accessToken, authenticationToken, refreshToken },
          },
        } = {},
      } = data;
      if (authenticationToken) store.dispatch.modal.toggleClose(false);

      Session.setSession(cognitoPayload);

      if (inviteCode) {
        const inviteResponse = await this.props.client.mutate({
          mutation: AcceptConnectInvite,
          variables: { inviteCode },
        });
        const {
          data: { acceptConnectInvite: { payload: { invite_info: inviteData } = {}, labels = {} } = {} } = {},
        } = inviteResponse;
        if (inviteData) {
          if (isMobileDevice()) {
            sessionStorage.clear();
            this.setState({ connectedToMultiProviders: true, loading: false });
            return true;
          } else {
            store.dispatch.notification.open({ data: inviteData, labels });
          }
        }
      }
      if (!inviteCode && cognitoPayload && cognitoPayload.authenticationToken) {
         const { data: { getSettings: encryptedData } }  = await this.props.client.query({
          query: GetSettings,
          variables: { input: { withSchema: false ,  patientId, practiceMgmt, practiceId  } },
        });
        const bytes = CryptoJS.AES.decrypt(encryptedData.encryptedData, 'sparrow');
        const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const {
              payload: {
                user_links: { represented_users = [] } = {},
                demographics: {
                  payload: {
                    personal_details: {
                      gender,
                      date_of_birth,
                      ethnicity,
                      primary_race: race,
                      preferred_language: language,
                    },
                  },
                },
              },
        } = data
        if (represented_users.length) {
          const cognitoTokens = {
            email,
            accessToken,
            authenticationToken,
            refreshToken,
          };
          store.dispatch.login.storeCognitoToken(cognitoTokens);
          path = '/delegateProfile';
        }
        store.dispatch.mixpanel.addMetadata({
          loginSuccess: true,
          baseProperties: { gender, date_of_birth, ethnicity, race, language },
        });
      }
      if (totalPractices > 1) {
        // Disable page loader
        this.setState({ loading: false });
        store.dispatch.login.selectPracticeView();
      } else {
        history.push(path);
        return true;
      }
    } catch (error) {
      sessionStorage.clear();
      console.error(error);
      store.dispatch.login.showSignInError(true);
      // Disable page loader
      this.setState({ loading: false });
      return false;
    }
  };

  handleSubmitOTP = () => {
    const {
      loginCredentials: { email, password },
      otp,
      device_identifier,
    } = this.state;
    this.setState(prevState => {
      return {
        ...prevState,
        otp: null,
      };
    });
    this.getCognitoPayload(email, password, otp,device_identifier);
  };

  changeLanguage = language => {
    if (localStorage.getItem('CC-Breeze-Web-language') === language) {
      store.dispatch.dropdown.toggleOpen('loginLanguageMenu');
      return;
    }
    localStorage.setItem('CC-Breeze-Web-language', language);
    window.location.reload();
  };
  checkmarkComplete = _ => {
    this.setState({ loadCheckmarkComplete: true, checkmark: true });
  };
  crossComplete = _ => {
    this.setState({ loadCrossIncomplete: true, cross: true });
  };
  resetState = _ => {
    this.setState({
      loadCheckmarkComplete: false,
      checkmark: false,
      loadCrossIncomplete: false,
      cross: false,
    });
    store.dispatch.login.showResetPasswordCheckmark(false);
    store.dispatch.login.showRequestSuccess(false);
  };
  RecoverEmail = ({ value }) => {
    const regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    if (value.length === 0 || regex.test(value) === false) {
      store.dispatch.button.enableButton({
        btn: 'recoverPasswordBtn',
        complete: false,
      });
    } else {
      store.dispatch.button.enableButton({
        btn: 'recoverPasswordBtn',
        complete: true,
      });
    }
  };
  recoverPassword = async e => {
    e.preventDefault();
    this.resetState();
    const regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    if (e.length === 0 || regex.test(e) === false) {
      store.dispatch.button.enableButton({
        btn: 'recoverPasswordBtn',
        complete: false,
      });
    }
    const email = e.target.forgotPasswordEmail.value;
    if (!email) return;
    try {
      store.dispatch.login.showCheckmark(true);
      const { data } = await this.props.client.mutate({
        mutation: RecoverPassword,
        variables: { email },
      });
      if (data.recoverPassword === 200) {
        this.checkmarkComplete();
        store.dispatch.login.showRequestSuccess(true);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  savePassword = async e => {
    e.preventDefault();
    this.resetState();
    const password = e.target.newPasswordRepeat.value;
    const queryString = new URLSearchParams(this.props.location.search);
    const username = queryString.get('un');
    const code = queryString.get('confirmation_code');
    try {
      // hide new password
      store.dispatch.login.createNewPasswordView();
      // to display correct success message form schema parser
      store.dispatch.login.showResetPasswordCheckmark(true);
      store.dispatch.login.showCheckmark(true);
      const { data } = await this.props.client.mutate({
        mutation: ResetPassword,
        variables: { input: { username, code, password } },
      });
      if (data.resetPassword === 200) {
        store.dispatch.login.showRequestSuccess(true);
        this.checkmarkComplete();
        setTimeout(() => {
          store.dispatch.login.showResetPasswordComplete();
        }, 3000);
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  checkForEmptyInputEmail = ({ value }) => {
    const regex = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);
    if (value.length === 0 || regex.test(value) === false) {
      store.dispatch.signup.setUpdatedEmail({ updatedEmail: value });
      store.dispatch.button.enableButton({
        btn: 'btnSignUpEmailUpdate',
        complete: false,
      });
    } else {
      store.dispatch.signup.setUpdatedEmail({ updatedEmail: value });
      store.dispatch.button.enableButton({
        btn: 'btnSignUpEmailUpdate',
        complete: true,
      });
    }
  };
  checkForEmptyInputOTP = ({ value }) => {
    const regex = new RegExp(/^\d{5}$/);
    if (value.length === 5 && regex.test(value)) {
      this.setState(prevState => {
        return {
          ...prevState,
          otp: value,
        };
      });
      store.dispatch.button.enableButton({
        btn: 'loginOTPVerifyBtn',
        complete: true,
      });
    } else {
      store.dispatch.button.enableButton({
        btn: 'loginOTPVerifyBtn',
        complete: false,
      });
    }
  };
  checkForEmptyInput = ({ id, value }) => {
    const regex = new RegExp(/^[A-Z0-9a-z._%+-]+@([A-Za-z0-9-]+\.)+[A-Za-z]{2,64}$/);

    if (store.getState().login.newSignUpEmail) {
      document.getElementById('email').value = store.getState().login.newSignUpEmail;
      store.dispatch.login.saveNewSignUpEmail('');
    }
    const field = id === 'email' ? 'emailInputEmpty' : 'passwordInputEmpty';
    if (field === 'emailInputEmpty' && value) {
      store.dispatch.login.setEmailInputValidator(!value);
    } else if (field === 'passwordInputEmpty' && value) {
      store.dispatch.login.setPasswordInputValidator(!value);
    }
    const email = document.getElementById("email");
    const password = document.getElementById("password");
    if (email.value.length !== 0 && password.value.length !== 0) {
      if (id === 'email' && regex.test(value)) {
        const isComplete = !store.getState().login.emailInputEmpty && !store.getState().login.passwordInputEmpty;
        store.dispatch.button.enableButton({
          btn: 'btnSignIn',
          complete: isComplete,
        });
      } else if (id === 'password') {
        const isEmailValid = regex.test(document.getElementById('email').value);
        const isComplete = isEmailValid && !store.getState().login.passwordInputEmpty;
        store.dispatch.button.enableButton({
          btn: 'btnSignIn',
          complete: isComplete,
        });
      } else {
        store.dispatch.button.enableButton({
          btn: 'btnSignIn',
          complete: false,
        });
      }
    } else {
      store.dispatch.button.enableButton({
        btn: 'btnSignIn',
        complete: false,
      });
    }
  };
  
  
  handlePracticeSelect = e => {
    e.preventDefault();
    // Enable page loader
    this.setState({ loading: true });

    const practice = store.getState().radioInput;
    let executionValue = "";
      if (practice.cloverapi === true) {
        executionValue = "cloverapi";
      } else if (practice.payeezy === true) {
        executionValue = "payeezy";
      }else{
        executionValue = 'no-value'
      }
    sessionStorage.setItem('practiceSelected', practice.selectBusinessValue);
    sessionStorage.setItem('practiceSelectedName', practice.selectBusiness);
    sessionStorage.setItem('execution', executionValue);
  };
  resendOTP = async _ => {
    store.dispatch.button.enableButton({
      btn: 'twofaResendBtn',
      complete: false,
    });
    store.dispatch.settings.setTimeDuration({ duration: 300 });
    const {
      loginCredentials: { email, password },
    } = this.state;
    try {
      const device_identifier = localStorage.getItem('device_identifier');
      const { data } = await this.props.client.mutate({
        mutation: signInResendOTP,
        variables: {
          email,
          password,
          device_identifier,
        },
      });
      if (data) {
        return true;
      }
    } catch (error) {
      store.dispatch.button.enableButton({
        btn: 'twofaResendBtn',
        complete: false,
      });
      return false;
    }
  }

  forgotShowPassword = password => {
    const inputElement = document.getElementById(password.passwordId);
    const eyeIcon = document.getElementById(password.buttonId);
    const eyeShowPasswordSrc = 'https://assets.gobreeze.com/eye-password-show-svgrepo-com.svg';
    const eyeHidePasswordSrc = 'https://assets.gobreeze.com/eye-password-hide-svgrepo-com.svg';
    if (inputElement.type === 'password') {
      inputElement.type = 'text';
      eyeIcon.querySelector('img').src = eyeHidePasswordSrc;
    } else {
      inputElement.type = 'password';
      eyeIcon.querySelector('img').src = eyeShowPasswordSrc;
    }
  }
    
  forgotShowPassword = password => {
    const inputElement = document.getElementById(password.passwordId);
    const eyeIcon = document.getElementById(password.buttonId);
    const eyeShowPasswordSrc = 'https://assets.gobreeze.com/eye-password-show-svgrepo-com.svg';
    const eyeHidePasswordSrc = 'https://assets.gobreeze.com/eye-password-hide-svgrepo-com.svg';
    if (inputElement.type === 'password') {
      inputElement.type = 'text';
      eyeIcon.querySelector('img').src = eyeHidePasswordSrc;
    } else {
      inputElement.type = 'password';
      eyeIcon.querySelector('img').src = eyeShowPasswordSrc;
    }
  }

  passwordShow = passwordId => {
    const inputElement = document.getElementById(passwordId);
    const eyeIcon = document.getElementById('eyeIcon');
    const eyeShowPasswordSrc = 'https://assets.gobreeze.com/eye-password-show-svgrepo-com.svg';
    const eyeHidePasswordSrc = 'https://assets.gobreeze.com/eye-password-hide-svgrepo-com.svg';
    
    if (inputElement.type === 'password') {
      inputElement.type = 'text';
      eyeIcon.querySelector('img').src = eyeHidePasswordSrc;
    } else {
      inputElement.type = 'password';
      eyeIcon.querySelector('img').src = eyeShowPasswordSrc;
    }
  }
  passwordShowByName = passwordId => {
    const inputElements = document.getElementsByName(passwordId.inputName);
    const eyeIcon = document.getElementById(passwordId.buttonId);
    const eyeShowPasswordSrc = 'https://assets.gobreeze.com/eye-password-show-svgrepo-com.svg';
    const eyeHidePasswordSrc = 'https://assets.gobreeze.com/eye-password-hide-svgrepo-com.svg';
    if (inputElements.length > 0) {
      const inputElement = inputElements[0];
  
      if (inputElement.type === 'password') {
        inputElement.type = 'text';
        eyeIcon.querySelector('img').src = eyeHidePasswordSrc;
      } else {
        inputElement.type = 'password';
        eyeIcon.querySelector('img').src = eyeShowPasswordSrc;
      }
    } else {
      console.error(`No input element found with name "${passwordId}"`);
    }
  };

  passwordShownBtn = () => {
    const inputElement = document.getElementById('password');
    if(inputElement !== null){
      const inputValue = inputElement.value;
      const buttonElement = document.getElementById('eyeIcon');
      const imgElement = buttonElement.querySelector('img');
      if(inputValue === ''){
        imgElement.style.display = 'none';
        buttonElement.style.pointerEvents = 'none'
      }else{
        imgElement.style.display = 'block';
        buttonElement.style.pointerEvents = 'all'
      }
      if(store.getState.formGroup?.change?.value){
        console.log(store.getState.formGroup.change.value);
      }
    }
  }
  render() {
    const {
      location,
      login: {
        screens,
        screens: { loginScreen, forgotPassword, mobilePrompt, mobilePromptConnectedSuccess, labels, selectPractice },
        showForgotPassword,
        showSelectPractice,
        resetPasswordComplete,
      },
    } = this.props;
    if (isEmpty(screens)) return null;
    if (Session.hasTokens() && sessionStorage.getItem('practiceSelected')) return <Redirect to={location.pathname} />;
    if (resetPasswordComplete) {
      if (location.search.includes('confirmation_code')) {
        store.dispatch.login.showCheckmark(false);
        return <Redirect to="/" />;
      }
    }
    let loginSchema = loginScreen;
    if (
      !this.state.inviteCode &&
      isMobileDevice() &&
      (getMobileDevice() === Utilities.GOOGLE || getMobileDevice() === Utilities.APPLE)
    ) {
      loginSchema = mobilePrompt;
    } else if (showForgotPassword) {
      loginSchema = forgotPassword;
    } else if (showSelectPractice) {
      loginSchema = selectPractice;
    } else if (this.state.connectedToMultiProviders) {
      loginSchema = mobilePromptConnectedSuccess;
    }
    return (
      <>
        <LoginContext.Provider
          value={{
            handleSubmitOTP: this.handleSubmitOTP,
            resendOTP: this.resendOTP,
            checkForEmptyInputOTP: this.checkForEmptyInputOTP,
          }}
        >
          <SchemaParser schema={[this.state.otpModal]} />
          <Login
            props={{
              ...this.props,
              handleSubmit: this.handleSubmit,
              recoverPassword: this.recoverPassword,
              savePassword: this.savePassword,
              handlePracticeSelect: this.handlePracticeSelect,
              loadCheckmarkComplete: this.state.loadCheckmarkComplete,
              loadCrossIncomplete: this.state.loadCrossIncomplete,
              cross: this.state.cross,
              checkmark: this.state.checkmark,
              errorMessage: this.state.errorMessage,
              loading: this.state.loading,
              isMobile: isMobileDevice(),
              isMobileSignup: this.isMobileSignup(),
              loginSchema,
              postSignUp: parseParams(this.props).confirmed,
              labels,
              loginScreen,
              showSelectPractice,
              selectPractice,

            }}
          />
        </LoginContext.Provider>
      </>
    );
  }
}
const mapStateToProps = ({ login, error }) => ({
  login,
  error,
  rightSide: login.rightSide,
  isMobile: isMobileDevice(),
});

const enhancer = compose(
  withRouter,
  withApollo,
  connect(mapStateToProps),
  graphql(GetLogin, {
    name: 'getLogin',
    options: props => {
      const confirmedParam = parseParams(props).confirmed;
      return {
        variables: {
          input: {
            getMobileDevice: getMobileDevice(),
            ...(confirmedParam && {
              emailConfirmed: JSON.parse(confirmedParam),
            }),
          },
        },
      };
    },
  }),
);

// LoginContainer.whyDidYouRender = true;

export default enhancer(LoginContainer);
