import { Query, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { store } from '../../../models';
import { ContentPage } from '..';
import { GetPatientStatement, GetPayments } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { breakBackButton } from '../../services/utilities';
import { paymentsContext as PaymentsContext } from '../../../contexts';

class PaymentsContainer extends PureComponent {
  componentDidMount() {
    breakBackButton();
  }
  componentDidUpdate() { 
    this.props.payments.length && this.props.loader.loading && store.dispatch.loader.activateLoader({});
  }

  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
  }

  loadPatientStatement = async param => {
    store.dispatch.payments.clearPayments();
    store.dispatch.loader.activateLoader({ loading: true });
    const { data: { getPatientStatement: { base64Str = String() } = {} } } = await this.props.client.query({
      query: GetPatientStatement,
      variables: { statementId: param.toString() },
    });
    const byteCharacters = atob(base64Str);
    const byteNumbers = new Array(byteCharacters.length);
    [...byteCharacters].forEach((value, i) => {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    });
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/pdf' });
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(file);
    } else {
      const url = URL.createObjectURL(file);
      window.open(url);
    }
    return store.dispatch.loader.activateLoader({ loading: false });
  };

  render() {
    const { payments } = this.props;
    let patientId;
    const totalPractice = JSON.parse(sessionStorage.getItem('totalPractice'))
  totalPractice === 1?
       patientId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === JSON.parse(sessionStorage.getItem('singleSelectedpractice')))?.[0]?.patient_id
      :
       patientId = JSON.parse(sessionStorage.getItem('Patient-Ids-List')).filter(x => x.practice_id === sessionStorage.getItem('practiceSelected'))?.[0]?.patient_id;
    return payments.length ? (
      <PaymentsContext.Provider value={{ loadPatientStatement: this.loadPatientStatement }}>
        <ContentPage schemas={payments} />
      </PaymentsContext.Provider>
    ) : (
      <Query query={GetPayments} skip={!isEmpty(payments)} variables={{patientId}}>
        {({ data: query, loading }) => {
          parseResult({ query, dataField: 'getPayments', resultHandler: store.dispatch.payments.fetchStart });
          return <ContentPage schemas={[]} loading={loading} />;
        }}
      </Query>
    );
  }
}

export default compose(
  connect(({ payments: { payments }, loader }) => ({ payments, loader })),
  withApollo,
)(PaymentsContainer);
