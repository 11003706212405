/*eslint-disable camelcase */
import { get } from '@carecloud/cloudpak';
import React, { useReducer } from 'react';
import classNames from 'classnames';
import { store } from '../../../models';
import { GetMoreTransactions } from '../../../graphql/queries';
import SchemaParser from '../../services/SchemaParser';
import { PageLoader } from '../page_loader/pageLoader';
import styles from '../button/button.module.scss';

import {
  MEDIUM_LOADER_SIZE,
  MEDIUM_LOADER_THICKNESS,
} from '../../../constants/Loader';
import { paymentSectionCollapsedContext as PaymentSectionCollapsedContext } from '../../../contexts';

const PaymentSectionCollapsedContainer = ({
  payments: {
    pageDetails: { total_pages, total_entries },
    collapsedPayments,
  },
  loadMoreBtn,
  client,
}) => {
  const paymentSectionCollapsedReducer = (state, payload) => ({
    ...state,
    ...payload,
  });
  const [state, dispatch] = useReducer(paymentSectionCollapsedReducer, {
    totalEntries: total_entries,
    displayNumber: 7,
    nextPage: 2,
    totalPages: total_pages,
    localLoading: false,
    loadMoreRemainingCount: total_entries - 10,
  });
  const {
    nextPage,
    displayNumber,
    localLoading,
    totalEntries,
    loadMoreRemainingCount,
  } = state;
  const handleLoadMore = async _ => {
    dispatch({ localLoading: true });
    !localLoading && store.dispatch.payments.loading(true);
    const { data } = await client.query({
      query: GetMoreTransactions,
      variables: { page: nextPage },
    });
    const combinedCollapsedPayments = [...collapsedPayments, ...get(data, 'getMoreTransactions.moreTransactions', [])];
    store.dispatch.payments.storeCollapsedPayments({ collapsedPayments: combinedCollapsedPayments });
    localLoading && store.dispatch.payments.loading(false);
    dispatch({
      displayNumber: displayNumber + 10,
      nextPage: nextPage + 1,
      loadMoreRemainingCount: total_entries - displayNumber - 13,
      localLoading: false,
    });
  };

  const { id, text, type } = loadMoreBtn;
  console.log(id);
  return localLoading ? (
    <PageLoader
      fixed={false}
      size={MEDIUM_LOADER_SIZE}
      thickness={MEDIUM_LOADER_THICKNESS}
    />
  ) : (
    <div>
      <PaymentSectionCollapsedContext.Provider value={{ handleLoadMore }}>
        <SchemaParser schema={collapsedPayments.slice(0, displayNumber)} />
      </PaymentSectionCollapsedContext.Provider>
      {loadMoreBtn && displayNumber + 3 < totalEntries && (
        <button
          id={id}
          type={type}
          className={classNames(styles.btn, styles[id])}
          onClick={handleLoadMore}
        >
          {`${text} (${loadMoreRemainingCount})`}
        </button>
      )}
    </div>
  );
};

export default PaymentSectionCollapsedContainer;
