import { Image } from '..';
import { SchemaParser } from '../../services';
import styles from './emptySection.module.scss';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const emptySection = React.memo(
  ({ message, link, preRegister = null, type, icon, subMessage, faIcon }) => (
    <div className={styles.wrapper}>
      <div className={classNames(styles.emptyBox, styles[type])}>
        {faIcon && (
          <div className={styles.iconContainer}>
            {<SchemaParser schema={[faIcon]} />}
          </div>
        )}
        {icon && <Image {...{ src: icon, alt: message }} />}
        {message && <h3 className={styles.emptyMessage}>{message}</h3>}
        {subMessage && <h4 className={styles.emptySubMessage}>{subMessage}</h4>}
        {preRegister && <SchemaParser schema={[preRegister]} />}
        {link && (
          <a
            className={classNames(styles.emptyLink, styles[link.type])}
            href={link.url}
          >
            {link.text}
          </a>
        )}
      </div>
    </div>
  ),
);

emptySection.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  type: PropTypes.string,
  icon: PropTypes.string,
};

export const EmptySection = emptySection;
