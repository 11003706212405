import { Session } from '../components/services';
import mix from '../components/services/mixpanel';

export const parseResult = async ({ query = {}, dataField, resultHandler }) => {
  if (!query[dataField]) return query;
  const { authenticationToken, metaData } = query[dataField];
  if (!authenticationToken) {
    metaData && metaData.userId && mix.identify(metaData.userId);
    return resultHandler(query[dataField]);
  }
  Session.setSession({ authenticationToken });
  const result = await query.refetch();
  return resultHandler(result.data[dataField]);
};

export const camelCaseAppend = (s = String()) => s && s[0].toUpperCase() + s.slice(1);
