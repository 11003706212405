import React, { memo, useContext, useEffect } from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import { Checkbox } from './checkbox';
import { store } from '../../../models';
import * as babyContexts from '../../../contexts';

const CheckboxContainer = memo(props => {
  const { formContext, id, defaultChecked, value, action = {}, formMapping, schema, onChange, formData } = props;
  const { model = String(), trigger = String(), param = String(), babyContext = String() } = action;
  const variableContext = babyContext && useContext(babyContexts[`${babyContext}Context`]);

  const isJsonSchema = !isEmpty(formContext);
  const getOnChange = _ => {
    if (isJsonSchema) return e => onChange(e.target.checked);
    const paramObj = {
      id: param,
      formMapping,
      param,
    };
    return e => {
      if (babyContext)
      variableContext[trigger]({ value: e.target.checked, ...paramObj })
      else if (model === 'formGroup' && trigger === 'change') {
        if (id === 'twofaToggle'){
          if (e.target.checked){
            store.dispatch.button.enableButton({
              btn: 'twofaEnableBtn',
                    complete:true,
              });
            if(document.getElementById('twofaPhoneRadio').checked === true){
              document.getElementById('twofaPhoneInputWrapper').style.display = 'block';
            }   
          }
          else{
            store.dispatch.button.enableButton({
              btn: 'twofaEnableBtn',
                    complete:false,
              });  
            document.getElementById('twofaPhoneInputWrapper').style.display = 'none';
            document.getElementById('twofaEmailRadio').disabled = true;
            document.getElementById('twofaPhoneRadio').disabled = true;
          }
        }
        const paramFormGroup = { id, fieldValid:true, paymentValid: false, isInitial: undefined, formMapping };
        store.dispatch[model][trigger]({ value: e.target.checked, ...paramFormGroup })
      }
      else store.dispatch[model][trigger]({ value: e.target.checked, ...paramObj })
    };
  };

  const mapJsonSchemaProps = _ => {
    if (!isJsonSchema) return {};
    return {
      label: schema.title,
      supportingText: schema.description,
      value: formData,
    };
  };

  useEffect(() => {
    if (isJsonSchema) return;
    // *****Be Careful****
    // if checkbox has an action, it will do it/trigger each time it renders/re-renders
    // making the value the contrary of w.e. it is at hte moment
    if (action) {
      const paramObj = {
        id,
        value: defaultChecked || value || false,
        param,
        isInitial: true,
        formMapping,
      };
      babyContext
        ? variableContext[trigger] && variableContext[trigger](paramObj)
        : store.dispatch[model][trigger](paramObj);
    }
  }, []);

  return (
    <Checkbox
      {...{
        ...props,
        ...mapJsonSchemaProps(),
        onChange: getOnChange(),
        isJsonSchema,
      }}
    />
  );
});

export default CheckboxContainer;
