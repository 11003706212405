import React from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import classNames from 'classnames';
import styles from './checkbox.module.scss';
import { SchemaParser } from '../../services';

const checkbox = React.memo(props => {
  const {
    theme,
    id,
    value,
    label,
    defaultChecked,
    onChange,
    isJsonSchema,
    checkboxBtn,
    disabled,
    supportingText,
    pending,
    required,
    rawErrors = [],
    errors,
    uiSchema: { requiredLabel, errorRequiredLabel } = {},
  } = props;

  return (
    <label htmlFor={id}>
      <div
        className={classNames(
          styles[theme],
          styles.checkBoxContainer,
          { [styles.disabled]: disabled },
          { [styles.jsonSchemaContainer]: isJsonSchema },
        )}
      >
        <input
          type="checkbox"
          className={classNames(styles.checkbox, {
            // eslint-disable-next-line object-curly-newline
            [styles.withPending]: pending,
          })}
          onChange={onChange}
          id={id}
          defaultChecked={defaultChecked || value}
          disabled={disabled}
        />
        <div
          className={classNames(styles.labelContainer, styles[`${id}Labels`], {
            // eslint-disable-next-line object-curly-newline
            [styles.withSupportingText]: supportingText,
          })}
        >
          <div>
            {label}
            {required && (
              <span className={styles.required}>
                {requiredLabel || required}
              </span>
            )}
          </div>
        </div>
        {checkboxBtn && <SchemaParser schema={[checkboxBtn]} />}{' '}
        {pending && <div className={styles.pending}>{pending}</div>}
      </div>
      {!isEmpty(rawErrors)
        ? rawErrors.map(error => (
            // eslint-disable-next-line react/jsx-indent
            <div className={styles.errorMessage} key={error}>
              {errorRequiredLabel}
            </div>
            // eslint-disable-next-line indent
          ))
        : errors &&
          errors.map(error => (
            <div key={error} className={styles.errorMessage}>
              {error}
            </div>
          ))}
      {supportingText && (
        <div className={styles.supportingText}>{supportingText}</div>
      )}
    </label>
  );
});

export const Checkbox = checkbox;
