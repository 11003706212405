import React, { useContext } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import styles from './delegateDropdown.module.scss';
import { SchemaParser } from '../../services';
import * as babyContexts from '../../../contexts';

const DelegateDropdown = React.memo(
  ({ duplicate, avatar, group, action: { babyContext = String(), trigger = String(), param = String() } = {} }) => {
    const variableContext = babyContext && useContext(babyContexts[`${babyContext}Context`]);
    return (
      <div
        className={classNames(styles.profile, { first: !duplicate }, { second: duplicate })}
        onClick={_ => variableContext[trigger](param)}
        role="button"
        tabIndex={0}
      >
        <SchemaParser schema={avatar} />
        <SchemaParser schema={group} />
      </div>
    );
  },
);

const mapStateToProps = ({ delegate }) => ({ delegate });

export default connect(mapStateToProps)(DelegateDropdown);
