import { Redirect, withRouter } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, isEmpty, has } from '@carecloud/cloudpak';
import React, { Component } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { ConfirmSignupEmail, GetSignup, ResendConfirmationEmail } from '../../../graphql/queries';
import { PageLoader } from '../../ui';
import * as Mutations from '../../../graphql/mutations';
import { REGISTER_ACCOUNT } from '../../../constants/Paths';
import { SchemaParser } from '../../services';
import { parseParams } from '../../services/routing';
import { parseResult } from '../../../graphql/utilities';
import { isMobileDevice } from '../../services/utilities';
import { store } from '../../../models';
import styles from './Signup.module.scss';
import Toast from '../../modals/toast/toastContainer';
import { signupContext as SignupContext } from '../../../contexts';
import { ContextProvider } from '../../root/TopLevelContext';

const PropsDependentRegisterContainer = styled.div`
  height: 100%;
  ${props => props.isMobile && props.isRotation && 'width: 100vw'};
  background: #eceff1;
  position: fixed;
  overflow: auto;
  animation: fadein 0.5s forwards;
  text-align: -webkit-center;
  & div[id*='signupFormGradient'] {
    width: 100vw;
    height: ${props => (props.isMobile ? '246px' : '246px')};
    z-index: 10;
    position: absolute;
    background: linear-gradient(
      133deg,
      rgba(255, 255, 255, 0.43) 0%,
      rgba(255, 255, 255, 0.18) 40%,
      rgba(255, 255, 255, 0.01) 96%,
      rgba(0, 0, 0, 0.01) 100%
    );
  }
  & div[id*='signupFormColor'] {
    width: 100vw;
    height: ${props => (props.isMobile ? '246px' : '246px')};
    z-index: 8;
    position: absolute;
    background: ${props =>
      props.whiteLabelColor
        ? props.whiteLabelColor
        : 'linear-gradient(225deg, #9fe5ed 0%, #5bc5d0 46.93%, #4ea9b3 100%)'};
  }
  & div[class*='Select-placeholder'] {
    text-align: left;
  }
  & div[class*='Select-control'] {
  padding: 13px 0;
  }
  & div[class*='signupContainer'] {
    align-items: center;
    justify-content: space-between;
    background-size: cover;
     width: ${props => (props.isMobile && props.isRotation ? '100vh' : '100vw')};
    height: ${props => (props.isMobile ? '246px' : '246px')};
  }
  & img[id*='mainLogo'] {
    height: 45px;
    margin: 70px 0px 50px;
  }
  & img[id*='whiteLabelLogo'] {
    height: ${props => (props.isMobile ? '14vh' : '106px')};
    margin: ${props => (props.isMobile ? '10px' : '2rem')} auto;
    ${props => props.isMobile && `margin-bottom: .5rem`};
  }
  & [id*='registerInformation'] {
    ${props => props.isMobile && 'margin-bottom: 10px;'}
    text-align: center;
    justify-content: center;
    min-height: 40px;
    padding-right:${props => (props.isMobile && props.isRotation ? '13vw' : '2vw')}
    padding-left:${props => (props.isMobile && props.isRotation ? '14vw' : '2vw')}
    & h4 {
      font-family: 'Metropolis';
      font-size: ${props => (props.isMobile ? '18px' : '24px')};
      font-weight: 400;
    }
  }
  & [id*='registerInformationDark'] {
    ${props => props.isMobile && 'margin-bottom: 10px;'}
    text-align: center;
    justify-content: center;
    min-height: 40px;
    padding-right: 2vw;
    padding-left: 2vw;
    & h4 {
      color: #455a64;
      font-family: 'Metropolis';
      font-size: ${props => (props.isMobile ? '18px' : '24px')};
      font-weight: 400;
    }
  }
  & [id*='signupSuccessFormWrapper'] {
    align-items: center;
    position: absolute;
    z-index: 9;
    & img[id*='mainLogo'] {
      height: 45px;
      margin: 70px 0px 50px;
    }
    & img[id*='whiteLabelLogo'] {
      height: 106px;
      margin: 2rem auto;
      ${props => props.isMobile && `margin-bottom: .5rem`};
    }
    & [id*='signupSuccessContainer'] {
      text-align: center;
      border-radius: 10px;
      margin: 0 auto;
      justify-content: center;
      display: grid;
      margin-top: 20px;
      margin-bottom: 30px;
      box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
      max-width: 380px;
      width: 90%
      background: #fff;
      & button[id*='patientSignupBtn'] {
        margin: auto !important;
        background-color: ${props => props.whiteLabelColor && props.whiteLabelColor};
      }
      & button[id*='patientSignupBtnDark'] {
        margin: auto !important;
        background-color: #455a64;
      }
      & img[id*='signupSuccessCheckmark'] {
        color: #5bc5d0;
        margin: 25px auto;
        height: 70;
      }
      & img[id*='signupSuccessCheckmarkNoLogo'] {
        color: #5bc5d0;
        margin: 25px auto;
        height: 70;
      }
      & h4 {
        color: #455a64;
        margin: 10px 30px;
        font-size: 18px;
      }
      & h5 {
        color: #90a4ae;
        margin: 5px auto;
        font-weight: 500;
        font-size: 12px;
      }
      & [id*='loginLinkGroup'] {
        margin-bottom: 10px;
      }
      & div[id*='loginLink'] > span {
        line-height: 38px;
        font-size: 13px;
        color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
        text-decoration: underline;
      }
    }
  }
  & div[class*='signupFormWrapper'] {
    overflow-y: scroll;
    overflow-x: hidden;
    width:100vw;
    & label{
      text-align: left;
    }
    & div[class*='SelectField_wrapper__1JQxp'] {
      text-align: left;
    }
    position: absolute;
    z-index: 10;
    align-items: center;
    -webkit-box-align: center;
    & form[id*='signupFields'] {
      /* width: 400px; */
      min-height: 620px;
      background-color: #fff;
      border-radius: 10px;
      padding-bottom: 15px;
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & [class*='divider'] {
        display: none;
      }
      & img[id*='whiteLabelLogo'] {
        height: 106px;
        margin-top: 0;
        margin-bottom: 20px;
      }
      & img[id*='mainBrandLogo'] {
        margin: 40px 0;
      }
      & div[id*='signupForm'] {
        margin-bottom: 20px;
      }
    }
    & [id*='createAccountForm'] {
      justify-content: flex-start;
      border-radius: 10px;
      margin: 0 auto;
      ${props => !props.isMobile && 'margin-top: 20px'};
      /* margin-top: 60px; */
      /* margin-bottom: 30px; */
      align-items: center;
      box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
      & [class*='heading'] {
        display: none;
      }
      & [class*='passwordPrompt'] > h5 {
        color: #90a4ae;
        font-size: 11px;
        font-family: 'Metropolis';
        font-weight: 400;
        line-height: 15px;
        margin: 0;
        width: 300px;
        text-align: center;
      }
      & label {
        color: #546e7a;
      }
      & input {
        font-size: 16px;
        color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
        &:-webkit-autofill {
          -webkit-text-fill-color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
        }
      }
      & [class*='formWrapper'] {
        padding: 0;
      }
      & div[class*='leftSideWrapper'] {
        padding: 0px !important;
        margin-left: 0px;
      }
      & button {
        margin: 10px 0px;
        background-color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
      }
      &[class*='disabled'] {
        background-color: #cfd8dc;
        pointer-events: none;
      }
    }
    & div[class*='guestRegisterTermsAndConditions'] {
      text-transform: none !important;
    }
    & input[id*='guestRegisterTermsAndConditions']:checked:after {
      background-color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
      border-color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
    }
    & a[id*='guestRegisterTermsAndConditionsBtn'] {
      color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
      text-decoration: underline;
    }
    & input[type='checkbox']:checked + div div {
      color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
    }
    & input[type='checkbox'] {
      width: 30px;
      height: 20px;
    }
    & div[id*='loginLink'] > span {
      color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
      text-decoration: underline;
    }
    & span[class*='Select-value-label'] {
      color: ${props => (props.darkMode ? '#455a64 !important' : `${props.whiteLabelColor} !important`)};
      font-weight: 400;
    }
  }
  & div[id*='signupFormBottom'] {
    min-height: 30px;
    width: 100vw;
  }
`;

const ConfirmEmailInvalid = styled.div`
  & div[id*='comfirmationTopBar'] {
    ${props => props.isMobile && ' display: none'}
  }
  & img[id*='mainLogo'] {
    ${props => props.isMobile && ' padding-bottom: 30px'}
  }
`;

class SignupContainer extends Component {
  static contextType = ContextProvider;
  componentDidMount() {
    this.context._setActions({
      passwordShowByNameSignup: this.passwordShowByNameSignup,
    });
    const { em, i, un, confirmation_code: code, reconfirm, confirm, practiceId, practiceMgmt, usedBtn } = parseParams(
      this.props,
    );
    if (reconfirm && em) {
      return this.resendConfirmationEmail({ em });
    }
    if (confirm && un && code) {
      return this.confirmSignupEmail({ un, code, em });
    }
    // Obtain encoded email value and invitation id from URL to include in mutation
    const fields = em
      ? [
          { map: 'signupInputEmail', field: em },
          { map: 'signupInvitationId', field: i },
        ]
      : [
          { map: 'practiceId', field: practiceId },
          { map: 'practiceMgmt', field: practiceMgmt },
        ];
    store.dispatch.form.addExternalSubmissionData(
      fields.map(({ map, field }) => ({
        value: field,
        formMapping: map,
      })),
    );
    store.dispatch.mixpanel.addNonActionableMetadata({
      signup: { practiceId, usedBtn },
    });
  }

  componentWillReceiveProps({ signup, getSignup, location: { pathname }, login }) {
    const { reconfirm, confirm } = parseParams(this.props);
    const registerAccount = pathname.includes(REGISTER_ACCOUNT);
    if (isEmpty(signup.signupScreen) && !registerAccount && !reconfirm && !confirm && !getSignup.loading) {
      store.dispatch.formGroup.showForm('signupForm');
      parseResult({
        query: getSignup,
        dataField: 'getSignup',
        resultHandler: store.dispatch.signup.fetchStart,
      });
    } else if (isEmpty(signup.signupScreen) && registerAccount && !isEmpty(login.screens)) {
      const {
        login: {
          screens: { signup },
        },
      } = this.props;
      store.dispatch.formGroup.showForm('createAccountForm');
      store.dispatch.signup.fetchStart({ signupScreen: signup.signupScreen });
    } else if (registerAccount && isEmpty(login.screens)) {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    store.dispatch.signup.resetSignUp();
  }

  // this method is used in three different pages, need to account for data coming from different locations
  resendConfirmationEmail = async params => {
    const {
      signup: { email, emailSent },
    } = this.props;
    store.dispatch.loader.persistLoader({ persist: true });
    // get email from url
    const { reconfirm, em } = parseParams(this.props);
    // get email from button click params in cyclops
    const username = (params && params.em) || em;
    const {
      data: { resendConfirmationEmail },
    } = await this.props.client.query({
      query: ResendConfirmationEmail,
      variables: { input: { username, email } },
    });
    store.dispatch.loader.persistLoader({ persist: false });
    if ((resendConfirmationEmail.error && !reconfirm) || (resendConfirmationEmail.error && reconfirm && emailSent)) {
      store.dispatch.modal.saveCustomMessage(resendConfirmationEmail.message);
      return store.dispatch.modal.toggleOpen('registerErrorModal');
    }
    if (email || (username && !reconfirm) || emailSent) {
      store.dispatch.modal.toggleOpen('cancelConfirmationSuccess');
    }
    if (reconfirm && has(resendConfirmationEmail, 'signupScreen')) {
      store.dispatch.signup.setEmailResent({ emailSent: true });
      return store.dispatch.signup.fetchStart({
        signupScreen: resendConfirmationEmail.signupScreen,
      });
    }
  };

  updateNewAccountEmail = async _ => {
    const email = store.getState().signup.updatedEmail;
    const { patientId, username } = store.getState().signup;
    const { practiceId } = parseParams(this.props);
    store.dispatch.loader.persistLoader({ persist: true });
    const {
      data,
      data: { updateNewAccountEmail },
    } = await this.props.client.mutate({
      mutation: Mutations.UpdateNewAccountEmail,
      variables: {
        email,
        practiceId,
        patientId,
        username,
      },
    });
    store.dispatch.modal.toggleOpen(false);
    if (updateNewAccountEmail.error) {
      store.dispatch.loader.persistLoader({ persist: false });
      store.dispatch.modal.saveCustomMessage(data.updateNewAccountEmail.message);
      return store.dispatch.modal.toggleOpen('registerErrorModal');
    } else {
      store.dispatch.signup.fetchStart({
        signupScreen: updateNewAccountEmail.signupScreen,
      });
      store.dispatch.signup.storeEmail({ email });
      store.dispatch.loader.persistLoader({ persist: false });
    }
  };
  confirmSignupEmail = async ({ un, code, em }) => {
    const {
      data: { confirmSignupEmail },
    } = await this.props.client.query({
      query: ConfirmSignupEmail,
      variables: { input: { un, code, em } },
    });
    if (!isEmpty(confirmSignupEmail)) {
      return store.dispatch.signup.fetchStart({
        signupScreen: confirmSignupEmail.signupScreen,
      });
    }
    this.props.history.push('/?confirmed=true');
  };
  routeToLogin = () => {
    this.props.history.push('/');
  };
  passwordShowByNameSignup = passwordId => {
    console.log(passwordId);
    const inputElements = document.getElementsByName(passwordId.inputName);
    const eyeIcon = document.getElementById(passwordId.buttonId);
    const eyeShowPasswordSrc = 'https://assets.gobreeze.com/eye-password-show-svgrepo-com.svg';
    const eyeHidePasswordSrc = 'https://assets.gobreeze.com/eye-password-hide-svgrepo-com.svg';
    if (inputElements.length > 0) {
      const inputElement = inputElements[0];
  
      if (inputElement.type === 'password') {
        inputElement.type = 'text';
        eyeIcon.querySelector('img').src = eyeHidePasswordSrc;
      } else {
        inputElement.type = 'password';
        eyeIcon.querySelector('img').src = eyeShowPasswordSrc;
      }
    } else {
      console.error(`No input element found with name "${passwordId}"`);
    }
  };
  render() {
    const {
      signup: { signupScreen },
      loader: { persist },
      location: { pathname },
      login: { screens: { darkMode, whiteLabelColor } = {} },
    } = this.props;
    const { i, em, un, confirmation_code: code, practiceId, reconfirm, confirm } = parseParams(this.props);
    const registerAccount = pathname.includes(REGISTER_ACCOUNT);
    if ((i && em) || practiceId || (un && code) || reconfirm) {
      return (
        signupScreen && (
          <div>
            {persist && <PageLoader />}
            <SignupContext.Provider
              value={{
                resendConfirmationEmail: this.resendConfirmationEmail,
                routeToLogin: this.routeToLogin,
                updateNewAccountEmail: this.updateNewAccountEmail,
              }}
            >
              {registerAccount || reconfirm ? (
                <PropsDependentRegisterContainer
                  registerAccount={registerAccount || reconfirm}
                  darkMode={darkMode}
                  whiteLabelColor={whiteLabelColor}
                  className={classNames({ [styles.confirm]: confirm })}
                  isMobile={isMobileDevice()}
                  isRotation={
                    document.getElementsByClassName('rotation90').length > 0 ||
                    document.getElementsByClassName('rotation-90').length > 0
                  }
                >
                  <SchemaParser schema={signupScreen} />
                  {!registerAccount && <Toast />}
                </PropsDependentRegisterContainer>
              ) : (
                <ConfirmEmailInvalid isMobile={isMobileDevice()}>
                  <div className={classNames({ [styles.confirm]: confirm })}>
                    <SchemaParser schema={signupScreen} />
                  </div>
                </ConfirmEmailInvalid>
              )}
            </SignupContext.Provider>
            {!registerAccount && <Toast />}
          </div>
        )
      );
    }
    return <Redirect to="/" />;
  }
}

const mapStateToProps = ({ signup, form, loader, login }) => ({
  signup,
  form,
  loader,
  login,
});
const enhancer = compose(
  withRouter,
  withApollo,
  connect(mapStateToProps),
  graphql(GetSignup, {
    name: 'getSignup',
    skip: props =>
      parseParams(props).un || props.location.pathname.includes(REGISTER_ACCOUNT) || parseParams(props).reconfirm,
    options: props => ({
      variables: {
        invitationId: parseParams(props).i,
        invitationEmail: parseParams(props).em,
        sms: parseParams(props).sms,
      },
    }),
  }),
);

export default enhancer(SignupContainer);
