export const paymentPlan = {
  state: {
    monthlyPayment: 0,
    numberOfMonths: 0,
    planName: null,
    option: 'new',
    currentPlan: null,
    defaultValuesSet: true,
    existingPlanOption: false,
    agreeToTerms: true,
    newLineItems: null,
    selectedPlan: false,
    termsSent: true,
  },
  reducers: {
    updatePlan: (state, key, value) => ({
      ...state,
      [key]: value,
      defaultValuesSet: false,
    }),
    toggleTermsAgreement: (state, payload = {}) => {
      const { value } = payload || { value: !state.agreeToTerms };
      return {
        ...state,
        agreeToTerms: value,
      };
    },
    termsNotSent: state => ({
      ...state,
      termsSent: false,
    }),
    clearPaymentOption: state => ({
      ...state,
      option: 'new',
    }),
    clearPlanInputs: state => ({
      ...state,
      monthlyPayment: 0,
      numberOfMonths: 0,
    }),
    resetPaymentPlan: state => ({
      ...state,
      option: 'new',
      monthlyPayment: 0,
      numberOfMonths: 0,
      planName: null,
      dayOfMonth: 1,
      dayOfWeek: 7,
      currentPlan: null,
      defaultValuesSet: true,
      existingPlanOption: false,
      existingPlanName: '',
      selectedPlan: false,
    }),
    setDefault: (state, monthlyPayment) => ({
      ...state,
      monthlyPayment,
    }),
    setExistingPlan: (state, currentPlan) => ({
      ...state,
      currentPlan,
      monthlyPayment: currentPlan.monthlyPayment,
      dayOfMonth: currentPlan.dayOfMonth,
      dayOfWeek: currentPlan.dayOfWeek,
      installmentPaid: currentPlan.installmentPaid,
      planName: currentPlan.planName,
      numberOfMonths: currentPlan.installments,
      id: currentPlan.id,
      cardId: currentPlan.cardId,
      combinedTotal: currentPlan.total,
      lineItems: currentPlan.lineItems,
      frequencyCode: currentPlan.frequencyCode,
      existingPlanOption: true,
      selectedPlan: true,
      installments: currentPlan.installments,
      option: 'existing',
      planCardExpired: currentPlan.planCardExpired,
    }),
    setExistingPlanToEdit: (state, planDetails) => ({
      ...state,
      monthlyPayment: planDetails.monthlyPayment,
      numberOfMonths: planDetails.numberOfMonths,
      dayOfMonth: planDetails.dayOfMonth,
      existingPlanName: planDetails.planName,
      lineItems: planDetails.lineItems,
      cardId: planDetails.cardId,
      existingCardExpired: planDetails.existingCardExpired,
      patientId: planDetails.patientId,
      option: planDetails.option,
      totalAmount: planDetails.totalAmount,
      originalAmount: planDetails.originalAmount,
      frequencyCode: planDetails.frequencyCode,
      dayOfWeek: planDetails.dayOfWeek,
      paymentsMade: planDetails.paymentsMade,
      originalInstallments: planDetails.originalInstallments,
      currentPlan: { total: planDetails.totalAmount },
      selectedPlan: true,
    }),
    setLineItems: (state, newLineItems, settings) => ({
      ...state,
      newLineItems,
      settings,
    }),
    resetTerms: state => ({
      ...state,
      agreeToTerms: true,
    }),
    clearSelectedPlan: state => ({
      ...state,
      selectedPlan: false,
    }),
    setTermsFalse: state => ({
      ...state,
      agreeToTerms: false,
    }),
  },
};
