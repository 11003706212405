import React, { Component } from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import classNames from 'classnames';
import styles from './checkboxes.module.scss';
import { Checkbox } from '../index';

class Checkboxes extends Component {
  constructor(props) {
    super(props);
    this.state = { ...props.formData };
  }
  onChange = name => checked => {
    const checkedArray = Object.assign([], this.state.checkedArray);
    if (checked) {
      checkedArray.push(name);
    } else {
      const index = checkedArray.findIndex(checked => checked === name);
      checkedArray.splice(index, 1);
    }
    this.setState(
      { checkedArray },
      () => this.props.onChange(this.state.checkedArray),
      // eslint-disable-next-line function-paren-newline
    );
  };

  render() {
    const {
      schema: { title, description },
      schema,
      formData = [],
      required,
      rawErrors = [],
      errors,
      uiSchema: { requiredLabel, errorRequiredLabel } = {},
    } = this.props;
    return (
      <div>
        <label className={styles.title}>
          {title} {'  '}
          {required && (
            <span className={styles.required}>{requiredLabel || required}</span>
          )}
        </label>
        {schema['x-schema-form'].titleMap.map(item => (
          <Checkbox
            key={item.name}
            formContext={this.props.formContext}
            onChange={this.onChange(item.value)}
            schema={{ title: item.name }}
            formData={formData.includes(item.name)}
          />
        ))}
        {!isEmpty(rawErrors)
          ? rawErrors.map(error => (
              // eslint-disable-next-line react/jsx-indent
              <div className={styles.errorMessage} key={error}>
                {errorRequiredLabel}
              </div>
              // eslint-disable-next-line indent
            ))
          : errors &&
            errors.map(error => (
              <div key={error} className={styles.errorMessage}>
                {error}
              </div>
            ))}
        {description && (
          <div
            className={classNames(styles.supportingText, {
              // eslint-disable-next-line object-curly-newline
              [styles.supportingTextGreen]: !isEmpty(this.props.formData),
            })}
          >
            {description}
          </div>
        )}
      </div>
    );
  }
}
export default Checkboxes;
