import React from 'react';
import { getMobileDevice } from '../../services/utilities';
import { Utilities } from '../../../constants';

const MessagesLink = () => {
  return (
    <div>
      <a
        href={
          getMobileDevice() === Utilities.GOOGLE
            ? `${window.location.protocol}//${window.location.hostname}/messages?messageId=121421342134`
            : `${window.location.protocol}//${window.location.hostname.replace(
                'web',
                'breeze-deeplink',
              )}/messages?messageId=121421342134`
        }
        onClick={() => {
          setTimeout(function() {
            if (getMobileDevice() === Utilities.GOOGLE) {
              window.location = 'market://details?id=com.carecloud.carepay.patient';
            } else if (getMobileDevice() === Utilities.APPLE) {
              window.location = 'itms-apps://itunes.apple.com/app/id1197472721';
            }
          }, 300);
        }}
      >
        Login
      </a>
    </div>
  );
};

export default MessagesLink;
