import { Portal } from 'react-portal';
import React from 'react';
import classNames from 'classnames';
import styles from './modal/modal.module.scss';
import { Button, Icon, Header } from '../ui/index';

const clientSideModal = ({
  id,
  modalOpen,
  iconId,
  iconIcon,
  headerLabel,
  subHeaderLabel,
  subHeaderLabel2,
  headerId,
  subHeaderId,
  message,
  transform,
  buttons = [],
  timer = String(),
}) =>
  modalOpen === id && (
    <Portal>
      <div
        className={classNames(styles.backdrop, { [styles.fadeIn]: transform, styles })}
        role="button"
        tabIndex="-1"
        id="outerContent"
      >
        <div className={classNames(styles.container, styles.clientSideModalContainer, { [styles.slideIn]: transform })}>
          <div className={styles.clientSideModalHeaders}>
            <div>
              <i id="close" role="button" tabIndex="0" className={classNames('material-icons', 'close')} />
            </div>
            {iconId && <Icon {...{ id: iconId, theme: iconId, icon: iconIcon }} />}
            {headerId && <Header theme={headerId} id={headerId} type="h1" label={headerLabel} />}
            {subHeaderId && (
              <Header
                theme={subHeaderId}
                id={subHeaderId}
                type="h5"
                label={`${subHeaderLabel} ${timer} ${subHeaderLabel2}`}
              />
            )}
            <div className={styles.innerContent}>
              <p>{message}</p>
            </div>
          </div>
          <div className={styles.clientSideModalButtonContainer}>
            {buttons.map(({ id, text, route, action, disabled, theme }) => (
              <Button
                id={id}
                key={id}
                theme={theme}
                text={text}
                type="submit"
                route={route}
                disabled={disabled}
                action={action}
              />
            ))}
          </div>
        </div>
      </div>
    </Portal>
  );

export const ClientSideModal = clientSideModal;
