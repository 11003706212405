import React from 'react';
import classNames from 'classnames';
import styles from './linearLoader.module.scss';


const linearLoader = React.memo(
    ({
    className,
  }) =>  (
    <div
      className={classNames(
        styles.root,
        styles.colorPrimary,
        className,
      )}
      role="progressbar"
    >
      <div
        className={classNames(styles.bar, styles.bar1Indeterminate, styles.barColorPrimary)}
      />
      <div
        className={classNames(styles.bar, styles.bar2Indeterminate, styles.barColorSecondary)}
      />
    </div>
    ),
  );



export default linearLoader;