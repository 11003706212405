import React from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import classNames from 'classnames';
import styles from './inputDropdown.module.scss';

const inputDropdown = React.memo(
  ({
    Select,
    theme,
    placeholder,
    handleChange,
    value,
    name,
    id,
    options,
    label,
    required,
    searchable,
    loadFunction,
    arrowRenderer,
    valueKey,
    complexValue,
    labelKey,
    supportingText,
    disabled,
    rawErrors = [],
    errors,
    uiSchema: { requiredLabel, errorRequiredLabel } = {},
  }) => (
    <div id={`${id}inputDivWrapper`} className={classNames(styles[theme], styles.select)}>
      {label && (
        <label htmlFor={name} className={styles.label}>
          {label}
          {!value && required && <span className={styles.required}>{requiredLabel || required}</span>}
        </label>
      )}
      <div className={classNames({ [styles.selectWrapper]: !required, [styles.disabled]: disabled })}>
        <Select
          id={id}
          name={name}
          value={value}
          onChange={e => handleChange(e)}
          clearable={false}
          disabled={disabled}
          searchable={required ? !!searchable : false}
          loadOptions={loadFunction}
          simpleValue={!complexValue}
          placeholder={placeholder}
          options={options}
          arrowRenderer={arrowRenderer}
          valueKey={valueKey || undefined}
          labelKey={labelKey || undefined}
        />
      </div>
      {!isEmpty(rawErrors)
        ? rawErrors.map(error => (
            // eslint-disable-next-line react/jsx-indent
            <div className={styles.errorMessage} key={error}>
              {errorRequiredLabel}
            </div>
          ))
        : errors &&
          errors.map(error => (
            <div key={error} className={styles.errorMessage}>
              {error}
            </div>
          ))}
      {supportingText && (
        <div className={classNames(styles.supportingText, { [styles.supportingTextGreen]: value })}>
          {supportingText}
        </div>
      )}
    </div>
  ),
);

export const InputDropdown = inputDropdown;
