import React, { useContext } from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import styles from './textArea.module.scss';
import { ContextProvider } from '../../root/TopLevelContext';
import { store } from '../../../models';
import * as babyContexts from '../../../contexts';

const textArea = React.memo(
  ({
    schema,
    onChange,
    formData,
    value,
    placeholder,
    minRows = 1,
    required,
    requiredLabel,
    errorRequiredLabel,
    placeholder: propPlaceholder = String(),
    customAction: { model = String(), trigger = String(), babyContext = String() } = {},
    noScrollBar = false,
    rawErrors = [],
    errors,
  }) => {
    const context = useContext(ContextProvider);
    const variableContext = babyContext && useContext(babyContexts[`${babyContext}Context`]);
    return (
      <div>
        {styles && schema && (
          <label className={styles.title}>
            {schema.title} {'  '}
            {required && <span className={styles.required}>{requiredLabel || required}</span>}
          </label>
        )}
        <TextareaAutosize
          useCacheForDOMMeasurements
          className={classNames(styles.textarea || styles, noScrollBar && styles.noScrollBar)}
          onChange={
            onChange
              ? e => onChange(e.target.value)
              : e => {
                if (model === 'localActions') context._getAction(trigger)({ textAreaValue: e.target.value });
                else if (babyContext) variableContext[trigger]({ textAreaValue: e.target.value });
                else store.dispatch[model][trigger]({ textAreaValue: e.target.value });
              }
          }
          placeholder={placeholder || propPlaceholder}
          value={formData || value}
          minRows={minRows || 1}
        />
        {!isEmpty(rawErrors)
          ? rawErrors.map(error => (
            <div className={styles.errorMessage} key={error}>
              {errorRequiredLabel}
            </div>
          ))
          : errors &&
            errors.map(error => (
              <div key={error} className={styles.errorMessage}>
                {error}
              </div>
            ))}
        {schema && schema.description && (
          <div className={classNames(styles.supportingText, { [styles.supportingTextGreen]: formData })}>
            {schema.description}
          </div>
        )}
      </div>
    );
  },
);
export const TextArea = textArea;
