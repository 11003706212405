import React from 'react';
import { withApollo } from 'react-apollo';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { store } from '../../../models';
import {
  MODELS_TO_CLEAR,
  PROBLEMATIC_ROUTES,
} from '../../../constants/Delegate';
import styles from './dropdown.module.scss';
import SchemaParser from '../../services/SchemaParser';
import { dropdownContext as DropdownContext } from '../../../contexts';

const Dropdown = React.memo(
  ({
    data,
    profiles,
    theme,
    dropdown: { dropDownOpen },
    id,
    location: { pathname },
    history,
  }) => {
    const handleProfileSwitch = param => {
      // set profileId in session
      sessionStorage.removeItem('CC-Breeze-Web-representedUserId');
      param &&
        param !== 'self' &&
        sessionStorage.setItem('CC-Breeze-Web-representedUserId', param);
      store.dispatch.dropdown.toggleOpen('userMenu');
      store.dispatch.layout.clearStore();
      store.dispatch.layout.resetState();

      MODELS_TO_CLEAR.forEach(model => store.dispatch[model].clearStore());
      if (PROBLEMATIC_ROUTES.includes(pathname.split('/')[1])) {
        return history.push('/');
      }
      store.dispatch.loader.persistLoader({ persist: true });
    };
    return (
      dropDownOpen === id && (
        <div className={classNames(styles.dropDown, styles[theme])}>
          {!isEmpty(profiles) && (
            <div className={styles.profilesWrapper}>
              <DropdownContext.Provider value={{ handleProfileSwitch }}>
                <SchemaParser schema={profiles} />
              </DropdownContext.Provider>
            </div>
          )}
          <SchemaParser schema={data} />
        </div>
      )
    );
  },
);

export default compose(withApollo, withRouter)(Dropdown);
