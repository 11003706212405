export const WINDOWS = 'Windows';
export const APPLE = 'Apple';
export const GOOGLE = 'Google';
export const DESKTOP = 'Desktop';
export const MOUSEDOWN = 'mousedown';
export const TOUCHSTART = 'touchstart';
export const EVENTS = [MOUSEDOWN, TOUCHSTART];
export const PROVIDER_OTHER = 'OTHER';
export const RELATIONSHIP_SELF = 'Self';
export const SINGLE_NAME_FIELD_PATTERN = '^[A-z-\']+$';
export const DOUBLE_NAME_FIELD_PATTERN = '^[A-z]+(\\s[A-z]+)?$';
export const NAME_FIELD_PATTERN = '^[A-z]+((\\s[A-z]+)?)+$';
export const DATE_MASK = '99/99/9999';
export const DATE_BASE_FORMAT = 'MM/DD/YYYY';
