export const CHECK_OUT = 'check_out';
export const CHECK_IN = 'check_in';
export const PREPAYMENT = 'prepayment';
export const CANCELLATION = 'cancellation';
export const SCHEDULE_PAYMENT = 'schedule_payment';
export const EDIT_PAYMENT_PLAN = 'edit_payment_plan';
export const EDIT_ONE_TIME = 'edit_one_time';
export const REGISTER_ACCOUNT = 'register_account';
export const ADD_APPOINTMENT = 'add_appointment';
export const GUEST = 'guest';
