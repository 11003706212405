import React, { memo } from 'react';
import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { store } from '../../../models';
import SchemaParser from '../../services/SchemaParser';
import styles from './expander.module.scss';
import ClickAwayListener from '../../services/ClickAwayListener';
import { expanderContext as ExpanderContext } from '../../../contexts';

const ExpanderContainer = memo(
  ({ theme, id, expander, expanderOpen, expandedContent, withClickAway }) => {
    const { open } = store.getState().expander;
    const expandContent = expanderId => {
      const { open: expand, close: contract } = store.dispatch.expander;
      open.includes(expanderId)
        ? contract({ expanderId })
        : expand({ expanderId });
    };
    const expanded = expanderOpen.includes(id);
    const expandedJsx = _ => (
      <div
        className={classNames(styles.notExpanded, {
          // eslint-disable-next-line object-curly-newline
          [styles.expanded]: expanded,
        })}
      >
        <SchemaParser schema={expandedContent} />
      </div>
    );
    return (
      <div id={id} className={styles.wrapper}>
        <div
          className={classNames(
            styles[theme],
            { [styles.isNotExpanded]: !expanded },
            { [styles.isExpanded]: expanded },
          )}
        >
          <ExpanderContext.Provider value={{ expandContent }}>
            <SchemaParser schema={expander} />
          </ExpanderContext.Provider>
        </div>
        {withClickAway ? (
          <ClickAwayListener
            onClickAway={_ => store.dispatch.expander.close({ expanderId: id })}
          >
            <>{expandedJsx()}</>
          </ClickAwayListener>
        ) : (
          <>{expandedJsx()}</>
        )}
      </div>
    );
  },
);
const mapStateToProps = ({ expander: { showContent, open } }) => ({
  showContent,
  expanderOpen: open,
});
const enhancer = compose(
  connect(mapStateToProps),
);

// ExpanderContainer.whyDidYouRender = true;

export default enhancer(ExpanderContainer);
