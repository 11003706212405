import { withApollo } from 'react-apollo';
import { compose, get } from '@carecloud/cloudpak';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { store } from '../../../models';

import { ContinueThirdParty } from '../../../graphql/mutations';
import { deSerializeObjValues } from '../../services/utilities';
import { parseParams } from '../../services/routing';

class CheckinReEntry extends Component {
  constructor(props) {
    super(props);
    const parsedParams = Object.assign(...Object.keys(parseParams(props)).map(k => ({ [k.replace('I', '_i')]: parseParams(props)[k] })));
    const appointmentData = deSerializeObjValues(parsedParams);
    this.state = appointmentData;
    store.dispatch.loader.activateLoader({ loading: true });
  }

  async componentDidMount() {
    const {
      appointment_id: appointmentId,
      patient_id: patientId,
      practice_id: practiceId,
      locationName,
      providerName,
      appointmentStartTime,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state,
      zipCode,
      phoneNumber,
    } = this.state;
    const { data } = await this.props.client.mutate({
      mutation: ContinueThirdParty,
      variables: {
        input: {
          checkInIds: {
            appointmentId,
            patientId,
            practiceId,
          },
          additionalInfo: {
            locationName,
            providerName,
            appointmentStartTime,
            addressLine1,
            addressLine2,
            addressLine3,
            city,
            state,
            zipCode,
            phoneNumber,
          },
        },
      },
    });
    store.dispatch.loader.activateLoader({ loading: false });
    if (get(data, 'continueThirdParty.submissionResult.success')) {
      store.dispatch.appointmentCheckIn.storeRedirectPath(data.continueThirdParty.submissionResult.redirect);
      store.dispatch.appointments.resetState();
      this.props.history.push('/appointments');
    } else if (get(data, 'continueThirdParty')) {
      store.dispatch.appointmentCheckIn.storeAppointmentData(this.state);
      store.dispatch.appointmentCheckIn.fetchStart(data.continueThirdParty);
      this.props.history.push('/appointments/check_in');
    }
  }
  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
  }

  render() {
    return <div />;
  }
}

const enhancer = compose(withApollo, withRouter);
export default enhancer(CheckinReEntry);
