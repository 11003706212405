import { Portal } from 'react-portal';
import React from 'react';
import classNames from 'classnames';
import SchemaParser from '../../services/SchemaParser';
import styles from './modal.module.scss';

const modal = React.memo(
  ({
    clickHandler,
    transform,
    content,
    theme,
    modalOpen,
    id,
    hideClose,
    header,
    buttons,
    radioInputCancellationReason,
    enableCancelButton,
    handleOutsideClick,
    displayCustomMessage,
    modal: { customMessage },
    withBackdrop,
    otpError = false,
    errorMessage,
  }) =>
    modalOpen === id && (
      <Portal>
        <div
          onClick={handleOutsideClick}
          className={classNames({ [styles.fadeIn]: transform, [styles.backdrop]: withBackdrop, styles })}
          role="button"
          tabIndex="-1"
          id="outerContent"
        >
          <div
            className={classNames(
              styles.container,
              styles[theme],
              { [styles.slideIn]: transform },
              { [styles.cancelEnabled]: radioInputCancellationReason || enableCancelButton },
              { [styles.otherReasonEnabled]: radioInputCancellationReason === 'OT' },
            )}
          >
            {!hideClose && (
              <i
                onClick={clickHandler}
                id="close"
                role="button"
                tabIndex="0"
                className={classNames('material-icons', 'close', styles.closeIcon)}
              >
                close
              </i>
            )}
            {header && <SchemaParser schema={[header]} />}
            {!(displayCustomMessage && customMessage) && content && (
              <div className={header ? styles.scrollableModalContent : styles.innerContent}>
                <SchemaParser schema={[content]} />
              </div>
            )}
            {errorMessage && otpError && (
              <div className={styles.errorMessage}>
                <h6>{errorMessage}</h6>
              </div>
            )}
            {displayCustomMessage && customMessage && (
              <div id={styles.customMessage} className={styles.customMessage}>
                <h4>!</h4>
                <p>{customMessage}</p>
              </div>
            )}
            {buttons && <SchemaParser schema={[buttons]} />}
          </div>
        </div>
      </Portal>
    ),
);

export const Modal = modal;
