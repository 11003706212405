import React from 'react';
import classNames from 'classnames';
import modalStyles from '../modal/modal.module.scss';
import styles from './notification.module.scss';
import btnStyles from '../../ui/button/button.module.scss';
import appointmentStyle from '../../ui/appointment/appointment.module.scss'

const inheritedStyles = { ...modalStyles, ...styles };

const notification = ({ props: { open, clickHandler, data, transform, labels, actions } }) =>
  open &&
  data && (
    <div
      className={classNames(inheritedStyles.backdrop, { [inheritedStyles.fadeIn]: transform, inheritedStyles })}
      role="button"
      tabIndex="-1"
      id="outerContent"
    >
      <div className={classNames(inheritedStyles.container, { [inheritedStyles.slideIn]: transform })}>
        <div className={inheritedStyles.header}>
          <i
            onClick={clickHandler}
            id="close"
            role="button"
            tabIndex="0"
            className={classNames('material-icons', 'close', inheritedStyles.closeIcon)}
          >
            close
          </i>
          <h4>{labels.title}</h4>
        </div>
        <div className={inheritedStyles.message}>
          <p>
            {labels.message}
            {data.practiceName}
          </p>
        </div>
        {actions ?
          (
            <div className={appointmentStyle.buttons}>
              <div>
                <button 
                  className={classNames(btnStyles.btn, btnStyles.btnCheckInNowAppointment)}
                  onClick={() => actions.firstButtonAction(data)}
                >
                  {labels.checkIn}
                </button>
                <button 
                  className={classNames(btnStyles.btn, btnStyles.btnCheckInAtOfficeAppointment)}
                  onClick={() => actions.secondButtonAction(data)}
                >
                  {labels.title}
                </button>
              </div>
            </div>
          )
          :
          (
            <div className={inheritedStyles.actionSection}>
              <button className={inheritedStyles.actionButton} onClick={clickHandler}>
                {labels.button}
              </button>
            </div>
          )}
      </div>
    </div>
  );

export const Notification = notification;
