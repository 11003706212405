import { connect } from 'react-redux';
import React, { Component } from 'react';
import { store } from '../../../models';

import { Toast } from './toast';
import { Utilities } from '../../services';

class ToastContainer extends Component {
  componentDidMount() {
    this.props.resetToast();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.toast.open && !nextProps.toast.timeOutSet) {
      store.dispatch.toast.timerSet();
      const timeOut = setTimeout(this.close, 5000);
      this.setState({ timeOut });
    }
  }
  clickHandler = _ => {
    this.close();
  };
  close = _ => {
    const { close, transform } = this.props;
    Utilities.transition({ start: transform, end: close, duration: 400 });
  };
  mouseEnter = _ => clearTimeout(this.state.timeOut);
  mouseLeave = _ => this.setState({ timeOut: setTimeout(this.close, 5000) });
  render() {
    const { toast: { open, transform, data } } = this.props;
    return (
      <Toast
        props={{
          open,
          data,
          transform,
          clickHandler: this.clickHandler,
          mouseEnter: this.mouseEnter,
          mouseLeave: this.mouseLeave,
        }}
      />
    );
  }
}

const mapStateToProps = ({ toast }) => ({ toast });
const mapDispatchToProps = ({ toast }) => ({
  close: toast.close,
  transform: toast.transform,
  resetToast: toast.resetToast,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ToastContainer);
