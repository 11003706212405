import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './SearchField.module.scss';

export default React.memo(props => {
  const { disabled, name, onChange, placeholder, value, onApply, onCancel } = props;

  const handleChange = event =>
    onChange({
      target: {
        name,
        value: (event?.target?.value || '').trimLeft(' '),
      },
    });

  const handleKeyPress = ev => {
    if (ev.charCode === 13 || ev.keyCode === 13) {
      onApply();
    }
  };

  return (
    <div className={styles.container}>
      <input
        className={styles.input}
        disabled={disabled}
        name={name}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        placeholder={placeholder}
        value={value}
      />
      {value && !disabled && (
        <div className={styles.actions}>
          <FontAwesomeIcon icon="times-circle" color="#CFD8DC" onClick={onCancel} />
          <FontAwesomeIcon icon="search" color="#5BC5D0" onClick={onApply} />
        </div>
      )}
    </div>
  );
});
