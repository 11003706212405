export const checkDateOfBirth = ({ inputDates }) => {
  if (inputDates && inputDates.length > 0) {
    const dates = inputDates.split('/');
    if (dates.length >= 3) {
      const d = dates[0];
      const m = dates[1];
      const y = dates[2];
      return new Date(`${d}-${m}-${y}`) > new Date() && true;
    }
    return false;
  } else {
    return false;
  }
};
