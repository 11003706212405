export const makePayments = {
  state: { makePayments: [], isValid: false },
  reducers: {
    fetchStart: (state, { makePaymentsScreen }) => ({
      ...state,
      makePayments: makePaymentsScreen,
    }),
    updateValid: (state, isValid) => ({
      ...state,
      isValid,
    }),
    clearMakePayments: _ => ({
      makePayments: [],
      isValid: false,
    }),
    clearStore: () => ({ ...makePayments.state }),
  },
};
