import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import styles from './Login.module.scss';
import { SchemaParser } from '../../services';
import { Applink, Checkmark, PageLoader } from '../../ui';

const PropsDependentStyledLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.isMobile ? '100%' : '50%')};
  /* height: 100vh; */
  grid-row-gap: 10px;
  background: ${props => !props.whiteLabelColor && "url('/images/public-background.jpg') top / cover no-repeat"};
  justify-content: center;
  align-items: center;
  z-index: 7;
`;
const PropsDependentStyledMobileLoginOrSignup = styled.div`
  grid-row: 3;
  z-index: 3;
  width: ${props => (!props.isMobile ? '400px' : '80vw')};
  margin: 0 auto;
  justify-self: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  ${props =>
    props.isMobile &&
    `
  flex: 0px 0px 300px;
  padding: 0px 12px;
  align-self: center;
  & [class*='signupMobilePrompt'] {
    display: ${
      /* eslint-disable no-nested-ternary */
      props.isMobileSignup ? 'inline-block' : props.postSignUp || props.isMobileSignup ? 'block' : 'none'
    };
    }
  `}
`;
const PropsDependentStyledDropdown = styled.div`
  z-index: 3;
  grid-row: 4;
  justify-content: center;
  margin: 20px auto;
  align-self: ${props => (props.whiteLabelColor ? 'start' : 'center')};
  height: 150px;
  & span {
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 13px;
  }
  & [class*='loginLanguageMenu'] {
    & button:not(:last-child) {
      border-bottom: 1.5px solid #000;
      border-radius: 0px;
    }
  }
  & [class*='loginLanguageDropdown'] {
    color: ${props => (props.darkMode ? '#455a64' : '#FFF')};
  }
`;

const PropsDependentStyledLogo = styled.div`
  grid-row: ${props => (props.postSignUp || props.whiteLabelColor ? 1 : 2)};
  // align-self: ${props => (props.isMobile ? 'start' : 'end')};
  padding: 20px;
  & img {
    margin: 0 auto 20px auto !important;
  }
  ${props =>
    props.whiteLabelHeader.length &&
    props.whiteLabelHeader[0].id &&
    `
  grid-row: 1;
  align-self: center;
  & img {
    max-width: 500px;
    max-height: 100px;
  }
  `};
  ${props =>
    props.confirmationSuccessfulMessage &&
    `
  & img {
    max-width: 500;
  }`};
  ${props => props.isMobile && props.whiteLabelColor && `padding-bottom: 0px`}
`;

const PropsDependentLoginForm = styled.form`
  display: flex;
  padding: 20px 20px 5px 20px;
  flex-direction: column;
  ${props =>
    props.alternateView === 'patientPortal' &&
    `& #btnCreateNewAccount { display: none; }
          & #btnSignIn { margin-bottom: 10px; }
          & #sign_in_as_practice { display: none; }`};
  ${props =>
    props.whiteLabelHeader.length &&
    `
      & input & a {
        color: ${props.darkModeDependentWhiteLabelColor}
        -webkit-text-fill-color:  ${props.darkModeDependentWhiteLabelColor}
      }
      & input {
        color: ${props.darkModeDependentWhiteLabelColor} !important
        -webkit-text-fill-color:  ${props.darkModeDependentWhiteLabelColor}
      }
      & #forgotPassword {
        color: ${props.darkModeDependentWhiteLabelColor}
      }
      & #btnSignIn {
        background-color: ${props.darkModeDependentWhiteLabelColor}
        &:disabled {
          background-color: #ffffff;
        }
      }
      & #btnCreateAccount {
        background-color: white;
        border: 1px solid ${props.darkModeDependentWhiteLabelColor};
        border-radius: 6px;
        color: ${props.darkModeDependentWhiteLabelColor};
      }
      & #recoverPasswordBtn {
        background-color: ${props.darkModeDependentWhiteLabelColor}
      }
      & #signInBtn {
        color: ${props.darkModeDependentWhiteLabelColor}
      }
      `};
`;
const PropsDependentWhiteLabelHeaderWrapper = styled.div`
  grid-row: 2;
  ${props => props.isMobile && 'max-width: 100vw; justify-self: center'};
  & h1 {
    font-family: 'Metropolis';
    line-height: ${props => (props.isMobile ? '0px' : '50px')};
    font-weight: 600;
    color: #fff;
    max-width: 650px;
    text-align: center;
    font-size: ${props => (props.isMobile ? '1rem' : '36px')};
  }
  & div[id*='whiteLabelHeaderDark'] {
    & h1 {
      color: #455a64;
    }
  }
  & h3 {
    font-family: 'Metropolis';
    font-weight: 100;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 24px;
    ${props => props.isMobile && `display: none;`}
  }
  & div[id*='whiteLabelSubHeaderDark'] {
    & h3 {
      color: #455a64;
    }
  }
`;

const GradientOverlay = styled.div`
  z-index: 5;
  height: ${props => (props.isMobile ? '30%' : '100vh')};
  width: ${props => (props.isMobile ? '100%' : '50%')};
  position: absolute;
  background: linear-gradient(
    133deg,
    rgba(255, 255, 255, 0.43) 0%,
    rgba(255, 255, 255, 0.18) 40%,
    rgba(255, 255, 255, 0.01) 96%,
    rgba(0, 0, 0, 0.01) 100%
  );
`;

const GradientOverlayColor = styled.div`
  height: ${props => (props.isMobile ? '30%' : '100vh')};
  width: ${props => (props.isMobile ? '100%' : '50%')};
  background: ${props => props.whiteLabelColor || '#5bc5d0'};
  z-index: 4;
  position: absolute;
`;

const CreateAccountButtonSection = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  bottom: 0;
  & div[id*='landingPageView'] {
    ${props => props.postSignUp && 'display: none;'}
  }
  & div[id*='confirmedAccountView'] {
    ${props => !props.postSignUp && 'display: none;'}
  }
  & div[id*='thankYouHeader'] {
    display: flex;
    justify-content: center;
    font-size: 19px;
    color: #455a64;
    margin-top: 10px;
    font-weight: 450;
    line-height: 23px;
    letter-spacing: -1px;
  }
  & img[id*='signupSuccessLogo'] {
    padding-top: 0px;
  }
  & h6[id*='mobileAppsDescription'] {
    margin: 0 10%;
    font-size: 12px;
    line-height: 21px;
    margin-top: 10px;
  }
  & div[id*='patientPortalMobileAppLogo'] {
    & img {
      width: 134.9px;
      padding-top: 10px;
      max-width: none;
    }
  }
  & img {
    max-width: 75%;
    padding-top: 12vh;
  }
  & a[id*='btnCreateAccountMobile'] {
    margin: 28px auto 15px;
    text-transform: uppercase;
    height: 48px;
    font-family: 'Metropolis', sans-serif;
    font-size: 13px;
    font-weight: 500;
    box-shadow: none;
    background-image: none;
    border-radius: 6px;
    border: none;
    --webkit-font-smoothing: auto;
    background-color: ${props => props.whiteLabelColor};
    color: #fff;
    width: 88%;
  }
  & a[id*='btnCreateAccountMobileDark'] {
    background-color: #455a64;
  }
  & div[id*='poweredBy'] {
    display: block;
  }
  & div[id*='signupSuccessLogoHeader'] {
    justify-content: center;
    & h6 {
      font-size: 12px;
    }
  }
  & button[id*='loginLanguageDropdown'] {
    color: ${props => props.whiteLabelColor};
    margin: auto;
  }
`;
const SigninButtonGroup = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  margin-top: 20px;
  & h6 {
    font-size: 14px;
  }
  & a {
    color: ${props => props.whiteLabelColor || '#5bc5d0'};
    font-weight: bold;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    font-size: 14px;
  }
`;

const MobileLogoWrapper = styled.div`
  align-items: center;
  position: absolute;
  z-index: 9;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  & img[id*='mainBrandLogo'] {
    height: 45px;
    margin-top: 15%;
  }
  & img[id*='emailConfirmedLogo'] {
    height: 45px;
    margin-top: 15%;
  }
  & img[id*='whiteLabelLogo'] {
    height: 14vh;
    margin: 10px auto;
    ${props => props.isMobile && `margin-bottom: .5rem`};
  }
  & div[id*='whiteLabelHeaderDark'] {
    & h1 {
      color: #455a64;
    }
  }
  & div[id*='whiteLabelSubHeader'] {
    display: none;
  }
  & h1 {
    font-family: 'Metropolis';
    font-weight: 600;
    color: #fff;
    max-width: 650px;
    text-align: center;
    font-size: 19px;
    margin-top: 0px;
  }
`;

const Login = ({
  props: {
    loginSchema,
    loginScreen,
    selectPractice,
    login: {
      rightSide,
      alternateView,
      showForgotPassword,
      showSelectPractice,
      createNewPassword,
      recoverPasswordCheckmark,
      resetPasswordCheckmark,
      createNewPasswordFlowDisplay,
      requestSuccess,
      signInError,
      screens: {
        newPasswordScreen,
        forgotPasswordCheckEmail,
        newPasswordSaved,
        languageDropdown,
        logo,
        confirmationSuccessfulMessage,
        whiteLabelHeader = [],
        whiteLabelColor,
        darkMode,
        createAccountButton,
        poweredBy,
        appStoreIcon,
      },
      screens,
    },
    cross,
    loading,
    checkmark,
    loadCrossIncomplete,
    loadCheckmarkComplete,
    handleSubmit,
    recoverPassword,
    handlePracticeSelect,
    savePassword,
    errorMessage,
    postSignUp,
    isMobileSignup,
    isMobile,
    labels,
  },
}) => {
  const [showLoginPage, setShowLoginPage] = useState(false);
  const handleShowLoginPage = () => {
    setShowLoginPage(true);
    console.log('showLoginPage', showLoginPage);
  };
  // use for when on mobile and orientation changed on device
  const handleOrientationChange = () => {
    switch (window.orientation) {
      case 90:
        document.body.classList = '';
        document.body.classList.add('rotation90');
        document.body.classList.add('landscape');
        break;
      case -90:
        document.body.classList = '';
        document.body.classList.add('rotation-90');
        document.body.classList.add('landscape');
        break;
      default:
        document.body.classList = '';
        break;
    }
  };
  const isOTPError = error => {
    return error === 'One time password is invalid';
  };

  useEffect(() => {
    const registerChangeOrientation = () => {
      window.addEventListener('orientationchange', handleOrientationChange);
      return () => {
        window.removeEventListener('orientationchange', handleOrientationChange);
      };
    };
    isMobile && registerChangeOrientation();
    //triggering the orientation change in case refresh is done on landscape
    isMobile && handleOrientationChange();
  }, []);
  return (
    <div className={styles.login} style={{ minHeight: isMobile && '0px' }}>
      {loading && <PageLoader loading={loading} />}
      {((isMobile && createNewPasswordFlowDisplay) || !isMobile) && (
        <PropsDependentStyledLeftSide whiteLabelColor={whiteLabelColor} isMobile={isMobile}>
          <PropsDependentStyledLogo
            whiteLabelHeader={whiteLabelHeader}
            whiteLabelColor={whiteLabelColor}
            isMobile={isMobile}
            postSignUp={postSignUp}
            confirmationSuccessfulMessage={confirmationSuccessfulMessage}
          >
            <SchemaParser schema={logo} />
          </PropsDependentStyledLogo>
          {confirmationSuccessfulMessage &&
            postSignUp &&
            (!isMobile ? (
              <SchemaParser schema={confirmationSuccessfulMessage} />
            ) : (
              <PropsDependentWhiteLabelHeaderWrapper isMobile={isMobile}>
                <SchemaParser schema={whiteLabelHeader} />
              </PropsDependentWhiteLabelHeaderWrapper>
            ))}
          {!confirmationSuccessfulMessage && whiteLabelHeader.length ? (
            <PropsDependentWhiteLabelHeaderWrapper isMobile={isMobile}>
              <SchemaParser schema={whiteLabelHeader} />
            </PropsDependentWhiteLabelHeaderWrapper>
          ) : (
            ''
          )}
          <PropsDependentStyledMobileLoginOrSignup
            postSignUp={postSignUp}
            isMobileSignup={isMobileSignup}
            isMobile={isMobile}
          >
            {recoverPasswordCheckmark && (
              <div
                className={classNames(
                  styles.checkmarkContainer,
                  { [styles.loadComplete]: loadCheckmarkComplete },
                  { [styles.loadIncomplete]: loadCrossIncomplete },
                  { [styles.showCheckmark]: checkmark },
                  { [styles.showCross]: cross },
                )}
              >
                <Checkmark />
                {requestSuccess && (
                  <SchemaParser schema={resetPasswordCheckmark ? newPasswordSaved : forgotPasswordCheckEmail} />
                )}
                {cross && errorMessage && <h6>{errorMessage}</h6>}
                {checkmark && errorMessage && <h6>{errorMessage}</h6>}
              </div>
            )}

            <div className={styles.loginFormWrapper}>
              {errorMessage && signInError && !showForgotPassword && !isOTPError(errorMessage) && (
                <h6 className={styles.loginFailureText}>{errorMessage}</h6>
              )}
              {!createNewPassword && !recoverPasswordCheckmark && (
                <PropsDependentLoginForm
                  alternateView={alternateView}
                  whiteLabelHeader={whiteLabelHeader}
                  darkModeDependentWhiteLabelColor={darkMode ? '#455a64' : whiteLabelColor}
                  onSubmit={
                    !showForgotPassword ? (showSelectPractice ? handlePracticeSelect : handleSubmit) : recoverPassword
                  }
                  autoComplete="off"
                  style={{ display: isMobile && 'none' }}
                >
                  <SchemaParser schema={loginSchema} />
                </PropsDependentLoginForm>
              )}
            </div>

            {createNewPassword && (
              <div className={styles.newPasswordForm} onSubmit={savePassword}>
                <SchemaParser schema={newPasswordScreen} />
              </div>
            )}
          </PropsDependentStyledMobileLoginOrSignup>

          {(!isMobile || !whiteLabelColor) && (
            <PropsDependentStyledDropdown whiteLabelColor={whiteLabelColor} darkMode={darkMode}>
              <SchemaParser schema={languageDropdown} />
            </PropsDependentStyledDropdown>
          )}
        </PropsDependentStyledLeftSide>
      )}

      {isMobile && !createNewPasswordFlowDisplay && (
        <>
          <CreateAccountButtonSection whiteLabelColor={darkMode ? '#455a64' : whiteLabelColor} postSignUp={postSignUp}>
            <SchemaParser schema={createAccountButton} />
            {!postSignUp && !showLoginPage && (
              <>
                <SigninButtonGroup whiteLabelColor={darkMode ? '#455a64' : whiteLabelColor}>
                  <h6>{labels.signInTextBegin}</h6>
                  <Applink route="/login">{labels.signInTextLink}</Applink>
                  <h6>{labels.signInTextEnd}</h6>
                </SigninButtonGroup>
                <SchemaParser schema={appStoreIcon} />
              </>
            )}
            {showLoginPage && (
              <div className={styles.loginFormWrapper}>
                {errorMessage && signInError && !showForgotPassword && !isOTPError(errorMessage) && (
                  <h6 className={styles.loginFailureText}>{errorMessage}</h6>
                )}
                {!createNewPassword && !recoverPasswordCheckmark && (
                  <PropsDependentLoginForm
                    alternateView={alternateView}
                    whiteLabelHeader={whiteLabelHeader}
                    darkModeDependentWhiteLabelColor={darkMode ? '#455a64' : whiteLabelColor}
                    onSubmit={
                      !showForgotPassword ? (showSelectPractice ? handlePracticeSelect : handleSubmit) : recoverPassword
                    }
                  >
                    {showSelectPractice ? (
                      <SchemaParser schema={selectPractice} />
                    ) : (
                      <SchemaParser schema={loginScreen} />
                    )}
                    <SchemaParser schema={languageDropdown} />
                  </PropsDependentLoginForm>
                )}
              </div>
            )}
            {!showLoginPage && (
              <div>
                <button className={styles.continueOnWebBtn} onClick={handleShowLoginPage}>Continue on web </button>
              </div>
            )}
            {!showLoginPage && <SchemaParser schema={languageDropdown} />}
            <SchemaParser schema={poweredBy} />
          </CreateAccountButtonSection>
          <MobileLogoWrapper>
            <SchemaParser schema={logo} />
            <SchemaParser schema={whiteLabelHeader} />
          </MobileLogoWrapper>
        </>
      )}
      <GradientOverlay isMobile={isMobile} />
      <GradientOverlayColor whiteLabelColor={whiteLabelColor} isMobile={isMobile} />

      {alternateView ? (
        <div className={styles.patientPortalRightSide}>
          <SchemaParser schema={screens[alternateView]} />
        </div>
      ) : (
        <div
          className={classNames(styles.rightSide, {
            [styles.bg0]: rightSide.center,
          })}
          style={{ backgroundImage: `url(${rightSide.imageToShow})` }}
        />
      )}
    </div>
  );
};

export default Login;
