import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from '../../../models';
import styles from './timer.module.scss';

const Timer = ({ duration, timeDurationOTP }) => {
  const [minutes, setMinutes] = useState(Math.floor(duration / 60));
  const [seconds, setSeconds] = useState(Math.floor(duration % 60));

  useEffect(() => {
    if (timeDurationOTP){
      setMinutes(Math.floor(store.getState().settings.timeDurationOTP / 60))
      setSeconds(Math.floor(store.getState().settings.timeDurationOTP % 60))
    }
  }, [timeDurationOTP])
  
  useEffect(() => {
    let interval = null;
    if (minutes > 0 || seconds > 0) {
      interval = setInterval(() => {
        if (seconds > 0) setSeconds(seconds - 1);
        else if (minutes > 0) {
          setMinutes(minutes - 1);
          setSeconds(59);
        } else clearInterval(interval);
      }, 1000);
    }
    else {
      store.dispatch.settings.setTimeDuration({duration: 0})
      store.dispatch.button.enableButton({
        btn: 'twofaResendBtn',
        complete: true,
      });
    }
    return () => clearInterval(interval);
  }, [minutes, seconds]);

  return (
    <h6 className={styles.timer}>
      {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </h6>
  );
};

const mapStateToProps = state => {
  return {
    timeDurationOTP: state.settings.timeDurationOTP,
  };
};

export default connect(mapStateToProps, null)(Timer);
