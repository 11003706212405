import React from 'react';
import styles from './Label.module.scss';

export default React.memo(props => {
  const { required, text } = props;

  return (
    <label className={styles.root}>
      {text}
      {required && <span className={styles.required}>*</span>}
    </label>
  );
});
