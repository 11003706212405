export const shop = {
  state: { shop: [], shopInfo: {} },
  reducers: {
    fetchStart: (state, { shopScreen, shopInfo }) => ({
      ...state,
      shop: shopScreen,
      shopInfo,
    }),
    resetState: _ => ({
      shop: [],
      shopInfo: {},
    }),
    refetch: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    clearStore: _ => ({ ...shop.state }),
  },
};
