import { withApollo } from 'react-apollo';
import { compose } from '@carecloud/cloudpak';
import AvatarEditor from 'react-avatar-editor';
import React, { PureComponent } from 'react';
import 'react-rangeslider/lib/index.css';
import Slider from 'react-rangeslider';
import classNames from 'classnames';
import { UploadPhoto } from '../../../graphql/mutations';
import { store } from '../../../models';
import styles from './avatarUploader.module.scss';

class AvatarUploader extends PureComponent {
  state = { value: 100, file: null };
  setEditorRef = editor => {
    this.editor = editor;
    return this.editor;
  };
  handleOnChange = value => {
    this.setState({ value });
  };
  handleUpload = _ => e => {
    this.setState({ file: e.target.files[0] });
  };
  handleSave = _ => async e => {
    e.preventDefault();
    try {
      if (this.editor) {
        const profilePhoto = this.editor
          .getImage()
          .toDataURL('image/jpeg', 0.3);
        const imageToLarge = await new Promise(resolve => {
          this.editor.getImage().toBlob(blob => {
            if (blob.size >= 22897834) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
        });
        if (imageToLarge) {
          store.dispatch({
            type: 'toast/open',
            payload: { type: 'error', message: this.props.data.errorMessage },
          });
          throw new Error();
        }
        store.dispatch.loader.persistLoader({ persist: true });
        const { data } = await this.props.client.mutate({
          mutation: UploadPhoto,
          // eslint-disable-next-line object-curly-newline
          variables: { profilePhoto },
        });
        if (data[Object.keys(data)[0]] === null) throw new Error(data);
        this.props.schema.contentRefresh.forEach(model => {
          store.dispatch[model].resetState();
        });
        store.dispatch({
          type: 'toast/open',
          payload: data[Object.keys(data)[0]],
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    const {
      // eslint-disable-next-line object-curly-newline
      schema: { btnSave, btnChoosePhoto, headerSlider, image, initials },
    } = this.props;
    return (
      <div className={styles.avatarEditor}>
        {image || this.state.file ? (
          <AvatarEditor
            ref={this.setEditorRef}
            image={this.state.file || image}
            width={277}
            height={275}
            border={30}
            borderRadius={170}
            color={[38, 50, 56, 0.6]} // RGBA
            scale={this.state.value / 100}
            rotate={0}
          />
        ) : (
          <div className={styles.noImage}>
            <div>{initials}</div>
          </div>
        )}
        <div className={classNames({ [styles.disabled]: !this.state.file })}>
          <Slider
            value={this.state.value}
            min={100}
            max={400}
            orientation="horizontal"
            onChange={this.handleOnChange}
            tooltip={false}
          />
          <h4>{headerSlider}</h4>
        </div>
        <div className={styles.buttons}>
          <label htmlFor="loaderInput" className={styles.input}>
            {btnChoosePhoto}
            <input
              id="loaderInput"
              className="fileInput"
              style={{ display: 'none' }}
              type="file"
              accept=".jpg, .jpeg, .png, .gif"
              onChange={this.handleUpload()}
            />
          </label>
          <button
            id="saveProfilePicture"
            className={classNames(styles.savePhoto, {
              // eslint-disable-next-line object-curly-newline
              [styles.disabled]: !this.state.file,
            })}
            type="submit"
            onClick={this.handleSave()}
            disabled={!this.state.file}
          >
            {btnSave}
          </button>
        </div>
      </div>
    );
  }
}
const enhancer = compose(
  withApollo,
);
export default enhancer(AvatarUploader);
