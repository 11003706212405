import React from 'react';
import styled from 'styled-components';

const ObjectProperties = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ObjectProperty = styled.div`
  box-sizing: border-box;
  width: 100%;

  ${props => props.type === 'primary' && `
    padding-right: 8px;
    width: 70%;
  `}

  ${props => props.type === 'secondary' && `
    padding-left: 8px;
    width: 30%;
  `}
`;

export const ObjectFieldTemplate = ({ schema, properties }) => {
  const children = properties.map(element => {
    const elementSchema = schema?.properties[element.name];

  return (<>
    <ObjectProperty type={elementSchema.wrapper}>
      {element.content}
    </ObjectProperty>
  </>);
  });

  return (<>
    <ObjectProperties>{children}</ObjectProperties>
  </>);
};