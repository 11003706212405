import { compose, isEmpty } from '@carecloud/cloudpak';
import { withRouter } from 'react-router-dom';
import React from 'react';
import classNames from 'classnames';
import styles from './image.module.scss';
import { store } from '../../../models';

const image = React.memo(({ id, src, alt, theme, action = {}, history, alignCenter }) =>
  !isEmpty(action) ? (
    <a
      onClick={() => {
        const { model, trigger, param } = action;
        store.dispatch.dropdown.toggleOpen(null);
        model === 'history' ? history[trigger](param) : store.dispatch[model][trigger](param);
      }}
      role="button"
      tabIndex={0}
      style={{ display: 'flex', ...(alignCenter && { alignSelf: 'center' }) }}
    >
      <img className={styles[theme]} id={id} src={src} alt={alt} />
    </a>
  ) : (
    <img
      className={classNames(
        styles[theme],
        { [styles.avatar]: alt === 'avatar' },
        { [styles.avatarImage]: src && alt === 'avatar' },
      )}
      id={id}
      src={src}
      alt={`${alt}`}
    />
  ),
);

export const Image = compose(withRouter)(image);
