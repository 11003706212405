export const intelligentScheduler = {
  state: {
    numberOfQs: null,
    answered: [],
    visitType: [],
    lastAppointmentResources: {},
    schedulerResourceSelection: {},
  },
  reducers: {
    setNumberOfQs: (state, int) => ({
      ...state,
      numberOfQs: int,
    }),
    setAnswered: (state, idx, param) => ({
      ...state,
      answered: [...state.answered.slice(0, idx), param, ...state.answered.slice(idx + 1)],
    }),
    popAnswered: state => ({
      ...state,
      answered: [...state.answered.slice(0, state.answered.length - 1)],
    }),
    setVisitType: (state, param) => ({
      ...state,
      visitType: param,
    }),
    setLastAppointmentResources: (state, param) => ({
      ...state,
      lastAppointmentResources: param,
    }),
    setSchedulerResourceSelection: (state, param) => ({
      ...state,
      schedulerResourceSelection: param,
    }),
    clearAll: () => ({
      numberOfQs: null,
      answered: [],
      visitType: [],
      lastAppointmentResources: {},
      schedulerResourceSelection: {},
    }),
  },
};
