import React from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import classNames from 'classnames';
import moment from 'moment';
import SchemaParser from '../../services/SchemaParser';
import styles from './paymentAmount.module.scss';
import { formatMoney } from '../../services/utilities';

const paymentAmount = React.memo(
  ({
    checkBalance,
    paymentType,
    input,
    amount,
    payment,
    amountDisplay,
    monthDisplay,
    dayDisplay,
    text,
    payBtnInvalid,
    handlePay,
    previousBalance,
    copay,
    payLater,
    monthlyFrequencyChosen,
    paymentDateToday,
    paymentBtnDisabled,
    practice_mgmt,
    loadPatientStatement,
    guestEntry,
    patient_statements,
    patientStatementBit,
  }) => (
    <div>
      <div className={styles.container}>
        <div
          className={classNames(
            styles.header,
            { [styles.partial]: paymentType === 'partialPayment' },
            { [styles.plan]: paymentType === 'paymentPlan' },
          )}
        >
          {payment}
        </div>
        <div className={styles.subHeader}>
          {paymentType === 'paymentPlan'
            ? `${monthlyFrequencyChosen ? text.monthlyAmount : text.weeklyPayment}`
            : `${text.amountForVisit}`}
        </div>
        <div id="makePaymentsPayAmount" className={styles.amount}>
          {amountDisplay ? `$${formatMoney({ amount: parseFloat(amountDisplay) })}` : '$0.00'}
          {paymentType === 'paymentPlan' && <div>{monthlyFrequencyChosen ? text.shortMonth : text.shortWeek}</div>}
        </div>
        {paymentType === 'paymentPlan' && (
          <div className={styles.paymentPlanDetails}>
            {dayDisplay &&
              `${text.every} ${
                monthlyFrequencyChosen
                  ? moment(dayDisplay, 'DD')
                      .local()
                      .format('Do')
                  : dayDisplay
              }
          ${monthlyFrequencyChosen ? text.dayDuringWeek : text.duringFrequency} ${monthDisplay} ${
                monthlyFrequencyChosen ? text.months : text.weeks
              }`}
          </div>
        )}
        {input > 0 && paymentType === 'partialPayment' && (
          <div id="makePaymentsPendingAmount" className={styles.pendingBalance}>
            {text.pendingAmount}: ${formatMoney({ amount: amount - input.replace(/[^\d.]/g, '') })}
          </div>
        )}
        {text.patientBalance && (
          <div className={styles.balance}>
            <h3>{text.patientBalance}</h3>
            <h3 id="makePaymentsTotalBalance">${formatMoney({ amount })}</h3>
          </div>
        )}

        {previousBalance > 0 && (
          <div className={styles.previousBalance}>
            <h3>{text.previousBalance}</h3>
            <h3>${formatMoney({ amount: previousBalance })}</h3>
          </div>
        )}

        {copay && paymentType !== 'paymentPlan' && (
          <div
            className={classNames(styles.copay, {
              [styles.borderTop]: !previousBalance,
            })}
          >
            <h3>{text.copay}</h3>
            <h3>${formatMoney({ amount: copay })}</h3>
          </div>
        )}

        {!isEmpty(checkBalance) && (
          <div className={styles.balanceDetails}>
            <SchemaParser schema={[checkBalance]} />
          </div>
        )}
      </div>
      <button
        id="makePaymentsPayButton"
        onClick={!payBtnInvalid && !paymentBtnDisabled ? handlePay : undefined}
        className={classNames(styles.pay, {
          [styles.disable]: payBtnInvalid || paymentBtnDisabled,
        })}
      >
        {paymentDateToday ? text.payNow : text.pay}
      </button>
      {/* eslint-disable-next-line no-nested-ternary*/}
      {(guestEntry.guestMode && patientStatementBit && practice_mgmt === 'carecloud') ? 
        patient_statements && patient_statements[0].statements.length > 0 ?
        // && canViewPatientStatement 
          (
            <button 
              id='patientStatement'
              onClick={loadPatientStatement}
              className={classNames(styles.patientStatement)} 
            >
              {text.patientStatement}
            </button>
          ) :
          <></>
        : 
        <></>
      }
      {payLater && <SchemaParser schema={[payLater]} />}
    </div>
  ),
);

export const PaymentAmount = paymentAmount;
