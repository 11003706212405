import React from 'react';
import { Image } from '..';
import styles from './nav_icon.module.scss';
import { getMobileDevice, isMobileDevice } from '../../services/utilities';
import { Utilities } from '../../../constants';

export const Applink = ({ id, title, src, alt, children, route, href }) => {
  const getUniversalUrl = ({ path = '/login' }) => {
    const universalUrlArr = window.location.hostname.split('.');
    universalUrlArr[0] = getMobileDevice() === Utilities.APPLE ? 'breeze-deeplink' : 'web';
    const universalUrl = `${window.location.protocol}//${universalUrlArr.join('.')}${path}`;
    const googleApplink = `${universalUrlArr.join('.')}${path}`;
    return getMobileDevice() === Utilities.GOOGLE
      ? `intent://${googleApplink}#Intent;scheme=https;package=com.carecloud.carepay.patient;S.browser_fallback_url=${encodeURIComponent(
          'https://play.google.com/store/apps/details?id=com.carecloud.carepay.patient',
        )};end`
      : `${universalUrl}?current_url=${encodeURIComponent(window.location.href)}`;
  };
  return (
    <a href={isMobileDevice() ? getUniversalUrl({ path: route }) : href}>
      {children || (
        <div id={id} className={styles[id]}>
          {src && <Image {...{ src, alt }} />}
          <span className={styles.tooltip}>{title}</span>
        </div>
      )}
    </a>
  );
};
