import React from 'react';
import classNames from 'classnames';

const hamburger = React.memo(({ dropDownOpen, openDropdown }) => (
  <button
    className={classNames('hamburger', 'hamburger--collapse', { 'is-active': dropDownOpen === 'hamburgerMenu' })}
    onClick={() => openDropdown('hamburgerMenu')}
  >
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </button>
));
export const Hamburger = hamburger;
