import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { graphql, withApollo } from 'react-apollo';
import { ContentPage } from '..';
import { store } from '../../../models';
import { GetMyHealth, getLab, getPdf } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { breakBackButton } from '../../services/utilities';
import { myHealthContext as MyHealthContext } from '../../../contexts';

class MyHealthContainer extends Component {
  componentDidMount() {
    breakBackButton();
  }

  componentWillReceiveProps({ myHealth, getMyHealth, fetchStart, activateLoader, loader: { loading } }) {
    const queryLoading = getMyHealth && getMyHealth.loading;
    if (!queryLoading && loading) {
      activateLoader({ loading: false });
    }
    if (isEmpty(myHealth.myHealth) && !queryLoading) {
      parseResult({ query: getMyHealth, dataField: 'getMyHealth', resultHandler: fetchStart });
    }
  }

  componentWillUnmount() {
    const { activateLoader } = this.props;
    activateLoader({ loading: true });
  }
  setPositions = _ => {
    const links = Array.from(ReactDOM.findDOMNode(this).querySelector('#myHealthNav').children);
    const ids = links.map(selector => selector.hash);
    const nodes = ids.map(id => ReactDOM.findDOMNode(this).querySelector(id));
    const scrollPositions = nodes.map(header => header.offsetTop);
    scrollPositions.push(document.documentElement.scrollHeight + 300);
    scrollPositions.shift();
    this.props.setHashPositions({ scrollPositions, nodes });
  };
  fileController = ({ action, arrayBuffer, fileDownloadName }) => {
    if (action.type === 'print') {
      const file = new Blob([arrayBuffer], { type: 'application/pdf' });
      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(file);
      } else {
        const url = URL.createObjectURL(file);
        window.open(url);
      }
    } else if (action.type === 'download') {
      const file = new Blob([arrayBuffer], { type: 'octet/stream' });
      if (navigator && navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(file, `${fileDownloadName}.pdf`);
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = `${fileDownloadName}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  medicalRecordClickHandler = async action => {
    store.dispatch.loader.persistLoader({ persist: true });
    const { data } = await this.props.client.query({
      query: getPdf,
      variables: { patientId: action.patientId },
    });
    const arrayBuffer = new Int8Array(JSON.parse(data.getPdf).data);
    this.fileController({ action, arrayBuffer, fileDownloadName: 'medical_record' });
    store.dispatch.mixpanel.addMetadata({ medicalRecord: true });
    store.dispatch.loader.persistLoader({ persist: false });
  };
  labRecordClickHandler = async action => {
    store.dispatch.loader.persistLoader({ persist: true });
    const { data } = await this.props.client.query({
      query: getLab,
      variables: { labId: parseInt(action.labId, 10) },
    });
    const arrayBuffer = new Int8Array(JSON.parse(data.getLab).data);
    this.fileController({ action, arrayBuffer, fileDownloadName: 'lab' });
    store.dispatch.mixpanel.addMetadata({ labRecord: true });
    store.dispatch.loader.persistLoader({ persist: false });
  };
  handleWheel = () => {
    if (isEmpty(this.props.myHealth.scrollPositions)) {
      this.setPositions();
      return;
    }
    let active = 0;
    const { scrollPositions, nodes } = this.props.myHealth;
    scrollPositions.forEach((position, index) => {
      if (window.pageYOffset + 300 > position && window.pageYOffset + 300 < scrollPositions[index + 1]) {
        active = index + 1;
      }
    });
    if (`#${nodes[active].id}` !== this.props.myHealth.hash) {
      this.props.setHash(`#${nodes[active].id}`);
    }
  };
  handleCreateVisitSummary = _ => {
    const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
    if(practiceMgmt === 'vertex'){
      store.dispatch.modal.toggleOpen('createVisitSummaryModal');
      store.dispatch.formGroup.showForm('vertexCreateVisitSummary');
    }else {
      store.dispatch.modal.toggleOpen('createVisitSummaryModal');
      store.dispatch.formGroup.showForm('createVisitSummary');
    }
  };
  render() {
    const {
      myHealth: { myHealth },
      loader: { loading },
    } = this.props;
    return (
      myHealth && (
        <MyHealthContext.Provider
          value={{
            getMedicalRecord: this.medicalRecordClickHandler,
            getLab: this.labRecordClickHandler,
            handleCreateVisitSummary: this.handleCreateVisitSummary,
          }}
        >
          <ContentPage schemas={myHealth} onWheel={this.handleWheel} loading={loading} />
        </MyHealthContext.Provider>
      )
    );
  }
}

const mapStateToProps = ({ myHealth, loader }) => ({ myHealth, loader });
const mapDispatchToProps = ({ myHealth, loader }) => ({
  fetchStart: myHealth.fetchStart,
  setHash: myHealth.setHash,
  setHashPositions: myHealth.setHashPositions,
  activateLoader: loader.activateLoader,
});
const enhancer = compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GetMyHealth, { name: 'getMyHealth', skip: ({ myHealth }) => !isEmpty(myHealth.myHealth) }),
);
export default enhancer(MyHealthContainer);
