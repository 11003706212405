import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import SchemaParser from '../../services/SchemaParser';
import styles from './delegateCard.module.scss';

const DelegateCard = React.memo(({ id, profileId, topContent, expandedContent, avatar, currentUser, history }) => {
  const clickHandler = representedUserId => _ => {
    sessionStorage.removeItem('CC-Breeze-Web-representedUserId');
    representedUserId && sessionStorage.setItem('CC-Breeze-Web-representedUserId', representedUserId);
    history.push('/dashboard');
  };
  return (
    <div id={id} className={classNames(styles.delegateCardWrapper, { [styles.hasNoPractices]: !expandedContent[0].components.length })}>
      <div onClick={clickHandler(profileId)} role="presentation" className={classNames(styles.delegateCardTop, { [styles.currentUserTop]: currentUser })}>
        <SchemaParser schema={topContent} />
      </div>
      <div className={classNames(styles.delegateAvatar)}>
        <SchemaParser schema={avatar} />
      </div>
      <div className={classNames(styles.expandedContent)}>
        <SchemaParser schema={expandedContent} />
      </div>
    </div>
  );
});

const mapStateToProps = ({ delegateCard }) => ({ delegateCard });

export default connect(mapStateToProps)(DelegateCard);
