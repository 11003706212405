export const support = {
  state: { support: [] },
  reducers: {
    fetchStart: (state, { supportScreen }) => ({
      ...state,
      support: supportScreen,
    }),
    refetch: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    clearStore: _ => ({ ...support.state }),
  },
};
