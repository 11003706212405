import React from 'react';
import classNames from 'classnames';
import SchemaParser from '../../services/SchemaParser';
import styles from './table.module.scss';

const table = React.memo(
  ({
    id,
    heading,
    dataArray,
    pages,
    chevron,
    expand,
    expansionPanel,
    isExpanded,
    toggleActions,
    actions,
    showAction,
    selectedView,
    altViewMaxIndex,
    loadMore,
    altViewSelector,
    tableHeadingSection,
    lengthMap,
    parseData,
    theme,
    loadMoreLabel,
  }) => (
    <div className={styles[theme]} id={id}>
      {altViewSelector(styles)}
      {selectedView === 'list' ? (
        <div>
          <div className={styles.table}>
            {tableHeadingSection(styles)}
            {dataArray.map((data, i) => (
              <div
                id={i}
                key={data.id ? data.id : Math.random()}
                className={styles.menuItemWrapper}
                role="Menuitem"
                tabIndex={0}
                onClick={e =>
                  data.expanded && e.target.nodeName !== 'I' && expand(data.id)
                }
              >
                <div
                  className={classNames(
                    styles.menuItem,
                    styles[
                      `${lengthMap[heading.length]}${
                        heading.includes('actions') ? 'WithActions' : ''
                      }`
                    ],
                    { [styles.selected]: isExpanded(data.id) },
                  )}
                >
                  {heading.map(head => (
                    <div
                      key={head}
                      className={classNames(styles.dataItem, {
                        [styles.actionItem]: head === 'actions',
                      })}
                      role="Menuitem"
                      tabIndex={0}
                    >
                      {head === 'actions' ? (
                        <div>
                          <div
                            className={classNames(
                              styles.ellipis,
                              styles.actionsWrapper,
                              {
                                [styles.actionsWrapperSelected]:
                                  data.id === showAction,
                              },
                            )}
                            onClick={toggleActions(data.id)}
                            role="menuItem"
                            tabIndex="0"
                          >
                            <i
                              className={classNames(
                                'material-icons',
                                styles.actionsButton,
                              )}
                            >
                              more_horiz
                            </i>
                          </div>
                          {actions({ id: data.id, styles })}
                        </div>
                      ) : (
                        <div className={styles.data}>
                          {parseData({ data, head })}
                        </div>
                      )}
                    </div>
                  ))}
                  {data.expanded && chevron({ id: data.id, styles })}
                </div>
                {expansionPanel({ id: data.id, styles })}
              </div>
            ))}
          </div>
          {pages(styles)}
        </div>
      ) : (
        <div>
          <div className={styles.altViewWrapper}>
            <SchemaParser
              schema={dataArray
                .slice(0, altViewMaxIndex)
                .map(data => ({ ...data.altViewData, actions: data.actions }))}
            />
          </div>
          {altViewMaxIndex < dataArray.length && (
            <div className={styles.loadMoreWrapper}>
              <div className={styles.horizontalDivider} />
              <div
                role="menuItem"
                tabIndex="0"
                onClick={loadMore}
                className={styles.loadMore}
              >
                {loadMoreLabel}
              </div>
              <div className={styles.horizontalDivider} />
            </div>
          )}
        </div>
      )}
    </div>
  ),
);

export const Table = table;
