import { Image, TextArea, PageLoader } from '..';
import { isEmpty } from '@carecloud/cloudpak';
import styles from './MessagesSection.module.scss';
import React from 'react';
import SchemaParser from '../../services/SchemaParser';
import classNames from 'classnames';
import moment from 'moment';
import {
  MEDIUM_LOADER_THICKNESS,
  MEDIUM_LOADER_SIZE,
} from '../../../constants/Loader';
import { store } from '../../../models';

let fileUploader;
const messagesSection = React.memo(
  ({
    props,
    button,
    createNew,
    deleteModal,
    firstConversationPrompt,
    nextConversationPrompt,
    id,
    input,
    markRead,
    markUnread,
    providerList,
    providerMessages,
    providerSelected,
    read,
    showHelperText,
    subjectValue,
    theme,
    typeMessageLabel,
    viewMessagesOf,
    viewMessagesOf: { param, messageSubject, provider },
    onChange,
    value,
    tooltip,
    lastMessageTime,
    labels,
    handleUpload,
    hasAttachment,
    getAttachment,
    practiceMgmt,
  }) => (
    <div id={id} className={styles[theme]}>
      <div className={styles.providerNameMsg}>{provider}</div>
      <div className={styles.subjectHeader}>
        <div className={styles.subjectHeaderTop}>
          {!createNew ? (
            <h3>{messageSubject || provider}</h3>
          ) : (
            <SchemaParser schema={[input]} />
          )}
          {!createNew && (
            <SchemaParser schema={read ? [markUnread] : [markRead]} />
          )}
        </div>
        <div className={styles.details}>
          {createNew && (
            <h6 className={styles.date}>
              {moment()
                .local()
                .format('dddd h:mm A')}
            </h6>
          )}
          {!createNew && !isEmpty(viewMessagesOf) && (
            <h6 className={styles.date}>{lastMessageTime}</h6>
          )}
          {!createNew && <SchemaParser schema={[button]} />}
        </div>
      </div>
      {createNew &&
        (!subjectValue || /^\s+$/.test(subjectValue)) &&
        value &&
        showHelperText && (
          <div
            className={classNames(styles.subjectHelper, {
              [styles.vibrate]: showHelperText,
            })}
          >
            {tooltip}
          </div>
        )}
      {!providerSelected && createNew && (
        <SchemaParser schema={[providerList]} />
      )}
      {providerSelected && createNew && providerMessages.length < 1 && (
        <SchemaParser schema={[firstConversationPrompt]} />
      )}
      {providerSelected &&
        createNew &&
        providerMessages.length > 0 &&
        (store.getState().messages.sendNewMessage ? (
          <div className={styles.nextConversationLoaderWrapper}>
            <PageLoader
              overlay={false}
              fixed={false}
              size={MEDIUM_LOADER_SIZE}
              thickness={MEDIUM_LOADER_THICKNESS}
            />
          </div>
        ) : (
          <SchemaParser schema={[nextConversationPrompt]} />
        ))}
      {!providerSelected && !createNew && !isEmpty(viewMessagesOf) && (
        <div id={param} className={styles.messageConversation}>
          <SchemaParser
            getAttachment={getAttachment}
            schema={
              providerMessages.find(
                ({ message_id }) => viewMessagesOf.param === message_id,
              ).messages
            }
          />
        </div>
      )}
      <div
        className={classNames(
          styles.writeMessage,
          { [styles.messageReady]: value && !/^\s+$/.test(value) },
          { [styles.inactive]: (createNew && !providerSelected) || (subjectValue && !/^[a-zA-Z ]+$/.test(subjectValue))},
        )}
      >
        {createNew && (
          <>
            {practiceMgmt !== "vertex" && (
            <div
              onClick={_ => fileUploader.click()}
              role="menuitem"
              tabIndex={0}
              className={styles.addAttachment}
            >
              <Image
                {...{
                  alt: 'send attachment',
                  src: 'https://assets.gobreeze.com/messages-attachment.svg',
                }}
              />
            </div>
            )}
            {hasAttachment && <div className={styles.readMarker} />}
            <input
              type="file"
              id="file"
              ref={fileUploaderr => {
                fileUploader = fileUploaderr;
              }}
              style={{ display: 'none' }}
              onChange={handleUpload}
              accept=".jpg, .jpeg, .png, .pdf"
            />
          </>
        )}
        <TextArea
          className={styles.newMessageText}
          schema={{ title: 'new message' }}
          onChange={onChange}
          value={value}
          placeholder={typeMessageLabel}
          noScrollBar
        />
        <Image
          {...{
            ...props,
            id: 'sendMessage',
            alt: 'sendMessage',
            src: 'https://assets.gobreeze.com/messages-send-inactive.svg',
            action: { model: 'messages', trigger: 'sendMessages', param: true },
            alignCenter: true,
          }}
        />
      </div>
      <div className={styles.emergencyWarning}>
        <div>{labels.emergencyWarningTop}.</div>
        <div>{labels.emergencyWarningBottom}.</div>
      </div>
      <SchemaParser schema={[deleteModal]} />
    </div>
  ),
);

export const MessagesSection = messagesSection;
