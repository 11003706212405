import React, { PureComponent } from 'react';

import { ContentPage } from '..';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { GetDelegate } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { store } from '../../../models';

class Delegate extends PureComponent {
  componentDidUpdate() {
    this.props.delegate.length && this.props.loading && store.dispatch.loader.activateLoader({});
  }
  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
  }
  render() {
    return this.props.delegate.length ? (
      <ContentPage schemas={this.props.delegate} />
    ) : (
      <Query query={GetDelegate}>
        {({ data: query, loading }) => {
          parseResult({ query, dataField: 'getDelegate', resultHandler: store.dispatch.delegate.fetchStart });
          return <ContentPage schemas={[]} loading={loading} />;
        }}
      </Query>
    );
  }
}

const mapStateToProps = ({ loader: { loading }, delegate: { delegate } }) => ({ loading, delegate });

export default connect(mapStateToProps)(Delegate);
