const AppleMobileUniversalLink = () => {
  const host = window.location.hostname;
  const queryString = new URLSearchParams(window.location.search);
  const previousEncodedUrl = queryString.get('current_url');
  let nextUrl;
  if (previousEncodedUrl) {
    nextUrl = decodeURIComponent(previousEncodedUrl);
  } else {
    // if routing from any other subdomain without current url replace with web
    const urlArr = host.split('.');
    urlArr[0] = 'web';
    nextUrl = `${window.location.protocol}//${urlArr.join('.')}`;
  }
  // open the app store first and then load the previous page or landing page
  window.location = 'https://itunes.apple.com/us/app/breeze-a-better-healthcare-experience/id1197472721';
  setTimeout(function() {
    window.location = nextUrl;
  }, 300);
};

export default AppleMobileUniversalLink;
