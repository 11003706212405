import gql from 'graphql-tag';

export const GetUserToken = gql`
  mutation GetUserToken($email: String!, $password: String!, $otp: String, $device_identifier: String) {
    getUserToken(username: $email, password: $password, otp: $otp, device_identifier:$device_identifier)
  }
`;
export const getZipcodeInfo = gql`
  mutation getZipcodeInfo($zipcode: String!) {
    getZipcodeInfo(zipcode: $zipcode)
  }
`;
export const UpdateNewAccountEmail = gql`
  mutation UpdateNewAccountEmail($email: String!, $practiceId: String!, $patientId: String!, $username: String!) {
    updateNewAccountEmail(email: $email, practiceId: $practiceId, patientId: $patientId, username: $username)
  }
`;
export const AcceptConnectInvite = gql`
  mutation AcceptConnectInvite($inviteCode: String!) {
    acceptConnectInvite(inviteCode: $inviteCode)
  }
`;
export const MakePayment = gql`
  mutation MakePayment($input: MakePaymentInput!) {
    makePayment(input: $input)
  }
`;
export const MakeShopPayment = gql`
  mutation MakeShopPayment($input: ShopPaymentInput!) {
    makeShopPayment(input: $input)
  }
`;

export const MakeCheckoutPayment = gql`
  mutation MakeCheckoutPayment($input: CheckoutPaymentInput!) {
    makeCheckoutPayment(input: $input)
  }
`;

export const MakeOneTimePayment = gql`
  mutation MakeOneTimePayment($input: OneTimePaymentInput!) {
    makeOneTimePayment(input: $input)
  }
`;

export const AddToPaymentPlan = gql`
  mutation AddToPaymentPlan($input: AddToPaymentPlanInput!) {
    addToPaymentPlan(input: $input)
  }
`;

export const CreatePaymentPlan = gql`
  mutation CreatePaymentPlan($input: CreatePaymentPlanInput!) {
    createPaymentPlan(input: $input)
  }
`;

export const MakeCancellationPayment = gql`
  mutation MakeCancellationPayment($input: CancellationPaymentInput!) {
    makeCancellationPayment(input: $input)
  }
`;

export const MakeCheckInPayment = gql`
  mutation MakeCheckInPayment($input: MakeCheckInPaymentInput!) {
    makeCheckInPayment(input: $input)
  }
`;

export const EditPaymentPlan = gql`
  mutation EditPaymentPlan($input: EditPaymentPlanInput!) {
    editPaymentPlan(input: $input)
  }
`;

export const MakePrepayment = gql`
  mutation MakePrepayment($input: MakePrepaymentInput!) {
    makePrepayment(input: $input)
  }
`;

export const AddCreditCard = gql`
  mutation AddCreditCard($input: AddCreditCardInput!) {
    addCreditCard(input: $input)
  }
`;

// Create a new message from messages service
export const CreateMessage = gql`
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input)
  }
`;

// Deletes a message conversation from messages service
export const DeleteMessage = gql`
  mutation DeleteMessage($messageId: String!) {
    deleteMessage(messageId: $messageId)
  }
`;

// Marks the current message as unread
export const MarkMessageUnread = gql`
  mutation MarkMessageUnread($messageId: String!) {
    markMessageUnread(messageId: $messageId)
  }
`;

// Marks the current message as unread
export const MarkMessageRead = gql`
  mutation MarkMessageRead($messageId: String!) {
    markMessageRead(messageId: $messageId)
  }
`;

// Send a message reply from messages service
export const ReplyMessage = gql`
  mutation ReplyMessage($input: ReplyMessageInput!) {
    replyMessage(input: $input)
  }
`;

// Submits user's information from signup page
export const signupForm = gql`
  mutation CreateNewUser($input: CreateNewUserInput!) {
    createNewUser(input: $input)
  }
`;

// Submit password recovery request
export const RecoverPassword = gql`
  mutation RecoverPassword($email: String!) {
    recoverPassword(email: $email)
  }
`;

// Save new password
export const ResetPassword = gql`
  mutation ResetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export const RequestAppointmentInput = gql`
  mutation RequestAppointmentInput($input: RequestAppointmentInput!) {
    getRequestAppointment(input: $input)
  }
`;

// SubmitForm
export const SubmitForm = gql`
  mutation SubmitForm($formData: FormData!) {
    submitForm(formData: $formData)
  }
`;
export const changeEmail = gql`
  mutation ChangeEmail($input: ChangeEmailInput!) {
    changeEmail(input: $input)
  }
`;
export const createVisitSummary = gql`
  mutation CreateVisitSummary($input: CreateVisitSummaryInput!) {
    createVisitSummary(input: $input)
  }
`;
export const changePassword = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input)
  }
`;
export const personal = gql`
  mutation UpdatePersonal($input: UpdatePersonalInput!) {
    updatePersonal(input: $input)
  }
`;
export const UploadPhoto = gql`
  mutation UpdatePhoto($profilePhoto: String!) {
    updatePhoto(profilePhoto: $profilePhoto)
  }
`;
export const notifications = gql`
  mutation ToggleNotifications($input: NotificationsInput!) {
    toggleNotifications(input: $input)
  }
`;
export const demographics = gql`
  mutation updateDemographics($input: UpdateDemographicsInput!) {
    updateDemographics(input: $input)
  }
`;
export const documents = gql`
  mutation updateDocuments($input: UpdateDocumentsInput!) {
    updateDocuments(input: $input)
  }
`;
export const makeCheckOutAppointment = gql`
  mutation makeCheckoutAppointment($input: MakeCheckoutAppointmentInput!) {
    makeCheckoutAppointment(input: $input)
  }
`;

export const MakeCheckoutPrepayment = gql`
  mutation makeCheckoutPrepayment($input: CheckoutPrepaymentInput!) {
    makeCheckoutPrepayment(input: $input)
  }
`;

export const infoCheckPage = gql`
  mutation PostCheckInDemographics($input: PostCheckInDemographicsInput!) {
    postCheckInDemographics(input: $input)
  }
`;

export const patientForms = gql`
  mutation PostCheckInPatientForms($input: PostCheckInPatientFormsInput!) {
    postCheckInPatientForms(input: $input)
  }
`;

export const intakeForms = gql`
  mutation PostCheckInIntakeForms($input: PostCheckInIntakeFormsInput!) {
    postCheckInIntakeForms(input: $input)
  }
`;

export const medsAllergyPage = gql`
  mutation PostCheckInMedsAllergies($input: PostCheckInMedsAllergiesInput!) {
    postCheckInMedsAllergies(input: $input)
  }
`;

export const CancelAppointment = gql`
  mutation CancelAppointment($input: CancelAppointmentInput!) {
    cancelAppointment(input: $input)
  }
`;

export const deleteCreditCard = gql`
  mutation deleteCreditCard($input: DeleteCreditCardInput!) {
    deleteCreditCard(input: $input)
  }
`;

export const payment = gql`
  mutation setDefaultCreditCard($input: DefaultCreditCardInput!) {
    setDefaultCreditCard(input: $input)
  }
`;

export const checkoutForms = gql`
  mutation PostCheckoutForms($input: CheckoutFormsInput!) {
    postCheckoutForms(input: $input)
  }
`;

export const pendingForms = gql`
  mutation postPendingForms($input: PostPendingFormsInput!) {
    postPendingForms(input: $input)
  }
`;

export const ContinueThirdParty = gql`
  mutation ContinueThirdParty($input: ContinueThirdPartyInput!) {
    continueThirdParty(input: $input)
  }
`;

export const DeleteScheduledPayment = gql`
  mutation DeleteScheduledPayment($input: DeleteScheduledPaymentInput!) {
    deleteScheduledPayment(input: $input)
  }
`;

export const CancelPaymentPlan = gql`
  mutation CancelPaymentPlan($input: CancelPaymentPlanInput!) {
    cancelPaymentPlan(input: $input)
  }
`;

export const guestAppointment = gql`
  mutation guestAppointment($input: GuestAppointmentInput!) {
    guestAppointment(input: $input)
  }
`;

export const guestRescheduleAppointment = gql`
  mutation guestRescheduleAppointment($input: GuestRescheduleAppointmentInput!) {
    guestRescheduleAppointment(input: $input)
  }
`;

export const PostPendingSurvey = gql`
  mutation PostPendingSurvey($input: PostPendingSurveyInput!) {
    postPendingSurvey(input: $input)
  }
`;

export const GetSurveysNext = gql`
  mutation GetSurveysNext($input: GetSurveysNextInput!) {
    getSurveysNext(input: $input)
  }
`;

export const guestPay = gql`
  mutation guestPay($input: GuestPayInput!) {
    guestPay(input: $input)
  }
`;

export const guestPayNoCode = gql`
  mutation guestPayNoCode($input: GuestPayNoCodeInput!) {
    guestPayNoCode(input: $input)
  }
`;

export const GenerateVisitSummary = gql`
  mutation GenerateVisitSummary($input: GenerateVisitSummaryInput!) {
    generateVisitSummary(input: $input)
  }
`;

export const delegateForm = gql`
  mutation delegateForm($input: DelegateFormInput!) {
    delegateForm(input: $input)
  }
`;
export const delegateVerification = gql`
  mutation delegateVerification($input: DelegateVerificationInput!) {
    delegateVerification(input: $input)
  }
`;
export const delegateLogin = gql`
  mutation delegateLogin($input: DelegateLoginInput!) {
    delegateLogin(input: $input)
  }
`;
export const delegateForgotPassword = gql`
  mutation delegateForgotPassword($input: DelegateForgotPasswordInput!) {
    delegateForgotPassword(input: $input)
  }
`;
export const delegateCreateAccount = gql`
  mutation delegateCreateAccount($input: DelegateCreateAccountInput!) {
    delegateCreateAccount(input: $input)
  }
`;

export const createAccountForm = gql`
  mutation createAccount($input: CreateNewAccountInput!) {
    createAccount(input: $input)
  }
`;
export const mergeDelegateProfileMaybe = gql`
  mutation mergeDelegateProfileMaybe($input: mergeDelegateProfileMaybeInput!) {
    mergeDelegateProfileMaybe(input: $input)
  }
`;
export const mergeDelegateProfile = gql`
  mutation mergeDelegateProfile($input: mergeDelegateProfileInput!) {
    mergeDelegateProfile(input: $input)
  }
`;
export const disconnectDelegateProfile = gql`
  mutation disconnectDelegateProfile($input: disconnectDelegateProfileInput!) {
    disconnectDelegateProfile(input: $input)
  }
`;
export const updateDelegatePermissions = gql`
  mutation updateDelegatePermissions($input: updateDelegatePermissionsInput!) {
    updateDelegatePermissions(input: $input)
  }
`;
export const updateTwoFA = gql`
  mutation updateTwoFA($input: updateTwoFAInput!) {
    updateTwoFA(input: $input)
  }
`;
export const settingsResendOTP = gql`
  mutation settingsResendOTP($input: settingsResendOTPInput!) {
    settingsResendOTP(input: $input)
  }
`;
export const signInResendOTP = gql`
  mutation signInResendOTP($email: String!, $password: String!, $device_identifier: String) {
    signInResendOTP(username: $email, password: $password, device_identifier:$device_identifier)
  }
`;
export const vertexRequestAppointmentInput = gql`
  mutation vertexRequestAppointmentInput($input: vertexRequestAppointmentInput!) {
    getVertexRequestAppointment(input: $input)
  }
`;