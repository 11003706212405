import React,{useState,useEffect} from 'react';
// import classNames from 'classnames';
import ClickAwayListener from '../../services/ClickAwayListener';
// import { Hamburger } from '../../ui';
import styles from './nav.module.scss';
import SchemaParser from '../../services/SchemaParser';
import { store } from '../../../models';

// let once = true;
// const showNavbarInitially = () => {
//   if (!once) return;
//   once = false;
//   setTimeout(() => {
//     store.dispatch.dropdown.toggleOpen(null);
//   }, 15000);
// };

const nav = React.memo(
  ({ schema: { hamburgerMenu,practiceSelectDropdown, rightSection, secondNavbar, sidebar } }) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [openNvabar,SetopenNavbar] = useState(false)
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 567);
    };
const onopenside = () =>{
  SetopenNavbar(!openNvabar)
}
    useEffect(() => {
      checkScreenSize();
      window.addEventListener('resize', checkScreenSize);
      return () => {
        window.removeEventListener('resize', checkScreenSize);
      };
    }, []);
    if (isSmallScreen) {
      return (
        <ClickAwayListener
          onClickAway={_ => store.getState().dropdown.dropDownOpen}
        >
          <div className={styles.container} id="navbar">
            <div className={styles.logoContainer}>
              <button onClick={onopenside}>
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
            <div className={styles.practiceSelectContainer}>
              <SchemaParser schema={practiceSelectDropdown} />
            </div>
            <SchemaParser schema={[rightSection]} />
          </div>
          <div className={styles.navContainer}>
            {openNvabar && <button onClick={onopenside}><SchemaParser schema={sidebar} /></button> }
            <SchemaParser schema={secondNavbar} />
          </div>
        </ClickAwayListener>
      );
    } else {
      return (
        <ClickAwayListener
          onClickAway={_ => store.getState().dropdown.dropDownOpen && store.dispatch.dropdown.toggleOpen(null)}
        >
          <div className={styles.container} id="navbar">
            <div className={styles.logoContainer}>
              <SchemaParser schema={hamburgerMenu} />
            </div>
            <div className={styles.practiceSelectContainer}>
              <SchemaParser schema={practiceSelectDropdown} />
            </div>
            <SchemaParser schema={[rightSection]} />
          </div>
          <div className={styles.navContainer}>
            <SchemaParser schema={sidebar} />
            <SchemaParser schema={secondNavbar} />
          </div>
        </ClickAwayListener>
      )
    }
  },
);
nav.whyDidYouRender = true;

export const Nav = nav;
