import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { get } from '@carecloud/cloudpak';
import { store } from '../../../models';
import { DateTimeSelect } from './DateTimeSelect';

class DateTimeSelectContainer extends PureComponent {
  constructor(props) {
    super(props);
    const { id } = this.props;
    store.dispatch.dateTimeSelect.resetSelected({ id });
  }
  componentDidMount() {
    const { changeSelected, dateTimeSection, id } = this.props;
    const index = dateTimeSection.findIndex(day => day.time.times.length > 0);
    const firstBubble = get(dateTimeSection, `[${index}].time.times[0]`);
    store.dispatch.button.enableButton({ btn: 'btnRequestAppointment', complete: !!firstBubble });
    store.getState().loader.persist && store.dispatch.loader.persistLoader({ persist: false });
    if (!firstBubble) {
      return;
    }
    const { start_time, end_time } = firstBubble;
    changeSelected({ id, selected: firstBubble.id, value: { start_time, end_time } });
  }
  componentWillReceiveProps(nextProps) {
    const { dateTimeSelect, id, button } = nextProps;
    const timeIsSelected = !!(dateTimeSelect[id] && dateTimeSelect[id].selected);
    if (timeIsSelected !== button.btnRequestAppointment) {
      store.dispatch.button.enableButton({ btn: 'btnRequestAppointment', complete: timeIsSelected });
    }
  }
  clickHandler = ({ selected, value }) => {
    const { changeSelected, id } = this.props;
    changeSelected({ id, selected, value });
  };
  isSelected = ({ timeId }) => {
    const { dateTimeSelect, id } = this.props;
    return dateTimeSelect[id] && dateTimeSelect[id].selected === timeId;
  };
  render() {
    const { dateTimeSelect, classes } = this.props;
    return (
      <DateTimeSelect
        {...{
          ...this.props,
          dateTimeSelect,
          classes,
          clickHandler: this.clickHandler,
          isSelected: this.isSelected,
        }}
      />
    );
  }
}

const mapStateToProps = ({
  dateTimeSelect,
}) => ({ dateTimeSelect });
const mapDispatchToProps = ({ dateTimeSelect }) => ({ changeSelected: dateTimeSelect.changeSelected });
export default connect(mapStateToProps, mapDispatchToProps)(DateTimeSelectContainer);
