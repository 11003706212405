import React, { useEffect, useReducer } from 'react';
import styled from 'styled-components';
import { FormField, Help, Text, CheckBoxField } from '../../styleguide';
import { reducer, getIsCompleted, getListed, getUnlisted, getValidState } from './Medications.reducer';
import ListedMedications from './ListedMedications';
import UnlistedMedications from './UnlistedMedications';
import { store } from '../../../models';

const Details = styled.div`
  margin: 32px 0 0;
`;

export default React.memo(props => {
  const {
    formData,
    onChange,
    schema,
    schema: { title, description, showCheckmark },
    rawErrors,
  } = props;

  const [state, dispatch] = useReducer(reducer, formData);

  useEffect(() => {
    onChange(getValidState(state));
  }, [state]);

  const showDetails = !state.noMedsCheckbox;
  const hasErrors = (rawErrors || []).length > 0;
  const listedMedications = getListed(state);
  const unlistedMedications = getUnlisted(state);
  const isRequired = !getIsCompleted(state);

  if (showDetails && listedMedications.length < 1) {
    dispatch({ type: 'addListed' });
  }

  if (showDetails && unlistedMedications.length < 1) {
    dispatch({ type: 'addUnlisted' });
  }

  const getConcileData =  getListed(state)?.filter(x => x?.allow_delete === false)
  const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
   const handleCheckboxChange = ({ target: { checked } }) => {
    if(getConcileData?.length && practiceMgmt === 'talkehr'){
      store.dispatch.modal.toggleOpen('reconcileMedicationmodal');
      store.dispatch.modal.toggleClose('reconcileMedicationmodal');
    }
    else{
      dispatch({ type: 'setNoMedsCheckbox', noMedsCheckbox: checked });
    } 
  };
  return (
    <>
      <Text tag="p" text={title} type="groupTitle" icon={showCheckmark && !hasErrors} />
      <Help text={description}>
        <FormField>
          <CheckBoxField
            name="noMedsCheckbox"
            value={state.noMedsCheckbox}
            text={schema.properties.noMedsCheckbox.title}
            onChange={handleCheckboxChange}
          />
        </FormField>
        {showDetails && (
          <Details>
            <ListedMedications
              formData={state.medicationsArray}
              schema={schema.properties.medicationsArray}
              dispatch={dispatch}
              required={isRequired}
              state={state}
            />
            <UnlistedMedications
              formData={state.unlistedMedsArray}
              schema={schema.properties.unlistedMedsArray}
              dispatch={dispatch}
              required={isRequired}
              state={state}
            />
          </Details>
        )}
      </Help>
    </>
  );
});
