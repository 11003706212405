import { connect } from 'react-redux';
import React from 'react';
import { store } from '../../../models';

import { Notification } from './notification';

const NotificationContainer = ({ notification: { open, labels, data, actions } }) => (
  <Notification
    props={{
      open,
      clickHandler: () => store.dispatch.notification.close(),
      transform: true,
      labels,
      data,
      actions,
    }}
  />
);

const mapStateToProps = ({ notification }) => ({ notification });

export default connect(mapStateToProps)(NotificationContainer);
