import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, FormField, Text } from '../../styleguide';

const PrimaryContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #cfd8dc;
`;

const SecondaryContent = styled.div`
  background-color: #f9fafa;
  padding: 16px;
`;

const fullName = formData => {
  return [formData.firstName, formData.middleName, formData.lastName].filter(item => !!item).join(' ');
};

const isEmptyEmergencyContact = formData => {
  if (!formData.firstName || !formData.lastName) {
    return true;
  }
};

export default React.memo(props => {
  const {
    required,
    formData,
    errorSchema,
    schema: { title, editText, addText, emptyText },
    registry: {
      fields: { ObjectField },
    },
  } = props;

  const hasErrors = Object.keys(errorSchema || {}).length > 0;
  const [edit, setEdit] = useState(hasErrors);
  const isEmpty = isEmptyEmergencyContact(formData);
  const primaryText = isEmpty ? emptyText : fullName(formData);

  return (
    <>
      <FormField text={title} required={required} value={!isEmpty}>
        <PrimaryContent>
          <div>
            <Text tag="p" text={primaryText} type="primary" />
          </div>
          <div>
            <Button
              low
              text={isEmpty ? addText : editText}
              secondary
              main
              disabled={!isEmpty && hasErrors}
              onClick={_ => setEdit(!edit)}
            />
          </div>
        </PrimaryContent>
        {edit && (
          <SecondaryContent>
            <ObjectField {...props} />
          </SecondaryContent>
        )}
      </FormField>
    </>
  );
});
