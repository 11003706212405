class Environment {
  static initialize = () => {
    try {
    const request = new XMLHttpRequest();
      request.open('GET', '/assets/config.json', false);
      request.send(null);
      if (request.status === 200) {
        Environment.setEnvironmentVariables(request.responseText);
      }
    } catch (error) {
      console.error('Unable to retreive config.json');
    }
  };

  static hasEnvironmentVariables = () => {
    const envVars = sessionStorage.getItem('BREEZE_ENV');
    return JSON.parse(envVars);
  };

  static setEnvironmentVariables = env => {
    sessionStorage.setItem('BREEZE_ENV', env);
  };

  static get = key => {
    try {
      return JSON.parse(sessionStorage.getItem('BREEZE_ENV'))[key];
    } catch (error) {
      console.error('Unable to retreive environment variable: ', key);
    }
  };
}

Environment.initialize();

export default Environment;
