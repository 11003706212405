import { withApollo } from 'react-apollo';
import { compose } from '@carecloud/cloudpak';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { store } from '../../models';

import { GetUnshortenedUrl } from '../../graphql/queries';
import { PageLoader } from '../ui/page_loader/pageLoader';
import Toast from '../modals/toast/toastContainer';

const nakedToastStyle = {
  position: 'absolute',
  right: 0,
  bottom: 0,
};

class ShortenedUrlRedirector extends Component {
  state = { error: false };
  async componentDidMount() {
    const { match: { params: { type, hash } } } = this.props;
    const { data: { getUnshortenedUrl: { longUrl = String(), caughtTooEarlyError = false, error: { message } = {} } } } = await this.props.client.query({
      query: GetUnshortenedUrl,
      variables: { input: { type, hash } },
    });
    if (caughtTooEarlyError) {
      this.setError();
      store.dispatch.toast.open({ type: 'error', message });
      return setTimeout(() => {
        this.props.history.push('/');
      }, 2000);
    }
    // const foo = longUrl.replace('https://web.development.gobreeze.com', 'http://localhost:3000');
    // window.location.href = foo;
    window.location.href = longUrl;
  }

  setError = _ => {
    this.setState({ error: true });
  };

  render() {
    return this.state.error ? (
      <div style={nakedToastStyle}>
        <Toast />
      </div>
    ) : (
      <PageLoader loading />
    );
  }
}

const enhancer = compose(
  withApollo,
  withRouter,
);
export default enhancer(ShortenedUrlRedirector);
