import { Query, withApollo } from 'react-apollo';
import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { store } from '../../../../models';

import { ContentPage } from '../..';
import { GetAppointmentPrepayment } from '../../../../graphql/queries';
import { parseResult } from '../../../../graphql/utilities';

/* eslint-disable camelcase */
class AppointmentPrepaymentContainer extends Component {
  componentDidMount() {
    const {
      history,
      addAppointment: {
        locationId,
        visitReasonId,
        selectedProvider: { resourceId, practiceId, patientId },
      },
    } = this.props;
    if (!(locationId && patientId && practiceId && resourceId && visitReasonId)) {
      history.push('/appointments/add_appointment');
    }
  }

  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.appointmentPrepayment.clearPrepayScreen();
    // clears available hours screen
    store.dispatch.availableHours.resetState();
  }

  render() {
    const {
      appointmentPrepayment: { appointmentPrepayment },
      loader: { loading },
      dateTimeSelect: { availableHoursSection: { value: { start_time = String() } = {} } = {} },
      addAppointment: {
        selectedProvider: {
          resourceId: resource_ids,
          practiceId: practice_id,
          patientId: patient_id,
          practiceMgmt: practice_mgmt,
          provider: { name: providerName, photo } = {},
          providerGuid: provider_id,
        },
        addressLine1,
        addressLine2,
        phone,
        prePayment,
        office,
        visitType,
        locationId: location_ids,
        visitReasonId: visit_reason_id,
        locationGuid: location_id,
      },
      appointmentCheckOut,
      creditCard,
    } = this.props;
    store.dispatch.mixpanel.addNonActionableMetadata({
      paymentAmount: prePayment,
      location_id,
      provider_id,
      practice_id,
    });
    const appointmentPrepaymentWithWarning = appointmentPrepayment;
    if (appointmentPrepayment[2]?.components[2]?.components[1]?.components[2] === null)
    {
      appointmentPrepaymentWithWarning[2]?.components[2]?.components[1]?.components.push( // eslint-disable-line
      {
        component: 'GROUP',
        components: [
          {
            id: "warningText",
            label: "CareCloud Clinic does not accept online payments.",
            icon: null,
            component: "HEADER",
            type: "H3",
            format: null,
          },
          {
            id: "patientBalanceRight",
            label: "Please call your practice to make a payment.",
            icon: null,
            component: "HEADER",
            type: "H3",
            format: null,
          },
        ],
        direction: 'VERTICAL',
        id: 'warningMessage',
      })
    }
    return appointmentPrepayment.length ? (
      <ContentPage schemas={appointmentPrepaymentWithWarning} loading={loading} />
    ) : (
      <Query
        query={GetAppointmentPrepayment}
        skip={!(location_ids && patient_id && practice_id && resource_ids && visit_reason_id)}
        variables={{
          input: {
            patient_id,
            practice_mgmt,
            practice_id,
            addressLine1,
            addressLine2,
            dateTime: start_time,
            phone,
            office,
            visitType,
            providerName,
            amount: parseFloat(prePayment),
            checkOut: appointmentCheckOut.appointmentCheckOut.length > 0,
            photo,
          },
        }}
      >
        {({ data: query, loading: queryLoading }) => {
          parseResult({
            query,
            dataField: 'getAppointmentPrepayment',
            resultHandler: store.dispatch.appointmentPrepayment.fetchStart,
          });
          !queryLoading &&
            loading &&
            !creditCard.processing &&
            store.dispatch.loader.activateLoader({ loading: false });
          return <ContentPage schemas={[]} loading={queryLoading} />;
        }}
      </Query>
    );
  }
}
const mapStateToProps = ({
  appointmentPrepayment,
  addAppointment,
  loader,
  dateTimeSelect,
  appointmentCheckOut,
  availableHours,
  creditCard,
  modal,
}) => ({
  appointmentPrepayment,
  dateTimeSelect,
  appointmentCheckOut,
  addAppointment,
  loader,
  availableHours,
  creditCard,
  modal,
});

export default compose(
  connect(mapStateToProps),
  withApollo,
)(AppointmentPrepaymentContainer);
