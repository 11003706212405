import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import { store } from '../../../models';
import { Modal } from './modal';
import { ContextProvider } from '../../root/TopLevelContext';

class ModalContainer extends PureComponent {
  static contextType = ContextProvider;
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      otpError: false,
    };
  }
  componentDidMount() {
    const parsedLocalActions = this.props.localActions && JSON.parse(this.props.localActions);
    this.context._setActions({ ...parsedLocalActions });
    if (this.props.externalData) {
      store.dispatch.form.addExternalSubmissionData(this.props.externalData);
    }
  }
  componentWillReceiveProps({ modal: { cancel } }) {
    const {
      modal: { modalOpen },
    } = this.props;
    if (cancel && modalOpen) {
      store.dispatch.modal.toggleCancel(false);
      this.clickHandler();
    }
  }
  componentDidUpdate(e){
    const { error } = this.props;
    // clearing error on modal close
    if (e.modal.transform === false)
      this.setState({ errorMessage: null}) //eslint-disable-line react/no-did-update-set-state
    if (error?.errors) {
      this.setState({ errorMessage: error.errors[0].message }); //eslint-disable-line react/no-did-update-set-state
      store.dispatch.error.resetError();
    }
  }
  componentWillUnmount() {
    store.dispatch.modal.toggleOpen(false);
    document.body.classList.remove('modal-open');
  }
  clickHandler = _ => {
    const { radioInputCancellationReason } = this.props;
    const {
      radioInput: { resetRadioInput } = {},
      formGroup: { resetEvents } = {},
      inputDropdown: { resetValues } = {},
      modal: { toggleOpen, toggleTransform } = {},
    } = store.dispatch;
    setTimeout(() => {
      toggleOpen(null);
      toggleTransform(true);
      radioInputCancellationReason && resetRadioInput() && resetEvents();
      store.getState().inputDropdown.createVisitSummaryPractice && resetValues();
    }, 300);
    toggleTransform(false);
  };
  handleOutsideClick = e => {
    const { hideClose, closeOnOutsideClick = false } = this.props;
    if (closeOnOutsideClick) store.dispatch.modal.toggleClose();
    if (e.target.id === 'outerContent' && !hideClose) {
      this.clickHandler();
    }
  };
  render() {
    const {
      modal: { transform, modalOpen },
    } = this.props;
    modalOpen ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open');
    // disable select business continue button on opening Modal
    if (this.props.id === 'selectBusiness'){
      store.dispatch.button.enableButton({
        btn: 'intelligentSchedulerContinue',
        complete: false,
      });
    }
    if (this.props.id === 'signInOTPModal'){
      this.setState(prevState => {
        return {
          ...prevState,
          otpError: true,
        };
      });
    }
    return (
      <Modal
        {...{
          ...this.props,
          transform,
          modalOpen,
          otpError: this.state.otpError,
          errorMessage: this.state.errorMessage,
          handleOutsideClick: this.handleOutsideClick,
          clickHandler: this.clickHandler,
        }}
      />
    );
  }
}
const mapStateToProps = ({ error }) => ({
  error,
});
const enhancer = compose(
  withRouter,
  connect(mapStateToProps),
);
export default enhancer(ModalContainer);
