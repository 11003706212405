export const errorPage = {
  state: {
    errorOccurred: false,
    middlewareErrorOccurred: false,
    errorMessage: null,
    errorSubMessage: null,
    oopsScreen: [],
    unSupportedBrowserScreen: [],
  },
  reducers: {
    showErrorPage: (state, { errorOccurred }) => ({
      ...state,
      errorOccurred,
    }),
    showMiddlewareErrorPage: (state, { middlewareErrorOccurred, errorMessage, errorSubMessage }) => ({
      ...state,
      middlewareErrorOccurred,
      errorMessage,
      errorSubMessage,
    }),
    showOopsErrorPage: (state, param) => ({
      ...state,
      oopsScreen: param,
    }),
    showUnsupportedBrowserErrorPage: (state, param) => ({
      ...state,
      unSupportedBrowserScreen: param,
    }),
    resetState: state => ({ ...state }),
  },
};
