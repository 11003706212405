import React from 'react';
import { Help, Text } from '../styleguide';

export default props => {
  const {
    errorSchema,
    registry: {
      fields: { ObjectField },
    },
    schema: { title, showCheckmark, description },
  } = props;

  const hasErrors = Object.keys(errorSchema || {}).length > 0;

  return (
    <>
      <Text tag="p" text={title} type="groupTitle" icon={showCheckmark && !hasErrors} />
      <Help text={description}>
        <ObjectField {...props} />
      </Help>
    </>
  );
};
