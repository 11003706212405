import React, { PureComponent } from 'react';
import { isEmpty, times } from '@carecloud/cloudpak';
import classNames from 'classnames';
import moment from 'moment';
import { store } from '../../../models';
import { Table } from './table';
import SchemaParser from '../../services/SchemaParser';

class TableContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      data: props?.data || [],
      expand: null,
      sortBy: 'name',
      sort: 'asc',
      showAction: null,
      selectedView: 'list',
      altViewMaxIndex: 6,
      lengthMap: { 1: 'one', 2: 'two', 3: 'three', 4: 'four' },
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if (data && isEmpty(this.state.data)) {
      const { sort, sortBy } = this.state;
      this.sortData({ data, sort, sortBy });
    }
  }

  loadMore = _ =>
    this.setState({ altViewMaxIndex: this.state.altViewMaxIndex + 6 });
  sortClickHandler = sortBy => _ => {
    const { data } = this.state;
    const sort = this.state.sort === 'asc' ? 'desc' : 'asc';
    this.setState({ sortBy, sort });
    this.sortData({ data, sortBy, sort });
  };

  tableHeadingSection = styles => {
    const { heading } = this.props;
    const { lengthMap } = this.state;
    return (
      <div
        className={classNames(
          styles.menuItem,
          styles.headerMenuItem,
          styles[
            `${lengthMap[heading.length]}${
              heading.includes('actions') ? 'WithActions' : ''
            }`
          ],
        )}
      >
        {heading.map(head => {
          if (head === 'actions') {
            return <div key={head} className={styles.heading} />;
          }
          return (
            <div
              key={head}
              className={styles.heading}
              onClick={this.sortClickHandler(head)}
              role="Menuitem"
              tabIndex={0}
              id={head}
            >
              <div className={styles.head}>{head}</div>
              <svg height="13" width="10" className={styles.filterSvg}>
                <polygon
                  points="1,5 5,1 9,5"
                  style={{
                    fill: '#5BC5D0',
                    stroke: '#5BC5D0',
                    strokeWidth: 1,
                    strokeLinejoin: 'round',
                  }}
                />
                <polygon
                  points="1,8 5,12 9,8"
                  style={{
                    fill: '#5BC5D0',
                    stroke: '#5BC5D0',
                    strokeWidth: 1,
                    strokeLinejoin: 'round',
                  }}
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          );
        })}
      </div>
    );
  };
  altViewSelector = styles => {
    const { hasAltView } = this.props;
    const { selectedView } = this.state;
    return (
      <div className={styles.altSelector}>
        {hasAltView && (
          <div className={styles[selectedView]}>
            <i
              className="material-icons module"
              onClick={this.changeView('module')}
              role="menuItem"
              tabIndex="0"
            >
              view_module
            </i>
            <i
              className="material-icons list"
              onClick={this.changeView('list')}
              role="menuItem"
              tabIndex="0"
            >
              view_list
            </i>
          </div>
        )}
      </div>
    );
  };
  changeView = view => _ => {
    this.setState({ selectedView: view });
  };
  toggleActions = id => _ => {
    console.log('id', id);
    this.setState({ showAction: this.state.showAction ? null : id });
  };
  actions = ({ id, styles }) => {
    if (id !== this.state.showAction) return null;
    const item = this.state.data.find(item => item.id === id);
    return (
      <div
        className={styles.actionSchemaWrapper}
        onBlur={this.toggleActions(id)}
        role="menuitem"
        tabIndex="0"
        onClick={e => e.stopPropagation()}
      >
        <SchemaParser schema={item.actions} />
      </div>
    );
  };
  isExpanded = id => id === this.state.expand;
  expansionPanel = ({ id, styles }) => {
    const item = this.state.data.find(item => item.id === id);
    if (!item) return null;
    return (
      <div
        className={classNames(styles.expansionPanel, {
          [styles.open]: this.isExpanded(id),
        })}
      >
        <SchemaParser schema={item.expanded} />
      </div>
    );
  };
  chevron = ({ id, styles }) => (
    <i
      role="menuItem"
      tabIndex="0"
      onClick={() => this.expand(id)}
      className={classNames(styles.chevron, 'material-icons', {
        [styles.rotateChevron]: this.isExpanded(id),
      })}
    >
      keyboard_arrow_down
    </i>
  );
  expand = id => {
    console.log('id', id);
    const item = this.props.data.find(item => item.id === id);
    store.dispatch.mixpanel.addMetadata({
      viewDetails: { table: this.props.id, item },
    });
    this.setState({ expand: !this.isExpanded(id) && id });
  };
  parseData = ({ data, head }) => {
    let result = data[head];
    if (moment(result, moment.ISO_8601, true).isValid()) {
      result = moment(result)
        .local()
        .format('MM/DD/YYYY');
    }
    return result;
  };
  pageOffset = _ => (this.state.pageNumber - 1) * 10;
  updatePage = pageNumber => _ => this.setState({ pageNumber });
  sortData = ({ data, sort, sortBy }) => {
    let sortedData = Object.assign([], data);
    const allEqual = sortedData.reduce(
      (a = {}, b = {}) => (a[sortBy] === b[sortBy] ? a : NaN),
      [],
    );
    if (sortBy && !allEqual) {
      sortedData =
        sort === 'desc'
          ? sortedData.sort((a, b) => {
              const formattedA =
                a[sortBy] && a[sortBy].toString().toLowerCase();
              const formattedB =
                b[sortBy] && b[sortBy].toString().toLowerCase();
              if (formattedB === formattedA) return 0;
              if (formattedB < formattedA) return -1;
              if (formattedB > formattedA) return 1;
              return 0;
            })
          : sortedData.sort((a, b) => {
              const formattedA =
                a[sortBy] && a[sortBy].toString().toLowerCase();
              const formattedB =
                b[sortBy] && b[sortBy].toString().toLowerCase();
              if (formattedB === formattedA) return 0;
              if (formattedA < formattedB) return -1;
              if (formattedA > formattedB) return 1;
              return 0;
            });
    }
    this.setState({ data: sortedData });
  };
  pageNumberComponent = ({ n, message, styles, isText }) => (
    <div
      key={n}
      role="menuItem"
      tabIndex="0"
      onClick={this.updatePage(n)}
      className={classNames(
        styles.pageNumber,
        { [styles.currentPage]: n === this.state.pageNumber },
        { [styles.pageNumberText]: isText },
      )}
    >
      {message}
    </div>
  );
  pages = styles => {
    const { data } = this.props;
    const pageTotal = Math.ceil(data.length / 10);
    if (pageTotal <= 1) {
      return null;
    }
    if (pageTotal >= 2 && pageTotal <= 3) {
      return (
        <div className={styles.pageSelectionWrapper} pagetotal={pageTotal}>
          {times(pageTotal, n =>
            this.pageNumberComponent({ n: n + 1, message: n + 1, styles }),
          )}
        </div>
      );
    }
    if (pageTotal > 3) {
      return this.paginationWithFirstLast({ pageTotal, styles });
    }
    return null;
  };
  paginationWithFirstLast = ({ pageTotal, styles }) => {
    const { pageNumber } = this.state;
    const { text } = this.props;
    const showFirst = pageNumber > 2;
    const showLast = pageNumber + 1 < pageTotal;
    return (
      <div className={styles.pageSelectionWrapperWithLast}>
        {showFirst &&
          this.pageNumberComponent({
            n: 1,
            message: text.first,
            styles,
            isText: true,
          })}
        {times(3, n => {
          let number = n + pageNumber;
          if (pageNumber === pageTotal) {
            number -= 2;
          } else if (!(pageNumber === 1)) {
            number -= 1;
          }
          return this.pageNumberComponent({
            n: number,
            message: number,
            styles,
          });
        })}
        {showLast &&
          this.pageNumberComponent({
            n: pageTotal,
            message: text.last,
            styles,
            isText: true,
          })}
      </div>
    );
  };
  render() {
    return (
      <Table
        {...{
          ...this.props,
          dataArray: this.state.data.slice(
            this.pageOffset(),
            10 + this.pageOffset(),
          ),
          pages: this.pages,
          chevron: this.chevron,
          expand: this.expand,
          expansionPanel: this.expansionPanel,
          isExpanded: this.isExpanded,
          toggleActions: this.toggleActions,
          actions: this.actions,
          showAction: this.state.showAction,
          selectedView: this.state.selectedView,
          altViewMaxIndex: this.state.altViewMaxIndex,
          loadMore: this.loadMore,
          altViewSelector: this.altViewSelector,
          tableHeadingSection: this.tableHeadingSection,
          lengthMap: this.state.lengthMap,
          parseData: this.parseData,
        }}
      />
    );
  }
}

export default TableContainer;
