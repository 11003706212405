export const delegateProfiles = {
  state: { delegateProfile: [], selectedProfile: String() },
  reducers: {
    fetchStart: (state, delegateProfile) => ({
      ...state,
      delegateProfile,
    }),
    setProfile: (state, id) => ({
      ...state,
      selectedProfile: id,
    }),
  },
};
