import { withApollo } from 'react-apollo';
import React, { useState, useEffect, useCallback } from 'react';
import { compose, isEmpty, debounce } from '@carecloud/cloudpak';
import classNames from 'classnames';
import { store } from '../../../models';
import { MarkMessageRead } from '../../../graphql/mutations';
import { GetMoreMessages } from '../../../graphql/queries';
import SchemaParser from '../../services/SchemaParser';
import { PageLoader } from '../page_loader/pageLoader';

import ProviderCardContainer from '../provider_card/providerCardContainer';
import styles from './MessagesPanel.module.scss';
import { MEDIUM_LOADER_SIZE, MEDIUM_LOADER_THICKNESS } from '../../../constants/Loader';

const MessagesPanelContainer = React.memo(props => {
  const {
    messages: {
      providerSelected,
      createNew,
      subjectEvent: { value },
      messagesScreen: {
        messagesSection: { providerMessages } = {},
      },
      viewMessagesOf: { read, param } = {},
    } = {},
    id,
    theme,
    providerMessageList,
    messagePromptSection,
    newProviderMessage,
    client,
  } = props;
  const [state, setState] = useState({
    providerId: String(),
    avatar: {},
    provider: String(),
    specialty: String(),
    loadingMore: false,
  });
  const autoMarkAsRead = async (messageId = String()) => {
    try {
      await client.mutate({
        mutation: MarkMessageRead,
        variables: { messageId },
      });
      store.dispatch.messages.markRead(false);
      store.dispatch.messages.removeUnreadMarker({ conversationId: messageId });
    } catch (error) {
      console.error(error);
    }
  };

  const loadMoreMessages = async e => {
    const practice = sessionStorage.getItem('practiceSelectedMgmt');
    if(practice === 'carecloud'){
      const { currentPage: currentPageFromModel, totalEntries } = store.getState().messages?.messagesScreen?.paginationData;
  
      if (providerMessages.length === totalEntries) return;
  
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight) {
       
        setState({ ...state, loadingMore: true });
        const {
          data: { getMoreMessages: { nextProviderMessages = [], nextProviderMessageList = [], currentPage = 1 } = {} } = {},
        } = await client.query({
          query: GetMoreMessages,
          variables: {
            input: { requestedPage: currentPageFromModel + 1 },
          },
        });
        store.dispatch.messages.appendNextPage({ nextProviderMessages, nextProviderMessageList, currentPage });
        setState({ ...state, loadingMore: false });
      }
    }
  };

  const debouncedLoadMoreMessages = useCallback(debounce(loadMoreMessages, 200), []);

  useEffect(
    _ => {
      if (providerSelected) {
        const { param, provider, specialty, avatar } = providerSelected;
        setState({
          ...state,
          providerId: param,
          provider,
          specialty,
          avatar,
        });
      }
      if (!createNew && !read && param) autoMarkAsRead(param);
    },
    [providerSelected, param],
  );
  const { providerId, avatar, provider, specialty } = state;

  return (
    <div id={id} className={styles[theme]}>
      {isEmpty(providerMessages) && !providerSelected && <SchemaParser schema={[messagePromptSection]} />}
      <div className={classNames({ [styles.short]: providerSelected })}>
        {providerSelected && createNew && (
          <div
            className={classNames({
              [styles.selectedProvider]: providerSelected,
            })}
          >
            <ProviderCardContainer
              props={{
                ...props,
                props,
                value,
                id: providerId,
                theme: providerId,
                avatar,
                provider,
                specialty,
                option: false,
                action: false,
                read: true,
              }}
            />
            {isEmpty(providerMessages) && <SchemaParser schema={[newProviderMessage]} />}
          </div>
        )}
        {providerMessageList && (
            <>
              {state.loadingMore && 
                <>
                  <div className={styles.loaderWrapper}>
                    <PageLoader className="centered" overlay={false} fixed={false} size={MEDIUM_LOADER_SIZE} thickness={MEDIUM_LOADER_THICKNESS} />
                  </div>
                </>}
              <div
                onScroll={e => {
                e.persist();
                debouncedLoadMoreMessages(e)
              }}
                className={classNames({ [styles.fadedMessageList]: state.loadingMore })}
              >
                <SchemaParser schema={[providerMessageList]} />
              </div>
            </>
        )}
      </div>
    </div>
  );
});


export default compose(withApollo)(MessagesPanelContainer);
