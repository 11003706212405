import { Image } from '..';
import React from 'react';
import classNames from 'classnames';
import { isEmpty } from '@carecloud/cloudpak';
import styles from './avatar.module.scss';
import { store } from '../../../models';
import { NAV_AVATAR } from '../../../constants/Delegate';

export const Avatar = React.memo(({ url, initials, theme, action = {}, profileId }) => {
  const id = sessionStorage.getItem('CC-Breeze-Web-representedUserId') || 'self';
  return (
    <div
      className={classNames(
        styles.wrapper,
        theme === NAV_AVATAR && (id === profileId ? styles.activeProfile : styles.inactiveProfile),
      )}
      onClick={!isEmpty(action) ? () => store.dispatch[action.model][action.trigger](action.param) : null}
      role="button"
      tabIndex={0}
    >
      {url && (
        <Image
          {...{
            alt: 'avatar',
            src: url,
            theme,
          }}
        />
      )}
      {initials && (
        <div className={classNames(styles.avatar, styles.initials, styles[theme])}>
          <p className={styles.initialsLabel}>{initials}</p>
        </div>
      )}
    </div>
  );
});
