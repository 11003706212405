import React, { PureComponent } from 'react';

import { ApolloConsumer, Query } from 'react-apollo';
import { ContentPage } from '..';
import { connect } from 'react-redux';
import { getGuestPaymentReceipt, getGuestWorkflowSuccess } from '../../../graphql/queries';
import { isMobileDevice, breakBackButton } from '../../services/utilities';
import { parseResult } from '../../../graphql/utilities';
import { store } from '../../../models';
import styles from './GuestSuccess.module.scss';
import { ContextProvider } from '../../root/TopLevelContext';

class GuestSuccess extends PureComponent {
  static contextType = ContextProvider;
  componentDidMount() {
    breakBackButton();
  }

  componentDidUpdate() {
    this.props.guest.guestSuccess.length && this.props.loading && store.dispatch.loader.activateLoader({});
  }
  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
  }
  setActionsWithParam = param => {
    this.context._setActions({ showGuestPaymentReceipt: this.showGuestPaymentReceipt(param) });
  };
  showGuestPaymentReceipt = client => async _ => {
    const { guest: { successfulGuestData, guestPaymentData } } = this.props;
    const { data: { getGuestPaymentReceipt: { guestReceiptScreen: guestSuccessfulScreen } } } = await client.query({
      query: getGuestPaymentReceipt,
      variables: { input: { successfulGuestData, guestPaymentData } },
    });
    store.dispatch.guest.fetchStart({ guestSuccessfulScreen });
  };
  render() {
    const { guest: { guestSuccess, successfulGuestData } } = this.props;
    return guestSuccess.length ? (
      <ApolloConsumer>
        {client => {
          this.setActionsWithParam(client);
          return (
            <ContentPage className={isMobileDevice() ? styles.guestSuccessWrapper : String()} schemas={guestSuccess} />
          );
        }}
      </ApolloConsumer>
    ) : (
      <Query query={getGuestWorkflowSuccess} variables={{ input: { successfulGuestData } }}>
        {({ data: query, loading }) => {
          parseResult({ query, dataField: 'getGuestWorkflowSuccess', resultHandler: store.dispatch.guest.fetchStart });
          return <ContentPage schemas={[]} loading={loading} />;
        }}
      </Query>
    );
  }
}
const mapStateToProps = ({ guest, loader: { loading }, activateLoader }) => ({
  guest,
  loading,
  activateLoader,
});

export default connect(mapStateToProps)(GuestSuccess);
