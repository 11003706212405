import React, { useReducer, useEffect, useCallback } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Icon } from '..';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import classNames from 'classnames';
import { store } from '../../../models';
import styles from './datePicker.module.scss';
import { WEEKDAY_FORMAT, PREVIOUS_MONTH, NEXT_MONTH } from '../../../constants/DatePicker';

const DatePickerContainer = ({ id, lastValidDate, preselectedDate, disallowToday }) => {
  const initialDatePickerState = {
    dateSelected: moment(preselectedDate),
    focus: false,
  };
  const datePickerReducer = (state, payload) => ({ ...state, ...payload });
  const [state, dispatch] = useReducer(datePickerReducer, initialDatePickerState);
  const getValidRange = day => {
    if (!lastValidDate) return day.isBefore(moment().startOf('day'));
    const beforeDate = disallowToday
      ? moment()
        .add(1, 'days')
        .startOf('day')
      : moment().startOf('day');
    return day.isBefore(beforeDate) || day.isAfter(lastValidDate);
  };

  useEffect(
    _ => {
      store.dispatch.datePicker.selectDate({ id, value: state.dateSelected });
    },
    [state.dateSelected],
  );

  const dateToStoreCb = useCallback(_ => store.dispatch.datePicker.selectDate({ id, value: state.dateSelected }), []);
  useEffect(
    _ => {
      dateToStoreCb();
      return _ => store.dispatch.datePicker.resetState();
    },
    [dateToStoreCb],
  );
  return (
    <div className={classNames(styles.wrapper, { oneTimePaymentDatePickerFocused: state.focus })}>
      <div className={styles.activeCalendarWrapper}>
        <SingleDatePicker
          onOutsideClick={_ => dispatch({ focus: false })}
          onFocusChange={({ focused }) => dispatch({ focus: focused })}
          keepOpenOnDateSelect
          readOnly
          numberOfMonths={1}
          date={state.dateSelected || moment()}
          onDateChange={date => dispatch({ dateSelected: date, focus: false })}
          isOutsideRange={day => getValidRange(day)}
          customInputIcon={<span />}
          displayFormat={String()}
          navPrev={<Icon {...{ id: PREVIOUS_MONTH, theme: PREVIOUS_MONTH, icon: 'arrow-left' }} />}
          navNext={<Icon {...{ id: NEXT_MONTH, theme: PREVIOUS_MONTH, icon: 'arrow-right' }} />}
          focused={state.focus}
          enableOutsideDays
          hideKeyboardShortcutsPanel
          weekDayFormat={WEEKDAY_FORMAT}
          screenReaderInputMessage=""
        />
      </div>
    </div>
  );
};
// DatePickerContainer.whyDidYouRender = true;

export default DatePickerContainer;
