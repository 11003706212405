export const expander = {
  state: { open: [], showContent: false },
  reducers: {
    open: (state, { expanderId }) => ({
      ...state,
      ...(!state.open.includes(expanderId) && { open: state.open.concat(expanderId) }),
    }),
    close: (state, { expanderId }) => ({
      ...state,
      open: state.open.filter(eId => eId !== expanderId),
    }),
    closeAll: state => ({ ...state, open: [] }),
    toggleShowContent: state => ({
      ...state,
      showContent: !state.showContent,
    }),
  },
};
