export const login = {
  state: {
    screens: {},
    state: 'patient_app_signin',
    alternateView: null,
    signInError: false,
    showForgotPassword: false,
    showSelectPractice: false,
    createNewPassword: false,
    recoverPasswordCheckmark: false,
    resetPasswordComplete: false,
    resetPasswordCheckmark: false,
    requestSuccess: false,
    rightSide: {
      images: ['/images/welcome1.jpg', '/images/welcome2.jpg', '/images/welcome3.jpg', '/images/welcome4.jpg'],
      imageToShow: '/images/welcome1.jpg',
      center: true,
    },
    limboCognitoTokens: Object(),
    emailInputEmpty: true,
    passwordInputEmpty: true,
    patientIdsList: [],
  },
  reducers: {
    changePicture: (state, image, isCentered) => ({
      ...state,
      rightSide: {
        ...state.rightSide,
        imageToShow: image,
        center: isCentered,
      },
    }),
    setEmailInputValidator: (state, emailInputEmpty) => ({
      ...state,
      emailInputEmpty,
    }),
    setPasswordInputValidator: (state, passwordInputEmpty) => ({
      ...state,
      passwordInputEmpty,
    }),
    showSignInError: (state, param) => ({
      ...state,
      signInError: param,
    }),
    setSelectPractice: (state, param) => ({
      ...state,
      screens: { ...state.screens, selectPractice: param },
    }),
    selectPracticeView: state => ({
      ...state,
      showSelectPractice: true,
    }),
    recoverPasswordView: state => ({
      ...state,
      showForgotPassword: !state.showForgotPassword,
    }),
    createNewAccount: state => ({ ...state }),
    activateAlternateView: (state, view) => ({
      ...state,
      alternateView: view,
    }),
    createNewPasswordView: state => ({
      ...state,
      createNewPassword: !state.createNewPassword,
    }),
    createNewPasswordFlowDisplay: state => ({
      ...state,
      createNewPasswordFlowDisplay: !state.createNewPasswordFlowDisplay,
    }),
    showCheckmark: (state, param) => ({
      ...state,
      recoverPasswordCheckmark: param,
    }),
    showResetPasswordComplete: state => ({
      ...state,
      resetPasswordComplete: true,
      createNewPasswordFlowDisplay: false,
    }),
    showResetPasswordCheckmark: (state, param) => ({
      ...state,
      resetPasswordCheckmark: param,
    }),
    showRequestSuccess: (state, param) => ({
      ...state,
      requestSuccess: param,
    }),
    fetchStart: (state, screens) => ({
      ...state,
      screens: { ...screens },
    }),
    storeCognitoToken: (state, token) => ({
      ...state,
      limboCognitoTokens: token,
    }),
    setPatientIdsList: (state, param) => ({
      ...state,
      patientIdsList: param,
    }),
    clearCognitoToken: state => ({
      ...state,
      limboCognitoTokens: Object(),
    }),
  },
};
