export const oneTimePayment = {
  state: { oneTimePayment: [] },
  reducers: {
    fetchStart: (state, { oneTimePaymentScreen, patientId, oneTimeDetails }) => ({
      ...state,
      oneTimePayment: oneTimePaymentScreen,
      patientId,
      oneTimeDetails,
    }),
    clearScreen: _ => ({ oneTimePayment: [] }),
  },
};
