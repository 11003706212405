import { Redirect, withRouter } from 'react-router-dom';
import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { store } from '../../../../models';
import { ContentPage } from '../..';
import { GetShopMakePayment } from '../../../../graphql/queries';
import { parseParams } from '../../../services/routing';
import { parseResult } from '../../../../graphql/utilities';
import { shopContext as ShopContext } from '../../../../contexts';

/* eslint-disable camelcase */
class ShopMakePaymentContainer extends Component {
  componentWillReceiveProps({ shopMakePayment, getShopMakePayment, creditCard: { processing }, loader: { loading } }) {
    if (isEmpty(shopMakePayment.shopMakePayment) && !getShopMakePayment.loading) {
      parseResult({
        query: getShopMakePayment,
        dataField: 'getShopMakePayment',
        resultHandler: store.dispatch.shopMakePayment.fetchStart,
      });
    }
    if (!getShopMakePayment.loading && loading && !processing) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
  }

  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.shopMakePayment.clearStore();
  }

  shopRedirect = _ => {
    const { shopMakePayment: { path } } = this.props;
    window.location.replace(path);
  };

  render() {
    const {
      shopMakePayment: { shopMakePayment },
      loader: { loading },
    } = this.props;
    const { store_id, transaction_id } = parseParams(this.props);
    if (store_id && transaction_id) {
      return (
        shopMakePayment && (
          <ShopContext.Provider value={{ shopRedirect: this.shopRedirect }}>
            <ContentPage schemas={[shopMakePayment]} loading={loading} />
          </ShopContext.Provider>
        )
      );
    }
    return <Redirect to="/shop" />;
  }
}
const mapStateToProps = ({ shopMakePayment, modal, loader, creditCard }) => ({
  shopMakePayment,
  modal,
  loader,
  creditCard,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps),
  graphql(GetShopMakePayment, {
    name: 'getShopMakePayment',
    options: props => ({
      variables: {
        storeId: parseParams(props).store_id,
        transactionId: parseParams(props).transaction_id,
        amount: parseParams(props).amount,
      },
    }),
  }),
);
export default enhancer(ShopMakePaymentContainer);
