import React from 'react';
import { connect } from 'react-redux';
import { ClientSideModal } from '../clientSideModal';
import { LOGOUT_NOTIFICATION_LABELS } from '../../../constants/Inactivity';

const LogoutCountdownContainer = ({ clientSideModal }) => {
  const { SESSION_EXPIRE, SECONDS, CONTINUE, LOGOUT } = LOGOUT_NOTIFICATION_LABELS;
  return (
    <ClientSideModal
      {...{
        ...clientSideModal,
        id: 'logoutCountdownModal',
        transform: true,
        headerId: 'logoutCountdownModalHeader',
        subHeaderId: 'logoutCountdownModalSubHeader',
        headerLabel: '!',
        subHeaderLabel: SESSION_EXPIRE,
        subHeaderLabel2: SECONDS,
        buttons: [
          {
            id: 'logoutCountdownModalLogoutBtn',
            theme: 'logoutCountdownModalLogoutBtn',
            text: LOGOUT,
            action: {
              model: 'localActions',
              trigger: 'logoutCountdownLogout',
            },
            disabled: false,
          },
          {
            id: 'logoutCountdownModalContinueBtn',
            theme: 'logoutCountdownModalContinueBtn',
            text: CONTINUE,
            action: {
              model: 'localActions',
              trigger: 'resetAndClosePopup',
            },
            disabled: false,
          },
        ],
      }}
    />
  );
};
const mapStateToProps = ({ clientSideModal }) => ({ clientSideModal });

export default connect(mapStateToProps)(LogoutCountdownContainer);
