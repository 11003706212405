import { NavLink } from 'react-router-dom';
import { isEmpty } from '@carecloud/cloudpak';
import React from 'react';
import classNames from 'classnames';
import { store } from '../../../models';
import styles from './link.module.scss';
import { _BLANK, _PARENT } from '../../../constants/Properties';
/* import { INCOMPLETE_REGISTRATION_ROUTE } from '../../../constants/Routes'; */

const link = ({
  id,
  url,
  text,
  target,
  theme,
  info,
  action = {},
  metaData,
  /* highlight, */
}) => {
  const externalLinks = [_BLANK, _PARENT];
  /* const displayRequired = (highlight, text) => {
    // Show required red asterik if 
    // 1: if it is profile settings link (highlight coming from cyclops)
    // 2: navigating to settings from completeRegistratoin popup
    // 3: There are missing fields
    let show = highlight && store.getState().appointments.highlightMissingForms &&
    store.getState().appointments.missingRegistrationFields.length
    // 4: Missing fields name matches the link
    const keys = store.getState().appointments.missingRegistrationFields.map(a => Object.keys(a)).flat()
    show = show && keys?.includes(INCOMPLETE_REGISTRATION_ROUTE.find(x => x.text === text)?.key)
    if (keys.includes('personal_details') && text==='Personal'){      
      const abc = store.getState().appointments.missingRegistrationFields[keys.indexOf('personal_details')]
      const missingFields =  abc[Object.keys(abc)[0]].map(a => Object.keys(a).find( key => (key !== 'required' || key !== 'message')))
      let inPersonal = false
      const personalFields = INCOMPLETE_REGISTRATION_ROUTE.find(x => x.text === 'Personal')?.fields
      missingFields.forEach(e => {
        if (personalFields?.indexOf(e)>-1){
          inPersonal = true
        } 
      })

      if (!inPersonal){
        show = false
      }
    }
    return show
  } */
  return (
    <div className={classNames({ [styles.info]: info })}>
      <div className={classNames({ [styles.contain]: info })}>
        {!externalLinks.includes(target) ? (
          <NavLink
            id={id}
            activeClassName={styles.active}
            className={styles[theme]}
            to={url}
            target={target}
            onClick={() => {
              store.dispatch.mixpanel.addMetadata({ [theme]: url });
              store.dispatch.mixpanel.addNonActionableMetadata({
                link: { ...metaData },
              });
              !isEmpty(action) &&
                store.dispatch[action.model][action.trigger](action.param);
            }}
            isActive={(_, location) => location.pathname.startsWith(url)}
          >
            {text}{id.includes('profileSettingsNavLink') && false/* console.log("missingFields.missingdata", missingFields.missing_data.personal_details);console.log("missingFields.missingdata", missingFields.missing_data.personal_details);(highlight, text) */ ? <span style={{color: 'red'}}>*</span> : null}
          </NavLink>
        ) : (
          <a id={id} className={styles[theme]} href={url} target={target}>
            {text}
          </a>
        )}
      </div>
      {info && <div>{info}</div>}
    </div>
  );
};
export const Link = link;