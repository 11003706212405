import React, { PureComponent } from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import { Rating } from './rating';
import { store } from '../../../models';

class RatingContainer extends PureComponent {
  constructor(props) {
    super(props);
    const selected = props.formData;
    this.state = { hover: 0, selected };
  }
  onMouseEnter = number => _ => {
    this.setState({ hover: number });
  };
  onMouseLeave = _ => this.setState({ hover: 0 });
  onClick = number => halfStarNumber => {
    const { onChange = null, action = {}, useHalfStars } = this.props;
    const { model = String(), trigger = String(), param = String() } = action;
    const num = useHalfStars ? halfStarNumber : number;
    this.setState({ selected: num });
    if (!isEmpty(action)) return store.dispatch[model][trigger]({ param, number: num });
    onChange && onChange(num);
  };
  isFull = number => {
    const { hover, selected } = this.state;
    const currentNumber = hover || selected;
    return currentNumber >= number;
  };
  render() {
    const {
      schema = Object(),
      supportingText = String(),
      title = String(),
      starColor = String(),
      starSize = Number(),
      questionTitle = String(),
      useHalfStars = false,
      required,
      rawErrors = [],
      errors,
      uiSchema: { requiredLabel, errorRequiredLabel } = {},
    } = this.props;
    return (
      <Rating
        {...{
          ...this.state,
          required,
          rawErrors,
          errors,
          requiredLabel,
          errorRequiredLabel,
          starColor,
          starSize,
          questionTitle,
          useHalfStars,
          onMouseEnter: this.onMouseEnter,
          onMouseLeave: this.onMouseLeave,
          onClick: this.onClick,
          isFull: this.isFull,
          title: schema ? schema.title : title,
          supportingText: schema ? schema.description : supportingText,
        }}
      />
    );
  }
}
export default RatingContainer;
