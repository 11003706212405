import React from 'react';
import { Image } from '../../ui';
import styles from './MiddlewareErrorPage.module.scss';

const middlewareErrorPage = ({
  props: { clickHandler, errorMessage, errorSubMessage },
}) => (
  <div className={styles.errorContainer}>
    <div className={styles.errorDetails}>
      <Image
        {...{
          id: 'breezeErrorLogo',
          theme: 'breezeErrorLogo',
          src: 'https://assets.gobreeze.com/error-displaced-logo.svg',
          alt: 'breeze-error-logo',
        }}
      />
      <h1> MIDDLEWARE IS DOWN </h1>
      <h3> {errorMessage} </h3>
      <h3> {errorSubMessage} </h3>
      <Image
        {...{
          id: 'tony',
          theme: 'tony',
          src: 'https://assets.gobreeze.com/tony.jpg',
          alt: 'tony',
        }}
      />
      <button
        id="errorPageRetryBtn"
        className={styles.errorPageRetryBtn}
        type="button"
        onClick={clickHandler}
      >
        {' '}
        Try Again
      </button>
    </div>
    <Image
      {...{
        id: 'breezeErrorFooterLogo',
        theme: 'breezeErrorFooterLogo',
        src: 'https://assets.gobreeze.com/Footer_Logo.svg',
        alt: 'breeze-footer-logo',
      }}
    />
  </div>
);

export default middlewareErrorPage;
