export const formHistory = {
  state: {
    formHistory: [],
    allForms: {},
    formViewHeading: [],
    formsToSign: [],
    form: String(),
    response: String(),
    pageDetails: {},
  },
  reducers: {
    fetchStart: (
      state,
      { formHistoryScreen, forms, formViewHeading, formViewLeftSide, booleans, isDisabled, errorRequiredLabel },
    ) => ({
      ...state,
      formHistory: formHistoryScreen,
      allForms: forms,
      formViewHeading,
      formViewLeftSide,
      booleans,
      isDisabled,
      errorRequiredLabel,
    }),
    selectProvider: (state, { param: { appointmentProvider, practiceId, patientId } }) => ({
      ...state,
      appointmentProvider,
      practiceId,
      patientId,
      ...(practiceId !== state?.practiceId && { formsToSign: { [appointmentProvider]: [] } }),
    }),
    storeForms: (state, { forms, practiceHeader }) => ({
      ...state,
      forms,
      practiceHeader,
    }),
    appendForms: (state, { forms, pageDetails, historicalForms, id }) => ({
      ...state,
      allForms: { ...state.allForms, historicalForms },
      providerForms: { [id]: forms },
      providerPageDetails: { [id]: pageDetails },
    }),
    clearForms: state => ({
      ...state,
      forms: null,
    }),
    storeText: (state, { text }) => ({
      ...state,
      text,
    }),
    resetStore: _ => ({ formHistory: [] }),
    addForms: (state, { id, forms }) => ({
      ...state,
      formsToSign: { [id]: forms },
    }),
    clearProvider: state => ({
      ...state,
      appointmentProvider: null,
    }),
    saveProviderForms: (state, { forms, id, pageDetails }) => ({
      ...state,
      providerForms: { ...state.providerForms, [id]: forms },
      providerPageDetails: { ...state.providerPageDetails, [id]: pageDetails },
    }),
    refetch: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    clearStore: _ => ({ ...formHistory.state }),
  },
};
