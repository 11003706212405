import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkButton } from '../../styleguide';
import { fullName, getAttributes } from './Physician.helper';

const Container = styled.div`
  width: 100%;
`;

const Primary = styled.div`
  border-bottom: 1px solid #cfd8dc;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px 0;

  div:nth-child(1) {
    width: 70%;
  }

  div:nth-child(2) {
    padding: 0 8px;
    width: 20%;
  }

  div:nth-child(10) {
    width: 20%;
  }

  svg {
    display: block;
    font-size: 20px;
    line-height: 20px;
    margin: 0;
  }
`;

const Secondary = styled.div`
  background: #f9fafa;
  padding: 8px;

  dl {
    display: grid;
    grid-auto-flow: row;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    grid-template-columns: 1fr 1fr;
    margin: 0;
    padding: 0;
  }

  dd,
  dt {
    color: #b0bec5;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    margin: 0;
  }
`;

const Name = styled.p`
  color: #607d8b;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  margin: 0;
`;

const Speciality = styled.p`
  color: #b0bec5;
  font-size: 13px;
  font-style: italic;
  font-weight: 300;
  line-height: 16px;
  margin: 0;
`;

export default React.memo(({ item, labels, onSelect, isSelected }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const attributes = getAttributes(item, labels);

  const toggleDetails = ev => {
    ev.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const toggleSelection = ev => {
    ev.stopPropagation();
    onSelect(isSelected ? undefined : item);
  };

  return (
    <Container>
      <Primary onClick={toggleSelection}>
        <div>
          <Name>{fullName(item)}</Name>
          <Speciality>{item?.specialty || ''}</Speciality>
        </div>
        <div>
          <LinkButton text={isExpanded ? labels.hideDetailsText : labels.showDetailsText} onClick={toggleDetails} />
        </div>
        <div>
          {isSelected ? (
            <FontAwesomeIcon icon="dot-circle" color="#5BC5D0" />
          ) : (
            <FontAwesomeIcon icon={['far', 'circle']} color="#CFD8DC" />
          )}
        </div>
      </Primary>
      {isExpanded && (
        <Secondary>
          <dl>
            {attributes.map(attribute => (
              <>
                <dt key={attribute.name}>{attribute.name}</dt>
                <dd key={attribute.name}>{attribute.value}</dd>
              </>
            ))}
          </dl>
        </Secondary>
      )}
    </Container>
  );
});
