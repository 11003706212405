export const appointmentCheckOut = {
  state: {
    appointmentCheckOut: [],
    appointmentCheckOutPayment: [],
    externalCheckoutData: null,
    fromAddAppointment: false,
  },
  reducers: {
    fetchStart: (state, { appointmentCheckOutScreen = [] }) => ({
      ...state,
      appointmentCheckOut: appointmentCheckOutScreen,
    }),
    continueCheckout: (state, { appointmentCheckOutScreen, input }) => ({
      ...state,
      appointmentCheckOut: appointmentCheckOutScreen,
      ...input,
    }),
    fetchCheckOutPayment: (state, { appointmentCheckOutPayment }) => ({
      ...state,
      appointmentCheckOutPayment,
    }),
    storeAppointmentData: (state, data) => ({
      ...state,
      ...data,
    }),
    storeRedirectPath: (state, path) => ({
      ...state,
      path,
    }),
    resetState: state => ({ ...state, refetch: true }),
    resetStore: state => ({
      ...state,
      appointmentCheckOut: [],
      appointmentCheckOutPayment: [],
      externalCheckoutData: null,
    }),
    resetAppointmentCheckOutPaymentStore: state => ({
      ...state,
      appointmentCheckOutPayment: [],
    }),
    clearStore: _ => ({
      appointmentCheckOut: [],
      appointmentCheckOutPayment: [],
      externalCheckoutData: null,
      providerCards: null,
      practiceId: null,
      practiceMgmt: null,
      patientId: null,
    }),
    didRefetch: state => ({ ...state, refetch: false }),
    setExternalCheckoutData: (state, { externalCheckoutData }) => ({ ...state, externalCheckoutData }),
    unsetExternalCheckoutData: state => ({ ...state, externalCheckoutData: null }),
    paymentPlanCreated: (state, paymentPlanCreated) => ({
      ...state,
      paymentPlanCreated,
    }),
    resetPaymentPlanCreated: state => ({ ...state, paymentPlanCreated: false }),
    setFromAddAppointment: (state, payload) => ({ ...state, fromAddAppointment: payload }),
  },
};
