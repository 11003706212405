import React, { PureComponent } from 'react';

import { ContentPage } from '..';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import { GetActivityLog } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { store } from '../../../models';
import { breakBackButton } from '../../services/utilities';

class ActivityLog extends PureComponent {
  componentDidMount() {
    breakBackButton();
  }
  componentDidUpdate() {
    this.props.activityLog.length && this.props.loading && store.dispatch.loader.activateLoader({});
  }
  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
  }
  render() {
    return this.props.activityLog.length ? (
      <ContentPage schemas={this.props.activityLog} />
    ) : (
      <Query query={GetActivityLog}>
        {({ data: query, loading }) => {
          parseResult({ query, dataField: 'getActivityLog', resultHandler: store.dispatch.activityLog.fetchStart });
          return <ContentPage schemas={[]} loading={loading} />;
        }}
      </Query>
    );
  }
}

const mapStateToProps = ({ loader: { loading }, activityLog: { activityLog } }) => ({ loading, activityLog });

export default connect(mapStateToProps)(ActivityLog);
