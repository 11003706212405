export const guestEntry = {
  state: {
    guestMode: false,
    guestPatientId: String(),
    guestScreen: [],
    reloadGuest: false,
    rescheduleAppointmentData: {},
    isReschedule: false,
  },
  reducers: {
    fetchStart: (state, param) => ({
      ...state,
      guestScreen: param,
    }),
    setGuestMode: (state, param) => ({ ...state, guestMode: param }),
    setPatientId: (state, param) => ({ ...state, guestPatientId: param }),
    setReloadGuest: (state, param) => ({ ...state, reloadGuest: param }),
    setRescheduleAppointmentData: (state, param) => ({ ...state, rescheduleAppointmentData: param }),
    setIsReschedule: (state, param) => ({ ...state, isReschedule: param }),
  },
};
