export const formDataTemplate = {
  id: '',
  type: '',
  providerName: '',
  providerCustomName: '',
  planName: '',
  planCustomName: '',
  memberId: '',
  groupNumber: '',
  relationshipType: 'Self',
  policyHolderFirstName: '',
  policyHolderMiddleName: '',
  policyHolderLastName: '',
  policyHolderDateOfBirth: '',
  policyHolderGender: '',
  frontPhoto: '',
  backPhoto: '',
  delete: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'addItem': {
      const items = [
        ...state.items,
        {
          ...formDataTemplate,
          id: Date.now(),
        },
      ];

      return { ...state, items };
    }
    case 'removeItem': {
      const items = state.items.map(item => {
        if (item.id !== action.item.id) {
          return item;
        }

        return { ...item, delete: true };
      });

      return { ...state, items };
    }
    case 'clearItem': {
      const items = state.items.map(item => {
        if (item.id !== action.item.id) {
          return item;
        }

        const persistentFields = action.persistentFields || {};
        const newItem = Object.keys(item).reduce((accumulator, key) => {
          accumulator[key] = persistentFields[key] || key === 'id' ? item[key] : formDataTemplate[key];

          return accumulator;
        }, {});

        return newItem;
      });

      return { ...state, items };
    }
    case 'updateItem': {
      const items = state.items.map(item => {
        if (item.id !== action.item.id) {
          return item;
        }

        return { ...item, ...action.item };
      });

      return { ...state, items };
    }
    case 'validateItem': {
      const itemsValidity = { ...state.itemsValidity, [action.item.id]: action.errors };

      return { ...state, itemsValidity };
    }
    default:
      return state;
  }
};

export const getProviderPlans = (records, name) => {
  const provider = records.find(record => record.name === name);
  if (provider && Array.isArray(provider.payer_plans)) {
    return provider.payer_plans;
  }
};

export const getInitialState = formData => {
  const persistentFields = formData.reduce((accumulator, item) => {
    accumulator[item.id] = {
      type: !!item.tyep,
      memberId: !!item.memberId,
      providerName: !!item.providerName,
      providerCustomName: !!item.providerCustomName,
      planName: !!item.planName,
      planCustomName: !!item.planCustomName,
      groupNumber: !!item.groupNumber,
    };

    return accumulator;
  }, {});

  return {
    items: formData,
    itemsValidity: {},
    persistentFields,
  };
};

export const canAddItem = (items, index) => index === items.length - 1 && items.length < 4;
export const canClearItem = (item, persistentFields) =>
  Object.keys(item).some(key => {
    if (key === 'id') {
      return false;
    }

    const value = item[key];

    return !!value && value !== formDataTemplate[key] && !(persistentFields && persistentFields[key]);
  });

export const hasItemDuplicates = (items, primaryItem) => {
  if (items.length <= 1) {
    return false;
  }

  const hasPrimaryProvider = !!primaryItem.providerName;
  const isNotCustomProvider = primaryItem.providerName === 'OTHER' && !primaryItem.providerCustomName;
  if (!hasPrimaryProvider || isNotCustomProvider) {
    return false;
  }

  const fields = ['type'];

  return items.some(secondaryItem => {
    if (primaryItem.id !== secondaryItem.id) {
      return fields.reduce((accumulator, field) => {
        const areEqual = (primaryItem[field] || '') === (secondaryItem[field] || '');
        return accumulator && areEqual;
      }, true);
    }

    return false;
  });
};

export const hasItemErrors = (item, itemsValidity) => {
  const itemValidity = itemsValidity[item.id];

  return itemValidity && Object.keys(itemValidity).length > 0;
};

export const hasItemValues = item =>
  Object.keys(item).some(key => {
    if (key === 'id') {
      return false;
    }

    const value = (item[key] && item[key]) || '';
    const templateValue = formDataTemplate[key] || '';

    return value !== templateValue;
  });

export const isItemValid = (items, item, itemsValidity) => {
  const hasErrors = hasItemErrors(item, itemsValidity);
  const hasDuplicates = hasItemDuplicates(items, item);

  return !hasErrors && !hasDuplicates;
};

export const areItemsValid = (items, itemsValidity) => {
  const displayItems = items.filter(item => !item.delete);

  return !displayItems.some(item => !isItemValid(displayItems, item, itemsValidity));
};
