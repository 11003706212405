export const activityLog = {
  state: { activityLog: [] },
  reducers: {
    fetchStart: (state, { activityLogScreen }) => ({
      ...state,
      activityLog: activityLogScreen,
    }),
    refetch: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    clearStore: _ => ({ activityLog: [] }),
  },
};
