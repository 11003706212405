import React from 'react';
import { Text } from '../styleguide';

export default props => {
  const {
    registry: {
      fields: { ObjectField },
    },
    schema: { title },
  } = props;

  return (
    <>
      <Text tag="p" text={title} type="subGroupTitle" />
      <div>
        <ObjectField {...props} />
      </div>
    </>
  );
};
