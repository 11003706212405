const state = {};
export const inputDropdown = {
  state,
  reducers: {
    updateValue: (state, key, value) => ({
      ...state,
      [key]: value,
    }),
    resetValues: _ => state,
    resetPaymentDay: state => ({
      ...state,
      dayOfWeek: 7,
      dayOfMonth: 1,
    }),
    saveWeeklyOptions: (state, weeklyOptions) => ({
      ...state,
      weeklyOptions,
    }),
    clearStore: _ => ({ ...state }),
  },
};
