import React, { useEffect, useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { withApollo } from 'react-apollo';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { GetIntelligentScheduler } from '../../../graphql/queries';
import { store } from '../../../models';
import { Button } from '../../styleguide';
import styles from './intelligentScheduler.module.scss';
import { SchemaParser } from '../../services';

const IntelligentScheduler = props => {
  const [selectedBusiness, setSelectedBusinessValue] = useState({
    name: store.getState().radioInput.selectBusinessValue,
    value: store.getState().radioInput.selectBusiness,
  });
  const [intelligentSchedulerSchema, setIntelligentSchedulerSchema] = useState(null);
  const [customTextModalSchema, setCustomTextModalSchema] = useState(null);
  const [autoSchedule, setAutoSchedule] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionsStatus, setQuestionsStatus] = useState([]);
  const [step, setStep] = useState(1);
  const [visitType, setVisitType] = useState({});
  const [answers, setAnswers] = useState([]);

  const history = useHistory();
  const getNextQuestion = question => {
    if (question.length <= 1) {
      setQuestionsStatus(questionsStatus => [...questionsStatus, question[0]]);
    } else {
      setQuestionsStatus(questionsStatus => [...questionsStatus, question]);
    }
    const option = store.getState().intelligentScheduler.answered[
      store.getState().intelligentScheduler.answered.length - 1
    ];
    let currentQuestion = question;
    if (currentQuestion === 0) {
      return null;
    }
    if (!option) {
      setQuestions(question[0]);
      return null;
    }
    if (currentQuestion.length <= 1) {
      // eslint-disable-next-line
      currentQuestion = question[0];
    }
    const idx = currentQuestion[1].components.findIndex(o => {
      return parseInt(o.id.split('_')[0], 10) === option;
    });

    if (idx >= 0) {
      questions[idx + 2].length > 1 ? setQuestions(questions[idx + 2]) : setQuestions(questions[idx + 2][0]);
      return null;
    }
  };

  useEffect(() => {
    store.dispatch.intelligentScheduler.clearAll();
    store.dispatch.addAppointment.clearStore();
    store.dispatch.addAppointment.resetSelectedOptions();
    let isSubscribed = true;
    (async () => {
      try {
        store.dispatch.loader.activateLoader({ loading: true });
        if (isSubscribed) {
          const { data } = await props.client.query({
            query: GetIntelligentScheduler,
            variables: {
              input: {
                practiceId:
                  sessionStorage.getItem('practiceSelected') ||
                  store.getState().layout?.metaData?.practiceInformation[0]?.practice_id,
              },
            },
          });
          const {
            getIntelligentScheduler: { customTextModal, lastAppointmentResources, automaticallyApproveRequests },
          } = data;
          setCustomTextModalSchema(customTextModal);
          setAutoSchedule(automaticallyApproveRequests);
          if (data?.getIntelligentScheduler?.IntelligentSchedulerSchema.length === 0) {
            history.push('/appointments/add_appointment');
          }
          setIntelligentSchedulerSchema(data?.getIntelligentScheduler?.IntelligentSchedulerSchema);
          if (!selectedBusiness.name || !selectedBusiness.value) {
            setSelectedBusinessValue(prevState => ({
              ...prevState,
              name: data?.getIntelligentScheduler?.practice_information?.practice_name,
              value: data?.getIntelligentScheduler?.practice_information?.practice_id,
            }));
          }
          store.dispatch.intelligentScheduler.setLastAppointmentResources(lastAppointmentResources);
          store.dispatch.loader.activateLoader({ loading: false });
          store.dispatch.modal.toggleOpen('customTextModal');
        }
      } catch (error) {
        store.dispatch.loader.activateLoader({ loading: false });
        console.log('Error getting intelligent scheduler response -> ', error);
      }
    })();
    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(intelligentSchedulerSchema)) {
      setQuestions(intelligentSchedulerSchema[2]);
      getNextQuestion([intelligentSchedulerSchema[2][0], intelligentSchedulerSchema[2][1]]);
    }
  }, [intelligentSchedulerSchema]);

  useEffect(() => {
    const { visitType } = store.getState().intelligentScheduler;
    setVisitType(visitType);
  });

  const handleNext = () => {
    if (!isEmpty(visitType)) {
      if (
        !isEmpty(store.getState().intelligentScheduler.lastAppointmentResources) &&
        !isEmpty(store.getState().intelligentScheduler.schedulerResourceSelection)
      ) {
        const {
          providersameaslast,
          locationsameaslast,
        } = store.getState().intelligentScheduler.schedulerResourceSelection;
        const {
          location,
          provider,
          resource_id,
          patient_id,
          practice_id,
          practice_mgmt,
        } = store.getState().intelligentScheduler.lastAppointmentResources;
        const selectedLocation = {
          locationId: location.id,
          addressLine1: location.address.line1,
          addressLine2: location.address.line2,
          addressLine3: location.address.line3,
          city: location.address.city,
          stateName: location.address.state_name,
          zip: location.address.zip_code,
          phone: location.phones.find(item => item.is_primary)?.phone_number,
          office: location.name,
          locationGuid: location.guid,
        };
        const selectedProvider = {
          resourceId: resource_id,
          practiceId: practice_id,
          patientId: patient_id,
          practiceMgmt: practice_mgmt,
          visitReasonId: store.getState().intelligentScheduler.visitType.id,
          providerId: provider.id,
          specialty: provider.speciality?.name,
          provider: {name: provider.name, photo: provider.photo, photo_metadata: provider.photo_metadata},
          name: provider.name,
          autoSchedule,
        };
        if (providersameaslast && locationsameaslast) {
          store.dispatch.addAppointment.selectLocation(selectedLocation);
          store.dispatch.addAppointment.setProvider(selectedProvider);
          history.push('/appointments/add_appointment/available_hours');
        } else if (providersameaslast) {
          store.dispatch.addAppointment.setProvider(selectedProvider);
          history.push('/appointments/add_appointment');
        } else if (locationsameaslast) {
          store.dispatch.addAppointment.selectLocation(selectedLocation);
          history.push('/appointments/add_appointment');
        } else {
          history.push('/appointments/add_appointment');
        }
      } else {
        history.push('/appointments/add_appointment');
      }
    } else {
      getNextQuestion(questions);
    }
    const answer = questions[1].components.find(option =>
      option.id.includes(store.getState().intelligentScheduler.answered[step - 1]),
    );
    setAnswers([...answers, { question: questions[0].label, answer: answer.label }]);
    setStep(step + 1);
  };
  const handleBack = () => {
    // Load new question for schema Parser
    setQuestionsStatus(questionsStatus => [...questionsStatus.slice(0, questionsStatus.length - 1)]);
    setQuestions(questionsStatus[step - 1]);

    // Pop intelligentScheduler.answer from redux
    // Removing current answer and previous answer if answer is selected on current screen and then back is pressed
    // Removing previous answer only if answer is not selected on current screen and back is pressed
    if (step === store.getState().intelligentScheduler.answered.length) {
      store.dispatch.intelligentScheduler.popAnswered();
    }
    store.dispatch.intelligentScheduler.popAnswered();

    // Pop Answer from tally Answer section
    setAnswers([...answers.slice(0, answers.length - 1)]);

    // remove visit type if user was on last question
    setVisitType({});
    store.dispatch.intelligentScheduler.setVisitType({});

    // Step--
    setStep(step => step - 1);
  };
  const handleCancel = () => {
    store.dispatch.modal.toggleOpen('CancelIntelligentSchedulerModal');
  };

  return (
    <div className={styles.schedulerWrapper}>
      {questions.length > 0 && (
        <>
          <h4>Intelligent Scheduler</h4>
          {selectedBusiness.name && <h6>Practice: {selectedBusiness.name}</h6>}
          <div className={styles.schedulerContainer}>
            <div className={classNames(styles.scheduler, styles.schedulerQuestions)}>
              <>
                <SchemaParser schema={[customTextModalSchema]} />
                <SchemaParser schema={[intelligentSchedulerSchema[0], intelligentSchedulerSchema[1]]} />
                <SchemaParser schema={[questions[0], questions[1]]} />
              </>
              <div className={styles.buttonWrapper}>
                <Button text="Back" primary main disabled={step === 1} onClick={_ => handleBack()} />
                <Button text="Cancel" primary main onClick={_ => handleCancel()} />
                <Button
                  text={`${!isEmpty(visitType) ? 'Continue' : 'Next'}`}
                  primary
                  main
                  disabled={store.getState().intelligentScheduler.answered.length < step}
                  onClick={_ => handleNext()}
                />
              </div>
            </div>
            <div className={classNames(styles.scheduler, styles.schedulerAnswers)}>
              <h3>Answers</h3>
              <div className={styles.tally}>
                {answers.map(tally => (
                  <div>
                    <h6 className={styles.tallyQuestion}>{tally.question}</h6>
                    <h6 className={styles.tallyAnswer}>{tally.answer}</h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = ({ radioInput }) => ({
  radioInput,
});

const enhancer = compose(withApollo, withRouter, connect(mapStateToProps));
export default enhancer(IntelligentScheduler);