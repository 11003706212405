import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import {
  isEmpty,
  get,
  compact,
  set,
  isEqual,
  compare,
} from '@carecloud/cloudpak';
import classNames from 'classnames';
import { store } from '../../../models';
import * as Mutations from '../../../graphql/mutations';
import { FormGroup } from './FormGroup';
import { SchemaParser } from '../../services';
import { parseParams } from '../../services/routing';
import styles from './FormGroup.module.scss';

class FormGroupContainer extends Component {
  constructor(props) {
    super(props);
    const { required, groups, dependentButtons } = this.props.props;
    store.dispatch.form.resetFields();
    store.dispatch.formGroup.resetEvents();
    const parsedParams = parseParams(this.props.props);
    const fieldValues = isEmpty(parsedParams) ? {} : {
      signupInputEmail: parsedParams.em,
      signupInvitationId: parsedParams.i,
    };
    this.state = {
      formGroup: groups,
      required,
      changedFields: [],
      fieldValues,
      completed: [],
      removed: [],
      invalidFields: [],
    };
    const data = [];
    groups.forEach(
      ({ additionalSubmissionData }) =>
        additionalSubmissionData && data.push(...additionalSubmissionData),
    );
    data.forEach(({ id, value, formMapping }) => {
      store.dispatch.formGroup.change({
        id,
        value,
        fieldValid: true,
        formMapping,
        isInitial: false,
      });
    });

    dependentButtons &&
      dependentButtons.forEach(
        btn => store.dispatch.button.enableButton({ btn, complete: false }),
        // eslint-disable-next-line function-paren-newline
      );
    this.checkFormComplete({
      required,
      completed: this.state.completed,
      changedFields: [],
      invalidFields: [],
    });
  }

  componentDidUpdate(prevProps) {
    const {
      id,
      formGroup: { events },
      formGroup,
    } = this.props.props;
    const { fieldValues, completed } = this.state; // Add this line
    if (!compare(events, prevProps.props.formGroup.events)) {
      !isEmpty(events) && this.onChange({ events, formGroup });
    }
    if (formGroup.submit === id) {
      store.dispatch.loader.activateLoader({ loading: true });
      this.handleSubmit(id);
      store.dispatch.formGroup.submitForm(null);
    }
    const ViewButton = document.getElementById('View_CC_TBI_CreateVisitSummary');
    if (fieldValues.createVisitSummaryFormat === 'PDF' && ViewButton && completed.length >= 4) {
      document.getElementById('View_CC_TBI_CreateVisitSummary').disabled = false;
    } else if (ViewButton) {
      document.getElementById('View_CC_TBI_CreateVisitSummary').disabled = true;
    }
  }
  onChange = ({ events, formGroup: { fields } }) => {
    const { completed, required, fieldValues, invalidFields } = this.state;
    const {
      props: {
        redux,
        submitWholeForm,
        makePayments: { isValid },
      },
    } = this.props;
    // As the form is being filled out, save the field values in local state or redux if set as true.
    const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
    events.forEach(
      ({ id, value, fieldValid = true, paymentValid = true, isInitial }) => {
        if (redux && fields[id] !== value && fieldValid && !isInitial) {
          store.dispatch.formGroup.storeInRedux({ id, value });
          !isValid &&
            paymentValid &&
            store.dispatch.makePayments.updateValid(true);
          !paymentValid &&
            isValid &&
            store.dispatch.makePayments.updateValid(false);
        } else if (redux && fields[id] && !fieldValid && !isInitial) {
          store.dispatch.formGroup.storeInRedux({ id, value: null });
          !paymentValid &&
            isValid &&
            store.dispatch.makePayments.updateValid(false);
        }
        const exists = value !== undefined;
        const isDifferent = this.state.fieldValues[id] !== value;
        if (exists && isDifferent) {
          fieldValues[id] = value;
        }
        if (
          value &&
          !completed.includes(id) &&
          required.includes(id) &&
          fieldValid
        ) {
          completed.push(id);
        } else if (!value || !fieldValid) {
          if (completed.includes(id)) {
            completed.splice(completed.indexOf(id), 1);
          }
        }
        if (fieldValues.visitSummaryEmailUnsecured === true && practiceMgmt ==='talkehr') {

          if (fieldValues.visitSummaryEmail && fieldValues.visitSummaryEmail !== '') {
            const indexToRemove = invalidFields.indexOf('visitSummaryEmail');
            if (indexToRemove > -1) {
              invalidFields.splice(indexToRemove, 1);
            }
          }
        } else {
          const index = invalidFields.findIndex(field => field === id);
          if (fieldValid && index > -1) {
            invalidFields.splice(index, 1);
          } else if (!fieldValid && index === -1) {
            invalidFields.push(id);
          }
        }
        if (fieldValues.visitSummaryEmail2 !== "" && practiceMgmt ==='vertex' && fieldValues.visitSummaryEmailUnsecured !== true ) {
        const domain = fieldValues?.visitSummaryEmail2?.split('@')[1]; // Extracting the domain part
        const wordToCheck = 'direct';
        if (domain?.includes(wordToCheck?.toLowerCase())) {
        store.dispatch.button.enableButton({
        btn: 'exportCreateVisitSummary',
        complete:true,
        });
        } else {
        store.dispatch.button.enableButton({
        btn: 'exportCreateVisitSummary',
        complete:false,
        });
        }
        }else if(fieldValues.visitSummaryEmail2 !== "" && practiceMgmt ==='vertex' && fieldValues.visitSummaryEmailUnsecured === true ){
        store.dispatch.button.enableButton({
        btn: 'exportCreateVisitSummary',
        complete:true,
        });
        }else if(fieldValues.visitSummaryEmail2 !== "" && practiceMgmt ==='vertex' ){
          store.dispatch.button.enableButton({
            btn: 'exportCreateVisitSummary',
            complete:false,
            });
        }
        const buttonElement = document.getElementById('eyeIcon');
        const buttonElement2 = document.getElementById('eyeIcon2');
        if(buttonElement && buttonElement2){
          const imgElement = buttonElement.querySelector('img');
          const imgElement2 = buttonElement2.querySelector('img');
          if(fieldValues.newPassword === ''){
            imgElement.style.display = 'none';
            buttonElement.style.pointerEvents = 'none'
          }else{
            imgElement.style.display = 'block';
            buttonElement.style.pointerEvents = 'all'
          }
          if(fieldValues.newPasswordRepeat === ''){
            imgElement2.style.display = 'none';
            buttonElement2.style.pointerEvents = 'none'
          }else{
            imgElement2.style.display = 'block';
            buttonElement2.style.pointerEvents = 'all'
          }
        }
      },
    );
    this.setState({ fieldValues, completed, invalidFields }, () => {
      const changedFields = Object.keys(fieldValues).filter(key => {
        if (submitWholeForm) return submitWholeForm;
        const fields = events.filter(({ id }) => id === key);
        let changed = false;
        if (fields.length > 1) {
          changed = !isEqual(fields[0].value, fields[1].value);
        } else changed = fields[0] && !fields[0].isInitial;
        return changed;
      });
      this.setState({ changedFields }, () => {
        this.checkFormComplete({
          required,
          completed,
          changedFields,
          invalidFields,
        });
      });
    });
  };

  getChangeValue = ({ formGroupId, fieldId }) => {
    const uniqueIdNumber = formGroupId.match(/\d+/g);
    let key = fieldId;
    if (uniqueIdNumber) {
      key = fieldId + uniqueIdNumber[0];
    }
    return this.state.fieldValues[key];
  };

  buildGroupComponent = ({
    group: {
      id,
      fields = [],
      dependentFields,
      repeatable,
      anchored,
      addMoreLabel,
      clearLabel,
      removeLabel,
      subHeading,
      rightSideText,
      resetValues,
    },
  }) => {
    // const { classes } = this.props;
    let numberVisibleFields = 0;
    return (
      <div key={id}>
        <div className={styles.formWrapper}>
          <div className={styles.leftSideWrapper}>
            <div className={classNames({ [styles.subHeading]: subHeading })}>
              <SchemaParser schema={subHeading} />
            </div>
            <form id={id}>
              {fields.map((field, i) => {
                numberVisibleFields += 1;
                const key = `${id}-${i}`;
                return (
                  <div
                    key={key}
                    className={classNames({
                      // eslint-disable-next-line object-curly-newline
                      [styles.multiInput]: field.row.length > 1,
                    })}
                  >
                    <SchemaParser
                      schema={field.row.map(row => ({
                        ...row,
                        formGroupId: id,
                        changeValue: this.getChangeValue({
                          formGroupId: id,
                          fieldId: row.id,
                        }),
                        resetValues,
                      }))}
                    />
                  </div>
                );
              })}
              {dependentFields &&
                dependentFields.map(dependentField => {
                  const fieldValues = this.state.fieldValues[
                    dependentField.field
                  ];
                  let fields = null;
                  const {
                    clearOnChange,
                    fieldValues: { value },
                  } = dependentField;
                  const fieldValuesExists =
                    fieldValues !== undefined && fieldValues !== null;
                  const valuesMatch =
                    value === null || value.includes(fieldValues);
                  if (fieldValuesExists && valuesMatch) {
                    fields = dependentField.fieldValues.fields.map(field => {
                      numberVisibleFields += 1;
                      return (
                        <div
                          key={field.id}
                          className={classNames({
                            // eslint-disable-next-line object-curly-newline
                            [styles.multiInput]: field.row.length > 1,
                          })}
                        >
                          <SchemaParser
                            schema={field.row.map(row => ({
                              ...row,
                              formGroupId: id,
                              changeValue: this.getChangeValue({
                                formGroupId: id,
                                fieldId: row.id,
                              }),
                              resetValues,
                              clearOnUnmount: clearOnChange,
                            }))}
                          />
                        </div>
                      );
                    });
                  }
                  return fields;
                })}
            </form>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {repeatable && numberVisibleFields > 0 && (
                <>
                  <div
                    onClick={this.remove({ id, anchored })}
                    role="menuItem"
                    tabIndex="0"
                    className={styles.remove}
                  >
                    {anchored ? clearLabel : removeLabel}
                  </div>
                  <div
                    onClick={this.addMore({ id })}
                    role="menuItem"
                    tabIndex="0"
                    className={styles.addMore}
                  >
                    {addMoreLabel}
                  </div>
                </>
              )}
            </div>
          </div>
          {!isEmpty(rightSideText) && (
            <>
              <div className={styles.divider} />
              <SchemaParser schema={rightSideText} />
            </>
          )}
        </div>
      </div>
    );
  };
  // Validates multiple input fields based on condition specified in schema
  multiFieldValidator = ({ fields, condition, options }) => {
    const { fieldValues } = this.state;
    if (!isEmpty(fieldValues)) {
      const validations = {
        MATCH: fields => fieldValues[fields[0]] === fieldValues[fields[1]],
        OR: fields => fields.some(field => this.hasValue(fieldValues[field])),
        COMPLETION_EQUAL: fields =>
          fields.every(field => !!fieldValues[field]) ||
          fields.every(field => !fieldValues[field]),
        CONDITIONALLY_REQUIRED: fields => {
          const { id, values } = options.drivingField;
          if (values.includes(this.state.fieldValues[id])) {
            return fields.every(field => this.hasValue(fieldValues[field]));
          }
          return true;
        },
      };
      if (!validations[condition]) return true;
      return validations[condition](fields);
    }
    return false;
  };

  hasValue = value => {
    if (Array.isArray(value)) {
      return value.some(item => item.action !== 'delete');
    }
    const typeMap = {
      boolean: value,
      undefined: false,
      number: !!`${value}`,
      string: !!`${value}`,
      object: !isEmpty(value),
    };
    const type = typeof value;
    return typeMap[type];
  };
  // Reducer for mutliFieldValidations.reduce in checkFormComplete.
  reducer = (acc, currentValue) =>
    acc && this.multiFieldValidator(currentValue);

  checkFormComplete = ({
    required,
    completed,
    changedFields,
    invalidFields,
  }) => {
    const { multiFieldValidations, submittableEmpty, submittableFields } = this.props.props;
    let valid = true;
    if (multiFieldValidations) {
      // Use reducer and multiFieldValidator to determine whether the validation passes/fails
      valid = multiFieldValidations.reduce(this.reducer, true);
    }
    const complete =
      (changedFields.length > 0 || submittableEmpty) &&
      valid &&
      required &&
      required.length === completed.length &&
      // If a form doesn't have any required fields then the form is only complete when changed fields are of the same form (submittable fields of that form)
      (changedFields.length > 0 && submittableFields.length > 0 && changedFields.filter(element => submittableFields.includes(element)).length) &&
      invalidFields.length === 0;
    const {
      // eslint-disable-next-line object-curly-newline
      props: { id, updateForm, dependentButtons, formGroup },
    } = this.props;
    if (complete && updateForm) {
      updateForm(
        Object.assign(
          {},
          ...changedFields.map(field => ({
            // eslint-disable-next-line object-curly-newline
            [field]: this.state.fieldValues[field],
          })),
        ),
      );
    }
    if (complete !== formGroup[id] && !this.state.removed.includes(id)) {
      // Store the form(s) id and completed status;
      store.dispatch.formGroup.changeCompleted({ id, complete });
      // IF dependentButtons exist, THEN store the button(s) and their completed status.
      dependentButtons &&
        dependentButtons.forEach(btn => {
          if (complete !== !!this.props.props.button[btn]) {
            store.dispatch.button.enableButton({ btn, complete });
          }
        });
    }
  };
  remove = ({ id, anchored }) => _ => {
    const formGroupId = this.props.props.id;
    const formGroup = Object.assign([], this.state.formGroup);
    const removedGroupIndex = formGroup.findIndex(this.findById(id));
    let { deleteableFormMapping } = this.state.formGroup[removedGroupIndex];
    const anchoredGroup = formGroup.splice(removedGroupIndex, 1)[0];
    this.setState(
      { formGroup, removed: this.state.removed.concat(formGroupId) },
      () => {
        if (anchored) {
          this.addMore({ id, anchored, anchoredGroup })();
        }
      },
    );
    if (deleteableFormMapping) {
      const idNumber = id.match(/\d+/g) ? id.match(/\d+/g)[0] : 0;
      deleteableFormMapping = deleteableFormMapping.replace(
        /(\[\*\])/,
        `[${idNumber}]`,
      );
      store.dispatch.formGroup.deleteGroup({
        deleteableFormMapping,
        formGroupId,
      });
    }
  };
  addMore = ({ id, anchored = false, anchoredGroup = {} }) => _ => {
    const baseId = id.match(/[A-z]+/g)[0];
    const newId = this.generateId({ baseId, anchored });
    const group = {
      ...anchoredGroup,
      ...this.state.formGroup.find(this.findById(id)),
      id: newId,
      resetValues: true,
      anchored,
    };
    const index = this.state.formGroup.findIndex(this.findById(id));
    const formGroup = Object.assign([], this.state.formGroup);
    formGroup.splice(
      anchored ? this.state.formGroup.length : index + 1,
      0,
      group,
    );
    this.setState({ formGroup });
  };
  findById = id => item => item.id === id;
  generateId = ({ baseId, anchored }) => {
    const currentIds = this.state.formGroup.map(group => group.id);
    let newId = anchored
      ? baseId +
        Date.now()
          .toString()
          .slice(-7)
      : baseId;
    while (currentIds.includes(newId)) {
      newId =
        baseId +
        Date.now()
          .toString()
          .slice(-7);
    }
    return newId;
  };
  // Handles form submission based on form's id
  handleSubmit = async id => {
    const {
      props: {
        form: { fields },
        formGroup: { events },
        contentRefresh,
      },
    } = this.props;
    try {
      let inputObject = {};
      let arrayPaths = [];
      Object.keys(fields).forEach(field => {
        const found = events.find(this.findEventsToSubmit(field));
        if (!found || !found.formMapping) return;
        inputObject = set(inputObject, found.formMapping, found.value, {
          // eslint-disable-next-line object-curly-newline
          mutate: true,
        });
        if (found.formMapping.match(/(\[[\d]+\])/)) {
          arrayPaths.push(found.formMapping.split(/(\[[\d]+\])/)[0]);
        }
      });
      arrayPaths = arrayPaths.filter(
        (value, index, arr) => arr.indexOf(value) === index,
      );
      arrayPaths.forEach(path => {
        let value = get(inputObject, path);
        value = compact(value);
        inputObject = set(inputObject, path, value, { mutate: true });
      });
      if (id === 'changeEmail'){
        inputObject.loginEmail = sessionStorage.getItem('CC-Breeze-Web-username') || sessionStorage.getItem('CC-Breeze-Web-email')
      }
      if (id === 'changePassword'){
        inputObject.deviceIdentifier = localStorage.getItem('device_identifier') || 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
      }
      const { data } = await this.props.client.mutate({
        // Calls the mutation based on form's id
        mutation: Mutations[id],
        // Dynamically inserts form field values as variables
        variables: { input: inputObject },
      });
      if (data[Object.keys(data)[0]] === null) throw new Error(data);
      if (data.changeEmail && data.changeEmail.authenticationToken) {
        sessionStorage.setItem(
          'CC-Breeze-Web-authenticationToken',
          data.changeEmail.authenticationToken,
        );
      }
      contentRefresh.forEach(model => {
        store.dispatch[model].resetState();
      });
      store.dispatch.loader.persistLoader({ persist: false });
      store.dispatch({
        type: 'toast/open',
        payload: data[Object.keys(data)[0]],
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  findEventsToSubmit = field => event => {
    const { submittableFields } = this.props.props;
    const submittable =
      isEmpty(submittableFields) || submittableFields.includes(event.id);
    return event.id === field && !event.isInitial && submittable;
  };
  render() {
    const { required, completed, changedFields, invalidFields } = this.state;
    this.checkFormComplete({
      required,
      completed,
      changedFields,
      invalidFields,
    });
    return (
      !this.props.props.formGroup.formGroupsToNotShow.includes(
        this.props.props.id,
      ) && (
        <FormGroup
          props={{
            ...this.props.props,
            groups: this.state.formGroup,
            classes: this.props.classes,
            required: this.state.required,
            addMore: this.addMore,
            completed: this.props.props.formGroup[this.props.props.id],
            buildGroupComponent: this.buildGroupComponent,
            styles,
          }}
        />
      )
    );
  }
}

export default withApollo(FormGroupContainer);
