import React from 'react';
import styles from './Text.module.scss';

export default React.memo(props => {
  const { tag, text, type, icon } = props;

  const TextTag = tag;

  return (
    <TextTag className={styles[type]}>
      {text}
      {icon && <i className="material-icons">done</i>}
    </TextTag>
  );
});
