import { Image } from '..';
import styles from './header.module.scss';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { NAVBAR_TEXTS } from '../../../constants/Routes';

const header = React.memo(({ id, type, label, icon, theme, format }) => {
  const Tag = type.toLowerCase();
  const isNavPageTitle = id === 'navPageTitle';
  let textToShow = '';
  if (isNavPageTitle) {
    const location = useLocation();
    const matchedNav = NAVBAR_TEXTS.find(nav => {
      const normalizedPathname = location.pathname.replace(/\/\d+$/, ''); // Remove trailing numbers
      return nav.page === normalizedPathname;
    });
    if (matchedNav) {
      textToShow = matchedNav.text;
    }
  }
  return (
    <div id={id} className={classNames(styles.headerIcon, styles[theme])}>
      {icon ? <Image {...{ src: icon, alt: label }} /> : null}
      <Tag className={styles.tag} id={`${id}1:${Math.random()}`}>
        {format &&
          moment(label)
            .local()
            .format(format)}
        {isNavPageTitle ? textToShow : !format && label}
      </Tag>
    </div>
  );
});

export const Header = header;
