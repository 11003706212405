export const dashboard = {
  state: {
    dashboardScreen: {},
    userAvatar: {},
    twofaPopup: false,
  },
  reducers: {
    fetchStart: (state, { dashboardScreen, userAvatar, metaData }) => ({
      ...state,
      dashboardScreen,
      userAvatar,
      metaData,
    }),
    showTwofaPopup: (state, param) => ({ ...state, twofaPopup: param}),
    resetState: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    clearStore: _ => ({ ...dashboard.state }),
  },
};
