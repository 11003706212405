import PropTypes from 'prop-types';
import React,{useState} from 'react';
import { isEmpty, get } from '@carecloud/cloudpak';
import classNames from 'classnames';
import SchemaParser from '../../services/SchemaParser';
import styles from './appointment.module.scss';
import { PENDING_REQUEST_MSG } from '../../../constants/DisplayMessage';
import { CHECKED_IN, REQUESTED, REQUESTING } from '../../../constants/AppointmentStatuses';
import LinearLoader from '../LinearLoader/linearLoader';

const appointment = React.memo(
  ({
    status,
    providerName,
    specialty,
    avatar,
    buttons,
    button,
    office,
    address,
    phone,
    apptDate,
    apptTime,
    id,
    overlayiOS,
    overlayAndroid,
    appointments: { showOverlay, iOSOverlay },
    cancelModal,
    apptIndicator,
    visitTypeLabel,
    visitType,
    labels,
    handleVisitReason,
    topButtons,
    bottomButtons,
    pollingButtonId,
    comment,
    practice_management,
    rescheduleBtnDisplayText,
  }) =>{
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(!isHovered);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const practiceMgmt = JSON.parse(sessionStorage.getItem('Patient-Ids-List'))?.[0]?.practice_mgmt;
  return (
    <div id={id} className={styles.wrapper}>
      {!isEmpty(topButtons) ? (
        <div className={classNames(styles.topButtonsWrapper, { [styles.noVisibility]: pollingButtonId === id })}>
          <SchemaParser schema={topButtons} />
        </div>
      ) : (
        <div className={styles.topButtonsPlaceHolder} />
      )}
      <div
        className={classNames(
         practice_management ==="vertex"? styles.apptBox_for_vertex :styles.apptBox,
          {
            [styles.mediumHeight]: !isEmpty(get(buttons, '[0].components', [])) && visitType,
          },
          { [styles.largeHeight]: status === REQUESTING && visitType },
        )}
      >
        <div
          className={classNames(styles.heading, {
            // eslint-disable-next-line object-curly-newline
            [styles.headingCheckout]: status === CHECKED_IN,
            [styles.headingRequesting]: status === REQUESTED,
          })}
        >
          {pollingButtonId === id ? (
            <LinearLoader />
          ) : (
            <div className={classNames(styles.indicator, status.toLowerCase())} />
          )}
          <div className={status === REQUESTED ? styles.dateRequested : styles.date}>{apptDate()}</div>
          <div
            className={classNames(styles.time, {
              // eslint-disable-next-line object-curly-newline
              [styles.timeRequested]: status === REQUESTED,
              [styles.timeWhite]: status === CHECKED_IN,
            })}
          >
            {apptTime()}
          </div>
        </div>
        <div className={styles.section}>
          <SchemaParser schema={[avatar]} />
          {apptIndicator()}
          <div className={classNames(styles.providerName, status.toLowerCase())}>{providerName}</div>
          <div className={styles.specialty}>{specialty}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.officeName}>{office}</div>
          <div className={styles.address}>{address.line1}</div>
          <div className={styles.address}>{address.line2}</div>
          <div className={styles.address}>{phone}</div>
        </div>
        {visitType && (
          <div
            className={classNames(styles.visitTypeSection, {
              [styles.noBorderBottom]: isEmpty(get(buttons, '[0].components', [])) && status !== REQUESTING,
            })}
          >
            <div className={styles.visitTypeLabel}>{visitTypeLabel}</div>
            <div className={styles.visitType}>{visitType}</div>
          </div>
        )}
        {comment && practice_management ==="vertex" && (
          <div className={classNames(styles.commentTitle)}>
            <p>Appointment comment </p>
          </div>
        )}
        {comment && practice_management ==="vertex" && (
          <div className={classNames(styles.commentsection)}>
            <p>{comment.slice(0,35)}</p>
            {comment.length > 35 &&
            (
            <button
              onClick={handleMouseEnter}
            >More..
            </button>
            )
            }
          </div>
        )}
        {status === REQUESTING && practiceMgmt !=='vertex' &&(
          <div className={styles.visitReasonSection}>
            <div className={styles.visitReasonLabel}>{labels.reasonForVisit}</div>
            <textarea
              className={styles.visitReasonText}
              placeholder={labels.reasonPlaceholder}
              onChange={handleVisitReason}
              maxLength="100"
            />
          </div>
        )}

        {status !== REQUESTED ? (
          buttons && (
            <div>
              <div
                className={classNames(
                  (practice_management ==="vertex"&&comment)?styles.buttons_for_vertex:styles.buttons,
                  { [styles.buttonsWithVisitType]: visitType },
                  { [styles.disabled]: !button[Object.keys(button)[0]] },
                )}
              >
                <SchemaParser schema={buttons} />
              </div>
              <div
                className={classNames(
                  styles.buttons,
                  { [styles.buttonsWithVisitType]: visitType },
                  { [styles.disabled]: !button[Object.keys(button)[0]] },
                )}
              >
                <SchemaParser schema={bottomButtons} />
              </div>
              <div className={classNames(styles.useForRequestCancelAppointmentLabel)}>{rescheduleBtnDisplayText}</div>
            </div>
          )
        ) : (
          <div className={styles.pendingRequestMessage}>{PENDING_REQUEST_MSG}</div>
        )}
      </div>
      {showOverlay === id && (
        <div className={classNames(styles.wrapperOverlay)}>
          {<SchemaParser schema={[iOSOverlay ? overlayiOS : overlayAndroid]} />}
        </div>
      )}
      {!isEmpty(cancelModal) && <SchemaParser schema={[cancelModal]} />}
      {/* Popup Content */}
      {isHovered && (
        <div className={styles.popup}>
          <p className={styles.appointmentHeading}> Appointment comment <button className={styles.cross_Button} onClick={handleMouseLeave} /> </p>
          <div className={styles.commentContainer}>
            <p className={styles.commentText}>{comment}</p>
          </div>
        </div>
        )}
    </div>
  )},
);
appointment.propTypes = {
  status: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  specialty: PropTypes.string.isRequired,
  dateTime: PropTypes.string,
  message: PropTypes.string,
  office: PropTypes.string.isRequired,
  phone: PropTypes.string,
  indicatorIcon: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.object),
};

export const Appointment = appointment;
