import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import styles from './partialPayment.module.scss';
import { formatMoney } from '../../services/utilities';
import { Payments } from '../../../constants/index';
import { PartialPayment } from './partialPayment';

class PartialPaymentContainer extends PureComponent {
  componentWillReceiveProps({
    partialPayment: { displayInput },
    radioInput: { paymentType },
    paymentPlan,
  }) {
    const {
      resetInput,
      updateValid,
      toggleError,
      makePayments: { isValid },
      radioInput,
      paymentPlan: { currentPlan },
      partialPayment: { error },
    } = this.props;
    if (
      radioInput.paymentType !== paymentType &&
      (displayInput.length || error.display)
    ) {
      resetInput();
      updateValid(false);
      toggleError(false);
    } else if (
      paymentType === Payments.PARTIAL_PAYMENT &&
      isValid &&
      !displayInput.length
    ) {
      updateValid(false);
    }
    if (+displayInput.replace(/[^\d.]/g, '')) {
      if (
        currentPlan &&
        paymentPlan.currentPlan &&
        currentPlan.id !== paymentPlan.currentPlan.id
      ) {
        this.validator({
          isBlur: false,
          input: +displayInput.replace(/[^\d.]/g, ''),
          switchPlan: true,
          newTotal:
            paymentPlan.option === Payments.NEW
              ? false
              : paymentPlan.currentPlan.originalAmount,
        });
      }
    }
  }
  componentWillUnmount() {
    const { resetInput, updateValid, toggleError } = this.props;
    resetInput();
    updateValid(false);
    toggleError(false);
  }
  handleChange = event => {
    const {
      updateInput,
      updateDisplayInput,
      amount,
      option,
      validation,
    } = this.props;
    // clean input, dont allow more than 2 decimals places
    const value = event.target.value.replace(/[^\d.]/g, '');
    const regex = validation.value;
    const reg = new RegExp(regex);
    if (reg.test(value)) {
      if (option !== Payments.PAYMENT_PLAN) {
        value <= amount ? updateInput(value) : updateInput('-');
      }
      value ? updateDisplayInput(value) : updateDisplayInput('');
      this.validator({ input: value });
    }
  };
  numberFormatter = numberValid => {
    const {
      partialPayment: { displayInput },
      updateDisplayInput,
      updateInput,
      option,
    } = this.props;
    const formattedNum = formatMoney({
      amount: parseFloat(displayInput.replace(/[^\d.]/g, '')),
    });
    option !== Payments.PAYMENT_PLAN &&
      numberValid &&
      updateInput(formattedNum);
    updateDisplayInput(formattedNum);
  };
  validator = ({ input, isBlur, switchPlan, newTotal }) => {
    const {
      amount,
      minimumPayment,
      validation: { value },
      maxLimit,
      updateValid,
      paymentPlan: { currentPlan, settings },
      radioInput: { paymentType },
      partialPayment: { displayInput },
    } = this.props;
    let minimum = minimumPayment;
    if (
      (currentPlan || switchPlan) &&
      (newTotal ||
        isBlur ||
        displayInput.length ||
        (!isBlur && +displayInput.replace(/[^\d.]/g, ''))) &&
      paymentType === Payments.PAYMENT_PLAN
    ) {
      if (switchPlan) {
        minimum = minimumPayment - newTotal;
      } else {
        minimum -= currentPlan.originalAmount;
      }
      if (minimum < 0) {
        minimum = 0.01;
      }
    }
    let number = input;
    if (isBlur) number = input.target.value.replace(/[^\d.]/g, '');
    if (!switchPlan) number = formatMoney({ amount: number });
    const reg = new RegExp(value);
    let numberValid =
      +number <= (maxLimit || amount) && +number >= minimum && reg.test(number);
    if (
      paymentType === Payments.PAYMENT_PLAN &&
      settings.add_balance_to_existing &&
      +number > minimum &&
      +number < (maxLimit || amount)
    ) {
      numberValid = true;
    }
    paymentType === Payments.PARTIAL_PAYMENT && updateValid(numberValid);
    this.errorCheck({ numberValid, number, isBlur, minimum });
  };
  errorCheck = ({ numberValid, number, isBlur = false, minimum }) => {
    const {
      partialPayment: { displayInput },
      toggleError,
      validation: {
        errorMessage: { amountBetween, and, amount },
      },
    } = this.props;
    const paymentPlanErr = `${amountBetween} $${formatMoney({
      amount: minimum,
    })} ${and} ${amount}`;
    toggleError({
      display: !numberValid && (isBlur || number.length > 1 || number > 1),
      paymentPlanErr,
    });
    if (displayInput && isBlur) this.numberFormatter(numberValid);
  };
  render() {
    const {
      radioInput: { paymentType },
      partialPayment: { isValid, error, displayInput },
    } = this.props;
    return (
      <PartialPayment
        {...{
          ...this.props,
          styles,
          paymentType,
          error,
          isValid,
          displayInput,
          handleChange: this.handleChange,
          validator: this.validator,
        }}
      />
    );
  }
}

const mapStateToProps = ({
  radioInput,
  partialPayment,
  makePayments,
  paymentPlan,
  inputDropdown,
}) => ({
  radioInput,
  partialPayment,
  makePayments,
  paymentPlan,
  inputDropdown,
});
const mapDispatchToProps = ({ partialPayment, makePayments }) => ({
  updateInput: partialPayment.updateInput,
  updateDisplayInput: partialPayment.updateDisplayInput,
  updateValid: makePayments.updateValid,
  toggleError: partialPayment.toggleError,
  resetInput: partialPayment.resetInput,
});

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));
export default enhancer(PartialPaymentContainer);
