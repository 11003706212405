export const form = {
  state: { fields: {}, submit: false, Field:{} },
  reducers: {
    updateForm: (state, fields) => ({
      ...state,
      fields: { ...state.fields, ...fields },
    }),
    toggleSubmit: state => ({
      ...state,
      submit: !state.submit,
    }),
    resetFields: state => ({
      ...state,
      fields: {},
    }),
    addExternalSubmissionData: (state, externalSubmissionData) => ({
      ...state,
      externalSubmissionData,
    }),
    setFields: (state, Field) => ({
      ...state,
      Field: { ...state.Field, ...Field },
    }),
  },
};
