import React from 'react';
import { Image } from '../../ui';
import styles from './ErrorPage.module.scss';

const errorPage = ({ props: { clickHandler } }) => (
  <div className={styles.errorContainer}>
    <div className={styles.errorDetails}>
      <Image
        {...{
          id: 'breezeErrorLogo',
          theme: 'breezeErrorLogo',
          src: 'https://assets.gobreeze.com/error-displaced-logo.svg',
          alt: 'breeze-error-logo',
        }}
      />
      <h1> Our system is currently experiencing issues. </h1>
      <h3>
        {' '}
        We&#39;re sorry it wasn&#39;t a breeze getting to the page you&#39;re
        looking for. Don&#39;t worry, though! We are working quickly to resolve
        it.{' '}
      </h3>
      <button
        id="errorPageRetryBtn"
        className={styles.errorPageRetryBtn}
        type="button"
        onClick={clickHandler}
      >
        {' '}
        Try Again
      </button>
    </div>
    <Image
      {...{
        id: 'breezeErrorFooterLogo',
        theme: 'breezeErrorFooterLogo',
        src: 'https://assets.gobreeze.com/Footer_Logo.svg',
        alt: 'breeze-footer-logo',
      }}
    />
  </div>
);

export default errorPage;
