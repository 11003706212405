export const reducer = (state, action) => {
  switch (action.type) {
    case 'setNoAllergiesCheckbox': {
      return { ...state, noAllergiesCheckbox: action.noAllergiesCheckbox };
    }
    case 'addListed': {
      return {
        ...state,
        allergiesArray: [...state.allergiesArray, { action: 'add' }],
      };
    }
    case 'clearListed': {
      const allergiesArray = state.allergiesArray
        .map(item => {
          if (item.interoperableID === action.interoperableID && item.action === 'add') {
            return;
          } else if (item.interoperableID === action.interoperableID) {
            return { ...item, action: 'delete' };
          }
          return item;
        })
        .filter(item => item);
      return { ...state, allergiesArray };
    }
    case 'updateListed': {
      const allergiesArray = state.allergiesArray
        .map(item => {
          if (!item.interoperableID) {
            return { ...item, ...action.payload };
          } else if (action.payload && item.interoperableID === action.payload.interoperableID) {
            return;
          }
          return item;
        })
        .filter(item => item);
      return { ...state, allergiesArray };
    }
    case 'removeListed': {
      const allergiesArray = state.allergiesArray
        .map(item => {
          if (item.interoperableID === action.interoperableID && item.action === 'add') {
            return;
          } else if (item.interoperableID === action.interoperableID) {
            return { ...item, action: 'delete' };
          }
          return item;
        })
        .filter(item => item);
      return { ...state, allergiesArray };
    }
    default:
      return state;
  }
};

export const getListed = state => state.allergiesArray.filter(item => item.action !== 'delete');
export const getValidListed = state => state.allergiesArray.filter(item => !!item.interoperableID);
export const getValidState = state => {
  return {
    ...state,
    allergiesArray: getValidListed(state),
  };
};

export const getIsCompleted = state => {
  const listed = getValidListed(state);

  return state.noAllergiesCheckbox || listed.length > 0;
};
