import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { SchemaParser } from '../../services';
import styles from './ContentPage.module.scss';

const contentPage = ({
  schemas,
  splitSchemas = [],
  useGrid = false,
  onWheel,
  loading,
  localActions,
  withPadding,
  zeroPadding,
  className,
}) => (
  <div
    className={classNames(
      styles.container,
      { [styles.animate]: !loading },
      { [styles.withPadding]: withPadding },
      { [styles.zeroPadding]: zeroPadding },
      { [styles.grid]: useGrid },
      className,
    )}
    onWheel={onWheel}
  >
    {splitSchemas.length ? (
      splitSchemas.map(s => <SchemaParser schema={s} />)
    ) : (
      <SchemaParser schema={schemas} localActions={{ ...localActions }} />
    )}
  </div>
);

contentPage.defaultProps = { schemas: [] };
contentPage.propTypes = {
  schemas: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object).isRequired,
    PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
    PropTypes.arrayOf(PropTypes.array).isRequired,
  ]),
};

export default contentPage;
