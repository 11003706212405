import React, { memo, useContext, useEffect } from 'react';
import { compose, get, isEmpty } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import { ProviderCard } from './providerCard';
import { isMobileDevice } from '../../services/utilities';
import { store } from '../../../models';
import { Paths } from '../../../constants/index';
import styles from './providerCard.module.scss';

import * as babyContexts from '../../../contexts';

const ProviderCardContainer = memo(
  ({
    props,
    props: {
      provider,
      resource_ids,
      practice_id,
      patient_id,
      specialty,
      redirect,
      forms,
      practiceHeader,
      providerGuid,
      practice_mgmt,
      provider_id,
      avatar,
      messageSubject,
      read,
      autoSchedule,
      id,
      formHistory: { providerForms },
      pageDetails,
      option,
      messages: {
        providerSelected,
        viewMessagesOf,
        subjectEvent: { value },
        createNew,
      },
      addAppointment: { appointmentProvider },
      formHistory,
      location: { pathname } = {},
      action,
      action: { model = String(), trigger = String(), param = String(), babyContext = String() },
    },
  }) => {
    const variableContext = babyContext && useContext(babyContexts[`${babyContext}Context`]);
    const handleClick = async _ => {
      try {
        if (redirect) {
          props[model][trigger](param);
          return;
        }
        //store visit types for addAppointment view
        if (practiceHeader && model === 'formHistory') {
          store.dispatch.formHistory.storeForms({ forms, practiceHeader });
          !(id in providerForms) && store.dispatch.formHistory.saveProviderForms({ forms, id, pageDetails });
        }
        if (!isEmpty(action)) {
          if (babyContext) {
            variableContext[trigger](param);
          } else {
            const params = new URLSearchParams(props.location.search);
            if (params.get('provider')){
              params.delete('provider');
              props.history.replace('/messages')
            }
            await store.dispatch[model][trigger]({
              param,
              resource_ids,
              practice_id,
              patient_id,
              specialty,
              provider,
              practice_mgmt,
              provider_id,
              providerGuid,
              avatar,
              messageSubject,
              read,
              autoSchedule,
            });
          }
        }
      } catch (error) {
        console.error(error);
        store.dispatch.loader.persistLoader({ persist: false });
      }
    };

    useEffect(() => {
      const inAppointmentsWf = pathname.endsWith(Paths.ADD_APPOINTMENT);
      const inFormHistory = !isEmpty(store.getState().formHistory.formHistory);
      if (get(option, 'checked', null) && !formHistory.appointmentProvider && !inAppointmentsWf) {
        store.dispatch.formHistory.storeForms({ forms });
        store.dispatch.formHistory.saveProviderForms({ forms, id, pageDetails });
        if (!inFormHistory) {
          if (babyContext) {
            variableContext[trigger](param);
          } else {
            store.dispatch[model][trigger]({
              param,
              resource_ids,
              practice_id,
              patient_id,
              providerGuid,
              specialty,
              provider,
              practice_mgmt,
              provider_id,
              avatar,
              messageSubject,
              read,
            });
          }
        }
      }
    }, []);
    return (
      <ProviderCard
        props={{
          ...props,
          styles,
          providerSelected,
          value,
          ...(pathname && pathname.includes('surveys')
            ? {}
            : { appointmentProvider: appointmentProvider || formHistory.appointmentProvider }),
          viewMessagesOf,
          createNew,
          clickHandler: handleClick,
          isMobile: isMobileDevice(),
        }}
      />
    );
  },
);

const mapStateToProps = ({ addAppointment, loader }) => ({
  addAppointment,
  loader,
});

const enhancer = compose(connect(mapStateToProps));
export default enhancer(ProviderCardContainer);
