import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { compose } from '@carecloud/cloudpak';
import { store } from '../../../models';
import * as Mutations from '../../../graphql/mutations';
import { Form } from '../../rjsf';

const enableUpdateButton = complete =>
  store.dispatch.button.enableButton({
    btn: 'profileSettingsUpdate',
    complete,
  });

export const Component = React.memo(({ submit, client, schema, formData, uiSchema }) => {
  const [form, setForm] = useState({ formData });
  const isValid = !Array.isArray(form.errors) || form.errors.length < 1;

  useEffect(() => {
    store.dispatch.loader.hide();
  }, []);

  const handleChange = () => enableUpdateButton(form.edit && isValid);
  const handleSubmit = async () => {
    enableUpdateButton(false);

    try {
      const { data } = await client.mutate({
        mutation: Mutations.notifications,
        variables: {
          input: form?.formData?.types,
        },
      });

      store.dispatch.form.toggleSubmit();
      store.dispatch.toast.open(data[Object.keys(data)[0]]);
      store.dispatch.settings.refetch();
    } catch (error) {
      store.dispatch.form.toggleSubmit();
      store.dispatch.toast.error(error?.message);
      enableUpdateButton(true);
    }

    store.dispatch.loader.hide();
  };

  submit ? handleSubmit() : handleChange();

  return <Form formData={form.formData} schema={schema} uiSchema={uiSchema} onChange={setForm} />;
});

const mapStateToProps = ({ form: { submit } }) => ({ submit });

export default compose(withApollo, connect(mapStateToProps))(Component);
