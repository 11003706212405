import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { compose, isEmpty, get } from '@carecloud/cloudpak';
import { store } from '../../../../models';
import { ContentPage } from '../..';
import { GetAppointmentCheckIn, GetVideoVisitUrl, ContinueCheckInPayment } from '../../../../graphql/queries';
import { Properties as mixProperties, FormDescriptions } from '../../../../constants/Mixpanel';
import { parseResult } from '../../../../graphql/utilities';
import { serializeObjForParams, breakBackButton } from '../../../services/utilities';
import { ContextProvider } from '../../../root/TopLevelContext';
import { Paths } from '../../../../constants/index';
import { appointmentCheckInContext as AppointmentCheckInContext } from '../../../../contexts';

class AppointmentCheckInContainer extends Component {
  static contextType = ContextProvider;
  componentDidMount() {
    window.scrollTo(0, 0);
    this.context._setActions({
      refetch: this.refetch,
      returnToAppointments: this.returnToAppointments,
    });
    const { appointment_id, patient_id, practice_id } = this.props.appointmentCheckIn;
    /*eslint-disable camelcase */
    if (!(appointment_id && patient_id && practice_id)) {
      this.props.history.push('/appointments');
    }
    const fields = [
      { map: 'checkInIds', field: 'appointment_id' },
      { map: 'checkInIds', field: 'patient_id' },
      { map: 'checkInIds', field: 'practice_id' },
      { map: 'additionalInfo', field: 'providerName' },
      { map: 'additionalInfo', field: 'appointmentStartTime' },
      { map: 'additionalInfo', field: 'addressLine1' },
      { map: 'additionalInfo', field: 'addressLine2' },
      { map: 'additionalInfo', field: 'addressLine3' },
      { map: 'additionalInfo', field: 'city' },
      { map: 'additionalInfo', field: 'state' },
      { map: 'additionalInfo', field: 'zipCode' },
      { map: 'additionalInfo', field: 'phoneNumber' },
      { map: 'additionalInfo', field: 'locationName' },
    ];
    store.dispatch.form.addExternalSubmissionData(
      fields.map(({ map, field }) => ({
        value: this.props.appointmentCheckIn[field],
        formMapping: `${map}.${field.replace('_i', 'I')}`,
      })),
    );
    breakBackButton();
    store.dispatch.loader.activateLoader({ loading: true });
  }

  async componentDidUpdate() {
    const {
      appointmentCheckIn,
      getAppointmentCheckIn,
      creditCard: { processing },
      appointmentCheckIn: { thirdPartyRedirect },
    } = this.props;
    const {
      loader: { loading },
    } = store.getState();
    if (thirdPartyRedirect) {
      this.thirdPartyRedirect(thirdPartyRedirect?.payload);
    } else if (
      isEmpty(appointmentCheckIn.appointmentCheckIn) &&
      getAppointmentCheckIn &&
      !getAppointmentCheckIn.loading
    ) {
      const formToShow = get(getAppointmentCheckIn, 'getAppointmentCheckIn.formId', String());
      store.dispatch.formGroup.showForm(formToShow);
      store.dispatch.appointmentCheckIn.formId(formToShow);
      if (formToShow === mixProperties.INFO_CHECK_PAGE) {
        store.dispatch.mixpanel.addMetadata({ demographicsDocsCheckin: true });
        store.dispatch.mixpanel.addMetadata({ checkinStarted: true });
      }
      if (formToShow === mixProperties.MEDS_ALLERGIES_PAGE) {
        store.dispatch.mixpanel.addMetadata({ medsAllergiesCheckin: true });
      }
      if (get(getAppointmentCheckIn, 'getAppointmentCheckIn.submissionResult.success')) {
        this.returnToAppointments();
      } else {
        parseResult({
          query: getAppointmentCheckIn,
          dataField: 'getAppointmentCheckIn',
          resultHandler: store.dispatch.appointmentCheckIn.fetchStart,
        });
      }
    }
    if (getAppointmentCheckIn && !getAppointmentCheckIn.loading && loading && !processing) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
    if (getAppointmentCheckIn?.getAppointmentCheckIn?.thirdPartyRedirect) {
      this.thirdPartyRedirect(getAppointmentCheckIn.getAppointmentCheckIn.thirdPartyRedirect.payload);
    }
  }
  componentWillUnmount() {
    const { history: { location: { pathname } = {} } = {}, appointmentCheckIn: { formId } = {} } = this.props;
    const checkinLastStep = FormDescriptions[formId] || FormDescriptions.intakeForms;
    store.dispatch.loader.activateLoader({ loading: true });
    !pathname.includes(Paths.CHECK_IN) &&
      store.getState().mixpanel.nonActionableMetadata.checkinInProgress &&
      store.dispatch.mixpanel.addMetadata({ checkinCancelled: true, checkinLastStep });
    store.dispatch.appointmentCheckIn.resetPaymentPlanCreated();
    store.dispatch.appointments.resetState();
  }

  thirdPartyRedirect = data => {
    store.dispatch.loader.activateLoader({ loading: true });
    const { additionalInfo, input } = this.props.getAppointmentCheckIn.variables;
    const { provider_id: providerId, location_id: locationId } = this.props.appointmentCheckIn;
    const serializedParams = serializeObjForParams({ ...input, ...additionalInfo, providerId, locationId });
    const returnUrl = `${window.location.origin}/third_party/continue_check_in?${serializedParams}`;
    window.location = `${data.external_task.access_url}&return_url=${returnUrl}`;
  };
  refetch = async ({ getAppointmentCheckIn }) => {
    const { fetchStart, storeRedirectPath } = store.dispatch.appointmentCheckIn;
    store.getState().modal.modalOpen && store.dispatch.modal.toggleClose();
    const { data } = await getAppointmentCheckIn.refetch();
    if (!data) return;
    if (data?.getAppointmentCheckIn?.submissionResult?.success) {
      storeRedirectPath(data.getAppointmentCheckIn.submissionResult.redirect);
      fetchStart({ appointmentCheckInScreen: data.getAppointmentCheckIn.preRegisterSuccessModal });
      this.returnToAppointments();
    } else {
      fetchStart(data.getAppointmentCheckIn);
    }
  };
  successRedirect = async _ => {
    const {
      appointmentCheckIn: { appointment_id, practice_id, patient_id, practice_mgmt },
    } = this.props;
    store.dispatch.modal.toggleClose();
    store.dispatch.loader.activateLoader({ loading: true });
    try {
      const { data } = await this.props.client.query({
        query: ContinueCheckInPayment,
        variables: {
          input: {
            appointmentId: appointment_id,
            patientId: patient_id,
            practiceId: practice_id,
            practiceMgmt: practice_mgmt,
          },
        },
      });
      store.dispatch.mixpanel.addMetadata({ successRedirect: true });
      store.dispatch.loader.activateLoader({ loading: false });
      store.dispatch.appointments.resetState();
      if (get(data, 'continueCheckInPayment.submissionResult.success')) {
        store.dispatch.appointmentCheckIn.storeRedirectPath(data.continueCheckInPayment.submissionResult.redirect);
        store.dispatch.appointments.resetState();
        this.props.history.push('/appointments');
      } else if (get(data, 'continueCheckInPayment')) {
        store.dispatch.appointmentCheckIn.fetchStart(data.continueCheckInPayment);
      }
    } catch (error) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
  };
  returnToAppointments = _ => {
    store.dispatch.appointments.resetState();
    this.props.history.push('/appointments');
  };
  startVideoCall = async params => {
    store.dispatch.loader.activateLoader({ loading: true });
    const { practice_id, practice_mgmt, patient_id, appointment_id } = params;
    try {
      const {
        data: { getVideoVisitUrl: visitData },
      } = await this.props.client.query({
        query: GetVideoVisitUrl,
        variables: { input: { practice_id, practice_mgmt, patient_id, appointment_id } },
      });
      store.dispatch.loader.activateLoader({ loading: false });
      this.returnToAppointments();
      const url = get(visitData, 'video_visit.urls.web', '');
      window.open(url, '_blank');
    } catch (error) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
  };
  render() {
    const {
      appointmentCheckIn: { appointmentCheckIn },
    } = this.props;

    // Saving state in redux on getting SNN/DL Modal (to not submit form on showing modal and tackle 'submit' value when submitting form)
    if (appointmentCheckIn?.find(x => x?.id === "confirmSSNOrDLModal")){
      store.dispatch.modal.hasSSNOrDLModal(true)
    }
    return appointmentCheckIn ? (
      <AppointmentCheckInContext.Provider
        value={{
          successRedirect: this.successRedirect,
          returnToAppointments: this.returnToAppointments,
          startVideoCall: this.startVideoCall,
        }}
      >
        <ContentPage schemas={appointmentCheckIn} withPadding />
      </AppointmentCheckInContext.Provider>
    ) : null;
  }
}
const mapStateToProps = ({ appointmentCheckIn, creditCard }) => ({
  appointmentCheckIn,
  creditCard,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps),
  graphql(GetAppointmentCheckIn, {
    name: 'getAppointmentCheckIn',
    skip: ({ appointmentCheckIn: { appointment_id, patient_id, practice_id } }) =>
      !(appointment_id && patient_id && practice_id),
    options: ({
      appointmentCheckIn: {
        appointment_id,
        patient_id,
        practice_id,
        providerName,
        appointmentStartTime,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        state,
        zipCode,
        phoneNumber,
        locationName,
        findingsId,
        paymentPlanCreated,
      },
    }) => ({
      variables: {
        input: {
          appointmentId: appointment_id,
          patientId: patient_id,
          practiceId: practice_id,
          findingsId,
          paymentPlanCreated,
        },
        additionalInfo: {
          providerName,
          appointmentStartTime,
          addressLine1,
          addressLine2,
          addressLine3,
          city,
          state,
          zipCode,
          phoneNumber,
          locationName,
        },
      },
    }),
  }),
);
export default enhancer(AppointmentCheckInContainer);