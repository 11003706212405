export const formGroup = {
  state: { events: [], formShown: null, formGroupsToNotShow: [], submit: null, fields: {}, deleted: [] },
  reducers: {
    deleteGroup: (state, { formGroupId, deleteableFormMapping }) => ({
      ...state,
      [formGroupId]: true,
      deleted: state.deleted.concat(deleteableFormMapping),
    }),
    change: (state, { id, value, fieldValid, paymentValid, formMapping, isInitial, metaData }) => {
      const events = Object.assign([], state.events);
      const index = state.events.findIndex(event => event.id === id && event.isInitial === isInitial);
      if (index === -1) {
        events.push({ id, value, fieldValid, paymentValid, isInitial, formMapping, metaData });
      } else {
        events[index] = { id, value, fieldValid, paymentValid, isInitial, formMapping, metaData };
      }
      return {
        ...state,
        events,
      };
    },
    selectChange: (state, { id, value, formMapping, isInitial }) => {
      const events = Object.assign([], state.events);
      const index = state.events.findIndex(event => event.id === id && event.isInitial === isInitial);
      if (index === -1) {
        events.push({ id, value, isInitial, formMapping });
      } else {
        events[index] = { id, value, isInitial, formMapping };
      }
      return {
        ...state,
        events,
      };
    },
    changeCompleted: (state, { id, complete }) => ({
      ...state,
      [id]: complete,
    }),
    showForm: (state, param) => ({
      ...state,
      formShown: param,
    }),
    hideFormGroup: (state, param, checkState) => {
      let formGroups = [];
      if (!state.formGroupsToNotShow.includes(param)) formGroups.push(param);
      else if (checkState) formGroups = state.formGroupsToNotShow;
      return {
        ...state,
        formGroupsToNotShow: formGroups,
      };
    },
    submitForm: (state, param) => ({
      ...state,
      submit: param,
    }),
    storeInRedux: (state, { id, value }) => ({
      ...state,
      fields: { ...state.fields, [id]: value },
    }),
    resetEvents: state => ({
      ...state,
      events: [],
      deleted: [],
    }),
    resetFields: state => ({
      ...state,
      fields: {},
    }),
  },
};
