import { isEmpty } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';
import React, { useContext } from 'react';
import { store } from '../../../models';
import styles from './button.module.scss';
import { ContextProvider } from '../../root/TopLevelContext';
import * as babyContexts from '../../../contexts';
import { PageLoader } from '..';
import { MEDIUM_LOADER_SIZE, SMALL_LOADER_THICKNESS } from '../../../constants/Loader';

const ButtonContainer = React.memo(
  ({ button, id, type, text, theme, route, disabled, action = {}, showLoader, polling = null, image = null, activeClass=null }) => {
    const { model = String(), trigger = String(), param = String(), babyContext = String() } = action;
    const grandpaContext = useContext(ContextProvider);
    const history = useHistory();
    const variableContext = babyContext && useContext(babyContexts[`${babyContext}Context`]);
    const checkIn = () => {
      store.dispatch.notification.close();
      // store.dispatch.appointmentCheckIn.storeAppointmentData(data);
      history.push(route);
    };
    const clickHandler = _ => {
      try {
        store.dispatch.button.buttonParam({param});
        if (showLoader) store.dispatch.loader.persistLoader({ persist: true });
        if (polling) store.dispatch.button.polling({ polling });
        if (model === 'localActions' && trigger) {
          grandpaContext._getAction(trigger) && grandpaContext._getAction(trigger)(param);
        } else if (babyContext) {
          if (Object.keys(variableContext).length) {
            if (
              store.getState().radioInput.cancellationReason === 'PR' &&
              store.getState().radioInput.cancellationReasonValue === '1'
            ) {
              store.dispatch.addAppointment.setPrePaymentRescheduleInput(param)
              variableContext.rescheduleAppointment(param).then(() => {
                store.dispatch.addAppointment.setAppointmentCancelInput(param);
                // variableContext.cancelAppointment(param)
              });
              store.dispatch.radioInput.resetRadioInput();
              // if(param.practiceId !== 'ac6e00ee-5a80-49bf-8c67-f3f2218e6437' && param.practiceId !== '8f7fde47-9eae-4792-bc88-21cc059879ca') store.dispatch.radioInput.resetRadioInput();
            } else {
              variableContext[trigger](param);
            }
          } else {
            checkIn(param);
          }
        } else if (model && trigger) {
          // Showing modal at appointment check-in in absence of SSN/DL
          if (
            store.getState().modal.SNNOrDLModal &&
            model === 'form' &&
            trigger === 'toggleSubmit' &&
            store.getState().modal.showSNNorDLModal &&
            !store.getState().modal.modalOpen
          ) {
            store.dispatch.modal.toggleOpen('confirmSSNOrDLModal');
          } else {
            store.dispatch[model][trigger](param);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    // Allows form to enable/disable it's button(s)
    const enableFormDependent = _ => button[id];
    if (!isEmpty(action) || !route) {
      const isPolling = button.polling === polling;
      return !isPolling ? (
        <button
          id={id}
          className={classNames(styles.btn, styles[theme], {
            [styles.disabled]: disabled && !enableFormDependent(),
            [styles.hidden]: button.hidden.includes(id),
          },
          activeClass && 'active')}
          type={type}
          onClick={clickHandler}
          disabled={disabled && !enableFormDependent()}
        >
          {`${text} `}
          {image && <img src={image} alt="Video Visit icon" />}
        </button>
      ) : (
        <PageLoader
          className="centered"
          size={MEDIUM_LOADER_SIZE}
          thickness={SMALL_LOADER_THICKNESS}
          overlay={false}
          fixed={false}
        />
      );
    }
    return (
      <NavLink
        id={id}
        className={classNames(
          styles.btn,
          styles.nav,
          styles[theme],
          { [styles.disabled]: disabled && !enableFormDependent() },
          disabled && !enableFormDependent() ? 'disabled' : '',
        )}
        to={route}
      >
        {text}
      </NavLink>
    );
  },
);

const mapStateToProps = ({ button }) => ({ button });
export default connect(mapStateToProps)(ButtonContainer);