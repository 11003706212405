import { Query, withApollo } from 'react-apollo';
import { compose, isEmpty, get } from '@carecloud/cloudpak';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { store } from '../../../../models';
import { ContentPage } from '../..';
import { GetAppointments } from '../../../../graphql/queries';
import { parseResult } from '../../../../graphql/utilities';

class AppointmentCancellationContainer extends Component {
  componentDidMount() {
    const {
      history,
      appointments: { appointments },
    } = this.props;
    if (isEmpty(appointments)) history.push('/appointments');
    this.storeDataOrBuildSchema(false);
    const { appointmentData: { practice_id, provider_id, location_id } } = store.getState().appointments;
    store.dispatch.mixpanel.addMetadata({
      cancellationPaymentStarted: true,
      practice_id,
      provider_id,
      location_id,
    });
  }

  componentDidUpdate() {
    this.props.appointments.appointments.length &&
      this.props.loading &&
      !this.props.creditCard.processing &&
      store.dispatch.loader.activateLoader({});
  }

  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.appointments.resetState();
  }
  storeDataOrBuildSchema = rendering => {
    const { appointments: { appointmentData, appointments } } = this.props;
    let makePaymentSchema = [];
    appointments.forEach(schemaSection => {
      Object.keys(schemaSection).forEach(key => {
        if (Array.isArray(schemaSection[key])) {
          schemaSection[key].forEach(schemaComponent => {
            if (
              schemaComponent.id === this.props.match.params.appointmentId &&
              schemaComponent.makePayment.length > 0
            ) {
              if (rendering) {
                store.dispatch.mixpanel.addNonActionableMetadata({ appointmentCancellationVisitType: get(schemaComponent, 'visitType', String()) });
                makePaymentSchema = schemaComponent.makePayment;
              } else {
                isEmpty(appointmentData) &&
                  store.dispatch.appointments.storeAppointmentData(schemaComponent.checkInPayload);
              }
            }
          });
        }
      });
    });
    return makePaymentSchema;
  };

  render() {
    const {
      appointments: { appointments },
      loading,
    } = this.props;
    const makePaymentSchema = this.storeDataOrBuildSchema(true);
    if (appointments.length > 0 && makePaymentSchema.length < 1) this.props.history.push('/appointments');
    return appointments.length ? (
      <ContentPage schemas={makePaymentSchema} loading={loading} />
    ) : (
      <Query query={GetAppointments}>
        {({ data: query, loading: queryLoading }) => {
          parseResult({ query, dataField: 'getAppointments', resultHandler: store.dispatch.appointments.fetchStart });
          return <ContentPage schemas={[]} loading={queryLoading} />;
        }}
      </Query>
    );
  }
}
const mapStateToProps = ({ appointments, creditCard, loader: { loading } }) => ({ appointments, creditCard, loading });

export default compose(
  connect(mapStateToProps),
  withApollo,
)(AppointmentCancellationContainer);
