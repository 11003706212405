export const button = {
  state: { hidden: [],param:null },
  reducers: {
    // Stores button id and whether it needs to be enabled/disabled from form
    enableButton: (state, { btn, complete }) => ({
      ...state,
      [btn]: complete,
    }),
    polling: (state, { polling }) => ({
      ...state,
      polling,
    }),
    resetState: _ => ({ ...button.state }),
    deepHide: (state, { id }) => ({
      ...state,
      hidden: id,
    }),
    buttonParam: (state, { param }) => ({
      ...state,
      param,
    }),
  },
};
