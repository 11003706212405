import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { debounce } from '@carecloud/cloudpak';
import { store } from '../../models';
import { useIntervalHook } from '../services/utilities';
import { ContextProvider } from './TopLevelContext';
import {
  COUNTDOWN_TO_LOGOUT,
  DISPLAY_LOGOUT_NOTIFICATION_AT,
  FIVE_HUNDRED_MS,
  ZERO,
  SCROLL,
} from '../../constants/Inactivity';

const InactivityListener = ({ children, history }) => {
  const {formId} = store.getState().appointmentCheckIn;
  const  {Bit} = store.getState().appointmentCheckIn;
  const context = useContext(ContextProvider);
  let [timer, setTimer] = useState(COUNTDOWN_TO_LOGOUT); //eslint-disable-line prefer-const

  useIntervalHook(_ => {
    setTimer(timer--);
  }, FIVE_HUNDRED_MS);

  const resetLogoutTimer = to => setTimer(to);

  const resetAndClosePopup = _ => {
    resetLogoutTimer(COUNTDOWN_TO_LOGOUT);
    store.dispatch.clientSideModal.toggleClose();
    store.dispatch.clientSideModal.resetTimer();
  };

  const logoutCountdownLogout = _ => {
    resetAndClosePopup();
    sessionStorage.clear();
    store.dispatch({ type: 'RESET' });
    return history.push('/');
  };

  useEffect(_ => {
    context._setActions({ resetLogoutTimer, logoutCountdownLogout, resetAndClosePopup }, { overwrite: true });
    window.addEventListener(SCROLL, _ => debounce(resetLogoutTimer(COUNTDOWN_TO_LOGOUT), FIVE_HUNDRED_MS));
    return window.removeEventListener(SCROLL, _ => debounce(resetLogoutTimer(COUNTDOWN_TO_LOGOUT), FIVE_HUNDRED_MS));
  }, []);

  useEffect(
    _ => {
      if(formId === 'intake_forms' && Bit){
        resetLogoutTimer(COUNTDOWN_TO_LOGOUT);
        store.dispatch.clientSideModal.resetTimer();
        store.dispatch.appointmentCheckIn.TakingIntakeformBit(false);
      }else if  (timer <= DISPLAY_LOGOUT_NOTIFICATION_AT) {
        const { toggleOpen, updateTimer, toggleClose } = store.dispatch.clientSideModal;
        store.getState().clientSideModal.modalOpen !== 'logoutCountdownModal' &&
          toggleOpen({ id: 'logoutCountdownModal' });
        updateTimer({ timer });
        if (timer === ZERO) {
          toggleClose();
          sessionStorage.clear();
          store.dispatch({ type: 'RESET' });
          history.push('/');
        }
      }
    },
    [timer],
  );
  return (
    /*eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    <div
      role="main"
      onClick={_ => {
        resetLogoutTimer(COUNTDOWN_TO_LOGOUT);
      }}
    >
      {children}
    </div>
  );
};

export default withRouter(InactivityListener);
