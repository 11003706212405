const state = {
  messagesScreen: {},
  subjectEvent: {},
  failedMessages: [],
  cancelMessage: true,
  providerSelected: null,
  viewMessagesOf: {},
  sendNewMessage: null,
  sendingMessage: null,
  messageToResend: null,
  createNew: false,
  deleteMessage: false,
  markAsRead: false,
  markAsUnread: false,
};

/*eslint-disable camelcase */
export const messages = {
  state,
  reducers: {
    fetchStart: (state, { messagesScreen }) => ({
      ...state,
      messagesScreen,
    }),

    refetch: state => ({ ...state, refetch: true }),

    didRefetch: (state, param) => ({
      ...state,
      refetch: param,
    }),

    clearStore: _ => ({ ...state }),

    // Sets the subject header timestamp to the time of it's last message
    setConversationTime: (state, lastMessageTime) => ({
      ...state,
      lastMessageTime,
    }),

    // Displays failed message reply
    storeFailedMessage: (state, param) => ({
      ...state,
      failedMessages: [...state.failedMessages, param],
    }),

    getMessageToRetry: (state, param) => ({
      ...state,
      sendNewMessage: true,
      messageToResend: param,
    }),

    removeFailedMessage: (state, param) => ({
      ...state,
      failedMessages: state.failedMessages.filter(conversation => param !== conversation.replyId),
    }),

    // Displays the new individual message sent (front-end only)
    appendNewMessage: (state, { conversationId, newMessage }) => ({
      ...state,
      sendNewMessage: false,
      messagesScreen: {
        ...state.messagesScreen,
        messagesSection: {
          ...state.messagesScreen.messagesSection,
          providerMessages: state.messagesScreen.messagesSection.providerMessages.map(message => {
            if (message.message_id !== conversationId) {
              return message;
            }
            const updatedMessage = {
              ...message,
              messages: [...message.messages, newMessage],
            };
            return updatedMessage;
          }),
        },
      },
    }),

    appendNextPage: (state, { nextProviderMessages, nextProviderMessageList, currentPage }) => {
      const { messagesScreen: { messagesSection = {}, messagesSidePanel = {}, paginationData = {} } = {} } = state;
      const updatedProviderMessages = messagesSection.providerMessages.concat(nextProviderMessages);
      const updatedProviderMessageList = messagesSidePanel.providerMessageList.components.concat(
        nextProviderMessageList,
      );

      return {
        ...state,
        viewMessagesOf: {},
        messagesScreen: {
          ...state.messagesScreen,
          paginationData: { ...paginationData, currentPage },
          messagesSection: {
            ...messagesSection,
            providerMessages: updatedProviderMessages,
          },
          messagesSidePanel: {
            ...messagesSidePanel,
            providerMessageList: {
              ...messagesSidePanel.providerMessageList,
              components: updatedProviderMessageList,
            },
          },
        },
      };
    },

    // Creates a new individual message or new conversation in messages schema permanently
    sendMessages: (state, param) => ({
      ...state,
      sendNewMessage: param,
    }),
    checkIn: (state, param) => ({
      ...state,
      sendNewMessage: param,
    }),

    // Displays/hides 'sending...' animation
    toggleSending: (state, { id, sending }) => ({
      ...state,
      sendingMessage: { id, sending },
    }),

    // Displays the new message conversation created (front-end only)
    appendNewConversation: (state, { newConversation }) => ({
      ...state,
      messagesScreen: {
        ...state.messagesScreen,
        messagesSection: {
          ...state.messagesScreen.messagesSection,
          providerMessages: [newConversation, ...state.messagesScreen.messagesSection.providerMessages],
        },
      },
    }),

    // Allows user to begin creating a new message conversation
    createNewMessage: (state, param) => ({
      ...state,
      createNew: param,
      viewMessagesOf: {},
      cancelMessage: false,
    }),

    // Visibly removes the current message conversation when deleted (front-end only)
    removeSelectedConversation: (state, { conversationId }) => ({
      ...state,
      viewMessagesOf: {},
      messagesScreen: {
        ...state.messagesScreen,
        messagesSection: {
          ...state.messagesScreen.messagesSection,
          providerMessages: state.messagesScreen.messagesSection.providerMessages.filter(
            message => message.message_id !== conversationId,
          ),
        },
        messagesSidePanel: {
          ...state.messagesScreen.messagesSidePanel,
          providerMessageList: {
            ...state.messagesScreen.messagesSidePanel.providerMessageList,
            components: state.messagesScreen.messagesSidePanel.providerMessageList.components.filter(
              provider => provider.provider_id !== conversationId,
            ),
          },
        },
      },
    }),

    // Deletes the current message from messages schema permanently
    deleteMessage: (state, param) => ({
      ...state,
      deleteMessage: param,
      createNew: false,
      providerSelected: null,
    }),

    // Visibly removes unread flag for the current message conversation (front-end only)
    removeUnreadMarker: (state, { conversationId }) => ({
      ...state,
      viewMessagesOf: {
        ...state.viewMessagesOf,
        read: true,
      },
      messagesScreen: {
        ...state.messagesScreen,
        messagesSidePanel: {
          ...state.messagesScreen.messagesSidePanel,
          providerMessageList: {
            ...state.messagesScreen.messagesSidePanel.providerMessageList,
            components: state.messagesScreen.messagesSidePanel.providerMessageList.components.map(provider => {
              if (provider.provider_id !== conversationId) {
                return provider;
              }
              const updatedProvider = {
                ...provider,
                read: true,
              };
              return updatedProvider;
            }),
          },
        },
      },
    }),

    // Marks current message conversation as read in messages schema permanently
    markRead: (state, param) => ({
      ...state,
      markAsRead: param,
    }),

    // Visibly flags the current message conversation as 'unread' (front-end only)
    addUnreadMarker: (state, { conversationId }) => ({
      ...state,
      viewMessagesOf: {
        ...state.viewMessagesOf,
        read: false,
      },
      messagesScreen: {
        ...state.messagesScreen,
        messagesSidePanel: {
          ...state.messagesScreen.messagesSidePanel,
          providerMessageList: {
            ...state.messagesScreen.messagesSidePanel.providerMessageList,
            components: state.messagesScreen.messagesSidePanel.providerMessageList.components.map(provider => {
              if (provider.provider_id !== conversationId) {
                return provider;
              }
              const updatedProvider = {
                ...provider,
                read: false,
              };
              return updatedProvider;
            }),
          },
        },
      },
    }),

    // Mark current message conversation as unread in messages schema permanently
    markUnread: (state, param) => ({
      ...state,
      markAsUnread: param,
    }),

    // Selects a provider to start a new message with
    selectProvider: (state, param) => ({
      ...state,
      providerSelected: param,
    }),

    // Cancels the new message process
    cancelNewMessage: (state, param) => ({
      ...state,
      cancelMessage: param,
      createNew: false,
      providerSelected: null,
    }),

    // Stores the message subject as it is typed in the subject header and reflects on provider card
    updateSubject: (state, { id, value }) => ({
      ...state,
      subjectEvent: { id, value },
    }),

    // Shows the messages for the selected provider
    viewMessages: (state, param) => ({
      ...state,
      viewMessagesOf: param,
      providerSelected: null,
      createNew: false,
    }),
    // Resets state when user navigates away from page
    resetMessagesState: _ => state,
  },
};
