export const manageProfiles = {
  state: { manageProfiles: [] },
  reducers: {
    fetchStart: (state, { manageProfilesScreen }) => ({
      ...state,
      manageProfiles: manageProfilesScreen,
    }),
    addModalToScreen: (state, { modalSchema }) => {
      const augmentedScreen = state.manageProfiles.concat([modalSchema]);
      return {
        ...state,
        manageProfiles: augmentedScreen,
      };
    },
    clearStore: _ => ({ ...manageProfiles.state }),
  },
};
