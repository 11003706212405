import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { ContentPage } from '..';
import { withRouter } from 'react-router-dom';
import { GetAppointments } from '../../../graphql/queries';
import { store } from '../../../models';
import { ContextProvider } from '../../root/TopLevelContext';
import { CancelAppointment } from '../../../graphql/mutations';
import { parseResult } from '../../../graphql/utilities';
import { breakBackButton } from '../../services/utilities';
import { INCOMPLETE_REGISTRATION_ROUTE } from '../../../constants/Routes';

class AppointmentsContainer extends Component {
  static contextType = ContextProvider;
  componentDidMount() {
    store.dispatch.appointmentCheckIn.clearStore();
    store.dispatch.appointmentCheckOut.clearStore();
    store.dispatch.appointmentCheckOut.unsetExternalCheckoutData();
    store.dispatch.addAppointment.clearStore();
    store.dispatch.appointmentCheckOut.setFromAddAppointment(false);
    store.dispatch.intelligentScheduler.clearAll();
    store.dispatch.radioInput.clearSelectedBusiness();
    store.dispatch.appointments.resetState();
    this.context._setActions({
      redirectToSettings: this.redirectToSettings,
    });
    breakBackButton();
  }

  componentWillReceiveProps({ getAppointments }) {
    const { appointments, loader } = store.getState();
    const queryLoading = getAppointments && getAppointments.loading;
    if (queryLoading && !loader.loading) {
      store.dispatch.loader.activateLoader({ loading: true });
    }
    if (appointments.refetch && !queryLoading) {
      store.dispatch.loader.activateLoader({ loading: true });
      this.refetch();
      store.dispatch.appointments.didRefetch();
      return;
    }
    if (!queryLoading && loader.loading && !appointments.cancellingAppointment) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
    if (isEmpty(appointments.appointments) && !queryLoading) {
      parseResult({
        query: getAppointments,
        dataField: 'getAppointments',
        resultHandler: store.dispatch.appointments.fetchStart,
      });
    }
  }

  componentWillUnmount() {
    store.dispatch.appointmentCheckIn.resetState();
    store.dispatch.loader.activateLoader({ loading: true });
  }

  refetch = async () => {
    const data = await this.props.client.query({ query: GetAppointments });
    store.dispatch.appointments.fetchStart(data.data.getAppointments);
    store.dispatch.loader.activateLoader({ loading: false });
    store.dispatch.loader.persistLoader({ persist: false });
    // ensuring body doesn't have a 'modal-open' class that breaks scrolling
    store.dispatch.modal.toggleClose();
  };

  redirectToSettings = _ => {
    const missingFields = store.getState().appointments?.missingRegistrationFields
      const personal = missingFields?.personal
      const additional_demographics = missingFields?.additional_demographics;
      const document = missingFields?.documents;
      const payment = missingFields?.payment;
      store.dispatch.modal.toggleClose('completeRegistrationModal')
      let route = '/profile_settings/personal' 
      store.dispatch.appointments.showMissingForms({param: true})
      if(personal === true){
        route = INCOMPLETE_REGISTRATION_ROUTE.filter(x => x.url === "/profile_settings/personal" )?.[0].url
      }
      else if(additional_demographics === true){
        route = INCOMPLETE_REGISTRATION_ROUTE.filter(x => x.url === '/profile_settings/demographics')?.[0].url
      }
      else if(document === true){
        route = INCOMPLETE_REGISTRATION_ROUTE.filter(x => x.url === "/profile_settings/documents" )?.[0].url
      }
      else if(payment === true){
        route = INCOMPLETE_REGISTRATION_ROUTE.filter(x => x.url === "/profile_settings/payment" )?.[0].url
      }
      this.props.history.push(route)
  }
  render() {
    const {
      appointments: { appointments },
      loader: { loading },
    } = this.props;
    return appointments && <ContentPage schemas={[appointments]} loading={loading} />;
  }
}

const mapStateToProps = ({ appointments, loader }) => ({
  appointments,
  loader,
});

const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps),
  graphql(GetAppointments, {
    name: 'getAppointments',
    skip: () => !isEmpty(store.getState().appointments.appointments),
  }),
  graphql(CancelAppointment, { name: 'cancelAppointment' }),
);
export default enhancer(AppointmentsContainer);
