import React from 'react';
import { CheckBoxField, FormField } from '../styleguide';

export default ({ name, disabled, formData: value, schema: { title: label }, onChange }) => {
  const handleChange = ({ target: { checked } }) => onChange(checked);

  return (
    <FormField>
      <CheckBoxField name={name} value={value} disabled={disabled} text={label} onChange={handleChange} />
    </FormField>
  );
};
