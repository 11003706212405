import React, { Component } from 'react';
import { withApollo } from 'react-apollo';
import { compose, isEmpty, get } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { store } from '../../../models';
import { GetAddAppointment } from '../../../graphql/queries';
import { AddAppointmentWorkflow } from './addAppointmentWorkflow';
import { ContextProvider } from '../../root/TopLevelContext';
import { AddAppointment as AddAppointmentConstants } from '../../../constants/index';
import { parseParams } from '../../services/routing';
import { breakBackButton } from '../../services/utilities';
import { addAppointmentWorkflowContext as AddAppointmentWorkflowContext } from '../../../contexts';
import { GUEST, CHECK_OUT } from '../../../constants/Paths';
import styles from './addAppointmentWorkflow.module.scss';

class AddAppointmentWorkflowContainer extends Component {
  static contextType = ContextProvider;

  constructor() {
    super();
    this.state = {
      practiceNotSelectedError: false,
    };
  }

  componentDidMount() {
    this.context._setActions({ addAppointmentHandlePracticeChange: this.addAppointmentHandlePracticeChange });
    breakBackButton();
    if (
      store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_mgmt !== null &&
      store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_mgmt !== undefined
    ) {
      const practiceParam = {
        value: {
          label: sessionStorage.getItem('practiceSelectedName')
            ? sessionStorage.getItem('practiceSelectedName')
            : store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_name,
          practiceId: sessionStorage.getItem('practiceSelected')
            ? sessionStorage.getItem('practiceSelected')
            : store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_id,
          practiceMgmt: store.getState().layout?.metaData?.practiceInformation?.[0]?.practice_mgmt,
        },
      };
      if (!isEmpty(practiceParam)) {
        // this.addAppointmentHandlePracticeChange(practiceParam).then(() => {
        if (!isEmpty(store.getState().intelligentScheduler.visitType)) {
          const visitTypeParam = {
            visitReasonId: store.getState().intelligentScheduler?.visitType.id,
            prePayment: {amount: store.getState().addAppointment?.prePayment, label: "Prepayment"},
            visitType: store.getState().intelligentScheduler?.visitType.name,
          };
          store.dispatch.addAppointment.selectVisitReason({ ...visitTypeParam });
          if (!isEmpty(visitTypeParam)) {
            this.addAppointmentSelectVisitType(visitTypeParam);
          }
        }
      }
    }
    const { locationpicklist, providerpicklist } = store.getState().intelligentScheduler.schedulerResourceSelection;
    if (locationpicklist) {
      store.dispatch.addAppointment.selectLocation({});
    }
    if (providerpicklist) {
      store.dispatch.addAppointment.selectProvider({});
    }
    if (isEmpty(store.getState().intelligentScheduler.visitType) && !this.props.match.path.includes('check_out')) {
      store.dispatch.modal.toggleOpen('customTextModal');
    }
  }

  componentWillUnmount() {
    store.dispatch.radioInput.resetRadioInput();
    store.dispatch.inputDropdown.resetValues();
  }

  setPracticeNotSelectedError = () => {
    if (this.state.practiceNotSelectedError === false) {
      this.setState({ practiceNotSelectedError: true });
      setTimeout(() => {
        this.setState({ practiceNotSelectedError: false });
      }, 5000);
    }
  };

  addAppointmentHandlePracticeChange = async params => {
    const { value } = params;
    const { practiceId, practiceMgmt } = value;
    if (practiceId === '-1') {
      this.setPracticeNotSelectedError();
      return;
    }
    if (practiceId !== store.getState().addAppointment.practiceId) {
      store.dispatch.addAppointment.resetSelectedOptions();
      const {
        practice_mgmt: checkoutPracticeMgmt,
        practice_id: checkoutPracticeId,
      } = store.getState().appointmentCheckOut;

      store.dispatch.loader.persistLoader({ persist: true });
      store.dispatch.radioInput.resetRadioInput();
      const {
        data: { getAddAppointment: { addAppointmentScreen = [] } = {} },
      } = await this.props.client.query({
        query: GetAddAppointment,
        variables: {
          input: {
            practiceId: practiceId || checkoutPracticeId,
            practiceMgmt: practiceMgmt || checkoutPracticeMgmt,
            ...(store.getState().guestEntry.guestMode ? { patientId: parseParams(this.props).patientId } : {}),
            isGuest: this.props.location.pathname.includes(GUEST),
            inCheckOut: checkoutPracticeId && this.props.location.pathname.includes(CHECK_OUT),
          },
        },
      });
      const patientId = get(addAppointmentScreen, 'appointmentWorkflowMain.patientId', String());
      store.dispatch.addAppointment.setPracticePatientIds({ practiceId, practiceMgmt, patientId });

      store.dispatch.addAppointment.fetchStart({ addAppointmentScreen });
      return store.dispatch.loader.persistLoader({ persist: false });
    }
  };

  queryAndRefetch = async (input, exceptFor) => {
    if (input.practiceId === '-1') {
      this.setPracticeNotSelectedError();
      return;
    }
    store.dispatch.loader.persistLoader({ persist: true });
    const {
      data: { getAddAppointment: { addAppointmentScreen = [], innocentDelay, whatRequestAmI } = {} },
    } = await this.props.client.query({ query: GetAddAppointment, variables: { input } });
    !isEmpty(addAppointmentScreen) &&
      store.dispatch.addAppointment.refetch({ addAppointmentScreen, exceptFor, innocentDelay, whatRequestAmI });
    store.dispatch.addAppointment.clearFilterValue();
    const { noSearchResults, filteredLocations } = store.getState().addAppointment;
    let locationResults = filteredLocations;
    if (isEmpty(filteredLocations)) {
      locationResults = [noSearchResults];
      store.dispatch.addAppointment.filterLocations({ filteredLocations: locationResults });
    }
    store.dispatch.loader.persistLoader({ persist: false });
  };

  addAppointmentSelectProvider = async param => {
    if (store.getState().addAppointment.practiceId === '-1') {
      this.setPracticeNotSelectedError();
      return;
    }
    store.dispatch.addAppointment.selectProvider(param);
    const {
      locationId,
      visitReasonId,
      selectedProvider: { practiceId, practiceMgmt, patientId, resourceId },
      selectedProviderId,
    } = store.getState().addAppointment;
    const {
      practice_mgmt: checkoutPracticeMgmt,
      practice_id: checkoutPracticeId,
    } = store.getState().appointmentCheckOut;
    return this.queryAndRefetch(
      {
        resourceId,
        ...(visitReasonId && { visitReasonId }),
        ...(locationId && { locationId }),
        videoOption: !!store.getState().intelligentScheduler?.visitType.video_option,
        fromIntelligentScheduler: !!Object.keys(store.getState().intelligentScheduler?.visitType).length,
        practiceId: practiceId || checkoutPracticeId,
        practiceMgmt: practiceMgmt || checkoutPracticeMgmt,
        patientId,
        selectedProviderId,
        requests: [AddAppointmentConstants.VISIT_REASONS, AddAppointmentConstants.LOCATIONS],
        isGuest: this.props.location.pathname.includes(GUEST),
        inCheckOut: checkoutPracticeId && this.props.location.pathname.includes(CHECK_OUT),
      },
      AddAppointmentConstants.RESOURCES,
    );
  };

  addAppointmentSelectVisitType = async param => {
    store.dispatch.addAppointment.selectVisitReason(param);
    const {
      practiceId,
      practiceMgmt,
      selectedProvider: { patientId, resourceId } = {},
      patientId: noSelectedProviderPatientId,
      selectedProviderId,
      locationId,
      visitReasonId,
    } = store.getState().addAppointment;
    const {
      practice_mgmt: checkoutPracticeMgmt,
      practice_id: checkoutPracticeId,
    } = store.getState().appointmentCheckOut;
    return this.queryAndRefetch(
      {
        visitReasonId,
        ...(locationId ? { locationId } : {}),
        ...(resourceId ? { resourceId } : {}),
        videoOption: !!store.getState().intelligentScheduler?.visitType.video_option,
        fromIntelligentScheduler: !!Object.keys(store.getState().intelligentScheduler?.visitType).length,
        practiceMgmt: practiceMgmt || checkoutPracticeMgmt,
        practiceId: practiceId || checkoutPracticeId,
        selectedProviderId,
        patientId: patientId || noSelectedProviderPatientId,
        requests: [AddAppointmentConstants.RESOURCES, AddAppointmentConstants.LOCATIONS],
        isGuest: this.props.location.pathname.includes(GUEST),
        inCheckOut: this.props.location.pathname.includes(CHECK_OUT),
      },
      AddAppointmentConstants.VISIT_REASONS,
    );
  };

  addAppointmentSelectLocation = async param => {
    if (store.getState().addAppointment.practiceId === '-1') {
      this.setPracticeNotSelectedError();
      return;
    }
    store.dispatch.addAppointment.selectLocation(param);
    const { locationId } = param;
    const {
      practiceId,
      practiceMgmt,
      selectedProvider: { patientId, resourceId } = {},
      patientId: noSelectedProviderPatientId,
      visitReasonId,
      selectedProviderId,
    } = store.getState().addAppointment;
    const {
      practice_mgmt: checkoutPracticeMgmt,
      practice_id: checkoutPracticeId,
    } = store.getState().appointmentCheckOut;
    return this.queryAndRefetch(
      {
        locationId,
        ...(visitReasonId ? { visitReasonId } : {}),
        ...(resourceId ? { resourceId } : {}),
        videoOption: !!store.getState().intelligentScheduler?.visitType.video_option,
        fromIntelligentScheduler: !!Object.keys(store.getState().intelligentScheduler?.visitType).length,
        practiceMgmt: practiceMgmt || checkoutPracticeMgmt,
        practiceId: practiceId || checkoutPracticeId,
        selectedProviderId,
        patientId: patientId || noSelectedProviderPatientId,
        requests: [AddAppointmentConstants.RESOURCES, AddAppointmentConstants.VISIT_REASONS],
        isGuest: this.props.location.pathname.includes(GUEST),
        inCheckOut: checkoutPracticeId && this.props.location.pathname.includes(CHECK_OUT),
      },
      AddAppointmentConstants.LOCATIONS,
    );
  };

  handleCheckAvailabeTimesBtn = _ => {
    const {
      practiceMgmt,
    } = store.getState().addAppointment;
    const {
      history: { push },
      location: { pathname },
    } = this.props;
    store.dispatch.appointmentCheckOut.setFromAddAppointment(true);
    if (practiceMgmt ==="vertex") push('/appointments/add_appointment/Vertexavailable_hours');
    else if (store.getState().guestEntry.guestMode) push('/guest/workflow/appointments/add_appointment/available_hours');
    else if (store.getState().appointmentCheckOut.patient_id) push('/appointments/check_out/available_hours');
    else push(`${pathname}${pathname.endsWith('/') ? '' : '/'}available_hours`);
  };

  handleFilterInput = e => {
    const inputVal = e.target.value.toLowerCase();
    store.dispatch.addAppointment.setFilterValue({ filteredValue: inputVal });
    const regex = new RegExp(inputVal, 'g');
    const { initialLocations = [], noSearchResults, addAppointment } = store.getState().addAppointment;
    const locations = get(addAppointment, 'addAppointment.locations', []);
    const checkedLocation = locations.find(location => location.checked);
    checkedLocation &&
      initialLocations.map(location => {
        const checked = location.id === checkedLocation.id;
        return { ...location, checked };
      });
    let filtered = initialLocations.filter(location => {
      const { label } = location;
      return label.toLowerCase().match(regex);
    });
    if (isEmpty(filtered)) filtered = [noSearchResults];
    store.dispatch.addAppointment.filterLocations({ filteredLocations: filtered });
  };

  handleProviderFilterInput = e => {
    const inputVal = e.target.value.toLowerCase();
    store.dispatch.addAppointment.setProviderFilterValue({ providerFilterValue: inputVal });
    const regex = new RegExp(inputVal, 'g');
    const { providerCards = [], noSearchResults, addAppointment } = store.getState().addAppointment;
    const providers = get(addAppointment, 'addAppointment.providerCards', []);
    const checkedProvider = providers.find(provider => provider.option.checked);
    checkedProvider &&
      providerCards.map(provider => {
        const checked = provider.id === checkedProvider.id;
        return { ...provider, checked };
      });
    let filtered = providerCards.filter(providerObj => {
      const { provider } = providerObj;
      return provider.toLowerCase().match(regex);
    });
    if (isEmpty(filtered)) filtered = [noSearchResults];
    store.dispatch.addAppointment.filterProviders({ filteredProviderCards: filtered });
  };

  handleVisitTypeFilterInput = e => {
    const inputVal = e.target.value.toLowerCase();
    store.dispatch.addAppointment.setVisitTypeFilterValue({ visitTypeFilterValue: inputVal });
    const regex = new RegExp(inputVal, 'g');
    const { visitTypes = [], noSearchResults, addAppointment } = store.getState().addAppointment;
    const visittypes = get(addAppointment, 'addAppointment.visitTypes', []);
    const checkedVisitType = visittypes.find(visittype => visittype.checked);
    checkedVisitType &&
      visitTypes.map(visitType => {
        const checked = visitType.id === checkedVisitType.id;
        return { ...visitType, checked };
      });
    let filtered = visitTypes.filter(visitType => {
      const { label } = visitType;
      return label.toLowerCase().match(regex);
    });
    if (isEmpty(filtered)) filtered = [noSearchResults];
    store.dispatch.addAppointment.filterVisitTypes({ filteredVisitTypes: filtered });
  };

  render() {
    const {
      addAppointment: {
        addAppointment,
        filteredLocations,
        filteredProviderCards,
        filteredVisitTypes,
        practiceDropdownSchema,
        selectedProviderId,
        visitReasonId,
        locationId,
        scheduleLaterBtn,
        filteredValue,
        providerFilterValue,
        visitTypeFilterValue,
      } = {},
      appointmentCheckOut,
      classes,
    } = this.props;
    const inCheckOut = !isEmpty(appointmentCheckOut);
    return (
      !isEmpty(addAppointment) && (
        <AddAppointmentWorkflowContext.Provider
          value={{
            addAppointmentSelectProvider: this.addAppointmentSelectProvider,
            addAppointmentSelectVisitType: this.addAppointmentSelectVisitType,
            addAppointmentSelectLocation: this.addAppointmentSelectLocation,
          }}
        >
          {this.state.practiceNotSelectedError && (
            <div className={styles.practiceNotSelectedError}>Please select practice first</div>
          )}
          <AddAppointmentWorkflow
            {...{
              ...this.props,
              classes,
              addAppointment,
              filteredLocations,
              filteredProviderCards,
              filteredVisitTypes,
              selectedProviderId,
              filteredValue,
              providerFilterValue,
              visitTypeFilterValue,
              visitReasonId,
              locationId,
              practiceDropdownSchema,
              scheduleLaterBtn,
              handleCheckAvailabeTimesBtn: this.handleCheckAvailabeTimesBtn,
              handleFilterInput: this.handleFilterInput,
              handleProviderFilterInput: this.handleProviderFilterInput,
              handleVisitTypeFilterInput: this.handleVisitTypeFilterInput,
              inCheckOut,
            }}
          />
        </AddAppointmentWorkflowContext.Provider>
      )
    );
  }
}

const mapStateToProps = ({ addAppointment, radioInput, appointmentCheckOut }) => ({
  addAppointment,
  radioInput,
  appointmentCheckOut,
});

const enhancer = compose(withApollo, withRouter, connect(mapStateToProps));

export default enhancer(AddAppointmentWorkflowContainer);
