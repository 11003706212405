export const hasTokens = _ =>
  sessionStorage.getItem('CC-Breeze-Web-accessToken') &&
  sessionStorage.getItem('CC-Breeze-Web-authenticationToken') &&
  sessionStorage.getItem('CC-Breeze-Web-refreshToken');

export const setSession = session => {
  Object.keys(session).forEach(key => sessionStorage.setItem(`CC-Breeze-Web-${key}`, session[key]));
};

export const newAccountSetSession = ({ data }) => {
  const {
    email,
    data: { payload: { patient_app_signin: { cognito: { authentication: { AccessToken, IdToken, RefreshToken } } } } },
  } = data;
  setSession({
    accessToken: AccessToken,
    authenticationToken: IdToken,
    refreshToken: RefreshToken,
    username: email,
  });
};
