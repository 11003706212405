import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import styles from './SelectField.module.scss';

export default React.memo(props => {
  const {
    disabled,
    labelKey = 'label',
    name,
    onChange,
    options,
    placeholder,
    value,
    valueKey = 'name',
    bordered,
  } = props;

  const handleChange = item => {
    onChange({
      target: {
        name,
        value: item[valueKey],
      },
    });
  };

  return (
    <div className={classNames(styles.wrapper, { [styles.bordered]: bordered })}>
      <Select
        clearable={false}
        disabled={disabled}
        labelKey={labelKey}
        name={name}
        onChange={handleChange}
        options={options}
        placeholder={placeholder}
        searchable={false}
        value={value}
        valueKey={valueKey}
      />
    </div>
  );
});
