import React from 'react';
import Select from 'react-select';
import classNames from 'classnames';
import { withApollo } from '@apollo/react-hoc';
import styles from './TypeheadField.module.scss';

const Component = React.memo(props => {
  const {
    bordered,
    disabled,
    labelKey = 'label',
    name,
    onChange,
    placeholder,
    valueKey = 'name',
    value,
    client,
    transformRequest,
    transformResponse,
    query,
  } = props;

  const handleChange = value => {
    onChange({
      target: {
        name,
        value,
      },
    });
  };

  const loadOptions = searchableValue => {
    const search = searchableValue.replace(/\s\s+/g, '').trim();      
    if (!search || search.length < 3) {
      return Promise.resolve({ options: [] });
    }

    const input = transformRequest ? transformRequest({ search }) : { search };

    return client
      .query({
        query,
        variables: { input },
      })
      .then(({ data }) => {
        const options = transformResponse ? transformResponse(data) : data;
        return { options: options || [] };
      });
  };

  return (
    <div className={classNames(styles.wrapper, { [styles.bordered]: bordered })}>
      <Select.Async
        clearable={false}
        cacheOptions
        disabled={disabled}
        labelKey={labelKey}
        loadOptions={loadOptions}
        name={name}
        simpleValue={false}
        onChange={handleChange}
        placeholder={placeholder}
        valueKey={valueKey}
        value={value}
      />
    </div>
  );
});

export default withApollo(Component);
