import { AddAppointment, AvailableHours, MakePayment, Oops, Surveys } from '..';
import { PageLoader } from '../../ui/page_loader/pageLoader';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Session } from '../../services';
import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import {
  isMobileDevice,
  parseWorkflowFromSURLPath,
  breakBackButton,
} from '../../services/utilities';
import styles from './GuestLayout.module.scss';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import Toast from '../../modals/toast/toastContainer';
import classNames from 'classnames';

const nakedToastStyle = {
  position: 'absolute',
  right: 0,
  bottom: 0,
};

const isMobile = isMobileDevice();
const MobileDependentStyledGuestNavbar = styled.div`
  height: ${isMobile ? '80px' : '100px'};
  box-shadow: 0px 1px 2px 0px rgba(55, 71, 79, 0.3);
  & img {
    margin: 0 auto;
    width: 150px;
    display: flex;
    padding-top: ${isMobile ? '15px' : '25px'};
  }
  & h6 {
    color: #66c7d0;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    padding-top: 5px;
  }
`;

const GuestLayoutContainer = ({
  loader: { loading, persist },
  location: { pathname },
}) => {
  useEffect(_ => breakBackButton(), []);

  return !Session.hasTokens() ? (
    <div
      className={classNames(
        { [styles.guestMobileWrapper]: isMobileDevice() },
        styles.guestLayoutWrapper,
      )}
    >
      <MobileDependentStyledGuestNavbar>
        <img src="https://assets.gobreeze.com/nav-logo.svg" alt="breeze-logo" />
        {!pathname.endsWith('unavailable') && (
          <h6>{parseWorkflowFromSURLPath(pathname)}</h6>
        )}
      </MobileDependentStyledGuestNavbar>
      <Switch>
        <Route
          exact
          path="/guest/workflow/appointments/add_appointment"
          component={AddAppointment}
        />
        <Route
          exact
          path="/guest/workflow/appointments/add_appointment/available_hours"
          component={AvailableHours}
        />
        <Route exact path="/guest/workflow/survey" component={Surveys} />
        <Route exact path="/guest/workflow/payment" component={MakePayment} />
        <Route exact path="/guest/workflow/unavailable" component={Oops} />
      </Switch>
      {(loading || persist) && <PageLoader loading={loading} />}
      <div style={nakedToastStyle}>
        <Toast />
      </div>
    </div>
  ) : (
    <Redirect to="/" />
  );
};
const mapStateToProps = ({ loader }) => ({ loader });
const enhancer = compose(withRouter, connect(mapStateToProps));
export default enhancer(withRouter(GuestLayoutContainer));
