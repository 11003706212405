import React, { useReducer, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { isUndefined } from 'util';
import { store } from '../../../models';
import styles from './HierarchicalCheckboxes.module.scss';

const HierarchicalCheckboxes = props => {
  const reducer = (state, payload) => ({ ...state, ...payload });
  const [state, dispatch] = useReducer(reducer, { relationshipData: [], checkboxData: {} });
  const {
    relationshipData,
    checkboxData,
    id = String(),
    setInGlobalStore = false,
    neverEnabled = false,
    dependantButtonId = String(),
  } = state;

  const onChange = ({ key, checked, isParent = false, children = [] }) => {
    const copy = Object.assign({}, checkboxData);
    copy[key].enabled = checked;
    if (isParent) {
      const postObjKeys = Object.keys(copy);
      postObjKeys.forEach(k => {
        if (
          children
            .reduce((acc, { key }) => {
              acc.push(key);
              return acc;
            }, [])
            .includes(k)
        ) {
          copy[k].enabled = checked;
          copy[k].clickable = checked;
        }
      });
    }
    store.dispatch.button.enableButton({
      btn: dependantButtonId,
      complete: Object.values(copy).some(p => p.enabled),
    });
    setInGlobalStore && store.dispatch.hierarchicalCheckboxes.setData({ data: copy });
    return dispatch({ checkboxData: copy });
  };

  const generateCheckbox = ({ label, wrapperStyles, isParent = false, children = [], clickable = true, key }) => (
    <div key={label} className={classNames(wrapperStyles, styles.checkBoxContainer)}>
      <input
        type="checkbox"
        onChange={e => onChange({ key, checked: e.target.checked, isParent, children })}
        checked={checkboxData[key].enabled}
        disabled={neverEnabled || !clickable}
      />
      <span>{label}</span>
    </div>
  );

  const buildTree = _ => {
    if (!state.relationshipData.length) return <h3>Loading...</h3>;
    const tree = relationshipData.map(parent => {
      const { label: parentLabel, children, key: parentKey } = parent;
      const parentJsx = generateCheckbox({
        label: parentLabel,
        wrapperStyles: styles.parentWrapper,
        isParent: true,
        children,
        key: parentKey,
      });
      return (
        <div key={`${id}${parentKey}`} className="permissionGroup">
          {parentJsx}
          {children.map(child => {
            const { label: childLabel, key: childKey, clickable: initialClickable } = child;
            const clickable = isUndefined(checkboxData[childKey].clickable)
              ? initialClickable
              : checkboxData[childKey].clickable;
            return generateCheckbox({
              label: childLabel,
              wrapperStyles: styles.childWrapper,
              key: childKey,
              clickable,
            });
          })}
        </div>
      );
    });
    return tree;
  };

  const ambramovAppeaser = useCallback(_ => {
    dispatch({ ...props });
  }, []);

  useEffect(
    _ => {
      ambramovAppeaser();
    },
    [ambramovAppeaser],
  );

  return <div>{buildTree()}</div>;
};

export default HierarchicalCheckboxes;
