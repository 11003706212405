import { withApollo } from 'react-apollo';
import { compose, get, isEmpty } from '@carecloud/cloudpak';
import React, { useReducer, useEffect } from 'react';
import moment from 'moment';
import SchemaParser from '../../services/SchemaParser';
import { EMPTY_SECTION, REQUESTED } from '../../../constants/Properties';
import { GetMoreAppointments } from '../../../graphql/queries';
import { store } from '../../../models';
import { historyAppointmentContext as HistoryAppointmentContext } from '../../../contexts';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'setPracticeId':
      return { ...state, practiceId: payload, currentPage: 1 }; // Set the currentPage to 1 when practiceId is changed
    case 'incrementPage':
      return { ...state, nextPage: payload, currentPage: state.currentPage + 1 }; // Increment currentPage when loading more appointments
    case 'storeCombinedAppointments':
      return { ...state, combinedAppointmentComponents: payload };
    case 'storeAppointmentCounts':
      return { ...state, remainingAppointments: payload };
    case 'resetState':
      return { ...state, combinedAppointmentComponents: [], nextPage: 2, remainingAppointments: [] };
    default:
      throw new Error();
  }
};

const HistoryAppointment = React.memo(
  ({ appointments, defaultPractice, header, dropDown, loadMore, client, details, emptyHolder }) => {
    const [state, dispatch] = useReducer(reducer, {
      practiceId: get(defaultPractice, 'value', String()),
      nextPage: 2,
      currentPage: 2,
      combinedAppointmentComponents: [],
      remainingAppointments: {},
    });

    const handleHistoricalPracticeChange = item => {
      store.dispatch.loader.persistLoader({ persist: true });
      dispatch({ type: 'resetState' });
      dispatch({ type: 'setPracticeId', payload: item.value });
    };

    const _setRemainingAppointmentsCounts = (newDetails = details) => {
      const payload = newDetails.reduce(
        (acc, { metadata: { practice_id } = {}, payload: { total_entries, current_page, per_page } = {} }) => {
          acc[practice_id] = total_entries - current_page * per_page;
          return acc;
        },
        {},
      );
      dispatch({ type: 'storeAppointmentCounts', payload });
    };

    const generateSchema = _ => {
      const { combinedAppointmentComponents, practiceId, remainingAppointments } = state;

      isEmpty(combinedAppointmentComponents) &&
        dispatch({
          type: 'storeCombinedAppointments',
          payload: [...appointments.components],
        });
      isEmpty(remainingAppointments) && details && _setRemainingAppointmentsCounts();

      const filteredAppointments = combinedAppointmentComponents.filter(appointment => {
        const hiddenStatusses = [REQUESTED];
        const beforeToday = !moment(moment().utc()).isBefore(appointment.dateTime);
        return (
          appointment.practiceId === practiceId &&
          beforeToday &&
          !hiddenStatusses.includes(appointment.status) &&
          !!appointment
        );
      });

      const filteredAppointmentsToShow = filteredAppointments.filter(({ component }) => component !== EMPTY_SECTION)
        .length
        ? filteredAppointments
        : emptyHolder.components;
      const enhancedLoadMore =
        remainingAppointments[practiceId] > 0
          ? Object.assign(
            {},
            {
              ...loadMore,
              text: `${loadMore.text} ${
                remainingAppointments[practiceId] > 0 ? `(${remainingAppointments[practiceId]})` : ''
              }`,
            },
          )
          : {};
      return [header, dropDown, { ...appointments, components: filteredAppointmentsToShow }, enhancedLoadMore];
    };

    const handleLoadMoreAppointments = async _ => {
      store.dispatch.loader.persistLoader({ persist: true });
      const { data: { getMoreAppointments: { appointments: { components } = {}, details: newDetails } = {} } = {} } = await client.query({
        query: GetMoreAppointments,
        variables: { page: state.currentPage },
      });
      _setRemainingAppointmentsCounts(newDetails);
      const combined = state.combinedAppointmentComponents.concat(components);
      dispatch({ type: 'incrementPage', payload: state.currentPage++ });
      dispatch({ type: 'storeCombinedAppointments', payload: combined });
    };

    useEffect(_ => _ => store.dispatch.inputDropdown.resetValues(), []);

    useEffect(
      _ => {
        store.dispatch.loader.persistLoader({ persist: false });
      },
      [state.combinedAppointmentComponents, state.practiceId],
    );

    return (
      <HistoryAppointmentContext.Provider value={{ handleLoadMoreAppointments, handleHistoricalPracticeChange }}>
        <SchemaParser schema={generateSchema()} />
      </HistoryAppointmentContext.Provider>
    );
  },
);

export default compose(withApollo)(HistoryAppointment);
