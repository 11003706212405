import React from 'react';
import RJSForm from 'react-jsonschema-form';
import { ObjectFieldTemplate } from './ObjectFieldTemplate';
import CheckBoxField from './CheckBoxField';
import DateField from './DateField';
import FieldTemplate from './FieldTemplate';
import GroupField from './GroupField';
import ImageField from './ImageField';
import InputField from './InputField';
import MaskField from './MaskField';
import SectionField from './SectionField';
import SelectField from './SelectField';
import SubGroupField from './SubGroupField';

const defaultFields = {
  CheckBoxField,
  DateField,
  GroupField,
  ImageField,
  InputField,
  MaskField,
  SectionField,
  SelectField,
  SubGroupField,
};

const transformErrors = errors => errors.map(error => ({ ...error, message: error.name }));

export const Form = ({ customFields, formContext, formData, onChange, schema, uiSchema, validate }) => {
  const fields = customFields ? Object.assign({}, defaultFields, customFields) : defaultFields;

  return (
    <RJSForm
      fields={fields}
      FieldTemplate={FieldTemplate}
      formContext={formContext}
      formData={formData}
      liveValidate
      ObjectFieldTemplate={ObjectFieldTemplate}
      onChange={onChange}
      schema={schema}
      showErrorList={false}
      transformErrors={transformErrors}
      uiSchema={uiSchema}
      validate={validate}
    >
      <></>
    </RJSForm>
  );
};
