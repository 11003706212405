import React from 'react';
import classNames from 'classnames';
import { isEmpty } from '@carecloud/cloudpak';
import { SchemaParser } from '../../services';
import { store } from '../../../models';
import styles from './addAppointmentWorkflow.module.scss';

const addAppointmentWorkflow = React.memo(
  ({
    addAppointment: {
      addAppointmentHeaders,
      order,
      customTextModal,
      addAppointment: {
        headers: { providerHeader, visitTypeHeader, locationHeader } = {},
        availableHoursBtnLabel,
        providersInputPlaceholder,
        visittypeInputPlaceholder,
        locationsInputPlaceholder,
      },
    },
    filteredValue,
    providerFilterValue,
    visitTypeFilterValue,
    selectedProviderId,
    visitReasonId,
    locationId,
    practiceDropdownSchema,
    scheduleLaterBtn,
    filteredLocations,
    filteredProviderCards,
    filteredVisitTypes,
    handleCheckAvailabeTimesBtn,
    handleFilterInput,
    handleProviderFilterInput,
    handleVisitTypeFilterInput,
    inCheckOut,
  }) => {
    const preSelectedDropdownSchema = { ...practiceDropdownSchema };
    if (!isEmpty(practiceDropdownSchema)) {
      preSelectedDropdownSchema.disabled = true;
      const selectedBusiness = store.getState().radioInput.selectBusiness;
      const idxOfSelectedPractice = practiceDropdownSchema?.options.findIndex(x => x.value === selectedBusiness);
      if (idxOfSelectedPractice >= 0) {
        preSelectedDropdownSchema.value = practiceDropdownSchema?.options[idxOfSelectedPractice];
      }
    }
    const { visitType } = store.getState().intelligentScheduler;
    const { providersameaslast, locationsameaslast } = store.getState().intelligentScheduler.schedulerResourceSelection;
    const { name, specialty } = store.getState().addAppointment.selectedProvider;
    const { office, city, addressLine1 } = store.getState().addAppointment;
    return (
      <div className={styles.addAppointmentContainer}>
        <SchemaParser schema={[addAppointmentHeaders]} />
        <SchemaParser schema={[customTextModal]} />
        {!isEmpty(visitType) && (
          <>
            <h4>
              Your auto selected visit type is <span className={styles.visitType}>{visitType.name}</span>
            </h4>
            {providersameaslast && (
              <h4>
                You will see the same provider as your last visit{' '}
                <span className={styles.visitType}>
                  {name} - {specialty}
                </span>
              </h4>
            )}
            {locationsameaslast && (
              <h4>
                The location is the same as your last visit{' '}
                <span className={styles.visitType}>
                  {office} - {city} - {addressLine1}
                </span>
              </h4>
            )}
          </>
        )}
        {inCheckOut && <SchemaParser schema={[scheduleLaterBtn]} />}
        <div className={styles.contentContainer}>
          {!isEmpty(visitType) ? (
            <>
              {order
                .split('_')
                .filter(el => el !== 'visitType')
                .map(item => {
                  if (item === 'provider') {
                    return (
                      <>
                        {!providersameaslast && (
                          <div className={styles.column}>
                            <SchemaParser schema={[providerHeader]} />
                            <div className={styles.cardContainer}>
                              <input
                                className={styles.filterInput}
                                placeholder={providersInputPlaceholder}
                                onChange={handleProviderFilterInput}
                                value={providerFilterValue}
                              />
                              <SchemaParser schema={[filteredProviderCards]} />
                            </div>
                          </div>
                        )}
                      </>
                    );
                  } else {
                    return (
                      <>
                        {!locationsameaslast && (
                          <div className={styles.column}>
                            <SchemaParser schema={[locationHeader]} />
                            <div className={styles.cardContainer}>
                              <input
                                className={styles.filterInput}
                                placeholder={locationsInputPlaceholder}
                                onChange={handleFilterInput}
                                value={filteredValue}
                              />
                              <SchemaParser schema={filteredLocations} />
                            </div>
                          </div>
                        )}
                      </>
                    );
                  }
                })}
            </>
          ) : (
            <>
              {order.split('_').map(item => {
                if (item === 'provider') {
                  return (
                    <div className={styles.column}>
                      <SchemaParser schema={[providerHeader]} />
                      <div className={styles.cardContainer}>
                        <input
                          className={styles.filterInput}
                          placeholder={providersInputPlaceholder}
                          onChange={handleProviderFilterInput}
                          value={providerFilterValue}
                        />
                        <SchemaParser schema={[filteredProviderCards]} />
                      </div>
                    </div>
                  );
                } else if (item === 'visitType') {
                  return (
                    <div className={styles.column}>
                      <SchemaParser schema={[visitTypeHeader]} />
                      <div className={styles.cardContainer}>
                        <input
                          className={styles.filterInput}
                          placeholder={visittypeInputPlaceholder}
                          onChange={handleVisitTypeFilterInput}
                          value={visitTypeFilterValue}
                        />
                        <SchemaParser schema={[filteredVisitTypes]} />
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div className={styles.column}>
                      <SchemaParser schema={[locationHeader]} />
                      <div className={styles.cardContainer}>
                        <input
                          className={styles.filterInput}
                          placeholder={locationsInputPlaceholder}
                          onChange={handleFilterInput}
                          value={filteredValue}
                        />
                        <SchemaParser schema={filteredLocations} />
                      </div>
                    </div>
                  );
                }
              })}
            </>
          )}
        </div>
        <button
          id="availableHoursBtn"
          onClick={handleCheckAvailabeTimesBtn}
          disabled={!(selectedProviderId && visitReasonId && locationId)}
          className={classNames(styles.button, {
            // eslint-disable-next-line object-curly-newline
            [styles.enabled]: selectedProviderId && visitReasonId && locationId,
          })}
        >
          {availableHoursBtnLabel}
        </button>
      </div>
    );
  },
);

export const AddAppointmentWorkflow = addAppointmentWorkflow;