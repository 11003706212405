import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { compose, isString, get } from '@carecloud/cloudpak';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { store } from '../../../models';
import { Appointment } from './appointment';
import { GenerateVisitSummary, CancelAppointment } from '../../../graphql/mutations';
import { GetVisitSummary, GetVideoVisitUrl } from '../../../graphql/queries';
import { REQUESTING } from '../../../constants/AppointmentStatuses';
import { parseResult } from '../../../graphql/utilities';
import styles from './appointment.module.scss';
import mix from '../../services/mixpanel';
import { appointmentContext as AppointmentContext } from '../../../contexts';

const AppointmentContainer = props => <Component {...{ ...props, match: null }} />;

class Component extends PureComponent {
  cancelAppointment = async params => {
    const { practiceId, patientId, appointmentId, providerId, locationId, visitType } = params;
    const { cancellationReason, cancellationReasonValue: cancellationReasonId } = store.getState().radioInput;
    const foundCancellationComments = store
      .getState()
      .formGroup.events.find(event => event.id === 'otherReasonInput' && !event.isInitial);
    const cancellationComments =
      cancellationReason === 'OT' ? foundCancellationComments && foundCancellationComments.value : String();
    store.dispatch.modal.toggleClose();
    store.dispatch.loader.activateLoader({ loading: true });
    const input = {
      practiceId,
      patientId,
      appointmentId,
      cancellationReasonId,
      cancellationComments,
      providerId,
      locationId,
    };
    try {
      store.dispatch.appointments.toggleCancellingAppointment();
      store.dispatch.mixpanel.addNonActionableMetadata({ appointmentCancellationVisitType: visitType });
      const data = await this.props.client.mutate({ mutation: CancelAppointment, variables: { input } });
      // Display cancellation success message to user
      if (data?.data?.cancelAppointment === 200) {
        store.dispatch.modal.toggleOpen('cancelApptSuccessModal');
      }
      store.dispatch.radioInput.resetRadioInput();
    } catch (error) {
      console.error(error);
    }
  };

  toggleOpenVideoTab = async params => {
    store.dispatch.loader.activateLoader({ loading: true });
    const { canCheckin, labels } = params;
    if (canCheckin) {
      store.dispatch.notification.open({
        data: params,
        labels,
        actions: { firstButtonAction: this.checkIn, secondButtonAction: this.startVideoCall },
      });
    } else {
      this.startVideoCall(params);
    }
  };

  startVideoCall = async params => {
    store.dispatch.loader.activateLoader({ loading: true });
    const { practice_id, practice_mgmt, patient_id, appointment_id } = params;
    try {
      const {
        data: { getVideoVisitUrl: visitData },
      } = await this.props.client.query({
        query: GetVideoVisitUrl,
        variables: { input: { practice_id, practice_mgmt, patient_id, appointment_id } },
      });
      store.dispatch.loader.activateLoader({ loading: false });
      store.dispatch.notification.close();
      const url = get(visitData, 'video_visit.urls.web', '');
      window.open(url, '_blank');
    } catch (error) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
  };

  rescheduleAppointment = async ({
    prePayment,
    route,
    autoSchedule,
    appointment: {
      metadata: { practice_id: practiceId, patient_id: patientId, practice_mgmt: practiceMgmt },
      payload: {
        provider,
        visit_reason_id: visitReasonId,
        visit_reason: { name: visitType } = {},
        location: {
          id: locationId,
          address: {
            line1: addressLine1 = String(),
            line2: addressLine2 = String(),
            line3: addressLine3 = String(),
            city = String(),
            state_name: state = String(),
            zip_code: zip = String(),
            name: office = String(),
          },
          phones = [],
          guid: locationGuid,
        },
        resource_id: resourceId,
      },
    } = {},
  }) => {
    const { addAppointment } = store.dispatch;
    const primaryPhone = phones.length && phones.find(p => p.is_primary);
    const phone = get(primaryPhone, 'phone_number', String());
    const massagedLocation = {
      locationId,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state,
      zip,
      phone,
      office,
      locationGuid,
    };
    addAppointment.selectProvider({
      resourceId,
      practiceId,
      practiceMgmt,
      patientId,
      provider,
      providerId: provider.id,
      selectedProviderId: provider.id,
      providerGuid: provider.guid,
      autoSchedule,
      avatar: { url: provider.photo },
    });
    addAppointment.selectVisitReason({
      prePayment: { amount: prePayment },
      visitReasonId,
      visitType,
    });
    addAppointment.selectLocation({ ...massagedLocation });
    addAppointment.setPrePayment(prePayment);
    addAppointment.setFromReschedule();
    this.props.history.push(route);
  };
  vertex_rescheduleAppointment = async ({
    route,
    autoSchedule,
    appointment: {
      metadata: { practice_id: practiceId, patient_id: patientId, practice_mgmt: practiceMgmt },
      payload: {
        provider,
        visit_reason: {id:visitReasonId, name: visitType } = {},
        location: {
          id: locationId,
          address: {
            line1: addressLine1 = String(),
            line2: addressLine2 = String(),
            line3: addressLine3 = String(),
            city = String(),
            state_name: state = String(),
            zip_code: zip = String(),
            name: office = String(),
          },
          guid: locationGuid,
        },
        resource_id: resourceId,
      },
    } = {},
  }) => {
    const { addAppointment } = store.dispatch;
    const massagedLocation = {
      locationId,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      state,
      zip,
      office,
      locationGuid,
    };
    addAppointment.selectProvider({
      resourceId,
      practiceId,
      practiceMgmt,
      patientId,
      provider,
      providerId: provider.id,
      selectedProviderId: provider.id,
      providerGuid: provider.guid,
      autoSchedule,
      avatar: { url: provider.photo },
    });
    addAppointment.selectVisitReason({
      visitReasonId,
      visitType,
    });
    addAppointment.selectLocation({ ...massagedLocation });
    addAppointment.setFromReschedule();
    this.props.history.push(route);
  };
  apptDate = _ => {
    const { status, dateTime, dateTimeSelect } = this.props;

    const selectedLang =
      store.getState().guestEntry?.guestScreen[2]?.text ||
      store.getState().layout?.layoutScreen?.pageHeader?.rightSection?.components[0]?.text;

    const isSpanish = selectedLang === 'Español' && true;
    const dateFormat = isSpanish ? 'D [de] MMM [de] YYYY' : 'MMM D, YYYY';
    const startTime = dateTimeSelect?.availableHoursSection?.value?.start_time;

    const dateResult =
      status === REQUESTING && dateTimeSelect.availableHoursSection
        ? `${moment(startTime)
            .local()
            .calendar(null, {
              sameDay: `${isSpanish ? '[Hoy]' : '[Today]'}, ${dateFormat}`,
              nextDay: `${isSpanish ? '[Mañana]' : '[Tomorrow]'}, ${dateFormat}`,
              nextWeek: `dddd, ${dateFormat}`,
              sameElse: `dddd, ${dateFormat}`,
            })}`
        : `${
            dateTime
              ? `${moment(dateTime)
                  .local()
                  .format(`dddd, ${dateFormat}`)}`
              : ''
          }`;
    return dateResult;
  };
  apptTime = _ => {
    const { status, dateTime, message, dateTimeSelect } = this.props;
    return status === REQUESTING && dateTimeSelect.availableHoursSection
      ? `${moment(dateTimeSelect.availableHoursSection.value.start_time)
          .local()
          .format('hh:mm A')}`
      : `${
          dateTime
            ? moment(dateTime)
                .local()
                .format('h:mm A')
            : ''
        }${message ? ` / ${message}` : ''}`;
  };
  checkIn = data => {
    store.dispatch.notification.close();
    store.dispatch.appointmentCheckIn.storeAppointmentData(data);
    this.props.history.push('appointments/check_in');
  };
  checkOut = data => {
    store.dispatch.appointmentCheckOut.storeAppointmentData(data);
    mix.checkOut(data);
    this.props.history.push('appointments/check_out');
  };
  poll = async (jobId, count) => {
    if (count > 10) return store.dispatch.button.polling({});
    const { data } = await this.props.client.query({
      query: GetVisitSummary,
      variables: { input: { jobId } },
    });
    if (isString(data.getVisitSummary)) {
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:application/pdf;base64,${data.getVisitSummary}`;
      downloadLink.download = 'visit_summary.pdf';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setTimeout(downloadLink.remove, 100);
      store.dispatch.button.polling({});
    } else {
      setTimeout(_ => this.poll(jobId, count + 1), 1000);
    }
  };
  requestVisitSummary = async ({ appointment_id, practice_id, practice_mgmt, patient_id }) => {
    const input = { appointment_id, practice_id, practice_mgmt, format: 'pdf', patient_id };
    const { data: query } = await this.props.client.mutate({ mutation: GenerateVisitSummary, variables: { input } });
    parseResult({
      query,
      dataField: 'generateVisitSummary',
      resultHandler: jobId => {
        this.poll(jobId, 0);
      },
    });
  };
  apptIndicator = _ => {
    const { status } = this.props;
    const indicator = {
      MISSED: 'remove_circle',
      REQUESTED: 'schedule',
      CANCELLED: 'do_not_disturb_alt',
    }[status];
    if (!indicator) return null;
    return <i className={classNames('material-icons', styles.indicatorIcon, styles[indicator])}>{indicator}</i>;
  };
  handleVisitReason = event => {
    const { visitReason } = this.props;
    const newVisitReason = event.target.value;
    if (visitReason !== newVisitReason) {
      store.dispatch.addAppointment.addReasonForVisit(newVisitReason);
    }
  };
  render() {
    const { dateTimeSelect, pollingButtonId } = this.props;
    return (
      <AppointmentContext.Provider
        value={{
          checkIn: this.checkIn,
          checkOut: this.checkOut,
          requestVisitSummary: this.requestVisitSummary,
          rescheduleAppointment: this.rescheduleAppointment,
          cancelAppointment: this.cancelAppointment,
          toggleOpenVideoTab: this.toggleOpenVideoTab,
          vertex_rescheduleAppointment: this.vertex_rescheduleAppointment,
        }}
      >
        <Appointment
          {...{
            ...this.props,
            dateTimeSelect,
            apptDate: this.apptDate,
            apptTime: this.apptTime,
            apptIndicator: this.apptIndicator,
            handleVisitReason: this.handleVisitReason,
            pollingButtonId,
          }}
        />
      </AppointmentContext.Provider>
    );
  }
}

const mapStateToProps = ({ dateTimeSelect, appointments, button }) => ({
  dateTimeSelect,
  appointments,
  pollingButtonId: button.polling,
});

const enhancer = compose(withRouter, withApollo, connect(mapStateToProps));
export default enhancer(AppointmentContainer);
