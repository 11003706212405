import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import {
  PROVIDER_OTHER,
  RELATIONSHIP_SELF,
  SINGLE_NAME_FIELD_PATTERN,
  DOUBLE_NAME_FIELD_PATTERN,
  DATE_BASE_FORMAT,
} from '../../../constants/Utilities';

import { DateField, FormField, ImageField, InputField, SelectField } from '../../styleguide';
import { store } from '../../../models';

const validate = (formData, labels) => {
  const errors = {};
  if (!formData.type) {
    errors.type = labels.requiredFieldError;
  }
  const Required_Bit =    store.getState().settings.required_Bit
    if(Required_Bit === "true" || Required_Bit === true ){
     if(!formData.frontPhoto){
      errors.frontPhoto = labels.requiredFieldError;
    }
    if(!formData.backPhoto){
      errors.backPhoto = labels.requiredFieldError;
    }
  }
 
  if (!formData.memberId) {
    errors.memberId = labels.requiredFieldError;
  }

  if (!formData.providerName) {
    errors.providerName = labels.requiredFieldError;
  }

  if (formData.providerName === PROVIDER_OTHER && !formData.providerCustomName) {
    errors.providerCustomName = labels.requiredFieldError;
  }

  const isSelf = formData.relationshipType === RELATIONSHIP_SELF;
  if (!isSelf && !formData.policyHolderFirstName) {
    errors.policyHolderFirstName = labels.requiredFieldError;
  }

  if (!isSelf && formData.policyHolderFirstName && !formData.policyHolderFirstName.match(DOUBLE_NAME_FIELD_PATTERN)) {
    errors.policyHolderFirstName = labels.nameFieldError;
  }

  if (!isSelf && formData.policyHolderMiddleName && !formData.policyHolderMiddleName.match(SINGLE_NAME_FIELD_PATTERN)) {
    errors.policyHolderMiddleName = labels.nameFieldError;
  }

  if (!isSelf && !formData.policyHolderLastName) {
    errors.policyHolderLastName = labels.requiredFieldError;
  }

  if (!isSelf && formData.policyHolderLastName && !formData.policyHolderLastName.match(DOUBLE_NAME_FIELD_PATTERN)) {
    errors.policyHolderLastName = labels.nameFieldError;
  }

  if (isSelf && !formData.effectiveFrom) {
    errors.effectiveFromError = labels.requiredFieldError;
  }

  if (isSelf && formData.effectiveFrom) {
    const date = moment(formData.effectiveFrom, DATE_BASE_FORMAT, true);
    if (!date.isValid() || !date.isBetween(moment('1901-01-01'), moment(), null, '[]')) {
      errors.effectiveFromError = labels.effectiveFromValidDateErrorMsg;
    }
  }

  if (!isSelf && !formData.policyHolderDateOfBirth) {
    errors.policyHolderDateOfBirth = labels.requiredFieldError;
  }

  if (!isSelf && formData.policyHolderDateOfBirth) {
    const date = moment(formData.policyHolderDateOfBirth, DATE_BASE_FORMAT, true);
    if (!date.isValid() || !date.isBetween(moment('1901-01-01'), moment(), null, '[]')) {
      errors.policyHolderDateOfBirth = labels.dateFieldError;
    }
  }

  if (!isSelf && !formData.policyHolderGender) {
    errors.policyHolderGender = labels.requiredFieldError;
  }

  if (!isSelf && formData.relationshipType === 'Father' && formData.policyHolderGender === 'Female') {
    errors.policyHolderGender = labels.selectValidGenderErr;
  }
  if (!isSelf && formData.relationshipType === 'Mother' && formData.policyHolderGender === 'Male') {
    errors.policyHolderGender = labels.selectValidGenderErr;
  }

  return errors;
};

export default React.memo(props => {
  const {
    formData = {},
    genders,
    insuranceTypes,
    labels,
    onUpdate,
    onValidate,
    persistentFields = {},
    plans,
    providers,
    relationshipTypes,
  } = props;

  const hasCustomProvider = formData.providerName === PROVIDER_OTHER;
  const hasCustomPlan = !formData.providerName || hasCustomProvider || !plans || plans.length < 1;
  const hasRelationship = formData.relationshipType && formData.relationshipType !== RELATIONSHIP_SELF;
  const errors = useMemo(() => validate(formData, labels), [formData, labels]);

  useEffect(() => onValidate({ type: 'validateItem', item: formData, errors }), [errors]);

  const handleUpdate = formData => onUpdate({ type: 'updateItem', item: formData });
    const Card_Required =    store.getState().settings.required_Bit

  const effectiveFromLabel = formData.effectiveFrom ? ( <> {labels.effectiveFrom}<span style={{ color: 'red' }}>*</span> </>) : (labels.effectiveFrom );
  const Card_Required_labled_front = Card_Required === "true" || Card_Required === true  ? ( <> {labels.imageFront}<span style={{ color: 'red' }}>*</span> </>) : (labels.imageFront );
  const Card_Required_labled_back = Card_Required === "true" || Card_Required === true ? ( <> {labels.imageBack}<span style={{ color: 'red' }}>*</span> </>) : (labels.imageBack );
  const handleChange = ev => {
    const {
      target: { name, value },
    } = ev;

    handleUpdate({
      ...formData,
      [name]: value,
    });
  };

  const handleProviderChange = ev => {
    if (
      store.getState().appointmentCheckIn.practice_id === 'f1fe3157-5eae-4796-912f-16f297aac0da' &&
      !(ev.target.value === 'MEDICARE' || ev.target.value === 'MEDICAID' || ev.target.value === 'CIGNA')
    ) {
      store.dispatch.modal.toggleOpen('bannerApptContainer');
    }
    const {
      target: { name, value },
    } = ev;

    handleUpdate({
      ...formData,
      [name]: value,
      providerCustomName: '',
      planName: '',
      planCustomName: '',
      effectiveFrom: '',
    });
  };

  const handleRelationshipTypeChange = ev => {
    const {
      target: { name, value },
    } = ev;

    if (hasRelationship && (value === RELATIONSHIP_SELF || !value)) {
      return handleUpdate({
        ...formData,
        [name]: value,
        policyHolderFirstName: '',
        policyHolderMiddleName: '',
        policyHolderLastName: '',
        policyHolderDateOfBirth: '',
        policyHolderGender: '',
      });
    }

    return handleChange(ev);
  };
  const isInsuranceFrontPictureRequired = store.getState().settings.insuranceDetails;
  const isInsuranceBackPictureRequired = store.getState().settings.insuranceDetails;
  const isInsuranceFrontRequired = formData.frontPhoto;
  const isInsuranceBackRequired = formData.backPhoto;

  return (
    <div>
      <FormField error={(!(errors.frontPhoto) && isInsuranceFrontPictureRequired && (isInsuranceFrontRequired === undefined || isInsuranceFrontRequired === '')) ? "This field is required" : errors.frontPhoto} required>
        <ImageField
          name="frontPhoto"
          editText={labels.imageEdit}
          removeText={labels.imageRemove}
          text={Card_Required_labled_front}
          uploadText={labels.imageUpload}
          value={formData.frontPhoto}
          onChange={handleChange}
        />
      </FormField>

      <FormField error={(!(errors.backPhoto) && isInsuranceBackPictureRequired && (isInsuranceBackRequired === undefined || isInsuranceBackRequired === '')) ? "This field is required" : errors.backPhoto} required>
        <ImageField
          name="backPhoto"
          editText={labels.imageEdit}
          removeText={labels.imageRemove}
          text={Card_Required_labled_back}
          uploadText={labels.imageUpload}
          value={formData.backPhoto}
          onChange={handleChange}
        />
      </FormField>

      <FormField error={errors.type} text={labels.insuranceType} required value={formData.type}>
        <SelectField
          name="type"
          onChange={handleChange}
          options={insuranceTypes}
          placeholder={labels.selectType}
          value={formData.type}
        />
      </FormField>

      <FormField error={errors.providerName} text={labels.insuranceProvider} required value={formData.providerName}>
        <SelectField
          disabled={persistentFields.providerName}
          name="providerName"
          onChange={handleProviderChange}
          options={providers}
          placeholder={labels.selectProvider}
          value={formData.providerName}
        />
      </FormField>

      {hasCustomProvider && (
        <>
          <FormField
            error={errors.providerCustomName}
            text={labels.insuranceCustomProvider}
            required
            value={formData.providerCustomName}
          >
            <InputField
              disabled={persistentFields.providerCustomName}
              name="providerCustomName"
              onChange={handleChange}
              value={formData.providerCustomName}
            />
          </FormField>
        </>
      )}
      <FormField error={errors.memberId} text={labels.insuranceMemberIdNumber} required value={formData.memberId}>
        <InputField
          disabled={persistentFields.memberId}
          name="memberId"
          onChange={handleChange}
          value={formData.memberId}
        />
      </FormField>

      <FormField error={errors.effectiveFromError} text={effectiveFromLabel} required value={formData.effectiveFrom}>
        <DateField
          displayFormat={labels.localeDateFormat}
          name="effectiveFrom"
          onChange={handleChange}
          placeholder={labels.localeDateFormat}
          value={formData.effectiveFrom}
        />
      </FormField>

      {!hasCustomPlan && (
        <>
          <FormField error={errors.planName} text={labels.insurancePlan}>
            <SelectField
              disabled={persistentFields.planName}
              name="planName"
              onChange={handleChange}
              options={plans}
              placeholder={labels.selectPlan}
              value={formData.planName}
            />
          </FormField>
        </>
      )}

      {hasCustomPlan && (
        <>
          <FormField error={errors.planCustomName} text={labels.insuranceCustomPlan}>
            <InputField
              disabled={persistentFields.planCustomName}
              name="planCustomName"
              onChange={handleChange}
              value={formData.planCustomName}
            />
          </FormField>
        </>
      )}

      <FormField error={errors.groupNumber} text={labels.insuranceGroupNumber}>
        <InputField
          name="groupNumber"
          value={formData.groupNumber}
          onChange={handleChange}
          disabled={persistentFields.groupNumber}
        />
      </FormField>

      <FormField error={errors.relationshipType} text={labels.relationshipType}>
        <SelectField
          name="relationshipType"
          onChange={handleRelationshipTypeChange}
          options={relationshipTypes}
          placeholder={labels.selectRelationship}
          value={formData.relationshipType}
        />
      </FormField>

      {hasRelationship && (
        <>
          <FormField
            error={errors.policyHolderFirstName}
            text={labels.policyHolderFirstName}
            required
            value={formData.policyHolderFirstName}
          >
            <InputField name="policyHolderFirstName" value={formData.policyHolderFirstName} onChange={handleChange} />
          </FormField>

          <FormField error={errors.policyHolderMiddleName} text={labels.policyHolderMiddleName}>
            <InputField name="policyHolderMiddleName" value={formData.policyHolderMiddleName} onChange={handleChange} />
          </FormField>

          <FormField
            error={errors.policyHolderLastName}
            text={labels.policyHolderLastName}
            required
            value={formData.policyHolderLastName}
          >
            <InputField name="policyHolderLastName" value={formData.policyHolderLastName} onChange={handleChange} />
          </FormField>

          <FormField
            error={errors.policyHolderDateOfBirth}
            text={labels.policyHolderDateOfBirth}
            required
            value={formData.policyHolderDateOfBirth}
          >
            <DateField
              displayFormat={labels.localeDateFormat}
              name="policyHolderDateOfBirth"
              onChange={handleChange}
              placeholder={labels.localeDateFormat}
              value={formData.policyHolderDateOfBirth}
            />
          </FormField>

          <FormField
            error={errors.policyHolderGender}
            text={labels.policyHolderGender}
            required
            value={formData.policyHolderGender}
          >
            <SelectField
              name="policyHolderGender"
              onChange={handleChange}
              options={genders}
              placeholder={labels.selectGender}
              value={formData.policyHolderGender}
            />
          </FormField>
        </>
      )}
    </div>
  );
});
