import React, { Component } from 'react';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { store } from '../../../models';
import { ContentPage } from '..';
import { GetMakePayments } from '../../../graphql/queries';
import { Payments } from '../../../constants/index';
import { isMobileDevice } from '../../services/utilities';
import { parseParams } from '../../services/routing';
import { parseResult } from '../../../graphql/utilities';
import styles from './MakePaymentContainer.module.scss';
import { ContextProvider } from '../../root/TopLevelContext';

class MakePaymentsContainer extends Component {
  static contextType = ContextProvider;
  componentWillReceiveProps({
    makePayments,
    getMakePayments,
    creditCard: { processing },
    loader: { loading },
    radioInput: { paymentType },
    paymentPlan: { agreeToTerms },
  }) {
    const { radioInput } = this.props;
    if (isEmpty(makePayments.makePayments) && !getMakePayments.loading) {
      parseResult({
        query: getMakePayments,
        dataField: 'getMakePayments',
        resultHandler: store.dispatch.makePayments.fetchStart,
      });
    }
    if (!getMakePayments.loading && loading && !processing) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
    if (paymentType !== Payments.PAYMENT_PLAN && radioInput.paymentType !== paymentType) {
      !agreeToTerms && store.dispatch.paymentPlan.resetTerms();
    }
  }

  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.paymentPlan.resetPaymentPlan();
  }

  render() {
    const {
      makePayments: { makePayments },
      loader: { loading },
    } = this.props;
    return (
      makePayments && (
        <ContentPage
          className={isMobileDevice() ? styles.guestPayWrapper : String()}
          schemas={[makePayments]}
          loading={loading}
        />
      )
    );
  }
}
const mapStateToProps = ({ makePayments, modal, loader, creditCard, radioInput, paymentPlan, guestEntry, guest }) => ({
  makePayments,
  modal,
  loader,
  creditCard,
  radioInput,
  paymentPlan,
  guestEntry,
  guest,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps),
  graphql(GetMakePayments, {
    name: 'getMakePayments',
    options: ({
      location: { search, pathname },
      match: { params: { practiceId, patientId } },
      guestEntry: { guestMode },
      guest: { guestPaymentData },
    }) => ({
      variables: {
        patientId: patientId || parseParams({ location: { search } }).patientId,
        practiceId: practiceId || parseParams({ location: { search } }).practiceId,
        ...(guestMode ? { guestPaymentData } : {}),
        ...(!guestMode && pathname.includes('guest')
          ? { guestPaymentData: JSON.parse(sessionStorage.getItem('CC-Breeze-Web-persistedGuestData')) }
          : {}),
      },
    }),
  }),
);
export default enhancer(MakePaymentsContainer);
