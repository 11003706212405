import React, { useReducer } from 'react';

export const ContextProvider = React.createContext({});

const TopLevelProvider = ({ children }) => {
  const _initialTopLevelActions = {};
  const _topLevelActionsReducer = (state, action) => ({ ...state, [action.key]: action.value });
  const [_topLevelActions, dispatch] = useReducer(_topLevelActionsReducer, _initialTopLevelActions);
  const _getAction = action => _topLevelActions[action];
  const _setActions = (actions, options = {}) =>
    Object.entries(actions).forEach(
      ([k, v]) => (!_getAction(k) || options.overwrite) && dispatch({ key: k, value: v }),
    );

  return (
    <ContextProvider.Provider
      value={{ _setActions, _getAction, _topLevelActions, checkIn: _ => console.log('I Love', _) }}
    >
      {children}
    </ContextProvider.Provider>
  );
};

export default TopLevelProvider;
