import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactStars from 'react-stars';
import styled from 'styled-components';
import { times, isEmpty } from '@carecloud/cloudpak';
import classNames from 'classnames';
import styles from './rating.module.scss';

const PropsDependentFontAwesomeIconContainer = styled(FontAwesomeIcon)`
  font-size: ${props => props.starSize || 25}px;
  padding-left: 16px;
  width: 42px;
  padding-top: 1rem;
  color: #b0bec5;
`;

const rating = React.memo(
  ({
    supportingText,
    onMouseEnter,
    onMouseLeave,
    onClick,
    isFull,
    title,
    starColor,
    questionTitle,
    useHalfStars,
    selected,
    required,
    rawErrors = [],
    errors,
    requiredLabel,
    errorRequiredLabel,
    starSize,
  }) => (
    <div>
      <div className={questionTitle ? styles.questionTitle : styles.title}>
        {title || questionTitle}
        {required && (
          <span className={styles.required}>{requiredLabel || required}</span>
        )}{' '}
      </div>
      <div className={styles.wrapper}>
        {useHalfStars ? (
          <ReactStars
            value={selected}
            char="★"
            count={5}
            onChange={onClick()}
            size={45}
            color1="#dae0e3"
            color2="#f3a536"
          />
        ) : (
          times(5, number => (
            <div key={number} className={classNames(styles.fullIconWrapper)}>
              <div
                className={classNames(styles.halfIconWrapper)}
                role="menuitem"
                key={number}
                tabIndex="0"
                onMouseEnter={onMouseEnter(number + 1)}
                onMouseLeave={onMouseLeave}
                onClick={onClick(number + 1)}
              >
                <PropsDependentFontAwesomeIconContainer
                  key={number}
                  className={classNames('fa-horizontal', {
                    [styles[starColor]]: isFull(number + 1),
                  })}
                  icon={{
                    prefix: isFull(number + 1) ? 'fas' : 'far',
                    iconName: 'star',
                  }}
                  starSize={starSize}
                />
              </div>
            </div>
          ))
        )}
      </div>
      {!isEmpty(rawErrors)
        ? rawErrors.map(error => (
          <div className={styles.errorMessage} key={error}>
            {errorRequiredLabel}
          </div>
          ))
        : errors &&
          errors.map(error => (
            <div key={error} className={styles.errorMessage}>
              {error}
            </div>
          ))}
      {questionTitle && (
        <div
          className={classNames(styles.supportingText, {
            [styles.supportingTextGreen]: isFull(1),
          })}
        >
          {supportingText}
        </div>
      )}
    </div>
  ),
);

export const Rating = rating;
