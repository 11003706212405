import React, { useEffect, useState } from 'react';
import { ImageField, FormField } from '../styleguide';

export default ({
  required,
  name,
  formData: value,
  schema: { title: text, validationMessages, editText, removeText, uploadText },
  onChange,
  rawErrors,
}) => {
  const handleChange = ({ target: { value } }) => onChange(value === '' ? undefined : value);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Check if the field is required and value is not present
    if (required && !value) {
      setErrorMessage('This field is required');
    } else {
      // Otherwise, use the dynamic error message if available
      const errorId = Array.isArray(rawErrors) && rawErrors[0];
      const dynamicErrorMessage = validationMessages ? validationMessages[errorId] : errorId;
      setErrorMessage(dynamicErrorMessage || '');
    }
  }, [required, value, rawErrors, validationMessages]);

  return (
    <FormField error={errorMessage} required={required} value={value}>
      <ImageField
        name={name}
        editText={editText}
        removeText={removeText}
        text={text}
        uploadText={uploadText}
        value={value}
        onChange={handleChange}
      />
    </FormField>
  );
};
