import React from 'react';
import { searchMedications as graphqlSearchMedications } from '../../../graphql/queries';
import { FormField, ListControls, TypeheadField } from '../../styleguide';
import { getListed } from './Medications.reducer';

export default React.memo(({ formData = [], schema, dispatch, required, state }) => {
  const handleAdd = _ => dispatch({ type: 'addListed' });
  const handleClear = displayName => dispatch({ type: 'clearListed', displayName });
  const handleRemove = displayName => dispatch({ type: 'removeListed', displayName });
  const handleUpdate = (displayName, payload) => dispatch({ type: 'updateListed', displayName, payload });

  const { checkInIds } = schema.items;
  let listedItemCount = 0;

  return (
    <>
      {formData.map((medication, index) => {
        const displayOnUI = medication.action !== 'delete';
        displayOnUI && listedItemCount++;
        return (
          displayOnUI && (
            <>
              <FormField text={schema.items.title} required={required} value={medication?.displayName}>
                <TypeheadField
                  onChange={({ target: { value } }) => handleUpdate(medication?.displayName, value)}
                  disabled={medication?.displayName}
                  labelKey="displayName"
                  name={`medication_${index}`}
                  placeholder={schema.items.placeholder}
                  query={graphqlSearchMedications}
                  transformRequest={input => ({ ...input, checkInIds })}
                  transformResponse={output => output?.searchMedications || []}
                  value={medication?.action !== 'delete' ? medication : undefined}
                  valueKey="displayName"
                />
              </FormField>
              <ListControls
                addText={schema.items.addText}
                canAdd={!!medication?.displayName}
                canClear={!!medication?.displayName}
                canRemove={getListed(state).length > 1 &&  medication.allow_delete === true || medication.allow_delete === undefined}
                clearText={schema.items.clearText}
                onAdd={handleAdd}
                onRemove={_ => handleRemove(medication?.displayName)}
                onClear={_ => handleClear(medication?.displayName)}
                removeText={schema.items.removeText}
                showAdd={listedItemCount === getListed(state).length}
                showClear={!!medication?.displayName && getListed(state).length === 1}
                showRemove={getListed(state).length > 1 || !medication?.displayName}
              />
            </>
          )
        );
      })}
    </>
  );
});
