import React from 'react';
import InputMask from 'react-input-mask';
import moment from 'moment';
import styles from './inputField/InputField.module.scss';
import { DATE_MASK, DATE_BASE_FORMAT } from '../../constants/Utilities';

export default React.memo(props => {
  const {
    baseFormat = DATE_BASE_FORMAT,
    disabled,
    displayFormat = DATE_BASE_FORMAT,
    name,
    onChange,
    placeholder,
    value,
  } = props;

  const date = moment(value, baseFormat, true);
  const displayValue = date.isValid() ? date.format(displayFormat) : value;

  const handleChange = ({ target: { name, value } }) => {
    const newDate = moment(value, displayFormat, true);
    if (newDate.isValid()) {
      return onChange({ target: { name, value: newDate.format(baseFormat) } });
    }

    return onChange({ target: { name, value } });
  };

  return (
    <InputMask
      className={styles.input}
      disabled={disabled}
      mask={DATE_MASK}
      maskChar=""
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
      value={displayValue}
    />
  );
});
