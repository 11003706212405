import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { store } from '../../../models';
import { ContentPage } from '..';
import {
  GetGuestAlreadyDone,
  GetGuestAppointment,
  GetGuestPay,
  GetGuestPayNoCode,
  GetDelegateVerification,
  GetDelegateLogin,
  GetDelegateCreateAccount,
  GetDelegateForgotPassword,
  GetSmsSignupConfirmation,
  GetGuestRescheduleAppointment,
} from '../../../graphql/queries';
import { Guest as GuestConstants } from '../../../constants/index';
import { PageLoader } from '../../ui/page_loader/pageLoader';
import { camelCaseAppend, parseResult } from '../../../graphql/utilities';
import { isMobileDevice, breakBackButton } from '../../services/utilities';
import { parseParams } from '../../services/routing';
import styles from './GuestEntry.module.scss';
import { ContextProvider } from '../../root/TopLevelContext';

const PropsDependentGuestPayHeaderDiv = styled.div`
  & div[id*='guestPayHeader'] {
    background: ${props => props.whiteLabelColor ? props.whiteLabelColor : "url(https://assets.gobreeze.com/standalone-signup.jpg)"};
    img {
      margin: 0px !important;
      width: 15%;
    }

    h1[id*=guestPayPracticeNameLogo] {
      font-size: 53px !important;
    }

    @media screen and (max-width:576px){
      h1[id*=guestPayPracticeNameLogo] {
        font-size: ${props => props.practiceName?.length > 35 ? '40px' : "53px"} !important;
      }
    }
  }

  @media screen and (max-width:576px){
    & div[id*='guestPayHeader'] {
      background: ${props => props.whiteLabelColor ? props.whiteLabelColor : "url(https://assets.gobreeze.com/standalone-signup.jpg)"};
      img {
        margin: 0px !important;
        width: 50%;
        align-self: center;
      }
    }
  }

  @media screen and (max-width:566px){
    & div[class*=SingleDatePicker_picker] {
      top: -440px !important;
    }
  }

  & form[id*='guestPayNoCodeGroups'] input {
    color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
    :-webkit-autofill {
      -webkit-text-fill-color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
      -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.041) inset;
      -webkit-transition: background-color 5000s ease-in-out 0s;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  & form[id*='guestPayGroups'] input {
    color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
    :-webkit-autofill {
      -webkit-text-fill-color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
      -webkit-box-shadow: 0 0 0px 1000px rgba(255, 255, 255, 0.041) inset;
      -webkit-transition: background-color 5000s ease-in-out 0s;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  button[id*=loginLanguageDropdown] {
    color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
  }

  & [id*='guestPayLinkContainer'] a {
    color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
  }

  & [id*='guestPayNoCodeContinueBtn'] {
    color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
    border: 1px solid ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
  
    :hover {
      color: #ffffff !important;
      background-color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
    }
    
    :disabled {
      color: #cfd8dc !important;
      border: 1px solid #cfd8dc !important;
    }
  }

  & td[class*=CalendarDay__selected] {
    background-color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
  }

  & div[class*=DayPicker_weekHeader] {
    background-color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#2b6369"} !important;
  }

  & button[class*='saveBtn'] {
    background: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
  border: 2px solid ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
  }

  & [id*='guestPayContinueBtn'] {
    color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
    border: 1px solid ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
  
    :hover {
      color: #ffffff !important;
      background-color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
    }
    
    :disabled {c
      color: #cfd8dc !important;
      border: 1px solid #cfd8dc !important;
    }
  }
  
  & [id*='guestPayHaveCodeBtn'] {
    color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
    border: 1px solid ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
  
    :hover {
      color: #ffffff !important
      background-color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
    }
  }

  & div[class*=loginLanguageMenu] {
    button: hover {
      color: ${props => props.whiteLabelColor ? props.whiteLabelColor : "#5bc5d0"} !important;
    }
  }
`;

class GuestEntryContainer extends Component {
  static contextType = ContextProvider;
  componentDidMount() {
    this.context._setActions({ navigateGuestRoute: this.navigateGuestRoute, changeLanguage: this.changeLanguage });
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.guestEntry.setGuestMode(true);
    breakBackButton();
  }

  componentDidUpdate() {
    const {
      loader: { loading },
      formGroup,
      match: { params: { section } },
      guestEntry: { guestScreen, reloadGuest },
      error,
    } = this.props;

    if(error.errors && document.getElementById('errorText') !== null) {
      document.getElementById('errorText').textContent = error.errors[0]?.message;
      document.getElementById('errorText').style.display = 'block';
    }
    const queryName = `get${[camelCaseAppend(section)]}`;
    const query = this.props[queryName];
    const queryLoading = query && query.loading;
    if (!queryLoading && loading && !reloadGuest) store.dispatch.loader.activateLoader({ loading: false });
    if ((isEmpty(guestScreen) || reloadGuest) && !queryLoading) {
      store.dispatch.guestEntry.setReloadGuest(false);
      parseResult({
        query,
        dataField: queryName,
        resultHandler: store.dispatch.guestEntry.fetchStart,
      });
      store.dispatch.loader.persistLoader({ loading: false });
    }
    // guest register view
    if (section !== formGroup.formShown && !GuestConstants.FORMLESS_ENTRIES.includes(section)) {
      store.dispatch.formGroup.showForm(section);
      store.dispatch.loader.persistLoader({ loading: false });
    }
  }

  navigateGuestRoute = ({ route, clearParams = false }) => {
    const {
      history: { push },
      location: { search },
    } = this.props;
    store.dispatch.guestEntry.setReloadGuest(true);
    store.dispatch.loader.activateLoader({ loading: true });
    clearParams ? push(route) : push(`${route}${search}`);
  };

  changeLanguage = language => {
    if (localStorage.getItem('CC-Breeze-Web-language') === language) {
      store.dispatch.dropdown.toggleOpen('loginLanguageMenu');
      return;
    }
    localStorage.setItem('CC-Breeze-Web-language', language);
    window.location.reload();
  };

  render() {
    const {
      loader: { loading, persist },
      guestEntry: { guestScreen },
      match: { params: { section } },
    } = this.props;
    return (
      guestScreen && (
        <div
          className={classNames(
            isMobileDevice() ? styles.guestEntryWrapperMobile : styles.guestEntryWrapper,
            styles[`${section}Wrapper`],
            { [styles[`${section}WrapperMobile`]]: isMobileDevice() },
          )}
        >
          <PropsDependentGuestPayHeaderDiv 
            whiteLabelColor={guestScreen?.whiteLabelColor} 
            practiceName={parseParams(this.props).practice_name}
          >
            <ContentPage
              className={isMobileDevice() ? styles.guestMobileContentPage : styles.guestContentPage}
              schemas={
                //eslint-disable-next-line no-nested-ternary
                section === 'guestPayNoCode' ? 
                  [guestScreen.guestPayNoCodeScreen] : 
                  section === 'guestPay' ? 
                    [guestScreen.guestPayScreen] : 
                    [guestScreen]
              }
              loading={loading}
            />
            {(loading || persist) && <PageLoader loading={loading} />}
          </PropsDependentGuestPayHeaderDiv>
        </div>
      )
    );
  }
}
const mapStateToProps = ({ guestEntry, loader, formGroup, error }) => ({
  error,
  guestEntry,
  loader,
  formGroup,
});
const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps),
  graphql(GetGuestAppointment, {
    name: 'getGuestAppointment',
    skip: ({ match: { params: { section } } }) => section !== 'guestAppointment',
  }),
  graphql(GetGuestRescheduleAppointment, {
    name: 'getGuestRescheduleAppointment',
    skip: ({ match: { params: { section } } }) => section !== 'guestRescheduleAppointment',
  }),
  graphql(GetGuestPay, {
    name: 'getGuestPay',
    options: props => ({ variables: { input: { code: parseParams(props).code || String(), guestPayLanding: true, practice_name: parseParams(props).practice_name || String(),  practice_id: parseParams(props).practice_id || String(), practice_mgmt: parseParams(props).practice_mgmt || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'guestPay',
  }),
  graphql(GetGuestPayNoCode, {
    name: 'getGuestPayNoCode',
    options: props => ({ variables: { input: { practice_name: parseParams(props).practice_name || String(),  practice_id: parseParams(props).practice_id || String(), practice_mgmt: parseParams(props).practice_mgmt || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'guestPayNoCode',
  }),
  graphql(GetGuestAlreadyDone, {
    name: 'getGuestAlreadyDone',
    options: props => ({ variables: { input: { whatDone: parseParams(props).done || String(), practice_name: parseParams(props).practice_name || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'guestAlreadyDone',
  }),
  graphql(GetDelegateVerification, {
    name: 'getDelegateVerification',
    options: props => ({ variables: { input: { invitationCode: parseParams(props).code || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'delegateVerification',
  }),
  graphql(GetDelegateLogin, {
    name: 'getDelegateLogin',
    options: props => ({ variables: { input: { invitationCode: parseParams(props).code || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'delegateLogin',
  }),
  graphql(GetDelegateForgotPassword, {
    name: 'getDelegateForgotPassword',
    skip: ({ match: { params: { section } } }) => section !== 'delegateForgotPassword',
  }),
  graphql(GetDelegateCreateAccount, {
    name: 'getDelegateCreateAccount',
    options: props => ({ variables: { input: { invitationCode: parseParams(props).code || String() } } }),
    skip: ({ match: { params: { section } } }) => section !== 'delegateCreateAccount',
  }),
  graphql(GetSmsSignupConfirmation, {
    name: 'getSmsSignupConfirmation',
    options: props => ({ variables: { email: parseParams(props).email || String() } }),
    skip: ({ match: { params: { section } } }) => section !== 'smsSignupConfirmation',
  }),
);
export default enhancer(GuestEntryContainer);
