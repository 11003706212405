import React from 'react';
import styles from './Help.module.scss';

export default React.memo(props => {
  const { children, text } = props;

  return (
    <div className={styles.help}>
      <div className={styles.primary}>{children}</div>
      <div className={styles.secondary}>{text}</div>
    </div>
  );
});
