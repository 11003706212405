export const toast = {
  state: {
    open: false,
    transform: false,
    data: {},
    timeOutSet: false,
  },
  reducers: {
    resetToast: state => ({
      ...state,
      open: false,
      data: {},
      transform: false,
      timeOutSet: false,
    }),
    open: (state, data) => ({
      ...state,
      open: true,
      data,
    }),
    close: state => ({
      ...state,
      open: false,
      timeOutSet: false,
      transform: false,
    }),
    transform: state => ({
      ...state,
      transform: true,
    }),
    timerSet: state => ({
      ...state,
      timeOutSet: true,
    }),
    error: (state, message) => ({
      ...state,
      open: true,
      data: {
        type: 'error',
        message,
      },
    }),
  },
};
