import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import React from 'react';
import moment from 'moment';
import styles from './paymentSectionCollapsed.module.scss';
import { formatMoney } from '../../services/utilities';
import { SchemaParser } from '../../services';

const PaymentSectionContainer = React.memo(
  ({
    payment,
    message,
    date,
    processing,
    avatar,
    progress,
    format,
    id,
    modal: { modalOpen },
    modals,
    toggleOpen,
  }) => {
    const clickHandler = modalId => toggleOpen(modalId);
    return (
      <div className={styles.wrapper}>
        <div
          className={styles.container}
          role="button"
          tabIndex="0"
          onClick={() => clickHandler(id)}
        >
          <div className={styles.leftSide}>
            {avatar && <SchemaParser schema={[avatar]} />}
            <div className={styles.leftVertical}>
              <div className={styles.paymentProcessing}>
                {payment && (
                  <h2 className={styles.total}>
                    ${formatMoney({ amount: payment })}
                  </h2>
                )}
                {processing && (
                  <div className={styles.processing}>processing</div>
                )}
              </div>
              <h4 className={styles.message}>{message}</h4>
            </div>
          </div>
          <div className={styles.rightSide}>
            {date && (
              <div className={styles.date}>
                {!format
                  ? date
                  : moment(date)
                      .local()
                      .format(format)}
              </div>
            )}
            {progress && <SchemaParser schema={[progress]} />}
          </div>
        </div>
        {modals && modalOpen === modals.id && (
          <SchemaParser schema={[modals]} />
        )}
      </div>
    );
  },
);

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = ({ modal }) => ({
  toggleModal: modal.toggleModal,
  toggleOpen: modal.toggleOpen,
});

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));
export default enhancer(PaymentSectionContainer);
