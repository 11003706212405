import React from 'react';
import classNames from 'classnames';
import styles from './pageLoader.module.scss';
import {
  LARGE_LOADER_SIZE,
  LARGE_LOADER_THICKNESS,
} from '../../../constants/Loader';

const pageLoader = React.memo(
  ({
    className = '',
    size = LARGE_LOADER_SIZE,
    thickness = LARGE_LOADER_THICKNESS,
    overlay = true,
    fixed = true,
  }) => (
    <div
      className={classNames({
        [styles.overlay]: overlay,
        [styles.fixed]: fixed,
      })}
    >
      <div
        className={classNames(styles.root, styles[className])}
        style={{ width: size, height: size }}
        role="progressbar"
      >
        <svg
          className={styles.svg}
          viewBox={`${size / 2} ${size / 2} ${size} ${size}`}
        >
          <circle
            className={styles.circle}
            cx={size}
            cy={size}
            r={(size - thickness) / 2}
            fill="none"
            strokeWidth={thickness}
          />
        </svg>
      </div>
    </div>
  ),
);

export const PageLoader = pageLoader;
