import { Component } from 'react';
import ReactDOM from 'react-dom';
import { EVENTS } from './ClickAwayListener.constants';

class ClickAwayListener extends Component {
  componentDidMount() {
    this.bind();
  }

  componentDidUpdate(prevProps) {
    prevProps.onClickAway !== this.props.onClickAway && this.rebind();
  }

  componentWillUnmount() {
    this.unbind();
  }

  handleClickAway = event => {
    if (event.defaultPrevented) return;
    const el = ReactDOM.findDOMNode(this);
    if (document.documentElement.contains(event.target) && !this.isDesc(el, event.target)) {
      this.props.onClickAway(event);
    }
  };

  isDesc = (el, target) => {
    if (!target) return false;
    return el === target || this.isDesc(el, target.parentNode);
  };

  bind = _ => this.props.onClickAway && EVENTS.forEach(e => document.addEventListener(e, this.handleClickAway));
  unbind = _ => EVENTS.forEach(e => document.removeEventListener(e, this.handleClickAway));
  rebind = _ => {
    this.unbind();
    this.bind();
  };
  render() {
    return this.props.children;
  }
}

export default ClickAwayListener;
