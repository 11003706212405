import React, { useEffect, useState } from 'react';
import { withApollo, graphql } from 'react-apollo';
import { withRouter ,useHistory} from 'react-router-dom';
import { compose } from '@carecloud/cloudpak';
import { connect } from 'react-redux';
import { store } from '../../../models';
import { GetAvailablevertexTime } from '../../../graphql/queries';
import {  vertexRequestAppointmentInput } from '../../../graphql/mutations';
import { VertixavailbleTimeContext as vertexAvailableTimes  } from '../../../contexts';
import { SchemaParser } from '../../services';
import styles from '../surveys/Surveys.module.scss';

const VertexAvailableHourse = props => {
  const [availableVertexTime, setAvailableVertexTime] = useState({});
  const history = useHistory();
  let chief_complaint = "";
  let firstavailble_time;
  let Week_any_check;
  let this_week;
  let next_Week;
  //Days of week
  let days_of_week_Check;
  let monday;
  let tuesday;
  let wednesday;
  let thursday;
  let friday;
  //times of day
  let times_of_Day_check;
  let earlyMorning;
  let latemorning;
  let earlyEvening;
  let lateEvening;
  async function fetchAvailableVertexTime() {
    store.dispatch.loader.activateLoader({ loading: true });
    try {
      const{
        selectedProvider: {
          resourceId: resource_ids,
          practiceId: practice_id,
          patientId: patient_id,
          specialty,
          name: providerName,
          photo = String(),
          },
        locationId,
        addressLine1,
        addressLine2,
        addressLine3,
        city,
        zip,
        phone,
        office,
        locationGuid,
        visitType,
      } = store.getState().addAppointment
      if (!(locationId && patient_id && practice_id && resource_ids)) {
        history.push('/appointments');
        store.dispatch.loader.activateLoader({ loading: false })
      }
      const {
        data: { getAvailableVertexTime },
      } = await props.client.query({
        query: GetAvailablevertexTime,
        variables: {
        input: {
            Provider: providerName,
            patient_id,
            photo,
            practice_id,
            resource_ids,
            specialty,
            locationId,
            addressLine1,
            addressLine2,
            addressLine3,
            city,
            zip,
            phone,
            office,
            locationGuid,
            visitType,
          },
        },
      });
      setAvailableVertexTime(getAvailableVertexTime.VertexAppointmentScreen);
      store.dispatch.loader.activateLoader({ loading: false });
    } catch (error) {
      store.dispatch.loader.activateLoader({ loading: false });
      console.log('Error getting available vertex time response -> ', error);
    }
  }
  useEffect(() => {
      fetchAvailableVertexTime();
      store.dispatch.appointments.resetState();
  },[]);
  const Disbaled_enabledButton = () => {
    const isButtonEnabled =  (Week_any_check || this_week || next_Week ) && (days_of_week_Check || monday || tuesday || wednesday || thursday || friday) && ( times_of_Day_check || earlyMorning || latemorning || earlyEvening || lateEvening);
    document.getElementById('RequestAppointmentButton').disabled = !isButtonEnabled;
    if(this_week || next_Week || monday || tuesday || wednesday || thursday || friday  || earlyMorning || latemorning || earlyEvening || lateEvening){
      document.getElementById('first_Availble_Time').disabled = true;
    }else{
      document.getElementById('first_Availble_Time').disabled = false;
    }
    if(this_week || next_Week){
      document.getElementById('Week_CheckBoxs').disabled = true;
    }else{
      document.getElementById('Week_CheckBoxs').disabled = false;
    }
    if(monday || tuesday || wednesday || thursday || friday){
      document.getElementById('days_of_week').disabled =  true;
    }else{
      document.getElementById('days_of_week').disabled =  false;
    }
    if(earlyMorning || latemorning || earlyEvening || lateEvening){
      document.getElementById('times_of_Day').disabled =  true;
    }else{
      document.getElementById('times_of_Day').disabled =  false;
    }
  }
const  firstAvialbeltime = ()=>{
   firstavailble_time = document.getElementById('first_Availble_Time').checked;
   document.getElementById('RequestAppointmentButton').disabled = !firstavailble_time;
  if(firstavailble_time === true){
    document.getElementById('Week_CheckBoxs').checked = true;
    document.getElementById('days_of_week').checked =  true;
    document.getElementById('times_of_Day').checked =  true;

    document.getElementById('timeCard_Group').classList.add('timeCard_Group_DIsabled');
    document.querySelector('label[for="This_week"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Next_Week"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Monday_checkbox"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Tuesday_checkbox"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Wednesday_checkbox"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Thursday_checkbox"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Friday_checkbox"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Early_Morning"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Late_Morning"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Early_Afternoon"]').classList.add('week_DIsabled');
    document.querySelector('label[for="Late_Afternoon"]').classList.add('week_DIsabled');
    }else{
    document.getElementById('Week_CheckBoxs').checked = false;
    document.getElementById('days_of_week').checked =  false;
    document.getElementById('times_of_Day').checked =  false;

    document.getElementById('timeCard_Group').classList.remove('timeCard_Group_DIsabled');
    document.querySelector('label[for="This_week"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Next_Week"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Monday_checkbox"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Tuesday_checkbox"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Wednesday_checkbox"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Thursday_checkbox"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Friday_checkbox"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Early_Morning"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Late_Morning"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Early_Afternoon"]').classList.remove('week_DIsabled');
    document.querySelector('label[for="Late_Afternoon"]').classList.remove('week_DIsabled');
    }
  }
  //week
  const  Week_CheckBoxs = _ =>{
    Week_any_check = document.getElementById('Week_CheckBoxs').checked;
    if(Week_any_check === true){
      document.querySelector('label[for="This_week"]').classList.add('week_DIsabled');
      document.querySelector('label[for="Next_Week"]').classList.add('week_DIsabled');
    }else{
      document.querySelector('label[for="This_week"]').classList.remove('week_DIsabled');
      document.querySelector('label[for="Next_Week"]').classList.remove('week_DIsabled');
    }
    Disbaled_enabledButton();
  }
  const  This_week = _ =>{
    this_week = document.getElementById('This_week').checked;
    Disbaled_enabledButton();
  }
  const  Next_Week = _ =>{
    next_Week = document.getElementById('Next_Week').checked;
    Disbaled_enabledButton();
  }
//Days of week
  const  days_of_week = _ =>{
    days_of_week_Check = document.getElementById('days_of_week').checked;
    if(days_of_week_Check === true){
      document.querySelector('label[for="Monday_checkbox"]').classList.add('week_DIsabled');
      document.querySelector('label[for="Tuesday_checkbox"]').classList.add('week_DIsabled');
      document.querySelector('label[for="Wednesday_checkbox"]').classList.add('week_DIsabled');
      document.querySelector('label[for="Thursday_checkbox"]').classList.add('week_DIsabled');
      document.querySelector('label[for="Friday_checkbox"]').classList.add('week_DIsabled');    
    }else{
      document.querySelector('label[for="Monday_checkbox"]').classList.remove('week_DIsabled');
      document.querySelector('label[for="Tuesday_checkbox"]').classList.remove('week_DIsabled');
      document.querySelector('label[for="Wednesday_checkbox"]').classList.remove('week_DIsabled');
      document.querySelector('label[for="Thursday_checkbox"]').classList.remove('week_DIsabled');
      document.querySelector('label[for="Friday_checkbox"]').classList.remove('week_DIsabled');
    }
    Disbaled_enabledButton();
  }
  const  Monday_checkbox = _ =>{
    monday =  document.getElementById('Monday_checkbox').checked
    Disbaled_enabledButton();
  }
  const  Tuesday_checkbox = _ =>{
    tuesday = document.getElementById('Tuesday_checkbox').checked
    Disbaled_enabledButton();
  }
  const  Wednesday_checkbox = _ =>{
    wednesday = document.getElementById('Wednesday_checkbox').checked
    Disbaled_enabledButton();
  }
  const  Thursday_checkbox = _ =>{
    thursday = document.getElementById('Thursday_checkbox').checked
    Disbaled_enabledButton();
  }
  const  Friday_checkbox = _ =>{
    friday = document.getElementById('Friday_checkbox').checked
    Disbaled_enabledButton();
  }
//times of day
  const  times_of_Day = _ =>{
    times_of_Day_check = document.getElementById('times_of_Day').checked
    if(times_of_Day_check === true){
      document.querySelector('label[for="Early_Morning"]').classList.add('week_DIsabled');
      document.querySelector('label[for="Late_Morning"]').classList.add('week_DIsabled');
      document.querySelector('label[for="Early_Afternoon"]').classList.add('week_DIsabled');
      document.querySelector('label[for="Late_Afternoon"]').classList.add('week_DIsabled');
    }else{
      document.querySelector('label[for="Early_Morning"]').classList.remove('week_DIsabled');
      document.querySelector('label[for="Late_Morning"]').classList.remove('week_DIsabled');
      document.querySelector('label[for="Early_Afternoon"]').classList.remove('week_DIsabled');
      document.querySelector('label[for="Late_Afternoon"]').classList.remove('week_DIsabled');
    }
    Disbaled_enabledButton();
  }
  const  Early_Morning = _ =>{
    earlyMorning =document.getElementById('Early_Morning').checked
    Disbaled_enabledButton();
  }
  const  Late_Morning = _ =>{
    latemorning =document.getElementById('Late_Morning').checked
    Disbaled_enabledButton();
  }
  const  Early_Afternoon = _ =>{
    earlyEvening =document.getElementById('Early_Afternoon').checked
    Disbaled_enabledButton();
  }
  const  Late_Afternoon = _ =>{
    lateEvening =document.getElementById('Late_Afternoon').checked
    Disbaled_enabledButton();
  }

  const AppointmentComment = ({ textAreaValue }) => {
     console.log(textAreaValue)
      chief_complaint = textAreaValue;
  };

  async function  requestVertexAppointment () {
   const week = [];
   const weekString="";
   const daysof_week = [];
   const days_of_week = "";
   const timesof_day = [];
   const times_of_day = "";
    const{
      selectedProvider: {
        resourceId: resource_ids,
        patientId: patient_id,
        practiceId: practice_id,
        providerId: provider_id,
        practiceMgmt: practice_mgmt,
      },
      locationId: location_ids,
      visitReasonId: visit_reason_id,
    } = store.getState().addAppointment
  const input = {
    practice_mgmt,
    practice_id,
    provider_id,
    resource_ids,
    patient_id,
    location_ids,
    visit_reason_id,
    weekString,
    days_of_week,
    times_of_day,
    chief_complaint,
  };
  if(firstavailble_time === true){
    week.push ("Any");
    daysof_week.push("Any");
    timesof_day.push("Any");
  }
      if(Week_any_check === true){
          week.push ( "Any");
         }
        if (this_week === true) {
           week.push("This week");
           }
          if (next_Week === true) {
             week.push("Next week");
            }
             input.weekString = week.join(',');
        if(days_of_week_Check === true){
           daysof_week.push("Any");
          }
         if(monday === true){
            daysof_week.push("Monday");
          }
          if(tuesday === true){
            daysof_week.push("Tuesday");
           }
           if(wednesday === true){
             daysof_week.push("Wednesday");
             }
            if(thursday === true){
              daysof_week.push("Thursday");
              }
              if(friday === true){
                daysof_week.push("Friday");
                }
                input.days_of_week = daysof_week.join(',');



   if(times_of_Day_check === true){
        timesof_day.push("Any");
      }
      if(earlyMorning === true){
        timesof_day.push("Early Morning");
      }
      if(latemorning === true){
        timesof_day.push("Late Morning");
      }
      if(earlyEvening === true){
        timesof_day.push("Early Evening");
      }
      if(lateEvening === true){
        timesof_day.push("Late Evening");
      }
      input.times_of_day = timesof_day.join(',');
  try{
        store.dispatch.loader.persistLoader({ persist: true });
        const data = await props.client.mutate({
          mutation: vertexRequestAppointmentInput,
          variables: { input },
        })
        if (data.data?.getVertexRequestAppointment?.status === 200) {
          store.dispatch.addAppointment.clearStore();
          store.dispatch.intelligentScheduler.clearAll();
          store.dispatch.addAppointment.resetSelectedOptions();
          store.dispatch.loader.persistLoader({ persist: false });
              store.dispatch.modal.toggleOpen('requestApptSuccessModal');
              // history.push('/appointments');
            }

  console.log(data)
  }catch(error){
   console.log("error",error)
  }
}
  return (
    <vertexAvailableTimes.Provider
      value={{
        firstAvialbeltime,
        Week_CheckBoxs,
        This_week,
        Next_Week,
        times_of_Day,
        Early_Morning,
        Late_Morning,
        Early_Afternoon,
        Late_Afternoon,
        days_of_week,
        Monday_checkbox,
        Tuesday_checkbox,
        Wednesday_checkbox,
        Thursday_checkbox,
        Friday_checkbox,
        AppointmentComment,
        requestVertexAppointment,
      }}
    >
      <div className={styles.VertexScreen}>
        <SchemaParser schema={[availableVertexTime]} />
      </div>
    </vertexAvailableTimes.Provider>
  );
};
const mapStateToProps = ({ loader }) => ({
  loader,
});

const enhancer = compose(
  withApollo,
  withRouter,
  connect(mapStateToProps),
  graphql(vertexRequestAppointmentInput, { name: 'vertexRequestAppointmentInput' }),
  graphql(GetAvailablevertexTime, { name: 'GetAvailablevertexTime' }),
);
export default enhancer(VertexAvailableHourse);

