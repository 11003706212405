import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './selector.module.scss';
import { SchemaParser } from '../../services';
import { store } from '../../../models';

const SelectorContainer = ({ pages, headings, id, theme }) => {
  useEffect(_ => store.dispatch.formGroup.showForm(pages[0].id), []);
  return (
    <div id={id} className={classNames(styles.wrapper, styles[theme])}>
      <div className={styles.headingWrapper}>
        {headings.map((heading, index) => (
          <div key={heading} className={styles.headingSection}>
            <p>{heading}</p>
            {index === 0 && <div className={styles.arrow} />}
          </div>
        ))}
      </div>
      <SchemaParser schema={pages} />
    </div>
  );
};

export default SelectorContainer;
