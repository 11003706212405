import React from 'react';
import styles from './ImageField.module.scss';
import { Button } from '..';
import { compressImage } from '../../services/utilities';
import { store } from '../../../models';

export default React.memo(props => {
  const {
    accept = '.jpg, .jpeg, .png, .tif, .tiff, .gif',
    editText,
    name,
    onChange,
    removeText,
    text,
    uploadText,
    value,
    compression = true,
  } = props;

  const fileInput = React.createRef();

  const handleChange = value => {
    onChange({
      target: {
        name,
        value,
      },
    });
  };

  const openUploader = () => fileInput.current.click();

  const handleFileChange = ev => {
    const file = (ev?.target?.files || [])[0];
    if (!file) return;

    if (compression) {
      return compressImage(file, (_, result) => handleChange(result));
    }

    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = ev => handleChange(ev.target.result);
  };
  const onInputClick = event => {
     // eslint-disable-next-line
    event.target.value = ''
  }
  const Card_Required =    store.getState().settings.required_Bit_2
  const Photo_Required_labled_front = Card_Required === "true" || Card_Required === true  ? ( <> {"Front"}<span style={{ color: 'red' }}>*</span> </>) : (text);
  return (
    <div className={styles.wrapper}>
      <div className={styles.fileInput}>
        <input type="file" accept={accept} ref={fileInput} onChange={handleFileChange} onClick={onInputClick} />
      </div>
      {value && (
        <>
          <div className={styles.preview}>
            <div className={styles.previewText}>{text}</div>
            <div className={styles.previewImage}>
              <img src={value} alt={text} />
            </div>
          </div>
          <div className={styles.action}>
            <Button secondary long low text={removeText} destructive onClick={_ => handleChange()} />
          </div>
          <div className={styles.action}>
            <Button secondary long low text={editText} main onClick={_ => openUploader()} />
          </div>
        </>
      )}
      {!value && (
        <>
          {text ==="Front*"?<div className={styles.red}>{Photo_Required_labled_front}</div>:<div className={styles.text}>{text}</div>}
          <div className={styles.action} />
          <div className={styles.action}>
            <Button secondary long low text={uploadText} main onClick={_ => openUploader()} />
          </div>
        </>
      )}
    </div>
  );
});
