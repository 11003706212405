import React from 'react';
import styled from 'styled-components';
import { LinkButton } from '.';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button {
    margin: 0 1rem 0 0;
  }

  & > button:last-child {
    margin: 0;
  }
`;

export default React.memo(
  ({
    addText,
    canAdd,
    canClear,
    canRemove,
    clearText,
    onAdd,
    onClear,
    onRemove,
    onSave,
    removeText,
    showAdd,
    showClear,
    showRemove,
    showSave,
  }) => {
    return (
      <Container>
        {showClear && <LinkButton color="danger" text={clearText} onClick={onClear} disabled={!canClear} />}
        {showRemove && <LinkButton color="danger" text={removeText} onClick={onRemove} disabled={!canRemove} />}
        {showAdd && <LinkButton color="primary" text={addText} onClick={onAdd} disabled={!canAdd} />}
        {showSave && <LinkButton color="primary" text="Save" onClick={onSave} />}
      </Container>
    );
  },
);
