export const signup = {
  state: { signupScreen: [] },

  reducers: {
    fetchStart: (state, { signupScreen }) => ({
      ...state,
      signupScreen,
    }),
    storeEmail: (state, { email }) => ({
      ...state,
      email,
    }),
    storePatientData: (state, { patientId, username }) => ({
      ...state,
      patientId,
      username,
    }),
    setEmailResent: (state, { emailSent }) => ({
      ...state,
      emailSent,
    }),
    setUpdatedEmail: (state, { updatedEmail }) => ({
      ...state,
      updatedEmail,
    }),
    resetSignUp: state => ({
      ...state,
      signupScreen: [],
    }),
  },
};
