export const Events = {
  APPOINTMENT_REQUESTED: 'Appointment Requested',
  APPOINTMENT_CANCELLED: 'Appointment Cancelled',
  CHECKIN_STARTED: 'Check-in Started',
  CHECKOUT_STARTED: 'Check-out Started',
  CHECKIN_TIME: 'Check-in Time',
  CHECKOUT_TIME: 'Check-out Time',
  UPDATE_ALLERGIES: 'Updated Allergies',
  UPDATE_MEDICATIONS: 'Updated Medications',
  ADD_INSURANCE_PHOTO: 'Add Insurance Photo',
  ADD_IDENTITY_DOCUMENT: 'Add Identity Document',
  PAY_LATER_SELECTED: 'Pay Later Selected',
  SUCCESSFUL_LOGIN: 'Successful Login',
  UPDATED_DEMOGRAPHICS: 'Updated Demographics',
  UPDATED_NOTIFICATIONS: 'Updated Notifications Settings',
  UPDATED_EMAIL_NOTIFICATIONS_SETTINGS: 'Has Email Notifications Enabled',
  UPDATED_SMS_NOTIFICATIONS_SETTINGS: 'Has Text Notifications Enabled',
  SHOP_LAUNCHED: 'Launch Retail Store',
  START_RETAIL_CHECKOUT: 'Start Retail Checkout',
  NEW_USER_SIGNUP: 'New User Signup',
  VIEW_MEDICATION_EDUCATION: 'View Medication Education',
  VIEW_MEDICATIONS_DETAIL: 'View Medications Detail',
  VIEW_ALLERGIES_DETAIL: 'View Allergies Detail',
  HELP_BUTTON_CLICKED: 'Help Button Clicked',
  VIEW_MEDICAL_RECORD: 'View Medical Record',
  VIEW_LAB_RESULT: 'View Lab Result',
  COMPLETED_FORMS: 'Completed Forms',
  CHECKIN_COMPLETED: 'Check-in Completed',
  CHECKOUT_COMPLETED: 'Check-out Completed',
  TOTAL_CHECKINS_COMPLETED: 'Total Check-ins Completed',
  TOTAL_CHECKOUTS_COMPLETED: 'Total Checkouts Completed',
  TOTAL_PAYMENTS_MADE: 'Total Payments Made',
  FULL_PAYMENT_SELECTED: 'Full Payment Selected',
  PARTIAL_PAYMENT_SELECTED: 'Partial Payment Selected',
  PAYMENT_METHOD_SELECTED: 'Selected Payment Method',
  PREPAYMENT_STARTED: 'Prepayment Started',
  APPOINTMENT_CANCELLATION_STARTED: 'Appointment Cancellation Started',
  CANCELLATION_PAYMENT_STARTED: 'Cancellation Payment Started',
  ONETIME_PAYMENT_STARTED: 'One-Time Payment Started',
  PAYMENT_PLAN_EDITED: 'Payment Plan Edited',
  PAYMENT_PLAN_STARTED: 'Payment Plan Started',
  PAYMENT_PLAN_CREATED: 'Payment Plan Created',
  PAYMENT_PLAN_SUBMITTED: 'Payment Plan Submitted',
  PAYMENT_STARTED: 'Payment Started',
  UPDATED_CREDIT_CARDS: 'Updated Credit Cards',
  FAILED_PAYMENT: 'Failed Payment',
  COMPLETE_PAYMENT: 'Complete Payment',
  LEFT_SHOP: 'Left Retail Store',
  TOTAL_SURVEYS_COMPLETE: 'Total Surveys Completed',
  TOTAL_SATISFIED_SURVEYS_COMPLETE: 'Total Satisfied Surveys Completed',
  TOTAL_UNSATISFIED_SURVEYS_COMPLETE: 'Total Unsatisfied Surveys Completed',
  TOTAL_PAYMENT_PLAN_CANCELLED: 'Total Payment Plans Cancelled',
  TOTAL_ONE_TIME_PAYMENTS_MADE: 'Total One Time Payments Made',
  TOTAL_PREPAYMENTS_MADE: 'Total Prepayments Made',
  TOTAL_SCHEDULED_PAYMENT_DELETED: 'Total Scheduled Payments Scheduled',
  TOTAL_SCHEDULED_PAYMENTS: 'Total Scheduled Payments Deleted',
  TOTAL_PAYMENTS_AMOUNT: 'Total Payments Amount',
  SHOPPING_SESSION_TIME: 'Shopping Session Time',
  SURVEY_TIME: 'Survey Time',
  SURVEY_STARTED: 'Survey Started',
  SURVEY_COMPLETED: 'Survey Completed',
  SURVEY_CANCELLED: 'Survey Cancelled',
  CHECKIN_PATIENT_FORMS: 'Check-in Patient Forms Time',
  CHECKIN_DEMOGRAPHICS_DOCUMENTS: 'Check-in Demographics, Identification & Health Insurance Time',
  CHECKIN_MEDICATIONS_ALLEGRIES: 'Check-in Medications & Allergies Time',
  CHECKIN_INTAKE_FORMS: 'Check-in Intake Forms Time',
  CHECKOUT_NEXT_APPOINTMENT: 'Check-out Next Appointment Time',
  CHECKOUT_PAYMENT: 'Check-out Payment Time',
  CHECKIN_QR_TOGGLED: 'Check-in QR Code',
  CHECKIN_RESUMED: 'Check-in Resumed',
  CHECKIN_COMPLETED_FORMS: 'Check-in Completed Forms',
  UPDATE_EMAIL_CLICKED: 'Change Email clicked',
  UPDATE_PASSWORD_CLICKED: 'Change Password clicked',
  CREATE_NEW_ACCOUNT: 'New User Signup',
  CHECKOUT_CANCELLED: 'Check-out Cancelled',
  CHECKIN_CANCELLED: 'Check-in Cancelled',
};

export const Actions = {
  CHECKIN_FETCH_START: 'appointmentCheckIn/fetchStart',
  FORM_GROUP_CHANGE: 'formGroup/change',
  FORM_GROUP_SELECT_CHANGE: 'formGroup/selectChange',
  MODAL_OPEN: 'modal/toggleOpen',
  MIXPANEL_ADD_META_DATA: 'mixpanel/addMetadata',
  CHECKOUT_START: 'appointmentCheckOut/storeAppointmentData',
  SHOP_LAUNCHED: 'shop/resetState',
  START_SHOP_CHECKOUT: 'shopMakePayment/fetchStart',
  RADIO_SELECTED: 'radioInput/updateValue',
  PREPAYMENT_STARTED: 'appointmentPrepayment/fetchStart',
  FORM_SUBMITTED: 'form/toggleSubmit',
  CHECKIN_QR_TOGGLED: 'appointments/toggleOverlay',
};

export const Properties = {
  PASSWORD: 'Password',
  CHECK_IN: 'check_in',
  CHECK_OUT: 'check_out',
  SETTINGS_DEMOGRAPHICS: 'profile_settings/demographics',
  INFO_CHECK_PAGE: 'infoCheckPage',
  MEDS_ALLERGIES: 'medsAllergyPage',
  MEDS_TABLE: 'medicationsTable',
  ALLERGIES_TABLE: 'allergiesTable',
  CREDIT_CARD: 'Credit Card',
  PAYMENT_PLAN: 'paymentPlan',
  CREATE_PAYMENT_PLAN: 'Create Payment Plan',
  ADD_TO_EXISTING_PLAN: 'Add To Existing Plan',
  MONTHLY: 'monthly',
  CARD_ON_FILE: 'Card on File',
  NEW_CARD: 'New Card',
  PROFILE_SETTINGS: 'profile_settings',
  MEDS_ALLERGIES_PAGE: 'medsAllergiesPage',
  INTAKE_FORMS: 'Intake Forms',
  PATIENT_FORMS: 'Consent Forms',
  PRACTICE_FORM: 'practice_forms',
  CONSENT_FORM: 'consent_form',
  UPDATED_DEMOGRAPHICS: 'demographicsUpdated',
  SUPPORT_PATH: '/support',
  WEEKLY: 'weekly',
  OTHER: 'Other',
};

export const FormGroupChanges = {
  CHECKIN_INSURANCE_UPLOAD: 'checkInDocumentSectionInsuranceUpload',
  CHECKIN_ID_UPLOAD: 'checkInDocumentSectionIdentityLabel',
  SMS_NOTIFICATIONS: 'profileSettingsSmsNotifications',
  EMAIL_NOTIFICATIONS: 'profileSettingsEmailNotifications',
};

export const FormDescriptions = {
  infoCheckPage: 'Information Check',
  medsAllergyPage: 'Medications and Allergies',
  patientInfo: 'Consent Forms',
  intakeForms: 'Intake Forms',
};

export const DayOfWeekMap = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};
