import React from 'react';
import classNames from 'classnames';
import styles from './checkmark.module.scss';

const checkmark = () => (
  <div className={styles.checkmarkWrapper}>
    <div className={styles.circleLoader}>
      <div className={classNames(styles.checkmark, styles.draw)} />
      <div className={styles.cross} />
    </div>
  </div>
);

export const Checkmark = checkmark;
