export const shopMakePayment = {
  state: { shopMakePayment: [] },
  reducers: {
    fetchStart: (state, { shopMakePaymentScreen, paymentData }) => ({
      ...state,
      shopMakePayment: shopMakePaymentScreen,
      paymentData,
    }),
    clearStore: _ => ({ shopMakePayment: [] }),
    storeSuccessPath: (state, path) => ({ ...state, path }),
  },
};
