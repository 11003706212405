import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { compose, isEmpty, compact, get } from '@carecloud/cloudpak';
import classNames from 'classnames';
import { store } from '../../../models';
import { ContentPage } from '..';
import { GetMessages } from '../../../graphql/queries';
import { SchemaParser } from '../../services';
import { parseResult } from '../../../graphql/utilities';
import styles from './messages.module.scss';
import { breakBackButton } from '../../services/utilities';

class MessagesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isMobile: window.innerWidth < 567,
    };
  }
  componentDidMount() {
    breakBackButton();
    window.addEventListener('resize', this.handleResize);
  }
  componentWillReceiveProps({
    loader: { loading },
    messages: { createNew, viewMessagesOf, messagesScreen },
    getMessages,
  }) {
    const queryLoading = getMessages && getMessages.loading;
    if (isEmpty(messagesScreen) && !queryLoading) {
      parseResult({ query: getMessages, dataField: 'getMessages', resultHandler: store.dispatch.messages.fetchStart });
    }
    if (!queryLoading && loading) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
    if (isEmpty(viewMessagesOf) && !createNew && !isEmpty(messagesScreen)) {
      const { providerMessages } = get(messagesScreen, 'messagesSection', []);
      const IndexHolder = { index: 0 };
      const {
        location: { search = '' },
      } = this.props;
      const queryString = new URLSearchParams(search);
      const urlMessageId = queryString.get('message_id');
      const providerId = parseInt(queryString.get('provider'), 10);
      let findIndex = -1;
      if (urlMessageId) {
        findIndex = providerMessages.findIndex(pMessage => pMessage.message_id === urlMessageId);
        if (findIndex !== -1) {
          IndexHolder.index = findIndex;
        }
      }
      else if (providerId){
        findIndex = providerMessages.findIndex(pMessage => pMessage.provider_id === providerId);
        if (findIndex !== -1) {
          IndexHolder.index = findIndex;
        }
      }
      if (providerId && findIndex === -1){
        store.dispatch.messages.createNewMessage(true)
      }
      else{
        providerMessages.length > 0 &&
        store.dispatch.messages.viewMessages({
          param: get(providerMessages, `[${IndexHolder.index}].message_id`, String()),
          messageSubject: get(providerMessages, `[${IndexHolder.index}].subject`, String()),
          provider: get(providerMessages, `[${IndexHolder.index}].provider`, String()),
          read: get(providerMessages, `[${IndexHolder.index}].read`, String()),
        });
      }
    }
  }
  componentWillUnmount() {
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.messages.resetMessagesState();
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth < 567 });
  };
  // Method to open the modal
  openModal = () => {
    this.setState({ isModalOpen: true });
     };

  // Method to close the modal
  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const {
      getMessages,
      messages: {
        createNew,
        messagesScreen,
        messagesScreen: { messagesHeader, messagesSidePanel, messagesSection } = {},
      },
      loader: { loading },
    } = this.props;
    const {isModalOpen} = this.state;
    const { isMobile } = this.state;
    const queryLoading = getMessages && getMessages.loading;
    return !queryLoading && !isEmpty(messagesScreen) && !messagesSection.providerMessages.length && !createNew ? (
      <ContentPage props={{ ...this.props }} schemas={messagesScreen.noMessagesScreen} loading={loading} />
    ) : (
      <div className={classNames(styles.messages,{ [styles.toggleButton]: createNew })}>
        <SchemaParser schema={messagesHeader} />
        {isMobile&&<button className={styles.ProviderChatOpenBtn} onClick={this.openModal}>PROVIDER CHAT HISTORY</button>}
        {isModalOpen && (
          <div className="modal"><div className={styles.modalcontent}><button onClick={this.closeModal}><SchemaParser schema={compact([messagesSidePanel])} /></button></div>
          </div>
        )}
        <div className={styles.messagesBody}>
          {isMobile ? <SchemaParser schema={compact([messagesSection])} /> : <SchemaParser schema={compact([messagesSidePanel, messagesSection])} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ messages, loader }) => ({ messages, loader });
const enhancer = compose(
  withApollo,
  connect(mapStateToProps),
  graphql(GetMessages, { name: 'getMessages', skip: ({ messages: { messagesScreen } }) => !isEmpty(messagesScreen) }),
);
export default enhancer(MessagesContainer);
