export const editPaymentPlan = {
  state: { editPaymentPlan: [] },
  reducers: {
    fetchStart: (state, { editPaymentPlanScreen, planDetails }) => ({
      ...state,
      editPaymentPlan: editPaymentPlanScreen,
      planDetails,
    }),
    clearScreen: _ => ({ editPaymentPlan: [] }),
  },
};
