export const hierarchicalCheckboxes = {
  state: { data: {} },
  reducers: {
    setData: (state, { data }) => ({
      ...state,
      data,
    }),
    clearData: _ => ({ ...hierarchicalCheckboxes.state }),
  },
};
