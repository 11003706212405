import React from 'react';
import styled from 'styled-components';
import { Text } from '../../styleguide';

const Header = styled.div`
  margin: 0 0 40px;
  width: 90%;
`;

export default ({ title, description, icon = false }) => {
  return (
    <Header>
      <Text tag="p" text={title} type="groupTitle" icon={icon} />
      <Text tag="p" text={description} type="groupDescription" />
    </Header>
  );
};
