import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { store } from '../../../../models';
import { CancelPaymentPlan } from '../../../../graphql/mutations';
import { ContentPage } from '../..';
import { GetEditPaymentPlan } from '../../../../graphql/queries';
import { Payments } from '../../../../constants/index';
import { parseResult } from '../../../../graphql/utilities';
import { oneTimePaymentContext as OneTimePaymentContext } from '../../../../contexts';

class EditPaymentPlanContainer extends Component {
  componentWillReceiveProps({
    editPaymentPlan,
    getEditPaymentPlan,
    loader,
    paymentPlan: {
      currentPlan,
      existingPlanName,
      dayOfMonth,
      dayOfWeek,
      cardId,
      planName,
      numberOfMonths,
      existingCardExpired,
    },
    editPaymentPlan: { planDetails },
    creditCard: { processing },
    inputDropdown: { paymentFrequency },
    inputDropdown,
    updateValue,
    makePayments: { isValid },
    radioInput: { selectedExistingCard },
    updateValid,
  }) {
    if (isEmpty(editPaymentPlan.editPaymentPlan) && !getEditPaymentPlan.loading) {
      parseResult({
        query: getEditPaymentPlan,
        dataField: 'getEditPaymentPlan',
        resultHandler: store.dispatch.editPaymentPlan.fetchStart,
      });
    }
    if (!currentPlan && !isEmpty(editPaymentPlan.editPaymentPlan)) {
      store.dispatch.paymentPlan.setExistingPlanToEdit(planDetails);
      // check if frequency dropdown does not match plan and set the default payment day and clear inputs
      const frequencyChange = paymentFrequency && paymentFrequency !== planDetails.frequencyCode;
      frequencyChange && store.dispatch.paymentPlan.clearPlanInputs();
      frequencyChange &&
        (planDetails.dayOfWeek || planDetails.dayOfMonth) &&
        updateValue(
          planDetails.dayOfWeek ? Payments.DAY_OF_MONTH : Payments.DAY_OF_WEEK,
          planDetails.dayOfWeek ? 1 : 7,
        );
      store.dispatch.radioInput.updateValue('paymentType', 'paymentPlan');
      store.dispatch.radioInput.updateValue('paymentMethod', planDetails.paymentMethod);
      store.dispatch.radioInput.updateValue('selectedExistingCard', planDetails.cardId);
    }
    if (currentPlan && !isEmpty(editPaymentPlan.editPaymentPlan)) {
      if (+planDetails.numberOfMonths === +numberOfMonths && paymentFrequency === planDetails.frequencyCode) {
        if (
          (dayOfMonth && dayOfMonth !== inputDropdown.dayOfMonth) ||
          (dayOfWeek && dayOfWeek !== inputDropdown.dayOfWeek) ||
          (planName && planName !== existingPlanName) ||
          (cardId && cardId !== selectedExistingCard) ||
          (existingCardExpired && selectedExistingCard)
        ) {
          !isValid && updateValid(true);
        } else {
          isValid && updateValid(false);
        }
      }
    }

    if (!getEditPaymentPlan.loading && loader.loading && !processing) {
      store.dispatch.loader.activateLoader({ loading: false });
    }
  }
  componentWillUnmount() {
    const { paymentPlan: { agreeToTerms } } = this.props;
    store.dispatch.loader.activateLoader({ loading: true });
    store.dispatch.editPaymentPlan.clearScreen();
    store.dispatch.paymentPlan.resetPaymentPlan(0);
    !agreeToTerms && store.dispatch.paymentPlan.resetTerms();
  }
  cancelPaymentPlan = async _ => {
    const {
      cancelPaymentPlan,
      match: { params: { paymentPlanId, practiceId } },
      editPaymentPlan: { planDetails: { patientId } },
    } = this.props;
    store.dispatch.modal.toggleOpen(false);
    store.dispatch.loader.persistLoader({ persist: true });
    const { data: { cancelPaymentPlan: response } } = await cancelPaymentPlan({
      variables: {
        input: {
          paymentPlanId,
          patientId,
          practiceId,
        },
      },
    });
    store.dispatch.payments.clearStore();
    store.dispatch.loader.persistLoader({ persist: false });
    if (response === 200) {
      store.dispatch.modal.toggleOpen('cancelConfirmationSuccess');
      store.dispatch.mixpanel.addMetadata({ paymentPlanCancelled: true });
    }
  };
  render() {
    const { editPaymentPlan: { editPaymentPlan } } = this.props;
    return (
      editPaymentPlan && (
        <OneTimePaymentContext.Provider value={{ cancelPaymentPlan: this.cancelPaymentPlan }}>
          <ContentPage schemas={editPaymentPlan} />
        </OneTimePaymentContext.Provider>
      )
    );
  }
}
const mapStateToProps = ({
  editPaymentPlan,
  paymentPlan,
  radioInput,
  loader,
  creditCard,
  inputDropdown,
  makePayments,
}) => ({
  editPaymentPlan,
  inputDropdown,
  radioInput,
  loader,
  creditCard,
  paymentPlan,
  makePayments,
});
const mapDispatchToProps = ({ inputDropdown, makePayments }) => ({
  updateValue: inputDropdown.updateValue,
  updateValid: makePayments.updateValid,
});
const enhancer = compose(
  withApollo,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(GetEditPaymentPlan, {
    name: 'getEditPaymentPlan',
    options: ({ match: { params: { paymentPlanId, practiceId } } }) => ({ variables: { paymentPlanId, practiceId } }),
  }),
  graphql(CancelPaymentPlan, { name: 'cancelPaymentPlan' }),
);
export default enhancer(EditPaymentPlanContainer);
