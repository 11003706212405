import React from 'react';
import { flattenDeep } from '@carecloud/cloudpak';
import { SchemaParser } from '../../services';

export const FormGroup = ({ props: { heading, styles, completed, groups, id, theme, checkmark, buildGroupComponent } }) => (
  <div id={id} className={styles[theme]}>
    <div className={styles.heading}>
      <SchemaParser schema={heading} />
      {checkmark && completed && <i className="material-icons">done</i>}
    </div>
    {flattenDeep(groups.map(group => buildGroupComponent({ group })))}
  </div>
);
