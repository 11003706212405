import { connect } from 'react-redux';
import React, { Component } from 'react';
import { store } from '../../../models';


class ShopEcwidContainer extends Component {
  componentDidMount() {
    if (!document.getElementById('ecwid-script')) {
      const ecwidScript = document.createElement('script');
      ecwidScript.charset = 'utf-8';
      ecwidScript.type = 'text/javascript';
      ecwidScript.src = `https://app.ecwid.com/script.js?${this.props.match.params.id}&lang=${localStorage.getItem('CC-Breeze-Web-language') || 'en'}`;
      ecwidScript.id = 'ecwid-script';
      document.getElementById('productBrowser').appendChild(ecwidScript);
    } else {
      window.ecwid_onBodyDone.call();
    }
    window._xnext_initialization_scripts = [
      {
        widgetType: 'ProductBrowser',
        id: 'productBrowser',
        arg: [
          '"categoriesPerRow=3","views=grid(4,4) list(10) table(20)","categoryView=grid","searchView=list","style=","responsive=yes","id=productBrowser"',
        ],
      },
    ];

    const confirmScript = document.createElement('script');
    confirmScript.innerHTML = `
      setTimeout(function() {
        if (decodeURIComponent(window.location.href).indexOf('checkoutResult') !== -1 && window['Ecwid']) {
          window['Ecwid'].Cart.clear();
          window['Ecwid'].openPage('account/orders');
        }
      }, 1500);
    `;
    document.body.appendChild(confirmScript);
    const confirmPage = setInterval(_ => {
      const shop = document.getElementsByClassName('ec-footer');
      if (shop) {
        setTimeout(_ => {}, 500);
        this.props.activateLoader({ loading: false });
        document.getElementById('productBrowser').classList.add('animate');
        clearInterval(confirmPage);
      }
    }, 1000);
  }
  componentWillUnmount() {
    this.props.activateLoader({ loading: true });
    store.dispatch.mixpanel.addMetadata({ leftShop: true });
    window.location.reload();
  }
  render() {
    return <div id="productBrowser" />;
  }
}

const mapDispatchToProps = ({ loader }) => ({ activateLoader: loader.activateLoader });

export default connect(null, mapDispatchToProps)(ShopEcwidContainer);
