export const creditCard = {
  state: { creditCardValid: false, processing: false },
  reducers: {
    updateCard: (state, { id, value }) => ({
      ...state,
      [id]: value,
    }),
    resetCard: _ => ({ creditCardValid: false }),
    saveCard: state => ({ ...state, saveCard: !state.saveCard }),
    defaultCard: state => ({ ...state, defaultCard: !state.defaultCard }),
    toggleValid: (state, bool) => ({ ...state, creditCardValid: bool }),
    toggleProcessing: (state, bool) => ({ ...state, processing: bool }),
  },
};
