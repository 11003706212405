import React, { memo, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { isEmpty, isStringifiedJson, get } from '@carecloud/cloudpak';
import * as Queries from '../../../graphql/queries';
import { InputDropdown } from './inputDropdown';
import { store } from '../../../models';
import { ContextProvider } from '../../root/TopLevelContext';
import * as babyContexts from '../../../contexts';

const InputDropdownContainer = memo(props => {
  const {
    initialValue,
    value,
    formGroupId,
    action = {},
    resetValues,
    formContext,
    formData,
    inputDropdown,
    id,
    handleChange: handleChangeFromProps,
    onChange,
    formMapping,
    schema,
    uiSchema,
    search,
    client,
    disabled,
  } = props;

  const { trigger = String(), param = String(), model = String(), babyContext = String() } = action;
  const grandpaContext = useContext(ContextProvider);
  const variableContext = babyContext && useContext(babyContexts[`${babyContext}Context`]);
  const isJsonSchema = !isEmpty(formContext);

  const formIdNumber = ({ formGroupId = '' }) => {
    const uniqueIdNumber = formGroupId.match(/\d+/g);
    return uniqueIdNumber ? uniqueIdNumber[0] : '';
  };

  const buildIdForForms = ({ id }) => {
    if (!formGroupId) return id;
    let newId = id;
    if (newId.match(/\d+/g)) {
      newId = newId.replace(/\d+/g, formIdNumber({ formGroupId }));
    } else {
      newId += formIdNumber({ formGroupId });
    }
    return newId;
  };

  const getValue = _ => {
    if (isJsonSchema) return formData;
    return inputDropdown[buildIdForForms({ id })];
  };

  const handleChange = value => {
    if (isJsonSchema) return onChange(value);
    let newFormMapping = formMapping;
    const id = buildIdForForms(props);
    const fieldValue = typeof value === 'object' && value.value ? value.value : value;
    try {
      if (formMapping && formMapping.match(/(\[\*\])/)) {
        newFormMapping = formMapping.replace(/(\[\*\])/, `[${formIdNumber({ formGroupId }) || 0}]`);
      }
      if (!isEmpty(action)) {
        if (model === 'localActions') grandpaContext._getAction(trigger)({ param, id, value: fieldValue });
        else if (babyContext) {
          variableContext[trigger]({ param, id, value: fieldValue });
        } else if (model && trigger) {
          store.dispatch[model][trigger]({ param, id, value: fieldValue, formMapping: newFormMapping });
        }
      }
      store.dispatch.inputDropdown.updateValue(id, value);
    } catch (error) {
      console.error(error);
    }
  };

  const getHandleChange = _ => {
    if (handleChangeFromProps) return handleChangeFromProps;
    return handleChange;
  };

  const arrowRenderer = _ => {
    const { noArrow } = isJsonSchema ? uiSchema : props;
    return noArrow ? <span /> : <span className="Select-arrow" />;
  };

  const load = input => {
    if (!input) {
      return Promise.resolve({ options: [] });
    }
    const { query, dataKey, queryData } = search;
    return client
      .query({
        query: Queries[query],
        variables: { input: { ...queryData, search: input } },
      })
      .then(({ data }) => ({ options: data[dataKey].map(data => ({ ...data, action: 'add' })) }));
  };

  const selectTag = _ => {
    const { async } = isJsonSchema ? uiSchema : props;
    return async ? Select.Async : Select;
  };
  const mapJsonSchemaProps = _ => {
    if (!isJsonSchema) return {};
    const schemaParsed = isStringifiedJson(schema) ? JSON.parse(schema) : schema;
    const { title, uuid, description } = schema;
    const { uiSchema: { requiredLabel, errorRequiredLabel, placeholder } = {}, required } = props;
    return {
      label: title,
      required: required ? requiredLabel : String(),
      errorRequiredLabel,
      placeholder,
      id: uuid,
      supportingText: description,
      options: get(
        schemaParsed,
        'oneOf',
        get(schemaParsed, ['x-schema-form', 'titleMap'], []).map(({ value, name }) => ({ value, label: name })),
        oneOf => oneOf.map(({ const: value, title: label }) => ({ value, label })),
      ),
    };
  };

  useEffect(() => {
    if (isJsonSchema) return;
    let newFormMapping;
    const id = buildIdForForms(props);
    const formId = formIdNumber({ formGroupId });
    const fieldValue = resetValues ? '' : initialValue || value;

    if (formMapping && formMapping.match(/(\[\*\])/)) {
      newFormMapping = formMapping.replace(/(\[\*\])/, `[${formId || 0}]`);
    }
    if (action && trigger && param) {
      const argument = {
        param,
        id,
        value: fieldValue,
        formMapping: newFormMapping,
        isInitial: true,
      };
      if (model !== 'localActions') store.dispatch[model][trigger](argument);
    }
    if (typeof store.getState().inputDropdown[id] === 'undefined') {
      store.dispatch.inputDropdown.updateValue(id, fieldValue);
    }
  }, []);

  return (
    <InputDropdown
      {...{
        ...props,
        ...mapJsonSchemaProps(),
        Select: selectTag(),
        value: getValue(),
        arrowRenderer,
        loadFunction: load,
        handleChange: getHandleChange(),
        disabled: disabled && !resetValues,
      }}
    />
  );
});

export default withRouter(InputDropdownContainer);
