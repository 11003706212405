import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import styles from './Surveys.module.scss';
import { SchemaParser } from '../../services';

const PropsDependentStyledWrapper = styled.div`
  width: ${props => (props.isMobile ? '100%' : '1200px')};
  margin: 0 auto;
  padding: 120px 0px;
  min-height: calc(100vh - 206px);
  & div[id*='surveysHeadingsGroup'] {
    padding: 0 20px;
  }
  @media screen and (max-width: 1439px) {
    padding: 120px 0px 40px 100px;
  }
  @media screen and (max-width: 567px) {
    padding: 120px 0px 40px 0px;
  }
`;
const PropsDependentStyledContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
`;
const PropsDependentStyledLeftSideWrapper = styled.div`
  ${props =>
    !props.isMobile && 'border-right: 1px solid #f5f7f8; padding-right: 25px;'};
  ${props => props.isMobile && 'margin: 0 auto'};
  & label {
    & > div {
      margin-bottom: 25px;
    }
  }
`;
const PropsDependentStyledRightSideWrapper = styled.div`
  padding: ${props => (props.isMobile ? '0 10px' : '0px 0px 0px 25px')};
  max-width: ${props => (props.isMobile ? '1500px' : '600px')};
  & div[id*='surveyQuestionContainer'] {
    ${props =>
      !props.isMobile && 'border-left: 1px solid #ddd; padding: 0px 20px'};
    & div[id*='questionTitle'] {
      line-height: 22px;
    }
  }
  .topLeftHeaderWrapper {
    & h5 {
      font-size: 32px;
      font-weight: 500;
      font-family: 'Metropolis';
      line-height: 20px;
      color: #455a64;
      margin: 0px 0px 25px;
    }
  }
  .guestPracticeHeader {
    line-height: 45px !important;
    padding: 0px 15px;
  }
`;

export const Surveys = ({
  props: {
    surveys: {
      survey,
      surveysSelection,
      surveysHeading,
      practiceName = String(),
      surveysTopLeftHeader,
      singleSurveyView,
      guestSurveyCtx: { practiceName: guestPractice = String() } = {},
    },
    isMobile,
    guestMobileNext,
  },
}) => (
  <PropsDependentStyledWrapper
    isMobile={isMobile}
    className={classNames({
      [styles.guestMobileNextWrapper]: guestMobileNext,
    })}
  >
    {practiceName || isMobile ? (
      <div className={styles.topLeftHeaderWrapper}>
        {isMobile && (
          <div className={styles.guestPracticeHeaderContainer}>
            <h3 className={styles.guestPracticeHeader}>{guestPractice}</h3>
          </div>
        )}
        <SchemaParser schema={surveysTopLeftHeader} />
        {!isMobile && <h3 className={styles.practiceHeader}>{practiceName}</h3>}
      </div>
    ) : (
      <SchemaParser schema={surveysHeading} />
    )}
    <PropsDependentStyledContentWrapper
      isMobile={isMobile}
      className={styles.contentWrapper}
    >
      <PropsDependentStyledLeftSideWrapper
        isMobile={isMobile}
        className={classNames({
          [styles.disableClicks]: singleSurveyView,
        })}
      >
        <SchemaParser schema={surveysSelection} />
      </PropsDependentStyledLeftSideWrapper>
      <PropsDependentStyledRightSideWrapper isMobile={isMobile}>
        <SchemaParser schema={survey} />
      </PropsDependentStyledRightSideWrapper>
    </PropsDependentStyledContentWrapper>
  </PropsDependentStyledWrapper>
);
