import Form from 'react-jsonschema-form';
import React, { Component } from 'react';
import { get, compact, isUndefined, set } from '@carecloud/cloudpak';
import * as Mutations from '../../../graphql/mutations';
import { Checkbox, Checkboxes, Input, InputDropdown, Radios, Rating, TextArea } from '../index';
import { store } from '../../../models';
import { Paths } from '../../../constants/index';

class JsonSchemaForm extends Component {
  state = { forms: [], loadingComplete: false };
  uiMap = {
    phonenumber: {
      'ui:field': 'input',
      mask: '(999) 999-9999',
      requiredLabel: this.props.props.requiredLabel,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    datefield: {
      'ui:field': 'input',
      mask: '99/99/9999',
      placeholder: 'MM / DD / YYYY',
      requiredLabel: this.props.props.requiredLabel,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    textarea: {
      'ui:field': 'input',
      requiredLabel: this.props.props.requiredLabel,
      required: this.props.props.required,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    signature: {
      'ui:field': 'input',
      type: 'signature',
      placeholder: this.props.props.signaturePlaceholder,
      requiredLabel: this.props.props.requiredLabel,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    email: {
      'ui:field': 'input',
      type: 'email',
      requiredLabel: this.props.props.requiredLabel,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    select: {
      'ui:field': 'inputDropdown',
      requiredLabel: this.props.props.requiredLabel,
      required: this.props.props.required,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    checkboxes: {
      'ui:field': 'checkboxes',
      requiredLabel: this.props.props.requiredLabel,
      required: this.props.props.required,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    checkbox: {
      'ui:field': 'checkbox',
      requiredLabel: this.props.props.requiredLabel,
      required: this.props.props.required,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    help: { 'ui:field': 'forgiveMeForIHaveSinned' },
    radios: {
      'ui:field': 'radios',
      booleans: this.props.props.booleans,
      requiredLabel: this.props.props.requiredLabel,
      required: this.props.props.required,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    radiobuttons: {
      'ui:field': 'radios',
      booleans: this.props.props.booleans,
      requiredLabel: this.props.props.requiredLabel,
      required: this.props.props.required,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
    rating: {
      'ui:field': 'rating',
      requiredLabel: this.props.props.requiredLabel,
      required: this.props.props.required,
      errorRequiredLabel: this.props.props.errorRequiredLabel,
    },
  };
  componentDidMount() {
    if (this.props.props.state === 'checkin') store.dispatch.mixpanel.addMetadata({ patientFormsCheckin: true });
  }

  componentWillReceiveProps({ pageNumber, props: { totalPages } }) {
    if (pageNumber === totalPages && !store.getState().loader.persist && !this.state.loadingComplete) {
      store.dispatch.loader.persistLoader({ persist: true });
    }
  }

  componentWillUnmount() {
    const { history: { location: { pathname = String() } = {} } = {} } = this.props;
    !pathname.includes(Paths.CHECK_OUT) &&
      store.getState().mixpanel.nonActionableMetadata.checkoutInProgress &&
      store.dispatch.mixpanel.addMetadata({ checkoutCancelled: true, checkoutLastStep: 'Check-out Forms' });
  }

  onSubmit = async ({ formData }) => {
    const {
      pageNumber,
      nextPage,
      props: {
        id,
        appointmentCheckIn,
        appointmentCheckOut,
        pendingForms,
        uuid,
        totalPages,
        state,
        nextForm,
        formsCount,
        formIndex,
        version,
        schema,
      },
    } = this.props;
    let formValid = true;
    const requiredFields = Object.keys(formData).filter(k => get(schema, [[pageNumber], 'required'], []).includes(k));
    requiredFields.forEach(f => {
      if (isUndefined(formData[f]) || formData[f] === '') formValid = false;
    });
    store.dispatch.appointmentCheckIn.formValid({formValid})
    if (!formValid) return;
    if (!nextForm) nextPage();
    let checkInIds;
    let checkoutIds;
    let pendingIds;
    let externalSubmissionData = {};
    if (state === 'checkin') {
      checkInIds = {
        appointmentId: appointmentCheckIn.appointment_id,
        patientId: appointmentCheckIn.patient_id,
        practiceId: appointmentCheckIn.practice_id,
      };
      // for compatibility with AppointmentCheckingChanges
      store.getState().form.externalSubmissionData.forEach(({ formMapping, value }) => {
        externalSubmissionData = set(externalSubmissionData, formMapping, value);
      });
    } else if (state === 'checkout') {
      checkoutIds = {
        appointment_id: appointmentCheckOut.appointment_id,
        patient_id: appointmentCheckOut.patient_id,
        practice_id: appointmentCheckOut.practice_id,
      };
    } else if (state === 'pending') {
      pendingIds = {
        patientId: pendingForms.patientId,
        practiceId: pendingForms.practiceId,
      };
    }
    let inputData = Object.keys(formData).map(key => this.buildInputObject({ formData, key }));
    inputData = compact(inputData);
    const forms = this.state.forms.concat({
      formUuid: nextForm ? uuid : uuid[pageNumber],
      responses: inputData,
      ...(nextForm ? { version } : {}),
    });
    if (nextForm && formIndex !== formsCount) {
      this.setState({ forms });
      return nextForm();
    } else if (pageNumber + 1 === totalPages) {
      const input = { checkInIds, checkoutIds, patientForms: forms, pendingIds, ...externalSubmissionData };
      try {
        if (state === 'pending') store.dispatch.loader.persistLoader({ persist: true });
        const { data } = await this.props.props.client.mutate({
          mutation: Mutations[id],
          variables: { input },
        });
        if (get(data, 'postCheckoutForms.redirectSurvey')) {
          const {
            postCheckoutForms: { surveysSelection, surveys, surveysHeading },
          } = data;
          store.dispatch.surveys.fetchStart({ surveysSelection, survey: surveys, surveysHeading });
          store.dispatch.surveys.setCheckoutSurvey();
          store.dispatch.surveys.didRefetch();
          store.dispatch.loader.persistLoader({ persist: false });
          this.props.props.history.push('/appointments/check_out/survey');
        }
        if (get(data, 'postCheckoutForms.submissionResult.success')) {
          store.dispatch.appointmentCheckOut.storeRedirectPath(data.postCheckoutForms.submissionResult.redirect);
          store.dispatch.modal.toggleOpen('checkoutDoneModal');
        }
        if (get(data, 'postCheckInPatientForms.submissionResult.success')) {
          store.dispatch.appointmentCheckIn.storeRedirectPath(data.postCheckInPatientForms.submissionResult.redirect);
          store.dispatch.appointments.resetState();
          this.props.props.history.push('/appointments');
          store.dispatch.loader.persistLoader({ persist: false });
        } else if (get(data, 'postCheckInPatientForms')) {
          store.dispatch.appointmentCheckIn.fetchStart(data.postCheckInPatientForms);
          store.dispatch.loader.persistLoader({ persist: false });
        }

        if (state === 'checkout') {
          store.dispatch.appointmentCheckOut.fetchCheckOutPayment({
            appointmentCheckOutPayment: data?.postCheckoutForms?.appointmentCheckOutScreen,
          });
          store.dispatch.loader.persistLoader({ persist: false });
        } else if (state === 'pending') {
          store.dispatch.loader.persistLoader({ persist: false });
          this.props.props.history.push('/form_history');
          store.dispatch.formHistory.resetStore();
          store.dispatch.loader.activateLoader({ loading: true });
        }
        get(data, 'postCheckInPatientForms') &&
          store.dispatch.mixpanel.addMetadata({
            patientFormsComplete: true,
            patientFormsCount: this.state.forms.length,
          });
        store.dispatch.mixpanel.addMetadata({ formComplete: { total: totalPages, type: 'practice_forms' } });
      } catch (e) {
        console.error(e);
        this.resetForms();
      } finally {
        this.setState({ loadingComplete: true }, () => store.dispatch.loader.persistLoader({ persist: false }));
      }
    } else {
      this.setState({ forms });
    }
  };
  resetForms = _ => {
    this.props.resetForm();
    this.setState({ forms: [], loadingComplete: false });
  };
  successRedirect = _ => {
    const { appointmentCheckIn, appointmentCheckOut } = this.props;
    const path = appointmentCheckIn.path || appointmentCheckOut.path || 'appointments';
    store.dispatch.appointments.resetState();
    store.dispatch.mixpanel.addMetadata({ successRedirect: true });
    this.props.history.push(`/${path}`);
  };
  buildInputObject = ({ formData, key }) => {
    const result = formData[key];
    let value;
    let valueArray;
    let type;
    if (Array.isArray(result)) {
      valueArray = result;
      type = 'array';
    } else {
      value = result;
      type = typeof result;
    }
    return { propertyUuid: key, value, valueArray, type };
  };

  errorList = _ => <div />;
  /* eslint-disable react/no-danger */
  dangerouslySetInnerHtml = ({ schema }) => (
    <div>
      <div
        className={this.props.styles.dangerouslySetInnerHTML}
        dangerouslySetInnerHTML={{ __html: schema.helpvalue }}
      />
      {schema.description && <div className={this.props.styles.supportingText}>{schema.description}</div>}
    </div>
  );
  /* eslint-enable react/no-danger */
  uiSchema = _ => {
    const { properties } = this.props.props.schema[this.props.pageNumber];
    const fieldKeys = Object.keys(properties);
    const uiMapArray = fieldKeys.map(key => ({ [key]: this.uiMap[properties[key].field_type] }));
    const uiSchemaArray = fieldKeys.map(key => ({ [key]: properties[key] }));
    uiSchemaArray.sort((a, b) => a[Object.keys(a)[0]].sort_order - b[Object.keys(b)[0]].sort_order);
    const uiSchema = Object.assign({}, ...uiMapArray);
    uiSchema['ui:order'] = uiSchemaArray.map(item => Object.keys(item)[0]);
    return uiSchema;
  };
  addUpdatedDate = _ => {
    const { updatedDate } = this.props.props;
    const { properties } = this.props.props.schema[this.props.pageNumber];
    const fieldKeys = Object.keys(properties);
    return fieldKeys.reduce((acc, id) => {
      acc[id] = Object.assign({}, properties[id]);
      if (properties[id].field_type === 'signature') {
        acc[id].updatedDate = updatedDate;
      }
      return acc;
    }, {});
  };
  render() {
    const {
      pageNumber,
      props: { id, schema, formData, updatedDate },
    } = this.props;
    if (this.props.pageNumber === this.props.props.totalPages) return <div />;
    if (updatedDate) schema[pageNumber].properties = this.addUpdatedDate();
    return (
      <Form
        schema={schema[pageNumber]}
        uiSchema={this.uiSchema()}
        formData={formData[pageNumber]}
        fields={{
          input: Input,
          inputDropdown: InputDropdown,
          checkboxes: Checkboxes,
          textArea: TextArea,
          forgiveMeForIHaveSinned: this.dangerouslySetInnerHtml,
          checkbox: Checkbox,
          radios: Radios,
          rating: Rating,
        }}
        onSubmit={this.onSubmit}
        formContext={{ id }}
        ErrorList={this.errorList}
      >
        <button
          ref={btn => {
            this.submitButton = btn;
          }}
          className="hidden"
        />
      </Form>
    );
  }
}
export default JsonSchemaForm;
