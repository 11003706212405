import { connect } from 'react-redux';
import { compose, isEmpty, get } from '@carecloud/cloudpak';
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { store } from '../../../models';
import { Paths, Payments } from '../../../constants/index';
import { PaymentPlan } from './paymentPlan';
import { CASH } from '../../../constants/Payments';

const PaymentPlanContainer = props => (
  <Component {...{ ...props, match: null }} />
);
class Component extends PureComponent {
  state = {
    monthError: false,
    paymentError: false,
    disableFrequency: false,
    chosenRule: false,
    disableInput: false,
    selectNew: true,
    clickedOnAdd: false,
    disableAdd: false,
    lastPaymentAmount: 0,
  };
  componentDidMount() {
    const { newLineItems, settings, setLineItems } = this.props;
    setLineItems(newLineItems, settings);
  }

  componentWillReceiveProps(nextProps) {
    const {
      radioInput: { paymentType },
      makePayments: { isValid },
      paymentPlan: {
        defaultValuesSet,
        monthlyPayment,
        numberOfMonths,
        option,
        currentPlan,
        selectedPlan,
        termsSent,
      },
      terms,
      partialPayment,
      inputDropdown,
    } = nextProps;
    const {
      radioInput,
      updateValid,
      partialPayment: { displayInput, error },
      inputDropdown: { paymentFrequency },
    } = this.props;
    const nextPaymentFrequency =
      inputDropdown.paymentFrequency || inputDropdown.paymentFrequencyDisabled;
    const editPaymentPlanPage = option === Payments.EDIT;
    const existingPlan = option === Payments.EXISTING;
    // amount entered into partial is good
    const amountGood =
      +displayInput.replace(/[^\d.]/g, '') >= 0.01 && !error.display;
    // reset plan values on frequency change
    if (
      paymentFrequency &&
      inputDropdown.paymentFrequency !== paymentFrequency &&
      option !== Payments.EXISTING
    ) {
      this.setState({ monthError: false, paymentError: false });
      this.selectRuleBasedOnFrequency({ frequency: nextPaymentFrequency });
      !editPaymentPlanPage && store.dispatch.inputDropdown.resetPaymentDay();
      store.dispatch.paymentPlan.resetPaymentPlan();
    }
    // reset input values on payment type change
    if (radioInput.paymentType !== paymentType) {
      this.toggleViewCreditCardComponent({
        show: paymentType !== Payments.PAYMENT_PLAN,
      });
      this.setState({ monthError: false, paymentError: false });
      store.dispatch.paymentPlan.resetPaymentPlan();
      store.dispatch.inputDropdown.resetValues();
    }

    if (
      paymentType === Payments.PAYMENT_PLAN &&
      store.getState().radioInput.hide !== CASH
    ) {
      store.dispatch.radioInput.deepHide({ id: CASH });
    } else if (
      paymentType !== Payments.PAYMENT_PLAN &&
      store.getState().radioInput.hide === CASH
    ) {
      store.dispatch.radioInput.deepHide({ id: String() });
    }
    // if payment plan option is weekly save the weekly label for used in payment amount
    if (
      nextPaymentFrequency === Payments.WEEKLY &&
      !inputDropdown.weeklyOptions
    ) {
      store.dispatch.inputDropdown.saveWeeklyOptions(
        this.props.weeklyDropdown.options,
      );
    }
    // if new amount is entered into partial amount reset plan values, and errors
    if (
      paymentType === Payments.PAYMENT_PLAN &&
      partialPayment.displayInput !== displayInput
    ) {
      this.setState({
        monthError: false,
        paymentError: false,
        selectNew: true,
        clickedOnAdd: false,
        disableAdd: false,
      });
      this.verifyWhichFrequencyAmountFallsUnder({ frequency: null });
      this.selectRuleBasedOnFrequency({ frequency: nextPaymentFrequency });
      !terms && termsSent && store.dispatch.paymentPlan.termsNotSent();
      store.dispatch.paymentPlan.resetPaymentPlan();
      (existingPlan || this.state.clickedOnAdd) &&
        this.setState({ selectNew: true });
      (existingPlan || this.state.clickedOnAdd) && this.selectNewPaymentPlan();
    }
    //if input plan amount or installments verify against range rules
    if (
      paymentType === Payments.PAYMENT_PLAN &&
      !defaultValuesSet &&
      numberOfMonths &&
      monthlyPayment &&
      option === Payments.NEW
    ) {
      this.validatePlanRules({ numberOfMonths, monthlyPayment });
    }
    // gets hit if existing plan inputs have been modified
    if (option === Payments.EXISTING && !selectedPlan) {
      this.validatePlanRules({ numberOfMonths, monthlyPayment });
    }
    // disable button if input fields are empty
    if (
      paymentType === Payments.PAYMENT_PLAN &&
      !numberOfMonths &&
      !monthlyPayment
    ) {
      this.setState({ monthError: false, paymentError: false });
      isValid && updateValid(false);
      option !== Payments.EXISTING &&
        amountGood &&
        this.selectRuleBasedOnFrequency({ frequency: nextPaymentFrequency });
    }
    // if edit payment plan page and inputs have data
    if (
      editPaymentPlanPage &&
      numberOfMonths &&
      monthlyPayment &&
      currentPlan
    ) {
      this.setState({ monthError: false, paymentError: false });
      this.verifyWhichFrequencyAmountFallsUnder({
        frequency: nextPaymentFrequency,
      });
      this.selectRuleBasedOnFrequency({ frequency: nextPaymentFrequency });
      !selectedPlan &&
        this.validatePlanRules({ numberOfMonths, monthlyPayment });
    }
  }
  componentWillUnmount() {
    store.dispatch.inputDropdown.resetValues();
    store.dispatch.paymentPlan.resetPaymentPlan();
  }
  getDisabledFrequencies = ({ settings }) => {
    const allowWeekly = get(settings, 'frequency_code.weekly.allow', false);
    const allowMonthly = get(settings, 'frequency_code.monthly.allow', false);
    const oneFrequencyDisabled =
      (allowWeekly && !allowMonthly) || (!allowWeekly && allowMonthly);
    return { allowWeekly, allowMonthly, oneFrequencyDisabled };
  };
  calculateInstallments = ({ totalAmount, paymentAmount }) => {
    const installments = totalAmount / paymentAmount;
    return Math.ceil(installments);
  };
  calculateMonthlyPayment = ({ totalAmount, installments }) => {
    const monthlyPayment = Math.ceil((totalAmount / installments) * 100) / 100;
    if (monthlyPayment * installments !== totalAmount) {
      const lastPaymentDifference =
        Math.ceil((totalAmount - monthlyPayment * installments) * 100) / 100;
      const lastPaymentAmount =
        Math.ceil((monthlyPayment + lastPaymentDifference) * 100) / 100;
      this.setState({ lastPaymentAmount });
    }
    return monthlyPayment;
  };
  // get called onChange in input fields
  updatePlan = event => {
    const {
      updatePlan,
      validation,
      partialPayment: { displayInput },
      paymentPlan: { combinedTotal, option, selectedPlan },
      editPaymentPlan: { planDetails },
    } = this.props;
    const { id, value } = event.target;
    if (id === Payments.PLAN_NAME) updatePlan(id, value);
    else {
      // clean up input data and verify no more than 2 decimal places
      const changedValue = value.replace(/[^\d.]/g, '');
      const regex = validation.value;
      const reg = new RegExp(regex);
      const installmentsChange = id === Payments.NUMBER_OF_MONTHS;
      const paymentChange = id === Payments.MONTHLY_PAYMENT;
      if (
        (paymentChange && reg.test(changedValue)) ||
        (installmentsChange && changedValue % 1 === 0)
      ) {
        this.setState({ monthError: false, paymentError: false });
        updatePlan(id, changedValue);
        if (changedValue > 0) {
          // Choose total from partial input or if adding to existing plan combined total
          const existingPlan = option === Payments.EXISTING;
          const editPlan = option === Payments.EDIT;
          let totalAmount = existingPlan
            ? combinedTotal
            : +displayInput.replace(/[^\d.]/g, '');
          if (editPlan) ({ totalAmount } = planDetails);
          (existingPlan || editPlan) &&
            selectedPlan &&
            store.dispatch.paymentPlan.clearSelectedPlan();
          // if number of months changed calculate monthly payment
          installmentsChange &&
            updatePlan(
              Payments.MONTHLY_PAYMENT,
              this.calculateMonthlyPayment({
                totalAmount,
                installments: changedValue,
              }),
            );
          // if monthly payment changed calculate number of months
          paymentChange &&
            updatePlan(
              Payments.NUMBER_OF_MONTHS,
              this.calculateInstallments({
                totalAmount,
                paymentAmount: changedValue,
              }),
            );
        } else {
          this.setState({ lastPaymentAmount: 0 });
          store.dispatch.paymentPlan.clearPlanInputs();
        }
      }
    }
  };
  toggleViewCreditCardComponent = ({ show }) => {
    const ccContainer = document.getElementById('creditCardsContainer');
    if (ccContainer) ccContainer.style.display = show ? 'block' : 'none';
  };
  // enables and disables frequencies/inputs based on rules it falls under
  verifyWhichFrequencyAmountFallsUnder = ({ frequency }) => {
    const {
      partialPayment: { displayInput },
      settings,
      frequencyDropdown,
      paymentPlan: { option },
      editPaymentPlan: { planDetails },
      inputDropdown,
    } = this.props;
    let weekly = false;
    let monthly = false;
    const editPage = option === Payments.EDIT;
    const pendingPaymentPage =
      option === Payments.EXISTING || option === Payments.NEW;
    let amount = +displayInput.replace(/[^\d.]/g, '');
    if (editPage) amount = planDetails.totalAmount;
    const {
      allowWeekly,
      allowMonthly,
      oneFrequencyDisabled,
    } = this.getDisabledFrequencies({ settings });
    settings.balance_range_rules.forEach(rule => {
      if (
        amount <= +rule.max_balance.value &&
        amount >= +rule.min_balance.value
      ) {
        if (!weekly && allowWeekly)
          weekly = rule.max_duration.interval === Payments.WEEKS;
        if (!monthly && allowMonthly)
          monthly = rule.max_duration.interval === Payments.MONTHS;
      }
    });
    // check id of component for disabled/enable  coming from cyclops
    const frequencyAlreadyDisabled =
      frequencyDropdown.id === Payments.PAYMENT_FREQUENCY_DISABLED;
    const fallsUnderOneRule = (weekly && !monthly) || (!weekly && monthly);
    const frequencyChanged =
      (editPage ? frequency : inputDropdown.paymentFrequency) !==
      frequencyDropdown.initialValue;
    const initialFrequencyWeekly =
      frequencyDropdown.initialValue === Payments.WEEKLY;
    const initialFrequencyMonthly =
      frequencyDropdown.initialValue === Payments.MONTHLY;
    //verify different combinations of settings
    if (pendingPaymentPage && !frequencyAlreadyDisabled && fallsUnderOneRule) {
      this.setState({ disableFrequency: true });
      store.dispatch.inputDropdown.updateValue(
        Payments.PAYMENT_FREQUENCY,
        monthly ? Payments.MONTHLY : Payments.WEEKLY,
      );
    } else if (pendingPaymentPage && !frequencyAlreadyDisabled) {
      this.setState({ disableFrequency: false });
      store.dispatch.inputDropdown.updateValue(
        Payments.PAYMENT_FREQUENCY,
        initialFrequencyMonthly ? Payments.MONTHLY : Payments.WEEKLY,
      );
    } else if (editPage && fallsUnderOneRule && oneFrequencyDisabled) {
      const disableFrequency =
        (initialFrequencyWeekly && weekly) ||
        (initialFrequencyMonthly && monthly);
      let disableInput =
        (initialFrequencyWeekly && monthly) ||
        (initialFrequencyMonthly && weekly);
      if (frequencyChanged) disableInput = false;
      this.setState({ disableFrequency, disableInput });
    } else if (editPage && !weekly && !monthly)
      this.setState({ disableFrequency: true, disableInput: true });
    else if (editPage && fallsUnderOneRule) {
      const disableFrequency =
        (initialFrequencyWeekly && !monthly) ||
        (initialFrequencyMonthly && !weekly);
      let disableInput =
        (inputDropdown.paymentFrequency === Payments.WEEKLY && !weekly) ||
        (inputDropdown.paymentFrequency === Payments.MONTHLY && !monthly);
      if (frequencyChanged) disableInput = false;
      this.setState({ disableFrequency, disableInput });
    }
  };

  // display error messsage if not within rules
  validatePlanRules = ({ numberOfMonths, monthlyPayment }) => {
    const {
      errors: { paymentLow, monthHigh, monthLow },
      weeklyErrors: { weekHigh, weekPaymentLow, weekLow },
      makePayments: { isValid },
      inputDropdown: { paymentFrequency, paymentFrequencyDisabled },
      updateValid,
      paymentPlan: { option },
      editPaymentPlan: { planDetails },
    } = this.props;
    const frequency = paymentFrequency || paymentFrequencyDisabled;
    const { chosenRule } = this.state;
    const monthlyFrequency = frequency === Payments.MONTHLY;
    const editPage = option === Payments.EDIT;
    const editPageOriginalValue =
      editPage &&
      +planDetails.numberOfMonths === +numberOfMonths &&
      frequency === planDetails.frequencyCode;
    const fallsInRule =
      chosenRule &&
      +chosenRule.minimum_payment_required.value <= monthlyPayment &&
      numberOfMonths > 1 &&
      +chosenRule.max_duration.value >= numberOfMonths;
    this.setState({ monthError: false, paymentError: false });
    // set error message and disable pay buttons
    if (chosenRule && +chosenRule.max_duration.value < numberOfMonths) {
      this.setState({
        monthError: `${monthlyFrequency ? monthHigh : weekHigh} ${
          chosenRule.max_duration.value
        }.`,
      });
      isValid && updateValid(false);
    }
    if (chosenRule && numberOfMonths <= 1) {
      this.setState({
        monthError: `${monthlyFrequency ? monthLow : weekLow} 1`,
      });
      isValid && updateValid(false);
    }
    if (
      chosenRule &&
      +chosenRule.minimum_payment_required.value > monthlyPayment
    ) {
      this.setState({
        paymentError: `${monthlyFrequency ? paymentLow : weekPaymentLow} $${
          chosenRule.minimum_payment_required.value
        }.`,
      });
      isValid && updateValid(false);
    }
    // enable buttons if inputs are changed and fall within rules
    if (
      (!editPage && fallsInRule) ||
      (editPage && !editPageOriginalValue && fallsInRule)
    ) {
      !isValid && updateValid(true);
    }
  };
  selectRuleBasedOnFrequency = ({ frequency, currentPlan }) => {
    const {
      partialPayment: { displayInput },
      settings,
      editPaymentPlan: { planDetails },
      paymentPlan: { option },
    } = this.props;
    this.setState({ chosenRule: false });
    const interval =
      frequency === Payments.MONTHLY ? Payments.MONTHS : Payments.WEEKS;
    let amount = +displayInput.replace(/[^\d.]/g, '');
    if (option === Payments.EDIT) amount = planDetails.totalAmount;
    if (currentPlan) amount = currentPlan.total;
    let chosenRule;
    settings.balance_range_rules.forEach(rule => {
      if (rule.max_duration.interval === interval && !chosenRule) {
        chosenRule =
          amount <= +rule.max_balance.value &&
          amount >= +rule.min_balance.value &&
          rule;
      }
    });
    chosenRule && this.setState({ chosenRule });
  };

  // triggers when displaying list of existing payment plans
  verifyExistingAgainstRules = ({ plan }) => {
    const {
      partialPayment: { displayInput },
      settings,
      frequencyDropdown,
    } = this.props;
    const allowWeekly = settings.frequency_code.weekly.allow;
    const interval =
      plan.frequencyCode === Payments.MONTHLY
        ? Payments.MONTHS
        : Payments.WEEKS;
    let disabledInterval;
    let showPlan;
    // if frequency disabled dont show the plans in list
    if (frequencyDropdown.id === Payments.PAYMENT_FREQUENCY_DISABLED) {
      disabledInterval = !allowWeekly ? Payments.WEEKS : Payments.MONTHS;
    }
    settings.balance_range_rules.forEach(item => {
      if (
        !showPlan &&
        item.max_duration.interval === interval &&
        interval !== disabledInterval
      ) {
        showPlan =
          +item.max_balance.value >=
          plan.total + +displayInput.replace(/[^\d.]/g, '');
      }
    });
    return showPlan;
  };
  // triggered when create new radio button for payment plan is selected
  selectNewPaymentPlan = _ => {
    store.dispatch.paymentPlan.resetPaymentPlan();
    this.setState({
      monthError: false,
      paymentError: false,
      selectNew: true,
      clickedOnAdd: false,
      disableAdd: false,
      disableInput: false,
    });
    this.verifyWhichFrequencyAmountFallsUnder({ frequency: null });
    this.toggleViewCreditCardComponent({ show: true });
  };
  // triggered when clicking on existing plans list to add
  selectExistingPlan = ({ e, plan }) => {
    const {
      updatePlan,
      setExistingPlan,
      updateValue,
      partialPayment: { displayInput },
      makePayments: { isValid },
      updateValid,
    } = this.props;
    if (e.event) {
      updatePlan(e.target.name, e.target.value);
    }
    if (plan) {
      this.setState({
        monthError: false,
        paymentError: false,
        selectNew: false,
        disableAdd: true,
        disableInput: false,
      });
      this.toggleViewCreditCardComponent({ show: false });
      //dont let user change frequency
      this.setState({ disableFrequency: true });
      !isValid && updateValid(true);
      // calculate new numbers
      const {
        paid,
        total,
        frequencyCode,
        dayOfWeek,
        dayOfMonth,
        installments,
        installmentPaid,
      } = plan;
      const updatedPlan = Object.assign({}, plan);
      const updatedTotal =
        +displayInput.replace(/[^\d.]/g, '') + (total - paid);
      updatedPlan.originalAmount = plan.total;
      updatedPlan.total = updatedTotal;
      updatedPlan.installments = installments - installmentPaid;
      const monthlyPayment = this.calculateMonthlyPayment({
        totalAmount: updatedTotal,
        installments: updatedPlan.installments,
      });
      updatedPlan.monthlyPayment = monthlyPayment;
      setExistingPlan(updatedPlan);
      updateValue(Payments.PAYMENT_FREQUENCY, frequencyCode);
      this.selectRuleBasedOnFrequency({
        frequency: updatedPlan.frequencyCode,
        currentPlan: updatedPlan,
      });
      dayOfMonth && updateValue(Payments.DAY_OF_MONTH, dayOfMonth);
      dayOfWeek && updateValue(Payments.DAY_OF_WEEK, dayOfWeek);
    }
  };
  displayExistingPlans = _ => {
    this.setState({ clickedOnAdd: true, disableInput: true });
    store.dispatch.mixpanel.addMetadata({ addToExistingPaymentPlan: true });
    store.dispatch.paymentPlan.clearPlanInputs();
  };
  render() {
    const {
      location: { pathname },
      radioInput: { paymentType },
      paymentPlan: {
        monthlyPayment,
        numberOfMonths,
        planName,
        dayOfMonth,
        currentPlan,
        option,
        existingPlanName,
      },
      editPaymentPlan: { editPaymentPlan },
      partialPayment: { displayInput, error },
      inputDropdown: { paymentFrequency, paymentFrequencyDisabled },
    } = this.props;
    const planDisplay = {};
    const installments = numberOfMonths;
    const newPlan = option === Payments.NEW;
    const editPlan = pathname.endsWith(Paths.EDIT_PAYMENT_PLAN);
    if (currentPlan && newPlan) {
      planDisplay.total = currentPlan.total;
      planDisplay.pending = currentPlan.total - currentPlan.paid;
    } else if (currentPlan) {
      let updatedTotal = currentPlan.total + this.props.amount;
      if (+displayInput.replace(/[^\d.]/g, '')) {
        updatedTotal =
          +displayInput.replace(/[^\d.]/g, '') + currentPlan.originalAmount;
      }
      planDisplay.total = updatedTotal;
      planDisplay.pending = updatedTotal - currentPlan.paid;
      planDisplay.monthlyPayment = monthlyPayment;
    }
    const amountEntered =
      (+displayInput.replace(/[^\d.]/g, '') >= 0.01 && !error.display) ||
      editPlan;
    amountEntered &&
      !editPlan &&
      this.toggleViewCreditCardComponent({ show: newPlan });
    const verifiedPlans =
      this.props.existingPlans &&
      this.props.existingPlans.filter(
        plan => this.verifyExistingAgainstRules({ plan }) && plan,
      );

    return (
      <PaymentPlan
        {...{
          ...this.props,
          paymentType,
          planDisplay,
          monthlyPayment,
          installments,
          numberOfMonths,
          amountEntered,
          paymentFrequency: paymentFrequency || paymentFrequencyDisabled,
          planName,
          dayOfMonth,
          currentPlan,
          option,
          existingPlanName,
          displayInput,
          blankInputs: !monthlyPayment || !numberOfMonths,
          selectExistingPlan: this.selectExistingPlan,
          selectNewPaymentPlan: this.selectNewPaymentPlan,
          updatePlan: this.updatePlan,
          paymentError: this.state.paymentError,
          monthError: this.state.monthError,
          editPaymentPlan: isEmpty(editPaymentPlan),
          verifyExistingAgainstRules: this.verifyExistingAgainstRules,
          disableFrequency: this.state.disableFrequency,
          chosenRule: this.state.chosenRule,
          disableInput: this.state.disableInput,
          selectNew: this.state.selectNew,
          clickedOnAdd: this.state.clickedOnAdd,
          displayExistingPlans: this.displayExistingPlans,
          disableAdd: this.state.disableAdd,
          verifiedPlans,
          lastPaymentAmount: this.state.lastPaymentAmount,
        }}
      />
    );
  }
}

const mapStateToProps = ({
  radioInput,
  editPaymentPlan,
  paymentPlan,
  makePayments,
  partialPayment,
  inputDropdown,
}) => ({
  radioInput,
  editPaymentPlan,
  paymentPlan,
  makePayments,
  partialPayment,
  inputDropdown,
});
const mapDispatchToProps = ({
  paymentPlan,
  makePayments,
  radioInput,
  inputDropdown,
}) => ({
  updatePlan: paymentPlan.updatePlan,
  storeExistingPlan: paymentPlan.storeExistingPlan,
  updateValid: makePayments.updateValid,
  resetPaymentPlan: paymentPlan.resetPaymentPlan,
  setDefault: paymentPlan.setDefault,
  setExistingPlan: paymentPlan.setExistingPlan,
  setLineItems: paymentPlan.setLineItems,
  resetPaymentMethod: radioInput.resetPaymentMethod,
  updateValue: inputDropdown.updateValue,
});

const enhancer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
export default enhancer(PaymentPlanContainer);
