export const layout = {
  state: {
    layoutScreen: {},
    userAvatar: {},
    practiceSelected: {},
  },
  reducers: {
    fetchStart: (state, { layoutScreen, userAvatar, metaData }) => ({
      ...state,
      layoutScreen,
      userAvatar,
      metaData,
    }),
    resetState: state => ({ ...state, refetch: true }),
    didRefetch: state => ({ ...state, refetch: false }),
    clearStore: _ => ({ ...layout.state }),
    setPracticeSelected: (state, param) => ({
      ...state,
      practiceSelected:  param,
    }),
  },
};
