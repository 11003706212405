import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { compose, isEmpty } from '@carecloud/cloudpak';
import { ContentPage } from '..';
import { GetSupport } from '../../../graphql/queries';
import { parseResult } from '../../../graphql/utilities';
import { breakBackButton } from '../../services/utilities';

class HelpContainer extends Component {
  componentDidMount() {
    breakBackButton();
  }
  componentWillReceiveProps({ support, getSupport, fetchStart, activateLoader, loader: { loading } }) {
    const queryLoading = getSupport && getSupport.loading;
    if (!queryLoading && loading) {
      activateLoader({ loading: false });
    }
    if (isEmpty(support.support) && !queryLoading) {
      parseResult({ query: getSupport, dataField: 'getSupport', resultHandler: fetchStart });
    }
  }

  componentWillUnmount() {
    const { activateLoader } = this.props;
    activateLoader({ loading: true });
  }

  render() {
    const {
      support: { support },
      loader: { loading },
    } = this.props;
    return support && <ContentPage schemas={support} loading={loading} />;
  }
}
const mapStateToProps = ({ support, loader }) => ({ support, loader });
const mapDispatchToProps = ({ support, loader }) => ({
  fetchStart: support.fetchStart,
  activateLoader: loader.activateLoader,
});
const enhancer = compose(
  withApollo,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(GetSupport, { name: 'getSupport', skip: ({ support }) => !isEmpty(support.support) }),
);
export default enhancer(HelpContainer);
