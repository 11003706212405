import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import { compose, set, has } from '@carecloud/cloudpak';
import { store } from '../../../models';
import * as Mutations from '../../../graphql/mutations';
import { Form } from '../../rjsf';
import { checkDateOfBirth } from '../../commonjs';
import { SchemaParser, Session } from '../../services';
import { signupContext as SignupContext } from '../../../contexts';
import { isMobileDevice } from '../../services/utilities';
import { parseParams } from '../../services/routing';

const SignupFormWrapper = styled.div`
  justify-content: center;
  border-radius: 10px;
  margin: 0 auto;
  /* ${props => !props.isMobile && 'margin-top: 20px'}; */
  /* margin-top: 60px; */
  /* margin-bottom: 30px; */
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(55, 71, 79, 0.3);
  background-color: #fff;
  padding: 24px 12px;
  margin-top: 20px;
  & form {
    width: 340px;
  }
  & [class*='passwordPrompt'] {
    margin: 0 auto;
  }
  & [class*='passwordPrompt'] > h5 {
    color: #90a4ae;
    font-size: 11px;
    font-family: 'Metropolis';
    font-weight: 400;
    line-height: 15px;
    margin: 0;
    width: 300px;
    text-align: center;
  } 
  & [id*='separator'] {
    min-height: 20px;
  }
  & div[class*='guestRegisterTermsAndConditions'] {
    text-transform: none !important;
  }
  & input[id*='guestRegisterTermsAndConditions']:checked:after {
    background-color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
    border-color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
  }
  & a[id*='guestRegisterTermsAndConditionsBtn'] {
    color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
    text-decoration: underline;
  }
  & input[type='checkbox']:checked + div div {
    color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
  }
  & input[type='checkbox'] {
    width: 30px;
    height: 20px;
  }
  & input:disabled {
    color: #cfd8dc;
    -webkit-text-fill-color: #cfd8dc;
    font-weight: 400;
  }
  & button[id*='patientSignupBtnDark'] {
    margin: auto !important;
    background-color: #455a64;
  }
  & button[id*='patientSignupBtn'] {
    margin: auto !important;
    background-color: ${props => (props.darkMode ? '#455a64' : props.whiteLabelColor && props.whiteLabelColor)};
  }
  & div[id*='loginLink'] > span {
    color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
    text-decoration: underline;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
  }
  & [id*='loginLinkGroup'] {
    margin-top: 25px;
    text-align: center;
  }
  & input {
    font-size: 16px;
    color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
    &:-webkit-autofill {
      -webkit-text-fill-color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
    }
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #c8d2d7;
    font-family: 'Metropolis';
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.846;
    font-weight: 400;
  }
  & div[class*='Select-value'] > span {
    font-size: 16px;
    color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
    &:-webkit-autofill {
      -webkit-text-fill-color: ${props => (props.darkMode ? `#455a64` : props.whiteLabelColor)};
    }
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #c8d2d7;
    font-family: 'Metropolis';
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.846;
    font-weight: 400;
  }
  & span[class*='required'] {
    font-size: 20px;
    margin-left: 5px;
    color: #f33;
    position: absolute;
    line-height: 16px;
  }
`;

export const SignupForm = ({ client, formData, schema, uiSchema, formBottom, history, darkTheme, whiteLabelColor }) => {
  const [signupForm, setSignupForm] = useState({ formData });
  const {
    formGroup: { events },
    form: { externalSubmissionData },
    signup: { signupScreen: [{ is_sms = false } = {}] = [] } = {},
  } = store.getState();
  const {
    formData: { birthdate, signupInputDOB },
  } = signupForm;
  const {
    location: { pathname },
  } = history;
  const registerForm = pathname.includes('register');
  const inviteSignupForm = pathname.includes('signup');

  const enableUpdateButton = complete => {
    store.dispatch.button.enableButton({
      btn: darkTheme ? 'patientSignupBtnDark' : 'patientSignupBtn',
      complete,
    });
  };
  const fetchDataAgainstZipcode = async () => {
    if (signupForm.formData?.zipcode?.length === 5) {
      try {
        const zipcode = signupForm.formData?.zipcode;
        const {
          data: {
            getZipcodeInfo: {
              lookups: {
                0: { result },
              },
            },
          },
        } = await client.mutate({
          mutation: Mutations.getZipcodeInfo,
          variables: {
            zipcode,
          },
        });
        const dataForm = JSON.parse(JSON.stringify(signupForm));
        if (!result[0].valid) {
          dataForm.formData.state = '';
          dataForm.formData.city = '';
          enableUpdateButton(false);
        } else {
          dataForm.formData.state = result[0].cities[0].stateAbbreviation;
          dataForm.formData.city = result[0].zipcodes[0].defaultCity;
        }
        setSignupForm(dataForm);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const validate = (formData, errors) => {
    if (registerForm && formData.password !== formData.repeatPassword) {
      errors.repeatPassword.addError('match');
    } else if (inviteSignupForm && formData.signupInputCreatePassword !== formData.repeatPassword) {
      errors.repeatPassword.addError('match');
    } else if (inviteSignupForm && formData.repeatEmail && formData.signupInputEmail !== formData.repeatEmail) {
      errors.repeatEmail.addError('match');
    } else if (formData.signupInputDOB || formData.birthdate) {
      const date = moment(
        inviteSignupForm ? formData.signupInputDOB : formData.birthdate,
        inviteSignupForm ? schema.properties.signupInputDOB.baseFormat : schema.properties.birthdate.baseFormat,
        true,
      );
      if (!date.isValid() || !date.isBetween(moment('1901-01-01'), moment(), null, '[]')) {
        if (inviteSignupForm) {
          errors.signupInputDOB.addError('pattern');
        } else {
          errors.birthdate.addError('pattern');
        }
      }
    }

    return errors;
  };

  const isValid = { valid: false };
  const termsConditionsCheckbox = events.find(
    ({ id, isInitial, value }) => id === 'guestRegisterTermsAndConditions' && !isInitial && value,
  );
  if (
    checkDateOfBirth({ inputDates: registerForm ? birthdate : signupInputDOB }) ||
    (registerForm && !termsConditionsCheckbox)
  ) {
    isValid.valid = false;
  } else {
    isValid.valid = !Array.isArray(signupForm?.errors) || signupForm?.errors?.length < 1;
  }

  const showPasswordBtn = () => {
    const passwordError = document.querySelector('input[name="password"] + p[class*="error"]');
    const passwordError2 = document.querySelector('input[name="signupInputCreatePassword"] + p[class*="error"]');
    const repeatPasswordError = document.querySelector('input[name="repeatPassword"] + p[class*="error"]');
    const passwordEyeIcon = document.getElementById('passwordeyeIcon');
    const repeatPasswordEyeIcon = document.getElementById('repeatPasswordEyeIcon');
    const passwordEyeIcon_2 = document.getElementById('passwordeyeIcon_2');
    const repeatPasswordEyeIcon_2 = document.getElementById('repeatPasswordEyeIcon_2');

    if (passwordEyeIcon !== null && repeatPasswordEyeIcon !== null) {
        if (passwordError && repeatPasswordError) {
            passwordEyeIcon.style.bottom = '11.3rem';
            repeatPasswordEyeIcon.style.bottom = '5rem';
        } else if (passwordError) {
            passwordEyeIcon.style.bottom = '10rem';
        } else if (repeatPasswordError) {
            passwordEyeIcon.style.bottom = '10rem';
            repeatPasswordEyeIcon.style.bottom = '5rem';
        } else {
            passwordEyeIcon.style.bottom = '9rem';
            repeatPasswordEyeIcon.style.bottom = '4rem';
        }
    }
    if (passwordEyeIcon_2 !== null && repeatPasswordEyeIcon_2 !== null) {
      if (passwordError2 && repeatPasswordError) {
        passwordEyeIcon_2.style.bottom = '11.3rem';
        repeatPasswordEyeIcon_2.style.bottom = '5rem';
      } else if (passwordError2) {
        passwordEyeIcon_2.style.bottom = '10rem';
      } else if (repeatPasswordError) {
        passwordEyeIcon_2.style.bottom = '10rem';
        repeatPasswordEyeIcon_2.style.bottom = '5rem';
      } else {
        passwordEyeIcon_2.style.bottom = '9rem';
        repeatPasswordEyeIcon_2.style.bottom = '4rem';
      }
  }
};
const passwordShownBtn = () => {
  const inputElements = document.getElementsByName('password');
  const inputElements2 = document.getElementsByName('repeatPassword');
  const inputElements3 = document.getElementsByName('signupInputCreatePassword');
  if (inputElements.length > 0 || inputElements2.length > 0 || inputElements3.length > 0){
    const inputElement = inputElements[0];
    const inputElement2 = inputElements2[0];
    const inputElement3 = inputElements3[0];
    if(inputElement !== undefined){
      const inputValue = inputElement.value;
      const inputValue2 = inputElement2.value;
      const buttonElement = document.getElementById('passwordeyeIcon');
      const buttonElement2 = document.getElementById('repeatPasswordEyeIcon');
      const imgElement = buttonElement.querySelector('img');
      const imgElement2 = buttonElement2.querySelector('img');
      if(inputValue === ''){
        imgElement.style.visibility = 'hidden';
        buttonElement.style.pointerEvents = 'none'
      }else{
        imgElement.style.visibility = 'visible';
        buttonElement.style.pointerEvents = 'all'
      }
      if(inputValue2 === ''){
        imgElement2.style.visibility = 'hidden';
        buttonElement2.style.pointerEvents = 'none'
      }else{
        imgElement2.style.visibility = 'visible';
        buttonElement2.style.pointerEvents = 'all'
      }
    }else {
      const inputValue2 = inputElement2.value;
      const inputValue3 = inputElement3.value;
      const buttonElement3 = document.getElementById('passwordeyeIcon_2');
      const buttonElement4 = document.getElementById('repeatPasswordEyeIcon_2');
      const imgElement = buttonElement3.querySelector('img');
      const imgElement2 = buttonElement4.querySelector('img');
      if(inputValue3 === ''){
        imgElement.style.visibility = 'hidden';
        buttonElement3.style.pointerEvents = 'none'
      }else{
        imgElement.style.visibility = 'visible';
        buttonElement3.style.pointerEvents = 'all'
      }
      if(inputValue2 === ''){
        imgElement2.style.visibility = 'hidden';
        buttonElement4.style.pointerEvents = 'none'
      }else{
        imgElement2.style.visibility = 'visible';
        buttonElement4.style.pointerEvents = 'all'
      }
    }
  }
}
document.addEventListener('click', function() {
  showPasswordBtn();
});
  useEffect(() => {
    store.dispatch.loader.hide();
    enableUpdateButton(false);
  }, [showPasswordBtn(),passwordShownBtn()]); 
  useEffect(() => {
    fetchDataAgainstZipcode();
  }, [signupForm.formData?.zipcode]);
  useEffect(() => {
    if (inviteSignupForm && isValid.valid) {
      enableUpdateButton(true);
      return;
    }
    if (isValid.valid && signupForm.formData?.state && signupForm.formData?.city) {
      enableUpdateButton(true);
    } else {
      enableUpdateButton(false);
    }
  }, [isValid.valid, signupForm.formData?.state, signupForm.formData?.city]);

  const registerAccount = async _ => {
    enableUpdateButton(false);
    store.dispatch.loader.persistLoader({ persist: true });
    let inputObject = {};
    // copied from formContainer to be able to use external data
    externalSubmissionData.forEach(data => {
      inputObject = set(inputObject, data.formMapping, data.value, {
        // eslint-disable-next-line object-curly-newline
        mutate: true,
      });
    });
    //cleaning variables before sending
    const finalInput = Object.assign({}, { ...signupForm.formData, ...inputObject });
    delete finalInput.repeatEmail;
    delete finalInput.repeatPassword;
    const {
      data,
      data: { createAccount },
    } = await client.mutate({
      mutation: Mutations.createAccountForm,
      variables: { input: { ...finalInput } },
    });
    if (createAccount?.data?.payload?.create_account_new_user) {
      const patientId = createAccount?.data?.payload?.create_account_new_user?.create_patient?.patient?.external_id;
      const username = createAccount.data.payload.create_account_new_user.sign_up.UserName;
      store.dispatch.signup.storePatientData({ patientId, username });
    }
    enableUpdateButton(true);
    // copied all this code from FormContainer, hopefully this covers all scenario and it works just like before.
    if (createAccount.error) {
      store.dispatch.loader.persistLoader({ persist: false });
      store.dispatch.modal.saveCustomMessage(data.createAccount.message);
      sessionStorage.clear();
      return store.dispatch.modal.toggleOpen('registerErrorModal');
    } else if (isMobileDevice() && has(data, 'createAccount.data.payload.create_account_existing_user.sign_in')) {
      store.dispatch.loader.persistLoader({ persist: false });
      store.dispatch.modal.toggleOpen('registerPreviousAccount');
      sessionStorage.clear();
      return;
    } else if (has(data, 'createAccount.data.payload.create_account_existing_user.sign_in')) {
      Session.newAccountSetSession({ data: data.createAccount });
      history.push('/dashboard');
    }
    const { practiceId, usedBtn } = parseParams(history);
    store.dispatch.mixpanel.addMetadata({
      // eslint-disable-next-line object-curly-newline
      createAccount: { usedBtn, practiceId },
    });
    const { email } = data.createAccount;
    store.dispatch.signup.storeEmail({ email });
    store.dispatch.signup.fetchStart({
      // eslint-disable-next-line object-curly-newline
      signupScreen: createAccount.signupScreen,
    });
    store.dispatch.loader.hide();
  };

  const createNewUser = async () => {
    const { i } = parseParams(history);
    enableUpdateButton(false);
    store.dispatch.loader.persistLoader({ persist: true });
    const finalInput = Object.assign({}, { ...signupForm.formData });
    delete finalInput.repeatEmail;
    delete finalInput.repeatPassword;
    if (is_sms) finalInput.signupIsSMS = 'true';
    const { data, data: { createNewUser: { data: { sms = 'false' } = {} } = {} } = {} } = await client.mutate({
      mutation: Mutations.signupForm,
      variables: { input: { ...finalInput, ...{ signupInvitationId: i } } },
    });
    sessionStorage.setItem(
      'Patient-Ids-List',
      JSON.stringify(data?.createNewUser?.data?.payload?.patient_app_signin?.user_links?.logged_in_user?.patients),
    );
    sessionStorage.setItem(
      'totalPractice',
      JSON.stringify(data?.createNewUser?.data?.payload?.patient_app_signin?.user_links?.practice_information?.length),
    );
    sessionStorage.setItem(
      'singleSelectedpractice',
      JSON.stringify(
        data?.createNewUser?.data?.payload?.patient_app_signin?.user_links?.practice_information?.[0]?.practice_id,
      ),
    );

    enableUpdateButton(true);
    if (data.createNewUser) {
      if (data.createNewUser.error) {
        store.dispatch.loader.persistLoader({ persist: false });
        store.dispatch.modal.saveCustomMessage(data.createNewUser.message);
        sessionStorage.clear();
        return store.dispatch.modal.toggleOpen('registerErrorModal');
      }
      const submissionData = data.createNewUser.data || {};
      if (sms === 'true') {
        if (!submissionData.redirect) return;
        store.dispatch.mixpanel.addMetadata({ successRedirect: true });
        return history.push(submissionData.redirect);
      } else if (typeof window.orientation !== 'undefined' || navigator.userAgent.includes('IEMobile')) {
        // If user is on a mobile device, redirect them to the mobile login page and do not auto-login
        history.push('/?redirect=mobileSignup');
      } else {
        Session.newAccountSetSession({ data: data.createNewUser });
        history.push('/dashboard');
      }
    }
    store.dispatch.loader.hide();
  };
  
  return (
    <SignupContext.Provider
      value={{
        signupNewUser: registerAccount,
        createNewUser,
      }}
    >
      <SignupFormWrapper darkMode={darkTheme} whiteLabelColor={whiteLabelColor}>
        <Form
          formData={signupForm.formData}
          onChange={setSignupForm}
          schema={schema}
          uiSchema={uiSchema}
          validate={validate}
        />
        <SchemaParser schema={formBottom} />
      </SignupFormWrapper>
    </SignupContext.Provider>
  );
};

export default compose(withApollo)(SignupForm);
