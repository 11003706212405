const state = { availableHours: [] };
export const availableHours = {
  state,
  reducers: {
    fetchStart: (state, { availableHoursScreen, appointment }) => ({
      ...state,
      availableHours: availableHoursScreen,
      appointment,
    }),
    resetState: () => ({ ...state }),
    clearStore: () => availableHours.reducers.resetState(),
  },
};
