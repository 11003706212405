import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { compose, flattenDeep, get } from '@carecloud/cloudpak';
import Modal from '../modals/modal/modalContainer';
import { Utilities } from './index';
import {
  Appointment,
  Avatar,
  AvatarUploader,
  Button,
  CareTeamMember,
  Checkbox,
  CreditCard,
  DatePicker,
  DateTimeSelect,
  Dropdown,
  EmptySection,
  Expander,
  Form,
  FormGroup,
  FormHistorySelect,
  Group,
  Hamburger,
  Header,
  HistoryAppointment,
  Icon,
  Image,
  Input,
  InputDropdown,
  IntakeForm,
  JsonSchemaForm,
  Link,
  Message,
  MessagesPanel,
  MessagesSection,
  NavIcon,
  PartialPayment,
  PaymentAmount,
  PaymentPlan,
  PaymentSection,
  PaymentSectionCollapsed,
  PaymentSectionCollapsedContainer,
  ProgressMeter,
  ProviderCardContainer,
  RJSFormContainer,
  RadioInput,
  Table,
  AddAppointmentWorkflow,
  Selector,
  Rating,
  TextArea,
  Timer,
  DelegateCard,
  DelegateDropdown,
  PaginationGroup,
  HierarchicalCheckboxes,
  HelpingText,
  Applink,
  ErrorText,
} from '../ui';

import CheckInInfo from '../pages/checkIn/Info';
import CheckInMedicationsAndAllergies from '../pages/checkIn/MedicationsAndAllergies';
import ProfileDemographics from '../pages/profile/Demographics';
import ProfileDocuments from '../pages/profile/Documents';
import ProfileNotifications from '../pages/profile/Notifications';
import ProfilePersonal from '../pages/profile/Personal';
import { SignupForm } from '../pages/signup/SignupForm';

class SchemaParser extends Component {
  generate = data => {
    if (!data || !data.component) return;
    const item = {
      ...data,
      theme: data.id && Utilities.parseThemeFromId({ id: data.id }),
    };
    const components = {
      JSON_SCHEMA_FORM: _ => <JsonSchemaForm key={item.id} props={Object.assign({}, this.props, item)} />,
      BUTTON: _ => <Button key={item.id} {...{ button: this.props.button, ...item }} />,
      HelpingText: _ => <HelpingText key={item.id} id={item.id} label={item.label} />,
      ErrorText: _ => <ErrorText key={item.id} id={item.id} label={item.label} display={item.display} />,
      INPUT: _ => (
        <Input
          key={item.id}
          {...{
            ...item,
            schema: JSON.stringify(this.props.schema),
          }}
        />
      ),
      MODAL: _ => (
        <Modal
          key={item.id}
          {...{
            radioInputCancellationReason: this.props.radioInput && this.props.radioInput.cancellationReason,
            modal: this.props.modal,
            localActions: JSON.stringify(this.props.actions.localActions),
            enableCancelButton: get(this.props, 'formGroup.events[1].value', String()),
            ...item,
          }}
        />
      ),
      LINK: _ => <Link key={item.id} {...{ pathname: this.props.location.pathname, ...item }} />,
      ICON: _ => <Icon key={item.id} {...{ history: this.props.history, ...item }} />,
      IMAGE: _ => <Image key={item.id} {...{ ...item }} />,
      HEADER: _ => <Header key={item.id} {...{ ...item }} />,
      RADIO_INPUT: _ => (
        <RadioInput
          key={item.id}
          {...{
            ...item,
            addAppointment: this.props.addAppointment,
            radioInput: this.props.radioInput,
          }}
        />
      ),
      CHECK_BOX: _ => (
        <Checkbox
          key={item.id}
          {...{
            ...item,
            onChange: this.props.onChange,
            formContext: this.props.formContext,
          }}
        />
      ),
      INPUT_DROPDOWN: _ => (
        <InputDropdown
          key={item.id}
          {...{
            ...item,
            schema: JSON.stringify(this.props.schema),
            client: this.props.client,
            inputDropdown: this.props.inputDropdown,
          }}
        />
      ),
      DATE_TIME_SELECT: _ => (
        <DateTimeSelect
          key={item.id}
          {...{
            button: this.props.button,
            dateTimeSelect: this.props.dateTimeSelect,
            ...item,
          }}
        />
      ),
      PAYMENT_PLAN: _ => <PaymentPlan key={item.id} {...{ ...item }} />,
      CREDIT_CARD: _ => <CreditCard key={item.id} {...{ ...item }} />,
      ADD_APPOINTMENT_WORKFLOW: _ => <AddAppointmentWorkflow key={item.id} {...{ ...item }} />,
      EMPTY_SECTION: _ => <EmptySection key={item.id} {...{ ...item }} />,
      PAYMENT_SECTION: _ => (
        <PaymentSection
          key={item.id}
          {...{
            ...item,
            localActions: JSON.stringify(this.props.actions.localActions),
            modals: item.modal,
            match: null,
          }}
        />
      ),
      PAYMENT_AMOUNT: _ => (
        <PaymentAmount
          key={item.id}
          {...{
            ...item,
            history: this.props.history,
            location: this.props.location,
            match: JSON.stringify(this.props.match),
          }}
        />
      ),
      FORM_HISTORY_SELECT: _ => (
        <FormHistorySelect
          key={item.id}
          {...{
            ...item,
            ...this.props.formHistory,
            history: this.props.history,
            client: this.props.client,
          }}
        />
      ),
      PARTIAL_PAYMENT: _ => <PartialPayment key={item.id} {...{ ...item }} />,
      PROGRESS_METER: _ => <ProgressMeter key={item.id} {...{ ...item }} />,
      PROVIDER_CARD: _ => <ProviderCardContainer key={item.id} props={Object.assign({}, this.props, item)} />,
      PAYMENT_SECTION_COLLAPSED: _ => <PaymentSectionCollapsed key={item.id} {...{ ...item, modals: item.modal }} />,
      PAYMENT_SECTION_COLLAPSED_CONTAINER: _ => (
        <PaymentSectionCollapsedContainer
          key={item.id}
          {...{
            ...item,
            payments: this.props.payments,
            client: this.props.client,
          }}
        />
      ),
      NAVICON: _ => <NavIcon key={item.id} {...{ myHealth: this.props.myHealth, ...item }} />,
      Applink: _ => <Applink key={item.id} {...item} />,
      GROUP: _ => (
        <Group key={item.id} props={item}>
          {item.components && item.components.map(component => component && this.generate(component))}
        </Group>
      ),
      PAGINATION_GROUP: _ => (
        <PaginationGroup key={item.id} {...{ paginationGroup: this.props.paginationGroup, ...item }} />
      ),
      AVATAR: _ => <Avatar key={item.id} {...{ ...item }} />,
      APPOINTMENT: _ => (
        <Appointment
          key={item.id}
          {...{
            ...item,
            localActions: this.props.localActions,
            visitReason: this.props.visitReason,
            button: this.props.button,
          }}
        />
      ),
      HISTORY_APPOINTMENT: _ => <HistoryAppointment key={item.id} {...{ ...item }} />,
      MESSAGES_SECTION: _ => (
        <MessagesSection
          key={item.id}
          {...{
            ...item,
            messages: this.props.messages,
            getMessages: this.props.getMessages,
            createMessage: this.props.createMessage,
            deleteMessage: this.props.deleteMessage,
            markMessageRead: this.props.markMessageRead,
            markMessageUnread: this.props.markMessageUnread,
            replyMessage: this.props.replyMessage,
            client: this.props.client,
          }}
        />
      ),
      MESSAGES_PANEL: _ => (
        <MessagesPanel
          key={item.id}
          {...{
            messages: this.props.messages,
            radioInput: this.props.radioInput,
            formHistory: this.props.formHistory,
            addAppointment: this.props.addAppointment,
            location: this.props.location,
            ...item,
          }}
        />
      ),
      MESSAGE: _ => (
        <Message
          key={item.id}
          {...{
            userAvatar: this.props.layout.userAvatar,
            messages: this.props.messages,
            getAttachment: this.props.getAttachment,
            ...item,
          }}
        />
      ),
      HAMBURGER: _ => <Hamburger key={item.id} {...{ ...item }} />,
      DROPDOWN: _ => <Dropdown key={item.id} {...{ dropdown: this.props.dropdown, ...item }} />,
      TABLE: _ => (
        <Table
          key={item.id}
          {...{
            ...item,
          }}
        />
      ),
      CARE_TEAM_MEMBER: _ => <CareTeamMember key={item.id} {...{ ...item }} />,
      SELECTOR: _ => <Selector key={item.id} {...{ ...item }} />,
      FORM: _ => <Form key={item.id} props={Object.assign({}, this.props, item)} />,
      FORM_GROUP: _ => <FormGroup key={item.id} props={Object.assign({}, this.props, item)} />,
      EXPANDER: _ => <Expander key={item.id} {...{ ...item }} />,
      AVATAR_UPLOADER: _ => <AvatarUploader key={item.id} {...{ ...item }} />,
      INTAKE_FORM: _ => (
        <IntakeForm
          key={item.id}
          {...{
            client: this.props.client,
            appointmentCheckIn: this.props.appointmentCheckIn,
            ...item,
            history: this.props.history,
          }}
        />
      ),
      DATE_PICKER: _ => <DatePicker key={item.id} {...{ ...item }} />,
      RATING: _ => (
        <Rating
          key={item.id}
          {...{
            schema: this.props.schema,
            formData: this.props.formData,
            onChange: this.props.onChange,
            ...item,
          }}
        />
      ),
      TEXT_AREA: _ => (
        <TextArea
          key={item.id}
          {...{
            textArea: this.props.textArea,
            formData: this.props.formData,
            ...item,
          }}
        />
      ),
      TIMER: _ => (
        <Timer
          key={item.id}
          {...{
            // textArea: this.props.textArea,
            // formData: this.props.formData,
            ...item,
          }}
        />
      ),
      RJS_FORM: _ => <RJSFormContainer key={item.id} {...{ leftSide: this.props.leftSide, ...item }} />,
      DELEGATE_CARD: _ => (
        <DelegateCard
          key={item.id}
          {...{
            history: this.props.history,
            delegateCard: this.props.delegateCard,
            ...item,
          }}
        />
      ),
      DELEGATE_DROPDOWN: _ => (
        <DelegateDropdown key={item.id} {...{ delegateDropdown: this.props.delegateDropdown, ...item }} />
      ),
      HIERARCHICAL_CHECKBOXES: _ => <HierarchicalCheckboxes key={item.id} {...{ ...item }} />,
      CHECK_IN_MEDICATIONS_AND_ALLERGIES: _ => (
        <CheckInMedicationsAndAllergies key={item.id} {...{ history: this.props.history, ...item }} />
      ),
      CHECK_IN_INFO: _ => <CheckInInfo key={item.id} {...{ ...item, history: this.props.history }} />,
      PROFILE_DEMOGRAPHICS: _ => <ProfileDemographics key={item.id} {...{ ...item }} />,
      PROFILE_DOCUMENTS: _ => <ProfileDocuments key={item.id} {...{ ...item }} />,
      PROFILE_NOTIFICATIONS: _ => <ProfileNotifications key={item.id} {...{ ...item }} />,
      PROFILE_PERSONAL: _ => <ProfilePersonal key={item.id} {...{ ...item }} />,
      SIGNUP_FORM: _ => (
        <SignupForm key={item.id} {...{ ...item, client: this.props.client, history: this.props.history }} />
      ),
    };
    try {
      return components[item.component]();
    } catch (error) {
      console.error(error);
    }
  };
  render() {
    return flattenDeep(this.props.schema || []).map(item => this.generate(item));
  }
}
SchemaParser.defaultProps = { schema: [] };
SchemaParser.propTypes = { schema: PropTypes.arrayOf(PropTypes.any) };
const mapStateToProps = props => ({ ...props });
const mapDispatchToProps = (state, ownProps) => ({
  actions: { ...state, ...ownProps },
});

const enhancer = compose(withApollo, withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhancer(SchemaParser);
