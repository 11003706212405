import React, { Component } from 'react';
import { compose, isEmpty } from '@carecloud/cloudpak';
import moment from 'moment';
import { graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { store } from '../../../../models';
import { ContentPage } from '../..';
import { DeleteScheduledPayment } from '../../../../graphql/mutations';
import { GetOneTimePayment } from '../../../../graphql/queries';
import { Payments } from '../../../../constants';
import { parseResult } from '../../../../graphql/utilities';
import { oneTimePaymentContext as OneTimePaymentContext } from '../../../../contexts';

class OneTimePaymentContainer extends Component {
  componentWillReceiveProps({
    oneTimePayment,
    oneTimePayment: { oneTimeDetails },
    getOneTimePayment,
    fetchStart,
    activateLoader,
    loader,
    creditCard: { processing },
    location: { pathname },
    formGroup: { fields },
    datePicker,
  }) {
    if (isEmpty(oneTimePayment.oneTimePayment) && !getOneTimePayment.loading) {
      parseResult({ query: getOneTimePayment, dataField: 'getOneTimePayment', resultHandler: fetchStart });
      store.dispatch.radioInput.updateValue('paymentMethod', Payments.CREDIT_CARD);
    }
    if (!getOneTimePayment.loading && loader.loading && !processing) {
      activateLoader({ loading: false });
    }
    if (pathname.includes('edit_one_time') && oneTimeDetails) {
      const amountChange =
        fields.makePaymentsOneTimeAmount &&
        +fields.makePaymentsOneTimeAmount.replace(/[^\d.]/g, '') !== oneTimeDetails.paymentAmount;
      const dateChange = !moment(oneTimeDetails.paymentDate).isSame(
        moment(datePicker.oneTimePaymentDatePicker).format('L'),
      );
      store.dispatch.makePayments.updateValid(amountChange || dateChange);
    }
  }

  componentWillUnmount() {
    const { activateLoader, clearScreen } = this.props;
    activateLoader({ loading: true });
    clearScreen();
    store.dispatch.formGroup.resetFields();
  }
  deleteScheduledPayment = async _ => {
    const {
      match: { params: { practiceId, oneTimePaymentId } },
      oneTimePayment: { patientId },
    } = this.props;
    store.dispatch.modal.toggleOpen(false);
    store.dispatch.loader.persistLoader({ persist: true });
    const { data: { deleteScheduledPayment: response } } = await this.props.client.mutate({
      mutation: DeleteScheduledPayment,
      variables: {
        input: {
          practiceId,
          patientId,
          oneTimePaymentId: oneTimePaymentId || store.getState().oneTimePayment.oneTimeDetails.oneTimePaymentId,
        },
      },
    });
    store.dispatch.loader.persistLoader({ persist: false });
    if (response === 200) {
      store.dispatch.modal.toggleOpen('cancelConfirmationSuccess');
      store.dispatch.mixpanel.addMetadata({ scheduledPaymentDeleted: true });
    }
  };
  render() {
    const { oneTimePayment: { oneTimePayment } } = this.props;
    return (
      oneTimePayment && (
        <OneTimePaymentContext.Provider value={{ deleteScheduledPayment: this.deleteScheduledPayment }}>
          <ContentPage schemas={oneTimePayment} />
        </OneTimePaymentContext.Provider>
      )
    );
  }
}
const mapStateToProps = ({ oneTimePayment, loader, creditCard, formGroup, datePicker }) => ({
  oneTimePayment,
  loader,
  creditCard,
  formGroup,
  datePicker,
});
const mapDispatchToProps = ({ oneTimePayment, loader }) => ({
  fetchStart: oneTimePayment.fetchStart,
  clearScreen: oneTimePayment.clearScreen,
  activateLoader: loader.activateLoader,
});
const enhancer = compose(
  withApollo,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  graphql(GetOneTimePayment, {
    name: 'getOneTimePayment',
    options: ({
      match: {
        params: { practiceId, paymentPlanId, oneTimePaymentId },
        path,
      },
    }) => ({
      variables: {
        practiceId,
        paymentPlanId,
        oneTimePaymentId,
        ...(path.includes('edit_one_time') ? { state: 'edit' } : {}),
      },
    }),
  }),
);
export default enhancer(OneTimePaymentContainer);
