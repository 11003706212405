import InputMask from 'react-input-mask';
import { isEmpty } from '@carecloud/cloudpak';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { Icon, Header } from '..';
import { SingleDatePicker } from 'react-dates';
import { WEEKDAY_FORMAT, PREVIOUS_MONTH, NEXT_MONTH } from '../../../constants/DatePicker';
import calendarOnlyStyles from './datePickerCalendarOnly.module.scss';
import CustomInfoPanel from '../datePicker/CustomInfoPanel';
import styles from './input.module.scss';
import stylesWithCalendar from './inputWithCalendar.module.scss';
import { isMobileDevice } from '../../services/utilities';

const component = React.memo(
  ({
    id,
    type,
    name,
    label,
    state,
    openDirection = 'OPEN_DOWN',
    inputMode = 'numeric',
    enableCalendar,
    placeholder,
    required,
    requiredLabel,
    onChange,
    value,
    disabled,
    maskInput,
    dispatch,
    getValidRange,
    maskChar,
    theme,
    defaultValue,
    handleValidation,
    validations,
    errors,
    autoFocus,
    supportingText,
    updatedDate,
    radios,
    errorRequiredLabel,
    rawErrors = [],
    displayOneErrorOnly = false,
    disableCopy,
    disablePaste,
  }) => {
    const onChangeWithValueChanger = e => {
      const { value } = e.target;
      const valueAfterInitialSpace = value.replace(/^\s+/, '');
      const replicaEvent = { ...e };
      if ( !value || valueAfterInitialSpace.length > 0 ) {
        let returnValue = valueAfterInitialSpace;
        const regex = /\//gi;
        returnValue = returnValue.replace(regex, '-');
       if (type !=='text' && type !=='password' && moment.locale() === 'es' && moment(returnValue, 'DD-MM-YYYY', true).isValid()) {
          const initialDate = moment(returnValue, 'DD/MM/YYYY');
          const formattedDate = initialDate.format('MM/DD/YYYY');
          replicaEvent.target.value = formattedDate;
        }
        onChange(replicaEvent);
      }
    };
    return (
      <div>
        <div id={`${id}Wrapper`} className={classNames(styles[theme], styles.formGroup)}>
          <label htmlFor={name} className={styles.label}>
            {label}
            {!value && required && <span className={styles.required}>{requiredLabel || required}</span>}
          </label>
          <div className={classNames(enableCalendar ? stylesWithCalendar.inputWrapper : styles.inputWrapper, { [styles.inputWrapperWithRequired]: required })}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {type === 'radios' ? (
              <fieldset
                style={{
                  margin: 'unset',
                  padding: 'unset',
                  border: 'unset',
                  width: '100%',
                }}
              >
                <legend />
                <div id="radios" className={styles.radioGroup}>
                  {radios.map(radio => (
                    <div key={radio} className={styles.radio}>
                      <label htmlFor={radio}>
                        <Header {...{ id: `${radio}-header`, label: radio, type: 'H6' }} />
                      </label>
                      <input
                        type="radio"
                        value={radio}
                        id={radio}
                        key={radio}
                        name="radios"
                        onChange={_ => onChange({ target: { value: radio, id } })}
                      />
                    </div>
                  ))}
                </div>
              </fieldset>
            ) : (
              enableCalendar ? (
                <div className={classNames(stylesWithCalendar.datePickerWrapper)}>
                  <InputMask
                    autoFocus={autoFocus}
                    id={id}
                    mask={maskInput}
                    maskChar={maskChar}
                    onChange={onChangeWithValueChanger}
                    style={{ flex: 1, background: isMobileDevice() && '#FFF' }}
                    placeholder={placeholder}
                    value={value}
                    defaultValue={defaultValue}
                    disabled={disabled}
                    type={type}
                    inputMode={inputMode}
                    onBlur={validations && handleValidation}
                    name={name}
                    onPaste={e => {
                      if (disablePaste) {
                        e.preventDefault();
                      }
                    }}
                    onCopy={e => {
                      if (disableCopy) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <div className={classNames(calendarOnlyStyles.wrapper, { createVisitSummaryDatePickerFocused: state.focus })}>
                    <div className={calendarOnlyStyles.activeCalendarWrapper}>
                      <SingleDatePicker
                        id={id}
                        required
                        openDirection={openDirection}
                        showDefaultInputIcon
                        onOutsideClick={_ => dispatch({ focus: false })}
                        onFocusChange={({ focused }) => { 
                          dispatch({ focus: focused })
                        }}
                        keepOpenOnDateSelect
                        numberOfMonths={1}
                        date={value === '' ? null : moment(value, 'MM/DD/YYYY')}
                        onDateChange={date => {
                          console.log(date)
                          //eslint-disable-next-line object-shorthand
                          onChangeWithValueChanger({ target: { value: moment(date).format('MM/DD/YYYY'), id: id  } })
                        }}
                        isOutsideRange={day => getValidRange(day)}
                        inputIconPosition="after"
                        displayFormat={String()}
                        navPrev={<Icon {...{ id: PREVIOUS_MONTH, theme: PREVIOUS_MONTH, icon: 'arrow-left' }} />}
                        navNext={<Icon {...{ id: NEXT_MONTH, theme: PREVIOUS_MONTH, icon: 'arrow-right' }} />}
                        focused={state.focus}
                        enableOutsideDays
                        hideKeyboardShortcutsPanel
                        weekDayFormat={WEEKDAY_FORMAT}
                        screenReaderInputMessage=""
                        calendarInfoPosition="bottom"
                        renderCalendarInfo={() => (
                          <CustomInfoPanel
                            handleSave={() => {
                              //eslint-disable-next-line object-shorthand
                              onChangeWithValueChanger({ target: { value: value, id: id  } })
                              dispatch({ focus: false })
                            }}
                            handleCancel={() => {
                              //eslint-disable-next-line object-shorthand
                              onChangeWithValueChanger({ target: { value: '', id: id  } })
                              dispatch({ focus: false })
                            }}
                            borderPosition='borderBottom' 
                            labels={{save: 'Save', cancel: 'Cancel'}}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <InputMask
                  autoFocus={autoFocus}
                  id={id}
                  mask={maskInput}
                  maskChar={maskChar}
                  onChange={onChangeWithValueChanger}
                  style={{ flex: 1, background: isMobileDevice() && '#FFF' }}
                  placeholder={placeholder}
                  value={value}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  type={type}
                  onBlur={validations && handleValidation}
                  name={name}
                  onPaste={e => {
                    if (disablePaste) {
                      e.preventDefault();
                    }
                  }}
                  onCopy={e => {
                    if (disableCopy) {
                      e.preventDefault();
                    }
                  }}
                />
              )
            )}
          </div>
        </div>
        {type === 'signature' && <div className={styles.signatureTime}>{updatedDate}</div>}
        {!isEmpty(rawErrors)
          ? rawErrors.map(error => (
            <div className={styles.errorMessage} key={error}>
              {errorRequiredLabel}
            </div>
            ))
          : errors &&
            !displayOneErrorOnly &&
            errors.map(error => (
              <div key={error} className={styles.errorMessage}>
                {error}
              </div>
            ))}
        {displayOneErrorOnly && (
          <div key={errors[0]} className={styles.errorMessage}>
            {errors[0]}
          </div>
        )}
        {supportingText && (
          <div className={classNames(styles.supportingText, { [styles.supportingTextGreen]: value })}>
            {supportingText}
          </div>
        )}
      </div>
    );
  },
);

export const Input = component;
