import React from 'react';
import { isEmpty } from '@carecloud/cloudpak';
import { Nav } from './main_nav/Nav';
import { PageLoader } from '../ui/page_loader/pageLoader';
import styles from './Layout.module.scss';
import SchemaParser from '../services/SchemaParser';
import { store } from '../../models';

const layout = ({
  props: {
    children,
    loading,
    persist,
    isSmallScreen,
    currentTime,
    layout: {
      layoutScreen: { pageFooter, pageHeader ,pageFooterforMobile},
      layoutScreen,
    },
    dropdown: { dropDownOpen },
    openDropdown,
  },
}) => (
  // If layout screen is empty and there are other screens loading, show the page loader
  // Otherwise, show the Layout screen
  <div className={styles.layoutWrapper}>
    {!isEmpty(layoutScreen) && (
      <div>
        <Nav dropDownOpen={dropDownOpen} openDropdown={openDropdown} schema={pageHeader} />
        {/* Display routes found in app.jsx */}
        {children}
        {/* Render page footer across all pages */}
        {(isSmallScreen && (
         store.getState().settings.settingsSection==="settingsPersonal" ||
         store.getState().settings.settingsSection==="settingsDemographics" ||
         store.getState().settings.settingsSection==="settingsDocuments" ||
         store.getState().settings.settingsSection==="settingsNotifications" ||
         store.getState().settings.settingsSection==="settingsAuthentication" ||
         store.getState().settings.settingsSection==="settingsPhoto" ||
         store.getState().settings.settingsSection==="settingsPayment"))
        ?<SchemaParser schema={pageFooterforMobile} /> : !isSmallScreen &&
        (
          <div className={styles.timer}>
            <SchemaParser schema={pageFooter} />
            <p className={styles.timerP}>Time: {currentTime}</p>
          </div>
        )}
      </div>
    )}
    {(loading || persist) && <PageLoader loading={loading} />}
  </div>
);
export const Layout = layout;
