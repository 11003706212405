export const PROBLEMATIC_ROUTES = ['appointments', 'payments'];
export const MODELS_TO_CLEAR = [
  'appointments',
  'myHealth',
  'payments',
  'makePayments',
  'messages',
  'formHistory',
  'surveys',
  'settings',
  'shop',
  'support',
  'activityLog',
  'appointmentCheckOut',
  'appointmentCheckIn',
  'appointmentPrepayment',
  'availableHours',
  'inputDropdown',
];
export const ROUTES_TO_MODELS = {
  '/appointments': 'appointments',
  '/my_health': 'myHealth',
  '/payments': 'payments',
  '/messages': 'messages',
  '/form_history': 'formHistory',
  '/surveys': 'surveys',
  '/profile_settings/personal': 'settings',
  '/shop': 'shop',
  '/support': 'support',
  '/activity_log': 'activityLog',
};
export const NAV_AVATAR = 'navAvatar';
