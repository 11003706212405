import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import styles from './partialPayment.module.scss';
import { formatMoney, isMobileDevice } from '../../services/utilities';
import { Payments } from '../../../constants/index';

const PropsDependentStyledPartialPaymentPlanContainer = styled.div`
  box-sizing: border-box;
  height: 70px;
  width: ${props => (props.isMobile ? '100%' : '340px')};
  display: flex;
  background-color: #f5a623;
  border-radius: 6px;
  position: relative;
  top: -5px;
  color: #fff !important;
  padding: 6px 15px 10px 15px;
  ${props => props.isMobile && `margin-top: 25px`};
  question: '';
  & input {
    font-family: 'Metropolis';
  }
  & input::-webkit-input-placeholder {
    color: #fce1bc;
    text-align: right;
    font-family: 'Metropolis';
  }
  & h4 {
    line-height: 0.5;
    font-weight: 400;
  }
`;

const PropsDependentStyledInputContainer = styled.input`
  background: transparent;
  border: none;
  text-align: right;
  color: #fff;
  font-size: 20px;
  width: ${props => (props.isMobile ? '100%' : '133px')};
  margin-top: 2px;
`;

const isMobile = isMobileDevice();

const partialPayment = React.memo(
  ({
    paymentType,
    handleChange,
    error,
    isValid,
    displayInput,
    labels,
    validator,
    validation: { errorMessage },
    option,
  }) =>
    option === paymentType && (
      <div>
        <PropsDependentStyledPartialPaymentPlanContainer
          className={classNames(styles.partial, {
            [styles.paymentPlan]: option === Payments.PAYMENT_PLAN,
          })}
          isMobile={isMobile}
        >
          <div className={styles.question}>
            <h4>{labels.howMuch}</h4>
            {labels.minimum && <h4>{labels.minimum}</h4>}
          </div>
          <PropsDependentStyledInputContainer
            onChange={handleChange}
            onBlur={e => validator({ input: e, isBlur: true })}
            placeholder={`$${formatMoney({ amount: 0.0 })}`}
            value={displayInput && `$${displayInput}`}
            isMobile={isMobile}
          />
        </PropsDependentStyledPartialPaymentPlanContainer>
        {error.display && !isValid && (
          <div className={styles.error}>
            {option === Payments.PAYMENT_PLAN
              ? error.paymentPlanErr
              : errorMessage}
          </div>
        )}
      </div>
    ),
);

export const PartialPayment = partialPayment;
