export const loader = {
  state: { loading: true, persist: false },
  reducers: {
    activateLoader: (state, { loading }) => ({
      ...state,
      loading,
    }),
    persistLoader: (state, { persist }) => ({
      ...state,
      persist,
    }),
    show: state => ({
      ...state,
      persist: true,
    }),
    hide: state => ({
      ...state,
      persist: false,
    }),
  },
};
