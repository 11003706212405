export const radioInput = {
  state: {
    selectBusiness: null,
    selectBusinessValue: null,
    payeezy:null,
    cloverapi:null,
  },
  reducers: {
    updateValue: (state, key, value) => ({
      ...state,
      [key]: value,
    }),
    updateValueId: (state, key, value) => ({
      ...state,
      [key]: value,
    }),
    resetRadioInput: state => ({
      selectBusiness: state.selectBusiness,
      selectBusinessValue: state.selectBusinessValue,
      payeezy:state.payeezy,
      cloverapi:state.cloverapi,
    }),
    clearSelectedBusiness: _ => ({
      selectBusiness: null,
      selectBusinessValue: null,
      payeezy:null,
      cloverapi:null,
    }),
    resetPaymentMethod: state => ({ ...state, paymentMethod: null }),
    clearSelectedCard: state => ({ ...state, selectedExistingCard: null }),
    toggleNewCardForm: (state, boolean) => ({ ...state, newCardForm: boolean }),
    deepHide: (state, { id }) => ({ ...state, hide: id }),
  },
};
