export const VERTICAL = 'VERTICAL';
export const HORIZONTAL = 'HORIZONTAL';
export const GRID = 'GRID';
export const EMPTY_SECTION = 'EMPTY_SECTION';
export const BUTTON = 'BUTTON';
export const PENDING = 'PENDING';
export const CHECKED_IN = 'CHECKED-IN';
export const CHECKED_OUT = 'CHECKED-OUT';
export const REQUESTED = 'REQUESTED';
export const CANCELLED = 'CANCELLED';
export const _BLANK = '_blank';
export const _PARENT = '_parent';
export const DEVELOPMENT = 'development';
export const FETCH_START = 'fetchStart';
export const REFETCH = 'refetch';
export const SHORT_MONTHS_EN = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
export const SHORT_MONTHS_ES = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sept', 'Oct', 'Nov', 'Dic'];
